.PrimaryButton {
  font-size: 14px;
  height: auto;
  color: #362148;
  background: #C0B4FF;
  border: 0;
  padding: 8px 25px;
  border-radius: 50px;
  font-weight: normal;
}

.PrimaryButton:disabled {
  background: #ccc !important;
  color: #000 !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

.PrimaryButton:hover, .PrimaryButton:focus{
  color: #fff;
  background-color: #362148;
  border-color: #362148;
}

.FilterResetButton {
  background: none;
  border: none;
  color: red;
  text-decoration: underline !important;
  font-size: 13px;
  font-weight: normal;
}

.FilterResetButton:hover {
  color: red !important;
}

.FilterResetButton span {
  color: inherit !important;
  text-decoration: inherit;
}