.ReportFormLabel {
  font-size: 12px;
  color: #362148;
}

.ReportFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.ReportFormField:disabled {
  color: #999999 !important;
}

.job-swms-preview-container * {
  font-family: 'Poppins', sans-serif !important ;
}

.report-preview-actions {
  visibility: hidden;
}

.report-preivew-photo-box:hover .report-preview-actions {
  visibility: visible;
}