.WeekTitleWrapper,
.DayTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.StatusWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.GreenStatusDot {
  border-radius: 50%;
  background: #03A791;
  height: 1rem;
  width: 1rem;
  margin-right: 5px;
  border: 0.5px solid #555
}

.YellowStatusDot {
  border-radius: 50%;
  background: rgb(209, 209, 19);
  height: 1rem;
  width: 1rem;
  margin-right: 5px;
  border: 0.5px solid #555
}

.BlackStatusDot {
  border-radius: 50%;
  background: #333;
  height: 1rem;
  width: 1rem;
  margin-right: 5px;
  border: 0.5px solid #555
}

.FooterActions {
  align-items: flex-end;
  margin-bottom: 10px;
}

.DayViewStaffWrapper {
  display: flex;
  flex-direction: row;
}

.ProfileIcon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 5px 5px 0 0;
  border: 1px solid #03A791;
}

.SideActions {
  position: absolute;
  right: 10px;
}

.SideActions > button ~ i {
  margin-left: 1rem;
}