.MainFormFieldsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
}

.MainFormFieldsContainer fieldset {
  margin: 10px 30px 10px 0;
}

.FormFieldsRow {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
}

.ControlsContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: scroll;
}

.ControlsContainer_JobSwms {
  display: flex;
  flex-flow: row;
  flex-wrap: unset;
  /* justify-content: space-between; */
  overflow-y: unset;
  overflow-x: unset;
}

.TextEditor {
  height: 100%;
  min-width: 25vw;
  width: 25vw;
  max-width: 25vw;
}

.TextEditor > label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.Dropdowns {
  min-width: 9.5rem;
  width: 9.5rem;
  max-width: 9.5rem;
}

.heading{
  height: 100%;
  min-width: 14vw;
  padding: 10px;
  background-color: rgb(238 238 241);
  width: 14vw;
  max-width: 14vw;
}
.ActivityHeader {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: 600
}

.SaveButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ControlsContainer>div {
  margin-right: 1rem;
}

.RiskLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MultiselectBox {
  min-width: 8rem;
  width: 8rem;
  max-width: 8rem;
}

.DropdownHeader {
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
}

.DropdownHeader_JobSwms {
  font-weight: 600;
  font-size: 0.9rem;
}

.MultiSelect>fieldset>button {
  min-width: 10rem;
  border: 1px solid #eff0f1;
  border-radius: 5px;
  max-width: 10rem;
  text-align: left;
}

.StaffFormLabel {
  font-size: 12px;
  color: #362148;
}

.JobsFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.RfqFormField:disabled {
  color: #999999 !important;
}

.Title {
  font-size: 13px;
  color: #000 !important;
  user-select: none;
  font-weight: 700;
}

.Content {
  font-size: 13px;
  color: #000 !important;
}

.TabsCover {
  border: 1px solid #3621484d;
  padding: 6px;
  border-radius: 8px; 
}

.SwmsFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}
