.BlueText {
  color: #337ab7 !important;
}

.TableSecondaryText {
  color: #362148;
  font-weight: bold;
}

.tableTitle {
  color: #337ab7;
  font-size: 16px;
  font-weight: 600;
}

.QuoteFormLabel {
  font-size: 12px;
  color: #362148;
}

.QuoteFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.PageTitle {
  font-size: 27px !important;
  font-weight: 700 !important;
  margin-bottom: 8px !important;
  font-family: 'Lato', sans-serif;
}

.Button {
  font-size: 14px;
  padding: 8px 10px;
}

.FeedbackContainer {
  font-size: 13px;
  color: #000000;
}

.clientSignOffContainer {
  font-size: 13px;
  text-align: initial !important;
  color: #000000;
  font-family: 'Lato', sans-serif !important;
}

.SmallGreyButton {
  background: #999;
  padding: 4px 6px;
  height: auto;
  border-radius: 3px;
}

.textArea {
  width: -webkit-fill-available;
  width: -moz-available;
  height: auto !important;
  border: 1px solid #ccc;
  border-radius: 1px;
  resize: none;
}

.textArea:hover,
.textArea:focus {
  border-color: #16abff !important;
}

.GreenSubmitButton {
  background: #3ac47d;
  border-radius: 4px;
  padding: 8px 12px;
  height: auto;
}

.GreyBox {
  padding: 10px 15px !important;
  background: #f9f9f9 !important;
}

.TermsTitle {
  font-size: 15px;
}

.GreenIcon {
  color: #3ac47d;
  font-size: 16px;
  cursor: pointer;
}

.signatureImage {
  max-height: 50px;
  max-width: 100px;
}

.overlayBox {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  background-color: #ffffff90;
  z-index: 2;
}

.BorderBottom{
  border-bottom: 1px solid #eaeaea;
}

.BorderBottom:last-child {
  border-bottom: 0px;
}

.SecondaryText{
  color: #362148;
}

.FilterButton {
  outline: none;
  height: auto;
  background: #362148;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: normal;
}