.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 14px;
  border-radius: 50px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 11px;
    text-align: center;
    line-height: 2px;
    display: block !important;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #F25F5C;
}
.strength-Fair::-webkit-progress-value {
  background-color: #FFE066;
}
.strength-Good::-webkit-progress-value {
  background-color: #247BA0;
}
.strength-Strong::-webkit-progress-value {
  background-color: #70C1B3;
}