.calendar-container-2 *,
.calendar-modal-window * {
  font-family: 'Poppins', sans-serif !important;
  text-align: initial;
}

.calendar-container-2 {
  background-color: #F0EFEE;
  padding: 16px;
  height: calc(100vh - 70px);
  border-radius: 50px;
}

.cs-table.cs-table-v2.table-calendar-v2 thead tr th,
.cs-table.cs-table-v2.table-calendar-v2 tbody tr td {
  font-size: 13px !important;
}

.cs-table.cs-table-v2.table-calendar-v2 thead tr,
.cs-table.cs-table-v2.table-calendar-v2 tbody tr {
  height: auto;
}

.calendar-v2-datepicker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.calendar-v2-datepicker .ant-input {
  background-color: #eaeaea;
  height: 34px;
  border: none;
  border-radius: 8px !important;
  font-size: 12px;
}

.calendar-v2-datepicker .ant-calendar-picker-clear {
  background-color: #eaeaea;
}

.adhoc-photo-uploader {
  height: 130px !important;
  background: #eaeaea !important;
}

.adhoc-photo-uploader.x-small-upload {
  height: 40px !important;
}

.adhoc-photo-uploader.x-small-upload span.ant-upload.ant-upload-btn {
  padding: 6px 0px !important;
}

/* 
.selectable-selectbox {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
} */


/* shift resourcing */

.main * {
  font-family: 'Poppins', sans-serif;
}

.selectable-selectbox {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
}

.hours-holder {
  display: flex;
  position: relative;
  flex-flow: row;
  align-items: stretch;
}

.hourColumn {
  min-height: 60px;
  /* width: 80px; */
  flex: 1;
  box-sizing: border-box;
  border-left: 1px solid #f2f2f2;
  position: relative;
  user-select: none;
}

.hourColumn.selected {
  background-color: #362148;
  color: #fff;
}

.dayHeading {
  width: 80px;
  height: 60px;
  border-right: 1px solid #f2f2f2;
  position: relative;
}

.dayHeadingContainer {
  display: flex;
  align-items: center;
  border-left: 1px solid #f2f2f2;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2000;
}

.dayContainer {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  width: calc(25 * 80px);
  border-right: 1px solid #f2f2f2;
}

.dayContainer:nth-child(1) {
  border-top: 1px solid #f2f2f2;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shift-group {
  background-color: green;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
  box-sizing: border-box;
  padding: 0px 2px;
  width: 80px;
  font-size: 13px;
}

.ShiftTaskEvent {
  height: 24px;
  position: absolute;
  background: rgb(56, 35, 130);
  display: flex;
  padding: 2px;
  border-radius: 6px;
  font-size: 13px;
  border: 1px solid #fff;
  cursor: pointer;
  z-index: 19;
  background-size: 7.0px 7.0px !important;
}

.ShiftTaskEventName {
  display: flex;
  position: sticky;
  left: 85px;
  white-space: nowrap;
  color: #fff;
  font-size: 13px;
  align-items: center;
}

.selecting-hours {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  font-size: 13px;
}

.dayTimeContainer {
  flex: 1;
}

.hoursHeader .hourColumn {
  color: #362148 !important;
  font-size: 13px !important;
}

.dayHeading {
  color: #362148 !important;
  font-size: 13px !important;
}

.calendar-unbooked-rotated {
  width: 250px;
  transform: rotate(90deg) translate(10px, -20px);
  transform-origin: top left;
  animation: rotate-heading 0.3s;
  position: absolute;
}

@keyframes rotate-heading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg) translate(10px, -20px);
  }
}

.calendar-v2-date-picker,
.calendar-v2-time-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.calendar-v2-date-picker .ant-input,
.calendar-v2-time-picker .ant-time-picker-input {
  background-color: #eaeaea;
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 8px !important;
  font-size: 12px;
}

.calendar-v2-date-picker .ant-input:focus,
.calendar-v2-time-picker .ant-time-picker-input:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
}

.calendar-v2-date-picker.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #ddd !important;
}

.calendar-v2-date-picker .ccalnd-icon,
.calendar-v2-time-picker .ant-time-picker-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #c0b4ff !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.calendar-v2-time-picker .ant-time-picker-clock-icon {
  color: #c0b4ff !important;
}

.calendar-v2-date-picker .ant-calendar-picker-clear,
.calendar-v2-time-picker .ant-time-picker-clear {
  background-color: #eaeaea;
  right: 30px;
  width: auto;
  background: transparent !important;
}

.calendar-v2-range-picker .ant-input {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
  font-size: 13px !important;
}

.calendar-v2-range-picker .ant-input:hover {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
}

.calendar-v2-range-picker input::placeholder {
  color: #000 !important;
}

.calendar-v2-range-picker input {
  padding: 4px 0px !important;
}

.calendar-v2-range-picker .ant-calendar-picker-icon {
  color: #36214880 !important;
}

.calendar-v2-range-picker .ant-input:focus {
  box-shadow: none !important;
}