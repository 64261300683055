




.container {
    max-width: 1200px;
}

.col-sm-1, 
.col-sm-2, 
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.container-fluid{
    padding: 0px;
}
.row{margin: 0px;}

.heading-jobSWMS{
  font-size: 22px !important;
  font-weight: 600;
}

.headbg {
    border-bottom: #595959 solid 2px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}


/*start*/
.form-group input{
    height: 22px; 
    border-radius: 0;
    font-size: 12px;
    padding: 0;
    padding-left: 2px;
}
.form-group textarea{
    border-radius: 0;
    font-size: 12px;
}
.form-group input[readonly], .form-group textarea[readonly] {
    background-color: #fff;
    opacity: 1;
    margin-top: 0px;
}
.form-group input:focus, .form-group textarea:focus{box-shadow: none;}
.form-group input[type="checkbox"][readonly] {
  pointer-events: none;
  height: auto;
  width: auto;
}
.form-group.flexlabel label.role {
    max-width: 68px;
}
/*end*/
.DraftEditor-root{
    height: 96px;
}

.previewSwmsMain .logo {
    height: 46px;
    margin-top: 12px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    position: relative;
}
.logo img {
    width: 100%;
    max-width: 100%;
}
.form-group.flexlabel{
    display: flex;
    display: -webkit-flex;
    margin-bottom: 4px;
}
.previewSwmsMain{
  min-height: 575px;
  overflow-y: auto;
  overflow-x: auto;
  height: 85vh;
  color: #000 !important;
  font-family: sans-serif !important;
}

.swms-preview-body {
  min-width: 1420px;
}

.previewSwmsMain p{
  font-family: sans-serif !important;
  font-size: 12px;
  margin: 0px;
}

@media (min-height: 768px) {
  .previewSwmsMain{
    height: 575px;
  }
}

@media (min-height: 900px) {
  .previewSwmsMain{
    height: 75vh;
  }
}

@media (min-height: 1000px) {
  .previewSwmsMain{
    height: 80vh;
  }
}

.m25{ margin-top:25px;}

.h2_risk-matrix{ font-size:14px; font-weight:bold; text-transform: uppercase;}

.table_risk_matrix > thead > tr > th {
    text-align: center !important;
	vertical-align: middle !important;
}

.table_risk_matrix td, .table_risk_matrix th{
   padding:5px;  
   border: 1px solid #000 !important;vertical-align: middle !important;text-align: center !important;background-color: #b7e4df; font-weight:bold;}



.subbg{ 
    background-color: #e6f6f5 !important;
} 

.lr{ 
    background-color: #66ff33 !important;
} 
.mr{ 
    background-color: #ffff01 !important;
}
.hr{ 
    background-color: #ffc000 !important;
} 
.er{ 
    background-color: #fe0000 !important;
} 
.vl{ 
    background-color: #01ffff !important;
}  


.w30{ width:30%;}
.w20{ width:20%;}



ul.square {list-style-type: none; padding:0; line-height:18px !important; vertical-align:middle; }

.lrsquare{background-color: #66ff33; width:14px;height:14px;display:inline-block; margin-right:15px;} 
.mrsquare{width:14px;height:14px;background-color: #ffff01;display:inline-block; margin-right:15px;}
.hrsquare{width:14px;height:14px;background-color: #ffc000;display:inline-block;margin-right:15px;} 
.ersquare{width:14px;height:14px;background-color: #fe0000;display:inline-block; margin-right:15px;} 
.vlsquare{width:14px;height:14px;background-color: #01ffff;display:inline-block;margin-right:15px;} 

.footer_block{ background:#e6f6f5; display:block;display: inline-block; padding:5px;width: 100%;}
.pt15{ padding-top:15px; font-weight:700;}

.risk-matrix-txt {
  padding-top: 17px;
  font-weight: 700;
}

.swmsPreview-footer p {
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  padding-top: 8px;
  font-family: sans-serif;
}

.previewswms-textarea {
  font-size: 12px !important;
}

.previewSwmsMain .form-group label {
    max-width: 110px; 
    width: 100%; 
    background: #105a92; 
    text-align: right;
    color: #fff; 
    font-size: 10px;
    font-weight: 500;
    padding-right: 5px; 
    padding-left: 3px; 
     margin: 0%;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: end;
}
.heading {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
    margin-bottom: 0px;
}
.heading p {
    font-size:22px;
    font-weight: 600;
}
.form-group label.half-width {
    min-width: 266px;
    max-width: 100%;
    width: fit-content;
    justify-content: center;
}
.content-checkbox {
    border: #ccc solid thin;
    padding: 6px;
}

.box-check ul {
    margin: 0px;
    padding: 0px;
}
.box-check ul li {
    line-height: normal;
    margin-bottom: 2px;
    /* display: inline-block; */
    float: left;
    width: 47.6%;
    padding-left: 30px;
    font-size: 12px;
}
.form-check{
    text-align: left;
}
.box-check ul li .form-check label {
    all: inherit;
    padding: 0;
    /*cursor: pointer;*/
}
.box-check.box-50 li {
    width: 49%;
}
.box-check.box-100 li {
    width: 100%;
}
.addplus i{margin-left: 10px;}
.deleterow, .addplus {
    font-size: 14px;
}

.service-farm {
    display: flex;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    margin-top: 30px;
}
.service-farm img {
    width: 100%;
    max-width: 160px;
}
.service-farm p {
    font-weight: 400;
    margin-right: 8px;
    margin-top: 6px;
    font-size: 16px;
}




/*custom-checkbox*/
.custom-check{
    position: relative;
}
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  background-color: #eee;
}
.custom-check:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.custom-check input:checked ~ .checkmark {
  background-color: #c0b4ff;
  border: #ccc solid 1px;
}

/*.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-check .checkmark:after{
    left: 2px;
    top: 5px;
    width: 9px;
    height: 2px;
    background: #fff;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(-45deg);
}
.custom-check .checkmark:before{
    position: absolute;
    content: "";
    display: block;
    right: 2px;
    top: 5px;
    width: 9px;
    height: 2px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.custom-check input:checked ~ .checkmark:after {
  display: block;
}*/


.footbg{
    margin-top: 10px;
    border-top: #595959 solid 2px;
    padding-top: 20px;
    clear: both;
}


/*end*/






@media all and (max-width: 991px){
.heading p{font-size: 18px;}
.box-check ul li{width: 49%;}
}

@media all and (max-width: 767px){
.heading p{font-size: 16px; margin-bottom: 15px;}
.heading{flex-wrap: wrap;}
.box-check ul li, .box-check.box-50 li{width: 100%;}
}



@media all and (max-width: 460px){

}




@media print{
.table .thead-dark th{
    background-color: #343a40 !important;
    border-color: #454d55 !important;
    color: #fff !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

}

/*page 3*/
.color-list ul {
    margin: 0px;
    padding: 0px;
}

.color-list ul li {
    list-style: none;
    display: inline-block;
    margin-right: 0px;
    background: #105a92;
    padding: 12.5px 26px;
    font-size: 13px;
    color: #fff;
    position: relative;
    width: 130px;
    text-align: center;
}
.color-list ul li::after{
    position: absolute;
    content: "";
    display: block;
    width: 0;
    height: 0;
    background: #fff;
    right: 0px;
    top: 0;
    border: 22px solid transparent;
    border-left-color: #105a92;
    border-right: 0;
}
.color-list ul li::before{
    position: absolute;
    content: "";
    display: block;
    width: 0;
    height: 0;
    background: #105a92;
    left: 0px;
    top: 0;
    border: 22px solid transparent;
    border-left-color: #ffffff;
    border-right: 0;
}
.color-list ul li:first-child::before {
    border: none;
}

.color-list ul li.list1{background: #3e7afe; position: relative;}
.color-list ul li.list1::after{
    border: 22px solid transparent;
    border-left-color: #3e7afe;
    border-right: 0;
}
.color-list ul li.list2{background: #16ad69;}
.color-list ul li.list2::after{
    border: 22px solid transparent;
    border-left-color: #16ad69;
    border-right: 0;
}
.color-list ul li.list2::before{
    background: #16ad69;
}
.color-list ul li.list3{background: #14d682;}
.color-list ul li.list3::after{
    border: 22px solid transparent;
    border-left-color: #14d682;
    border-right: 0;
}
.color-list ul li.list3::before{
    background: #14d682;
}
.color-list ul li.list4{background: #ffd705;}
.color-list ul li.list4::after{
    border: 22px solid transparent;
    border-left-color: #ffd705;
    border-right: 0;
}
.color-list ul li.list4::before{
    background: #ffd705;
}
.color-list ul li.list5{background: #f89645;}
.color-list ul li.list5::after{
    border: 22px solid transparent;
    border-left-color: #f89645;
    border-right: 0;
}
.color-list ul li.list5::before{
    background: #f89645;
}
.color-list ul li.list6{background: #f03015;}
.color-list ul li.list6::after{
    border: 22px solid transparent;
    border-left-color: #f03015;
    border-right: 0;
}
.color-list ul li.list6::before{
    background: #f03015;
}
.hoc {
    background: #0070c0;
    text-align: center;
    padding: 10px;
    color: #fff;
}
.title-most {
    font-size: 12px;
    padding-top: 8px;
}


.risk-title {
    background: #105a92;
    padding: 8px;
    color: #fff;
    max-width: 198px;
}
.mytable td.pbg1, .mytable td.pbg2, .mytable td.pbg3 {
    font-size: 9px;
}
.mytable td {
    text-align: center;
    vertical-align: middle;
    padding: 8px;
    font-size: 9px;
    border: #000 solid thin;
}
.mytable td.creams, .mytable2 td.creams{
    background: #fff2cc;
}
.mytable td.yellows, .mytable2 td.yellows{
    background: #ffff00;
}
.mytable td.oranges, .mytable2 td.oranges{
    background: #ffc000;
}
.mytable td.oranges2, .mytable2 td.oranges2{
    background: #ff6600;
}
.mytable td.reds, .mytable2 td.reds{
    background: #ff0000;
}



.mytable2 td.pbg1 {
    padding: 8px;
}
html .mytable4.table tbody td {
    padding: 4px;
    text-align: left;
    font-size: 13px;
    border: #000 solid thin;
}







/*custom-table*/
.parent-table{
    float: left;
    width: 100%;
    margin-bottom: 15px;
}
.left-table{
    float: left;
    width:39.9%;
    height: auto;
}
.black.blue-width {
    width: 228px;
    background: #0070c0;
}
.black {
    background: #595959;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    border: #000 solid thin;
    border-bottom: none;
}
.blues {
    background: #0070c0;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    border: #000 solid thin;
}
.grenbox{
    background: #00b050;
    float: left;
    color: #fff;
    width: 100%;
    text-align: center;
    border-right: #000 solid thin;
    border-left: #000 solid thin;
}
.grenbox .prob{
    width: 25%;
    float: left;
    padding: 5px;
    font-size: 10px;
    border-right: #000 solid thin;
}
.grenbox .prob + .prob {
    border-right: none;
}
.grenbox .prob.descrp{
    width: 351px;
}
.table-all{
    float: left;
    width: 100%;
    height: 201px;
    background: #fff;
    border: #000 solid thin;
    border-bottom: none;
}
.all-box {
    float: left;
    width: 100%;
}
.all-box .box-1 {
    width: 25%;
    height: 40px;
    float: left;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 9px;
}
.all-box .box-1 + .box-1 {border-right: #000 solid thin; border-bottom: #000 solid thin;}
.all-box .box-1:last-child{border-right: none;}
.all-box .box-1.borders{
    color: #000; 
    font-size: 12px; 
    padding-top: 5px; 
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
}
.all-box .box-1.reds{background: #f20; }
.all-box .box-1.orange{background: #ff6600;}
.all-box .box-1.orange2{background: #ffc000;}
.all-box .box-1.yellow{background: #ffff00;}
.all-box .box-1.cream{background: #fff2cc;}


.right-table{
    float: left;
    width: 55.9%;
    height: auto;
    background: transparent;
    margin-left: 0%;
}
.box-1.w-full {
    width: 239px;
}


.all-box .box-2 {
    width: 20%;
    height: 40px;
    float: left;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 9px;
    padding-top: 3px;
}
.all-box .box-2 + .box-2 {border-right: #000 solid thin; border-bottom: #000 solid thin;}
.all-box .box-2:last-child{border-right: none;}
.all-box .box-2.borders{
    color: #000; 
    font-size: 12px; 
    padding-top: 5px; 
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
}
.all-box .box-2.w-full {
    width:40%;
}
.grenbox .newitile.custom {
    width: 35%;
}
.grenbox .newitile:last-child{border-right: none;}
.grenbox .newitile {
    width: 20%;
    float: left;
    padding: 5px;
    font-size: 10px;
    border-right: #000 solid thin;
}
.all-box .box-2.reds{background: #f20; }
.all-box .box-2.orange{background: #ff6600;}
.all-box .box-2.orange2{background: #ffc000;}
.all-box .box-2.yellow{background: #ffff00;}
.all-box .box-2.cream{background: #fff2cc;}








.left-table-long {
    float: left;
    width: 67.3%;
    height: auto;
    position: relative;
}
.left-table-long::before {
    content: "";
    display: block;
    position: absolute;
    right: -4px;
    width: 5px;
    top: 0;
    background: #ffffff;
    bottom: 0px;
    margin-left: -41px;
    z-index: 999;
}
.left-table-long::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    top: 0;
    background: #000;
    bottom: -0px;
    margin-left: -41px;
    z-index: 99;
}

.all-box .box-3 {
    width: 17%;
    height: 26px;
    float: left;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 9px;
    padding-top: 3px;
}
.all-box .box-3 + .box-3 {border-right: #000 solid thin; border-bottom: #000 solid thin;}
.all-box .box-3.borders:nth-child(2){border-left: #000 solid thin;}
.all-box .box-3.borders {
    color: #000;
    font-size: 12px;
    padding-top: 5px;
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
    border-top: #000 solid thin;
}
.all-box .box-3.reds{background: #f20; }
.all-box .box-3.orange{background: #ff6600;}
.all-box .box-3.orange2{background: #ffc000;}
.all-box .box-3.yellow{background: #ffff00;}
.all-box .box-3.cream{background: #fff2cc;}
.all-box .box-3.greens{background: #00b050; }
.bluess {
    background: #0070c0;
    float: left;
    color: #fff;
    width: 100%;
    text-align: center;
    border-top: #000 solid thin;
    border-right: #000 solid thin;
}
.bluess .prob{
    width: 406.5px;
    float: left;
    padding: 5px;
    font-size: 12px;
    text-align: left;
    position: relative;
}
.bluess .prob .risk-box {
    position: absolute;
    background: #0070c0;
    left: 0;
    top: 0px;
    width: 33%;
    height: 54px;
    border-right: #000 solid thin;
    font-size: 14px;
    text-align: center;
    padding-top: 7px;
    border-bottom: #000 solid thin;
    border-left: #000 solid thin;
}
.bluess .prob.custom{
    width: 36%;
}
.all-box-parent {
    float: left;
    color: #fff;
    width: 100%;
    background:transparent;
}
.all-box-parent .left-text {
    float: left;
    color: #000;
    width: 8%;
    background: #0070c0;
    height: 200px;
    position: relative;
    border-left: #000 solid thin;
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
}
.all-box-parent .left-text p {
    content: "";
    display: block;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 37%;
    left: 0;
    margin-left:-48px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
    white-space: nowrap;
}
.all-box-parent .right-text{
    float: left;
    color: #000;
    width: 92%;
    border-right: #000 solid thin;
}
.all-box .box-3.blue-new {
    background: #0070c0;
    border: none;
    width: 15%;
}
.all-box-parent .parent-right .box-3 {
    width: 18.2%;
    float: left;
    height: 40px;
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
}
.all-box-parent .parent-right .box-3 + .box-3{border-right: #000 solid thin; border-bottom: #000 solid thin;}
.all-box-parent .parent-right .box-3:last-child{border-right: none;}
.all-box-parent .parent-right .box-3.custom {
    width: 66px;
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
}
.all-box-parent .right-text .parent-right:last-child .custom {
    border-bottom: #000 solid thin;
}

.all-box-parent .parent-right .box-3.reds{background: #f20; }
.all-box-parent .parent-right .box-3.orange{background: #ff6600;}
.all-box-parent .parent-right .box-3.orange2{background: #ffc000;}
.all-box-parent .parent-right .box-3.yellow{background: #ffff00;}
.all-box-parent .parent-right .box-3.cream{background: #fff2cc;}
.all-box-parent .parent-right .box-3.greens{background: #00b050; }



.right-table-short {
    float: left;
    width: 27.1%;
    height: auto;
    background: transparent;
    margin-left:4%;
}
.all-box .box-4 {
    width:65%;
    float: left;
    background: #f20;
    height: 82.5px;
    color: #000;
    border: #000 solid thin;
    border-bottom: none;
    text-align: center;
    padding: 28px;
}
.all-box .box-4:nth-child(5), .all-box .box-4:nth-child(6){
    border-bottom: #000 solid thin;
}
.all-box .box-4.custom {
    width: 35%;
    border-right: none;
}
.all-box .box-4.reds{background: #f20; }
.all-box .box-4.orange{background: #ff6600;}
.all-box .box-4.orange2{background: #ffc000;}
.all-box .box-4.yellow{background: #ffff00;}
.all-box .box-4.cream{background: #fff2cc;}
/*end*/

















@media all and (min-width: 1366px) and (max-width: 1710px){
.mytable td.pbg1, .mytable td.pbg2, .mytable td.pbg3 {
    font-size: 9px;
}
.mytable td {
    text-align: center;
    vertical-align: middle;
    padding: 8px;
    font-size: 9px;
    border: #000 solid thin;
}

}







@media print {
.mytable td.pbg1, .mytable2 td.pbg1{background: #595959 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}
.mytable td.pbg2{background: #3e7afe !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}
.mytable td.pbg3{background: #00b050 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}

.mytable td.exactprint1{background: #ff0000 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}
.mytable td.exactprint2{background: #ff6600 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}
.mytable td.exactprint3{background: #ffc000 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}
.mytable td.exactprint4{background: #ffff00 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}
.mytable td.exactprint5{background: #fff2cc !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;}

.mytable td.empty,
.mytable td.prbab,
.mytable td.risks{
    background: #3e7afe !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;
}

.mytable td.creams, .mytable2 td.creams{
    background: #fff2cc !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;
}
.mytable td.yellows, .mytable2 td.yellows{
    background: #ffff00 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;
}
.mytable td.oranges, .mytable2 td.oranges{
    background: #ffc000 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;
}
.mytable td.oranges2, .mytable2 td.oranges2{
    background: #ff6600 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;
}
.mytable td.reds, .mytable2 td.reds{
    background: #ff0000 !important; print-color-adjust: exact; -webkit-print-color-adjust: exact;
}


}
 

/*page4 /////////////////////////////*/
.table{height: 100%;}
.mytable4 th{
	text-align: center;
    border: 1px solid;
    padding: 5px;
    font-size: 13px;
}

html .mytable4.table thead th {
    text-align: center;
    vertical-align:middle;
    background-color: #343a40;
    padding: 2px;
    font-size: 12px;
    color: #fff;
    border-color: #454d55
}
.mytable4 td{
	text-align: left;
	/*border: #000 solid 1px;*/
	padding: 5px;
}
.table .remove-border th {
    border: none !important;
    padding: 2px 0px;
    border-right: #525252 solid thin !important;
}
.table .remove-border tr:first-child {
    border-bottom: #525252 solid thin;
}
ul.list-4 {
    margin: 0px;
    padding: 0px;
}

ul.list-4 li {
    list-style: none;
    position: relative;
    padding-left: 15px;
    line-height: 18px;
    font-size: 13px;
}
ul.list-4 li::after {
	content: "";
	display: block;
	position: absolute;
	width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    left: 2px;
    top: 6px;
}

ul.list-4 li ul {
    padding-left: 15px;
}
ul.list-4 li ul li {
    position: relative;
}
ul.list-4 li ul li::after {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 50%;
    left: 2px;
    top: 6px;
    border: #848484 solid thin;
}

.parent-fix {
    float: left;
    height: 100%;
    width: 150px;
}
.parent-fix .fix-1 {
    float: left;
    width: 33.33%;
    text-align: center;
    /*background: #f20;*/
    height: 100%;
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
}
.parent-fix .fix-1:last-child {
    border-right: none;
}
.parent-fix .fix-1.oranges{background: #ff6600;}
.parent-fix .fix-1.reds{background: #ff2200;}
.parent-fix .fix-1.creams{background: #fff2cc;}
.parent-fix .fix-1.yellows{background: #ffff00;}

/*page5*/
.mytable5 th{
	text-align: center;
}
.mytable5 td{
	text-align: center;
	height: 630px;
}
.risk-title5 {
    background: #105a92;
    padding: 8px;
    color: #fff;
    max-width: 150px;
    font-size: 9px;
    text-align: center;
}
/*end list1*/


.fixed-layout{
    table-layout: fixed;
}






@media print{

.table .remove-border td.orange_new{
	background: #ff6600 !important; 
    print-color-adjust: exact;
	-webkit-print-color-adjust: exact !important;
}
.table .remove-border td.reds_new{
	background: #f20 !important;
    print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}
.table .remove-border td.creams_new{
	background: #fff2cc !important;
    print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}
.table .remove-border td.yellows_new{
	background: #ffff00 !important;
    print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}

}


.mytable6 td{
	padding: 1.5px;
	text-align: right;
}
.mytable6 td.blue-table{
	background: #105a92;
	color: #fff;
}
.border-all{
    border: #000 solid 1px;
}
.form-group label.signs {
    max-width: 100%;
    justify-content: flex-start;
    padding-left: 5px;
}
.form-group .hfull{height: 44px; text-align: center;}
.form-group label.licence{
    width: 100%;
    max-width: 100%;
    justify-content: center;
    text-align: center;
    border-top: #000 solid 1px;
}
.form-group label.leftalign {
    justify-content: flex-start;
    padding-left: 5px;
    max-width: 108px;
}
.box-check-4 ul {
    margin: 0px;
    padding:5px 0;
}
.box-check-4 ul li {
    line-height: normal;
    margin-bottom: 2px;
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
}
.box-check-4 ul li::after {
    content: "";
    display: block;
    position: absolute;
    background: #000;
    width: 5px;
    height: 5px;
    top: 4px;
    left: 8px;
    border-radius: 50%;
}
.fix-width {
    max-width: 273.2px;
}







/*page7*/
.risk-title7 {
    background: #105a92;
    padding: 8px;
    color: #fff;
    max-width: 150px;
    text-align: center;
    font-size: 9px;
}
.list-content {
    padding: 10px;
    border: #ccc solid thin;
}
.list-content ol {
    margin: 0px;
    padding-left: 15px;
}
.list-content ol li {
    list-style: auto;
}
.mytable7 th{
	text-align: center;
}
.border-bottom-new {
    border-bottom: 1px solid #000;
}
.checkmark.centered {
    left: 50%;
    transform: translate(-50%, 0px);
}
.new_label_size .flexlabel label {
    max-width: 130px;
}
.new_label_size .flexlabel label.role-size {
    max-width: 68px;
}
.custom-width-role {
    flex: initial;
    max-width: 358px;
}
.custom-width-toolbox {
    flex: initial;
    max-width: 327px;
}
/*end*/




/*page8*/
.mytable8 td{vertical-align: middle;}
/*end*/









@media print{

.mytable6 td.blue-table{
	background: #105a92 !important;
    print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}


/*page7*/

/*end*/

}
.nitin  .col-sm-1, .nitin  .col-sm-2, .nitin  .col-sm-3, .nitin  .col-sm-4, .nitin  .col-sm-5, .nitin  .col-sm-6, .nitin  .col-sm-7, .nitin  .col-sm-8, .nitin  .col-sm-9, .nitin  .col-sm-10, .nitin  .col-sm-11, .nitin  .col-sm-12{
    padding: 0px;
}
.nitin .row {
    margin: 0px;
}

.swms-sign-image-holder{
    width: fit-content;
    margin: 0px auto;
}
.swms-sign-image {
    width: auto;
    max-height: 40px;
    max-width: 60px;
}

.list-style-custom ul>li {
    list-style-type: none;
    padding-left: 15px;
    position: relative;
    font-size: 13px;
}

.list-style-custom ul>li:after {
    content: "";
    background: #000;
    color: #000;
    left: 2px;
    top: 6px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: block;
    position: absolute;
}

.list-style-initial {
    padding-left: 15px;
}

.list-style-initial li {
    list-style-type: unset !important;
}