.ContainerPage {
  background: #EAEAEA;
  border-radius: 35px;
  padding: 12px;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
}

.PageBackground {
  background-color: #fff;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
  border-radius: 25px;
  padding: 8px 0px;
  overflow: hidden auto;
}

.PageHeading {
  font-size: 20px !important;
  font-weight: 600;
}

.UserFormLabel {
  font-size: 13px;
  color: #362148;
  font-weight: 600;
}

.UserFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.UserFormField:disabled {
  color: #999999 !important;
}

.AddNewBtn {
  background-color: #C0B4FF;
  color: #362148 !important;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
}

.AddNewBtn:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.AddNewBtn:focus {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.ResetBtn {
  background-color: transparent;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}

.AdvanceSearchRemoveIcon {
  font-size: 15px;
  cursor: pointer;
  padding: 2px;
}

.OrgField {
  background: #eaeaea;
  border-radius: 8px;
  padding: 8px;
  height: 35px;
}