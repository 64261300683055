.preview_task_images p {
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

.preview_information{
  text-align: left !important;
  padding: 10px;
  background: #f1f1f1;
  margin-bottom: 10px;
}

.main_imag_holder{
  height: 600px;
    background: #000;
    position: relative;
}

.img_main{
  max-width: 100%;
    height: auto;
    max-height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.preview_carousel_box{
  border: 1px solid #f1f1f1;
  width: 150px;
  height: 120px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview_carousel_box img{
  max-height: 100px;
  vertical-align: middle;
  cursor: pointer;
}

.preview_carousel{
  margin-bottom: 20px;
  margin-top: 20px;
}

.preview_carousel_box:hover {
  border: 1px solid #53db91;
}

.loaderDiv{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  color: #16abff;
}