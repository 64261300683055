/* Page & Container */
.ContainerPage {
  background: #fff;
  border-radius: 35px;
  padding: 16px 0px;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
  overflow: hidden auto;
}

.PageHeading {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 0px 5px;
}

/* Modal */
.ModalCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ModalHeading {
  font-size: 14px !important;
  font-weight: bold;
  color: #362148;
}

/* Buttons */
.PrimaryButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: 'Poppins', sans-serif !important;
}

.PrimaryButton:hover,
.PrimaryButton:focus {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.SecondaryButton {
  background-color: #362148;
  color: #E8FF84 !important;
  border-radius: 50px;
  padding: 9px 25px;
  font-size: 14px;
  border: 1px solid #362148;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: 'Poppins', sans-serif !important;
  box-sizing: border-box;
}

.SecondaryButton:hover,
.SecondaryButton:focus {
  background-color: #fff !important;
  color: #362148 !important;
}

.PrimaryButton:disabled,
.SecondaryButton:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}


.OutlinedButton {
  background-color: initial;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

.OutlinedButton:disabled {
  cursor: not-allowed;
  color: #eee !important;
}

.SmallButton {
  font-size: 13px !important;
  padding: 6px 15px !important;
}

/* Form & Fields */

.Label {
  font-size: 12px;
  color: #362148;
  font-weight: 700;
}

.TextField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
  font-family: 'Poppins', sans-serif;
  color: #000;
}

.TextArea {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  padding: 5px;
  resize: none;
  color: #000;
}

.TextField:disabled,
.TextArea:disabled {
  color: #999999 !important;
}

.TextField:focus,
.TextArea:focus {
  -webkit-box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
}

.FilterTextField {
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
  color: #000;
}

.FilterTextField::placeholder {
  color: #362148 !important;
}

.FilterTextField:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
}

.FilterTextField:hover {
  box-shadow: none;
  border: 1px solid #ddd;
}

/* Table */
.TableText {
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
}

.StickyTopRow {
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 1;
}

/* Status */
.StatusBox {
  border-radius: 25px;
  padding: 5px 10px;
  text-align: center;
  max-width: 130px;
  width: auto;
  height: auto;
  font-size: inherit;
  color: #000;
  margin: auto;
}

.ActiveStatus {
  background: #c0b4ff;
}

.InActiveStatus {
  background: #ffa874;
}


/* Icons (use with SVG & MUI's IconButton) */
.IconBtn {
  height: auto;
  padding: 0px !important;
}

.IconBtn:disabled {
  color: #00000042 !important;
  cursor: not-allowed;
}

.PrimaryColor {
  color: #c0b4ff !important;
}

.SuccessColor {
  color: #90bf00 !important;
}

.DangerColor {
  color: #f44336 !important;
}

.SecondaryColor {
  color: #362148 !important;
}

.DisabledColor {
  color: #ccc !important;
}

/* Collapse */
.CollapseHeading {
  padding: 8px 8px 8px 16px;
  border-radius: 8px 8px 0px 0px;
  background-color: #eaeaea;
  font-size: 13px;
  font-weight: bold;
  color: #362148;
  border: 1px solid #eaeaea;
}

.DetailsDivider {
  background: #F0EFEE;
  height: 1px;
  opacity: 1;
}

.DetailsDividerLight {
  border-top: #f5f5f5;
}

.ManagerDetailsContainer {
  gap: 10px;
  justify-content: end;
}

.ManagerEditButton {
  height: auto;
}

.ManagerModal {
  width: 700px !important;
}

.CollapseCard {
  padding: 5px 10px;
  border: 1px solid rgba(54, 33, 72, 0.3);
  border-radius: 8px;
}

.EmptyTab {
  height: 90px;
  width: 110px;
  display: flex;
  line-height: 1.2;
  justify-content: center;
  align-items: self-start;
}

.TabText {
  white-space: break-spaces;
  word-break: break-word;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center !important;
  transform: translate(-50%, -50%);
  font-size: 13px;
}

.TabContainer {
  color: #000;
  padding: 2px;
}

.ListSectionContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}

.ListBox {
  background-color: #FAFAFA;
  padding: 12px 0px;
  overflow: hidden auto;
  color: #000;
  border-radius: 14px;
}

.ListBoxTitle {
  color: #362148;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 12px;
  border-bottom: 1px solid #000000;
}

.ListBoxItems {
  overflow: hidden auto;
}

.ListItem {
  padding: 10px 16px;
  border-bottom: 1px solid #cccccc;
  display: block;
  color: #000 !important;
  cursor: pointer;
}

.ListItem:hover,
.ListItem:focus {
  color: #c0b4ff !important;
}

.InputFieldV2 {
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
  color: #000;
}

.InputFieldV2::placeholder {
  color: #362148 !important;
}

.InputFieldV2:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
}

.InputFieldV2:hover {
  box-shadow: none;
  border: 1px solid #ddd;
}

.TableLinkButton {
  height: auto;
  background: inherit;
  color: inherit;
  font-size: 13px;
  padding: 0px;
  margin: 0px;
  outline: 0px;
  text-decoration: underline;
  color: #c0b4ff;
}

/* Avatar */

.SelectableAvatar {
  margin: 0px auto;
  cursor: pointer;
  background-color: #ccc;
}

.SelectedAvatar {
  background-color: #C0B4FF !important;
  color: #fff !important;
}

.DisabledAvatar {
  background-color: #352148 !important;
  color: #ffffff !important;
  cursor: not-allowed;
}

.EditIcon {
  color: #90bf00;
  width: 16px;
}

.DeleteIcon {
  /* width: 16px; */
  color: #f44336 !important;
  height: auto;
}

.BlueTextButton {
  background: none;
  border: none;
  color: #362148;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.TaskHeaderText {
  font-size: 13px;
  font-weight: 400;
}

.DragIcon {
  color: #332246 !important;
  width: 16px;
}