.availability-table {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
  margin-bottom: 0 !important;
  text-align: center;
}

.availability-table tbody td,
.availability-table thead th {
  border: 1px solid !important;
  font-size: 13px !important;
  border-color: #000 !important;
}

.availability-table th {
  border: 1px solid !important;
  font-weight: 700;
}

.availability-table tbody td {
  border: 1px solid;
  padding: 6px;
  vertical-align: middle;
}