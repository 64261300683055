/* JSX Styles */

.DocsContainer {
  font-family: "Poppins", sans-serif !important;
}

.CheckboxCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CheckboxCellName {
  margin-left: 12px;
  color: #362148;
  font-weight: 700;
}

.CheckboxCellNameBlack {
  margin-left: 12px;
  color: #000000;
  font-weight: 700;
}

.StaffFlow {
  display: flex;
  flex-direction: row;
}

.PageHeading {
  color: #362148;
  font-size: 18px;
  font-weight: bold;
  padding-right: 10px;
}

.Title {
  font-size: 13px;
  color: #000 !important;
  user-select: none;
}

.Table {
  border: 1px solid #3621484d;
  border-radius: 8px !important;
  border-collapse: collapse;
  max-height: 30vh;
  overflow-y: scroll;
}

.Table>thead>tr, .Table>tbody>tr {
  border: 1px solid #3621484d;
  border-radius: 8px !important;
}

.Table>thead>tr>th {
  /* font-weight: 700; */
}

.Table>thead>tr>th, .Table>tbody>tr>td {
  /* border: 1px solid #ddd; */
  padding: 10px 15px !important;
  font-size: 13px;
  border-radius: 16px !important;
  color: #362148;
}

.Table>tbody>tr>td {
  color: #000 !important;
}

.TableHeading {
  color: #362148;
  font-weight: 700;
  border: none;
  font-size: 13px;
  font-family: "Poppins", sans-serif !important;
}

.CheckboxDefault {
  color: #362148;
}

.RfqFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.CheckboxDefault .CheckboxDefault::selection {
  background: #362148 !important;
}

.DetailsText {
  color: #362148;
  margin: 3px 0px;
}

.LabelBlue {
  color: #362148 !important;
}

.LabelGreen {
  color: #2ed47a !important;
}

.LabelRed {
  color: #f7685b !important;
  font-size: 13px !important;
  text-transform: none !important;
}

.InputDefault {
  border-color: transparent;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.InputDefault>div>input {
  border-color: transparent !important;
}

.InputDefault:hover {
  border-color: inherit;
}

.UploadBox {
  color: #b8b5b5 !important;
}

.UploadBox>.ant-upload {
  color: #b8b5b5 !important;
}

.CollapseIcon {
  color: #000 !important;
}

.TableButton {
  background: #fff;
  border-color: #fff;
  padding: 0px;
}

.Button {
  border: 1px solid #2e6da4;
  margin: 5px;
  padding: 10px 30px;
  border-radius: 0 !important;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
}

.ButtonPrimary {
  color: #fff;
  background-color: #337ab7;
}

.ButtonOutlined {
  color: #337ab7;
  background-color: #fff;
}

.ButtonOutlined:hover {
  background-color: #337ab7;
  color: #fff;
}

.AllocateBox {
  border: 2px dashed #ddd;
  border-radius: 6px;
  padding: 10px 15px;
}

.AllocateBoxTitle {
  color: #362148 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.RefreshButton {
  font-size: 22px !important;
  margin-top: -7px;
  margin-right: 5px;
}

.TitleWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
}

.Button:disabled {
  opacity: 0.8;
}

.Button:disabled:hover {
  cursor: not-allowed;
}

.Table>tbody>tr>td input, .Table>tbody>tr>td.Middle {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  padding: 8px;
  height: 2rem !important;
  color: black;
}

.Table>tbody>tr>td input:focus, .Table>tbody>tr>td input:focus-visible {
  border: none;
  outline: none;
  border-bottom: 1px solid #135f9b !important;
}

.CenterCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Bottom {
  vertical-align: bottom;
}

.PaddedCenterCell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.PaddedCenterCell>i {
  margin-right: 8px;
  font-size: 1.2rem;
}

.PaddedCenterCell:hover {
  cursor: pointer;
}

.Table>tbody>tr.SmallPaddingRow>td {
  padding: 2.5px !important;
}

.Table>tbody>tr.SmallPaddingRow>td input:focus {
  border: none !important;
}
/* 
.AddDocumentActions>button:nth-child(1) {
  margin-right: 10px;
  font-size: 15px;
  text-transform: uppercase;
} */

.EditTitle {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.EditTitle>p {
  margin-bottom: 0;
}

.IconMore {
  color: #000000;
  font-size: 20px;
}

.CheckboxCell2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}

.CheckboxCellLight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CheckboxCellLightName {
  color: #156298;
}

.CheckboxCellNameNormal {
  margin-left: 12px;
  color: #000000;
}

.TableSubHeading {
  color: #362148 !important;
  font-weight: 700 !important;
}

.AddIcon {
  color: #39c480;
  font-size: 20px;
}

.AddIconText {
  color: #39c480;
  font-size: 13px;
  font-weight: 700 !important;
}

.InsurName {
  color: #362148 !important;
}

.ant-collapse-arrow {
  color: #000 !important;
}

.PlusIcon {
  font-size: 15px !important;
  margin-right: 5px !important;
}

.AddDocBtn {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.collapseBorder {
  border: none !important;
}

.BorderPadd {
  padding: 10px !important;
  border: 1px solid #3621484d;
  padding-bottom: 0 !important; 
  border-radius: 8px; 
}

.CollapseMar {
  margin-bottom: 10px !important;
}

.OverFlowXAuto {
  overflow-x: unset !important;
}

.SimpleButton{
  background: none;
  height: auto;
  color: inherit;
  font-size: 13px;
  padding: 0;
}

.SingleLine {
  border-width: 0px !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #ccc !important;
  border-radius: 0px !important;
  padding: 1px 2px !important;
  height: 22px !important;
  color: #000 !important;
  font-size: 13px !important;
  outline: none !important;
  width: -webkit-fill-available !important;
}

.SingleLine:hover {
  border-width: none !important;
  border-right-width: 0px !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #135f9b !important;
  box-shadow: none !important;
  outline: none !important;
}

.SingleLine::placeholder {
  color: #999 !important;
}

.SingleLine:focus {
  box-shadow: none !important;
  border-right-width: 0px !important;
  outline: none !important;
  border-bottom-color: #135f9b !important;
}

.SingleLine[disabled] {
  color: #999999 !important;
}

.SingleLabel {
  color: #135f9b !important;
}

.UploadContentName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 140px;
}

.DisabledButton {
  background: #ccc !important;
  color: #000 !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

.AddNewBtn {
  background-color: #C0B4FF;
  color: #362148 !important;
  border-radius: 50px;
  padding: 5px 13px;
  line-height: 1;
  font-size: 13px;
  border: 0;
  font-weight: 500;
}

.AddNewBtn:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.AddNewBtn:focus {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.TabsCover {
  border: 1px solid #3621484d;
  padding: 6px;
  border-radius: 8px; 
}

.StaffFormLabel {
  font-size: 12px;
  color: #362148;
}