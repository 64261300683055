.ProfileName {
  padding: 0px 4px 0px 2px;
}

.ProfileImage {
  width: 25px;
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 25px;
  margin-right: 5px;
  object-fit: scale-down;
}

.DropdownButton {
  padding: 2px 10px 2px 3px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  height: auto;
  background: transparent;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}

.DropdownButton:hover {
  background: #ddd;
  border-radius: 25px;
}

.Nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 67px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 31;
  height: 100%;
  background-color: #362148;
  /* padding: 0 20px 0 0; */
}

.NavItemsWrap {
  width: 100%;
  /* height: calc(100% - -30px);
  padding: 40px 0; */
  /* border-radius: 0 50px 50px 0px; */
  background-color: #362148;
  flex: 1 1 100%;
  align-content: center;
}

.NavInner {
  margin: 0 auto;
  overflow-y: auto;
  height: 100%;
}

.NavItem {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  /* padding-top: 10px; */
  padding-bottom: 10px;
}

.NavItemLink {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #c0b4ff;
  transition: 0.3s;
}

.NavItemLink:hover {
  color: #fff !important;
}

.NavItemLinkIcon {
  font-size: 20px;
  height: 25px;
  vertical-align: unset !important;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavItemLinkIcon > * {
  font-size: 12px I !important;
}

.NavItemLinkHeading {
  font-size: 12px;
  padding-top: 5px;
}

.NavLinkActive {
  color: #fff !important;
}

.IconBtn {
  height: auto;
  padding: 0px !important;
}

.CategoryIconColor {
  color: #c0b4ff !important;
}

.CategAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.IconsGap {
  padding-top: 25px;
}

.Drawer {
  top: 0;
  left: 67px !important;
  bottom: 0;
  position: fixed;
  background: #c0b4ff;
  z-index: 30;
  width: 250px;
  height: 100vh;
  padding: 30px 10px;
  display: block;
  border-radius: 0px 50px 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: margin 0.4s;
}

.SubMenu {
  margin-left: -250px;
}

.ModuleGroup {
  overflow: auto hidden;
}

.ModuleLink {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #362148 !important;
  font-weight: bold;
}

.ActiveGroup {
  color: #d3f500 !important;
}
