@charset "UTF-8";

/* @import url(./assets/css/font-awesome.min.css); */

@import url("./assets/css/font-awesome.min.css");

html,
body {
    /* font-family: "Lato", sans-serif; */
    font-weight: normal;
}

html,
body {
    width: 100%;
    min-height: 100%;
    height: auto;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    /* font-family: "Lato", sans-serif; */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

b {
    font-weight: bold;
}

strong {
    font-weight: 900;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none;
}

a:hover {
    text-decoration: none;
    color: #008572;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #222222;
}


/* Box Model */

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.sf-page-layout {
    min-height: 100%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/* common css style */

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: auto;
}

.row {
    margin-right: -14px;
    margin-left: -14px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-right: 14px;
    padding-left: 14px;
}

.logo {
    height: 50px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
}

img.sf-logo {
    width: 300px;
}

.sf-login {
    width: 470px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: #5a5a5a;
    border-radius: 0;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    height: 2.85em;
    line-height: 2.95em;
    padding: 0 1.5em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

h2.sf-lg-heading {
    font-size: 30px;
    /* font-family: "Lato", sans-serif; */
    font-weight: 300;
    color: #656565;
    line-height: normal;
    margin: 15px 0 25px;
    padding: 0;
}

.userTxtbox label,
.passTxtbox label {
    display: none;
}

.userTxtbox input,
.passTxtbox input {
    /* font-size: 18px;
    color: #818181; */
    /* border-radius: 4px;
    height: 45px; */
    /* width: 100%; */
    /* font-weight: 400; */
    /* font-family: "Lato", sans-serif; */
    line-height: 1;
    /* border: 1px solid #ddd; */
    background: #fff;
    padding-left: 38px;
}

fieldset.userTxtbox,
fieldset.passTxtbox {
    position: relative;
}

svg.svg-ico-f {
    fill: #B4B4B4;
    position: absolute;
    left: 14px;
    top: 23px;
    width: 14px;
    height: 14px;
}

/* fieldset.passTxtbox svg.svg-ico-f {
    top: 14px;
} */

.password-strength-meter {
    text-align: left;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 20%;
}

button:focus,
button:hover,
button:focus-within {
    outline: none;
}

.normal-bnt {
    background: transparent;
    color: #03a791;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    display: flex;
    height: auto;
    line-height: normal;
}

.normal-bnt:hover {
    color: #008572;
}

.normal-bnt-v2 {
    background: transparent;
    color: #362148;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    display: flex;
    height: auto;
    line-height: normal;
}

.ccalnd-icon {
    position: absolute;
    right: 10px;
    color: #03a791;
    top: 7px;
    font-size: 13px;
}

.bnt:disabled {
    background: rgba(45, 59, 72, 0.1) !important;
    color: rgba(45, 59, 72, 0.29) !important;
    border: 0 !important;
    cursor: not-allowed;
}

.sf-shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.no-shadow {
    box-shadow: none !important;
}

.no-border {
    border: 0 !important;
}

.p-relative {
    position: relative;
}

.active-txt {
    color: #03a791;
}

.pl-bnt-2 .btn-hs-icon {
    padding-left: 10px;
}

.sm-txtarea-size.sf-form textarea {
    height: 45px;
    width: 160px;
}


/* Progress bar styling */

.password-strength-meter progress {
    border-radius: 20px;
}

.password-strength-meter progress::-webkit-progress-bar {
    border-radius: 20px;
}

.password-strength-meter progress::-webkit-progress-value {
    border-radius: 20px;
}

.password-strength-meter progress::-moz-progress-bar {
    border-radius: 20px;
}

.userTxtbox span,
.passTxtbox span {
    color: #FC544E;
    font-size: 14px;
    font-weight: 400;
    padding-top: 5px;
}

.sf-btn {
    background: #03a791;
    border-radius: 4px;
    height: 45px;
    color: #fff;
    line-height: 45px;
    width: 100%;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
}

.fldset-box {
    margin-bottom: 15px;
}

.row-two-col {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: -10px;
    margin-right: -10px;
}

.row-two-col .fldset-box {
    padding: 0 10px;
}

.row-two-col svg.svg-ico-f {
    left: 20px;
}

@media screen and (max-width: 500px) {
    .row-two-col {
        display: block;
    }
}


/* custom checkbox style */

.rember-me .styled-checkbox {
    position: absolute;
    opacity: 0;
}

.rember-me .styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: inline;
    font-size: 13px;
    font-weight: 400;
    color: #222222;
}

.rember-me .styled-checkbox+label:before {
    content: "";
    left: -20px;
    display: inline-block;
    vertical-align: text-top;
    width: 17px;
    height: 17px;
    background: #fff;
    border: 1px solid #03a791 !important;
    top: 0px;
    border-radius: 2px;
    margin-right: 10px;
}

.rember-me .styled-checkbox:hover+label:before {
    background: #03a791;
}

.rember-me .styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.rember-me .styled-checkbox:checked+label:before {
    background: #03a791;
    content: "check";
    font-family: "Material Icons";
    color: #fff;
    font-size: 14px;
}

.rember-me .styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.rember-me .styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.rember-me .styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: -17px;
    top: 8px;
    background: #fff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.forgt-pass {
    margin: 10px 0;
    color: #848484;
    font-size: 14px;
    font-weight: 400;
}

.forgt-pass a {
    color: #03a791;
    text-decoration: none;
}

.forgt-pass a:hover {
    color: #222222;
}


/* Absolute Center Spinner */

.sk-loder-st {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.25);
    height: 100%;
}

.sk-circle {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #03a791;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
sf-card
.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


/*  Alert */

.alert-danger {
    color: #ff151f;
}


/* user profile  */

a.usr-profile {
    color: #a1a7ad;
}

a.usr-profile:hover {
    color: #03a791;
}

img.ds-sf-logo {
    width: auto;
    height: 70%;
    margin-top: 12px;
}

.dsb-logo img {
    width: unset !important;
    max-width: unset !important;
    height: 35px;
}

.logo.dsb-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
}

.logo.dsb-logo strong {
    font-size: 18px;
    padding-left: 10px;
    color: #fff;
    font-weight: 600;
    display: block;
    opacity: 1;
    transition: 0.5s all;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.ant-layout-sider-collapsed .logo.dsb-logo strong {
    display: none;
    opacity: 0;
    width: 0px;
}

.ant-layout-sider-collapsed .logo.dsb-logo {
    justify-content: center;
}

.sett-badge {
    margin-left: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    line-height: normal;
}

.sett-badge i {
    font-size: 18px;
    color: rgba(45, 59, 72, 0.45);
}

.sett-badge:hover i.anticon,
.pro-sett a:hover .usr-txt {
    color: #03a791;
}

.sett-badge .ant-dropdown-open .usr-txt,
.pro-sett .ant-dropdown-open i,
.sett-badge .notifi-drop.ant-dropdown-open i {
    color: #03a791;
}

.notifi-drop {
    height: 100%;
    display: flex;
    align-items: center;
}

.pro-sett a.ant-dropdown-link {
    color: #a1a7ad;
    display: flex;
    align-items: center;
    height: 100%;
}

.pro-sett .usr-txt {
    padding: 0 7px;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: rgba(45, 59, 72, 0.45);
}

.sett-badge.pro-sett i.anticon {
    font-size: 13px;
}

li.ant-dropdown-menu-item {
    white-space: normal;
}

.user-details .user-name {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: #4a4a4a;
}

.user-details .user-id,
.user-details .org-name {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: rgba(45, 59, 72, 0.6);
}

.ant-dropdown-menu-item a:hover .user-name,
.ant-dropdown-menu-item a:hover .user-id {
    color: #fff;
}

.ant-dropdown-menu-item>a:not([href]):not([tabindex]) {
    color: rgba(0, 0, 0, 0.65);
}

.user-name-role {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 4px;
}

.user-name-role .user-name {
    width: auto;
}

.ant-dropdown-menu-item>i:first-child,
.ant-dropdown-menu-submenu-title>i:first-child {
    min-width: 12px;
    margin-right: 8px;
}

.user-name-role .pill {
    padding: 4px 8px;
    border-radius: 50px;
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    color: #03a791;
    text-align: center;
    border: 1px solid #fff;
    margin-left: 10px;
}

.ant-dropdown-menu-item a:hover .pill {
    color: #fff;
}

.change-org-name {
    display: inline-flex;
    flex-direction: column;
}

.change-org-name .org-name {
    color: rgba(45, 59, 72, 0.4);
    font-size: 12px;
}

.ant-dropdown-menu-item:hover .change-org-name .org-name {
    color: #fff;
}

.dash-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background: #fff;
    min-height: 50px;
}

.page-mn-hd {
    font-size: 21px;
    font-weight: 500;
    color: #222;
    margin: 0;
    text-align: left;
    line-height: 0;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.page-mn-hd i {
    font-size: 15px;
    margin: 0px 5px;
}

.bnt {
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    font-weight: 600;
    height: 40px;
    display: inline-block;
    line-height: 38px;
    border: 1px solid #03a791;
    background: #fff;
    color: #03a791;
    transition: 0.5s all;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnt i.material-icons {
    font-size: 20px;
    padding-right: 8px;
}

.bnt.bnt-sm {
    height: 30px;
    padding: 0px 10px 2px 10px;
}

.bnt-active,
input[type="submit"].bnt-active {
    background: #03a791;
    color: #fff;
    outline: none;
}

.bnt-active:hover,
input[type="submit"].bnt-active:hover {
    background: #008572;
    color: #fff;
    border-color: #008572;
}

.bnt-w-br {
    border: 1px solid #03a791;
    color: #03a791 !important;
    background: #fff;
}

.bnt-w-br:hover {
    background: #03a791;
    color: #fff !important;
    border: 1px solid #03a791;
}

.multibnt .btn-hs-icon {
    margin-left: -5px;
    margin-right: -5px;
}

.multibnt .btn-hs-icon .bnt {
    width: 100%;
    margin: 0 5px;
    height: 32px;
    font-size: 15px;
    font-weight: bold;
    min-width: auto;
}

.payment-bnt.multibnt .btn-hs-icon .bnt {
    min-width: auto;
    max-width: 130px;
    padding: 0 4px 1px 4px;
}


/* steper */

.sf-steps-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin: 10px 0px;
}

.sf-steps-row {
    display: flex;
    justify-content: space-between;
}

.sf-st-item .iconbx,
.sf-prog i {
    background: #e1e2e2;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    line-height: 47px;
    border-radius: 50px;
    position: relative;
    z-index: 2;
}

.sf-st-item .iconbx i,
.sf-prog i {
    font-size: 20px;
    font-style: normal;
    color: rgba(169, 165, 170, 0.45);
}

.active.sf-st-item .iconbx {
    font-family: "Material Icons";
}

.active.sf-st-item .iconbx:before {
    position: absolute;
    right: -8px;
    top: 0;
    content: "check";
    font-size: 10px;
    background: #03a791;
    border-radius: 50px;
    height: 14px;
    width: 14px;
    line-height: 14px;
    color: #fff;
}

.sf-st-item.sf-prog.active i {
    border-color: #03a791;
}

.sf-st-item.sf-prog .ant-progress-circle-trail {
    stroke: #d5d8da !important;
}

.sf-st-item.sf-prog .ant-progress-circle .ant-progress-text {
    color: #57626d;
    font-weight: 600;
    font-size: 11px;
}

.sf-step-4 .sf-st-item {
    width: 25%;
}

.sf-steps-status.sf-step-4 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sf-step-2 .sf-st-item {
    width: 50%;
}

.sf-st-item {
    padding: 0 20px;
    position: relative;
    text-align: center;
    color: #57626d;
}

.sf-st-item:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: rgba(45, 59, 72, 0.1);
    left: 52%;
    top: 20px;
    z-index: 1;
}

.sf-st-item.sf-prog:after,
.sf-st-item.no-bp:after {
    content: none;
}

.sf-steps-row .sf-st-item:last-child,
.sf-st-item.no-bp {
    padding-right: 0;
}

.sf-steps-row .sf-st-item:last-child:after {
    background: transparent;
}

.sf-st-item:first-child {
    padding-left: 0;
}

.sf-st-item.sf-prog {
    padding: 0;
    margin-left: 50px;
}

.sf-st-item.sf-prog i {
    background: transparent;
    line-height: 40px;
    font-size: 11px;
    font-weight: 600;
    color: #57626d;
}

.sf-st-item span {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    color: rgba(45, 59, 72, 0.8);
}

.usr-profile {
    justify-content: space-between;
    align-items: center;
    height: 100%;
}


/* --------- main container ------------- */

.sf-card-wrap {
    padding: 10px;
    box-shadow: 0 2px 4px 0px rgba(45, 59, 72, 0.1);
    background: #fff;
    border-radius: 4px;
}

.sf-card-wrap .sf-card {
    box-shadow: 0 2px 4px 2px rgba(45, 59, 72, 0.1);
}

.sf-card.has-shadow,
.p-tab-shadow .sf-card {
    box-shadow: 0 2px 4px 2px rgba(45, 59, 72, 0.1);
}

.sf-card {
    background: #fff;
    padding: 0;
    text-align: left;
    border-radius: 4px;
}

.sf-card {
    box-shadow: 0 2px 4px 2px rgb(45 59 72 / 10%);
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 5px;
    display: inline-block;
}

.sf-card-body,
.sf-card-head,
.sf-card-footer {
    padding: 15px;
}

.sf-card-inner {
    padding: 15px;
    border: 1px solid rgba(45, 59, 72, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
}

.sf-card-inn-bg {
    padding: 15px;
    background: rgba(45, 59, 72, 0.04);
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
}

.main-container {
    padding: 20px 15px 20px 33px;
    margin: 0;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.1);
    background: rgba(229, 225, 231, 0.24);
    min-height: calc(100vh - 130px);
}

.srch-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.srch-box {
    border: 1px solid #ddd;
    color: #a1a7ad;
    border-radius: 4px;
    height: 38px;
    padding: 5px 10px;
    margin: 10px 0;
}

.srch-box input.srch-input {
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0 10px;
    color: #222222;
    font-weight: 400;
}

.srch-box .srch-btn {
    padding: 0;
    background: transparent;
    color: #a1a7ad !important;
    box-shadow: none;
    outline: none;
}

.srch-box .srch-btn:hover {
    color: #222222 !important;
}

.sf-service-list .ant-avatar {
    background: #7b1fa2;
    padding: 0;
    border: 1px solid #e0e8e7;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Auto search  */

.auto-search-txt {
    padding: 5px;
}

.auto-search-txt .ant-select-auto-complete.ant-select-lg .ant-input {
    border: 1px solid rgba(45, 59, 72, 0.08);
    color: #222;
    border-radius: 50px;
    height: 38px;
    padding: 5px 10px;
    margin: 0;
    font-weight: 400;
}

.auto-search-txt .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin: auto;
    transform: translateY(-50%);
    left: 15px;
}

.auto-search-txt li.ant-select-search {
    padding-left: 0;
}

.search-lists .ant-input-affix-wrapper .ant-input-suffix {
    right: 0;
    background: #03a791;
    color: #fff;
    padding: 11px;
    border-radius: 50px;
    border: 1px solid #03a791;
}

.auto-search-txt .ant-input:hover,
.auto-search-txt .ant-input:focus {
    box-shadow: 0 0 0 1px rgba(3, 167, 145, 0.3);
}

.auto-search-txt .anticon {
    color: #fff;
}


/* Search 10 July 2019 */

.sf-searchbar {
    flex: 0 0 33.33%;
    transition: 0.3s all;
    position: relative !important;
}

.sf-searchbar .sf-card {
    width: calc(100% - 4px);
    border-radius: 4px 0 4px 4px;
}

.sf-searchbar.closed .sf-card {
    overflow: hidden;
}

.sf-searchbar.closed {
    max-width: 100px;
    overflow: hidden;
    flex: 0 0 100px;
}

.sf-searchbar.closed .sf-card-head {
    padding: 0;
    display: flex;
    justify-content: center;
}

.sf-searchbar.closed .sf-card-head .ant-select-auto-complete {
    width: auto;
}

.sf-searchbar.closed .sf-service-list.ant-list-split .ant-list-item a {
    padding: 5px 12px;
    height: 60px;
}

.sf-service-list.ant-list-split .ant-list-item a {
    height: 62px;
}

.sf-searchbar.closed .sf-card-footer {
    padding: 10px 5px;
    align-items: center !important;
}

.closed .v-all-org {
    font-size: 11px;
    height: auto;
    white-space: pre-line;
    width: 100%;
    word-break: break-word;
    padding: 5px 0;
}

.sf-searchbar .ant-list-item-meta-avatar label {
    cursor: pointer;
}

.bnt-searchbar {
    background: #fff;
    color: #03a791 !important;
    padding: 6px 6px;
    border-radius: 0 8px 8px 0;
    position: absolute;
    right: 0;
    height: 32px;
    line-height: 21px;
    width: 25px;
    display: block;
    top: 0;
    font-size: 10px;
    box-shadow: 1px 0 0 rgba(45, 59, 72, 0.1);
    outline: none;
}

.bnt-searchbar:hover {
    background: #fff;
}

.bnt-searchbar:focus {
    outline: none;
}

.closed .search-lists .ant-input-affix-wrapper .ant-input-suffix {
    right: -8px;
}

.closed .auto-search-txt .ant-select-auto-complete.ant-select-lg .ant-input {
    visibility: hidden;
    width: 0px;
    opacity: 0;
}

.search-lists .ant-select-selection {
    position: relative;
}

.closed .bnt-searchbar i.anticon {
    transform: rotate(180deg);
}


/* search click button expance colleps */

.col-lg-2,
.col-md-2,
.col-sm-2,
.col-xs-2,
.col-lg-3,
.col-md-3,
.col-sm-3,
.col-xs-3,
.col-lg-4,
.col-md-4,
.col-sm-4,
.col-xs-4,
.col-lg-5,
.col-md-5,
.col-sm-5,
.col-xs-5,
.col-lg-6,
.col-md-6,
.col-sm-6,
.col-xs-6,
.col-lg-7,
.col-md-7,
.col-sm-7,
.col-xs-7,
.col-lg-8,
.col-md-8,
.col-sm-8,
.col-xs-8,
.col-lg-9,
.col-md-9,
.col-sm-9,
.col-xs-9,
.col-lg-10,
.col-md-10,
.col-sm-10,
.col-xs-10,
.col-lg-11,
.col-md-11,
.col-sm-11,
.col-xs-11,
.col-lg-12,
.col-md-12,
.col-sm-12,
.col-xs-12 {
    transition: 0.3s all;
}

.col-md-srch {
    max-width: calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
    transition: 0.3s all;
}


/* -------- end ----------- */

.sf-service-list.ant-list-split .ant-list-item {
    padding: 0;
    border: none;
}

.sf-service-list.ant-list-split .ant-list-item a {
    border-bottom: 1px solid transparent;
    border-top: 1px solid rgba(45, 59, 72, 0.06);
    padding: 10px 15px;
    display: inline-block;
    width: 100%;
    transition: 0.5s all;
}

.sf-service-list.ant-list-split .ant-list-item:last-child a {
    border-bottom: 1px solid rgba(45, 59, 72, 0.06);
}

.sf-service-list.ant-list-split .ant-list-item a:hover,
.sf-service-list.ant-list-split .ant-list-item.active a {
    background: rgba(3, 167, 145, 0.06);
    color: #03a791;
}

.sf-service-list.ant-list-split .ant-list-item.active a {
    border-bottom: 1px solid rgba(45, 59, 72, 0.06);
}

.sf-service-list.ant-list-split .ant-list-item a:hover .ant-list-item-meta-title,
.sf-service-list.ant-list-split .ant-list-item.active a .ant-list-item-meta-title {
    color: #03a791;
}

.disable-lists {
    opacity: 0.4;
}

.ant-list-item-meta-title {
    text-transform: capitalize;
    color: #222;
    font-weight: 500;
}

.sf-service-list .ant-list-item-meta-description {
    font-size: 12px;
    color: rgba(45, 59, 72, 0.8);
    line-height: normal;
    display: flex;
    font-weight: 400;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.sf-service-list .ant-list-item-meta-description>span {
    max-width: 128px;
    min-width: 70px;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sf-service-list .ant-list-item-meta-description>span+span {
    max-width: none;
    min-width: auto;
    padding-right: 0;
    overflow: unset;
    text-overflow: unset;
}

.sf-service-list ul.ant-list-items {
    overflow: hidden;
}

.v-all-org {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    background: transparent !important;
    color: #C0B4FF !important;
    white-space: normal;
    line-height: normal;
    height: auto;
}

.v-all-org:focus {
    outline: none;
}

.v-all-org:hover {
    text-decoration: underline;
}

.v-all-org.v-all-add-pd {
    height: 25px;
    visibility: hidden;
    opacity: 0;
}

.sf-pg-heading {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.sf-sub-hd {
    display: inline-block;
    width: 100%;
    color: rgba(45, 59, 72, 0.45);
    font-size: 14px;
    line-height: normal;
    padding-top: 5px;
}

.abb-1 {
    border-bottom: 1px solid #eaebec;
}

.info-btn .more-info {
    height: 24px;
    width: 24px;
    display: inline-block;
    color: rgba(45, 59, 72, 0.45);
    font-size: 19px;
    text-align: center;
    line-height: 26px;
    border-radius: 50px;
    cursor: pointer;
}

.info-btn .more-info.ant-dropdown-open {
    background: #f1f1f1;
    color: #03a791;
}

.info-btn.disable-dot-menu .more-info {
    color: rgba(45, 59, 72, 0.45);
    cursor: not-allowed;
}

.info-btn .more-info.disable-drop-menu {
    color: rgba(45, 59, 72, 0.45);
    cursor: not-allowed;
}

.sf-form label,
.site-s-body .sf-form label,
.sf-form.view-text-value label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

.sf-form label.ant-radio-wrapper,
.sf-form label.ant-checkbox-wrapper {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.65);
}

.sf-form input[type="text"],
.sf-form input[type="name"],
.sf-form input[type="number"],
.sf-form textarea,
.sf-form select {
    height: 34px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid rgba(45, 59, 72, 0.08);
    color: rgba(45, 59, 72, 0.9);
    font-weight: 400;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    min-width: 90px;
}

.sf-form input[type="text"]:focus,
.sf-form input[type="name"]:focus,
textarea:focus {
    box-shadow: 0 0 0 1px rgba(3, 167, 145, 0.3);
    outline: none;
}

.sf-form textarea {
    height: 80px;
    resize: none;
}

.btn-hs-icon {
    display: inline-block;
    position: relative;
    cursor: pointer;
}


/* button has icons */

.btn-hs-icon .bnt,
a:not([href]):not([tabindex]).bnt {
    height: 35px;
    line-height: normal;
    font-size: 17px;
    text-align: center;
    font-weight: 700;
    min-width: 130px;
}

.btn-hs-icon .bnt-normal,
a:not([href]):not([tabindex]).bnt {
    color: #03a791;
    background: transparent;
}

.btn-hs-icon .bnt-normal:hover,
a:not([href]):not([tabindex]).bnt:hover {
    background: #03a791;
    color: #fff;
    border-color: #03a791;
}

a:not([href]):not([tabindex]).bnt {
    padding: 0 20px;
}

.btn-hs-icon .bnt i {
    font-size: 15px;
    padding-right: 8px;
}

.sf-sm-hd {
    font-size: 15px;
    font-weight: 500;
    padding-right: 10px;
}

.sm-bnt .bnt {
    height: 32px;
    font-size: 15px;
    line-height: 31px;
}

.sf-xs-hd {
    font-size: 13px;
    font-weight: 500;
}

.sf-xs-hd .uhd-name {
    font-size: 13px;
    color: #a1a7ad;
    font-weight: 400;
    padding-left: 5px;
}


/* closed button style */

button.closed-btn {
    padding: 0;
    background: transparent;
    color: #362148;
    font-size: 14px;
    outline: none;
    height: auto;
    line-height: normal;
}

button.closed-btn:hover {
    color: #362148;
}

form {
    margin: 0;
}


/* File upload style */

.logo-upload .ant-upload.ant-upload-drag {
    background: #eaeaea;
    border: none !important;
}

.logo-upload .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0;
    color: #362148;
    font-size: 10px;
    text-align: center;
    padding: 0 10px;
    line-height: normal;
}

.logo-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon i {
    font-size: 20px;
    color: #362148;
}

.logo-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 5px;
}

.logo-upload label {
    margin-bottom: 5px;
    color: #362148;
}

#user-edit {
    display: flex;
}

.delete-bnt {
    background: transparent !important;
    padding: 0;
    border: none;
    height: 16px;
    margin: 0 0 0 10px;
    text-align: center;
    width: 16px;
    line-height: normal;
    outline: none;
}

.delete-bnt:focus {
    outline: none;
}

.delete-bnt i {
    color: #c0b4ff;
    font-size: 16px;
}

.delete-bnt i:hover {
    color: #c0b4ff;
}


/* add user table */

.org-user-table .tr .td:last-child {
    width: 75px;
    vertical-align: middle;
    text-align: right;
}

.ve-a-user-t .tr .td:last-child {
    width: 30px;
    vertical-align: middle;
}

.add-user-table tr th {
    font-size: 15px;
    font-weight: 400;
}

.add-user-table tr td {
    font-size: 14px;
    font-weight: 300;
}

.add-user-table tr td:last-child {
    vertical-align: middle;
    /* line-height: normal; */
}

.add-user-table tr th,
.add-user-table tr td {
    border-top: none;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    vertical-align: top;
    transition: 0.5s all;
}

.add-user-table tr td input[type="text"],
.add-user-table tr td select,
.add-user-table tr td .ant-select-selection {
    height: 30px;
    padding: 5px;
    font-size: 13px;
    display: inline-block;
}

.add-user-table tr td label {
    display: none;
}

.add-user-table tr td select {
    min-width: 100px;
}

.add-user-table tr td .ant-select-selection .ant-select-selection__rendered {
    margin: 0 5px 0 0;
    line-height: normal;
}

.add-user-table tr td .ant-select-selection .ant-select-arrow {
    right: 0;
}

.add-user-table tr:hover td {
    background: #f1f1f1;
}

.sc-upld-files {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.view-text-value .sc-upld-files span {
    color: #03a791;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 5px;
    width: 100%;
}

.view-text-value .sc-upld-files:hover {
    background: rgba(169, 165, 170, 0.08);
}

.sc-upld-files .normal-bnt {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50px;
}

.sc-upld-files .normal-bnt i {
    font-size: 13px;
}

.sc-upld-files .normal-bnt:hover {
    border-radius: 50px;
    background: #03a791;
    color: #fff;
}


/* ------ right Collapse ------------- */

.sf-collps-rt.ant-collapse {
    background: #fff;
    box-shadow: none;
    border: none;
}

.sf-collps-rt.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 15px;
}

.sf-collps-rt.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: #222;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding: 15px;
    background: transparent;
    border-radius: 4px 4px 0 0 !important;
}

.sf-collps-rt.ant-collapse .ant-collapse-content {
    border: none;
}

.sf-collps-rt .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.ant-calendar-body table tbody tr:nth-child(2n+1) {
    background: transparent;
}

.ant-calendar-body table tbody tr {
    border: 0;
}

.ant-calendar table {
    margin: 0;
}

.ant-calendar-body table thead {
    border: 1px solid #ddd;
}

.sf-form .ant-select,
.sf-form .ant-calendar-picker {
    width: 100%;
}

.sf-form .ant-select {
    min-width: 100px;
}

.defaults-activity .ant-select {
    min-width: 150px;
}


/* .sf-form .ant-select span.ant-select-arrow {
  background: #03A791;
  color: #fff;
  padding: 8px;
  top: 0;
  border-radius: 0 4px 4px 0;
  right: 0;
  display: inline-block;
  margin: 0;
  border: 1px solid #03A791;
} */

.sf-form .ant-select-selection,
.sf-form .ant-calendar-picker-input {
    height: 34px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #eff0f1 !important;
    color: #222;
    font-weight: 400;
    width: 100%;
}

.sf-form .ant-calendar-picker-icon {
    right: auto;
    left: 12px;
}

.sf-form .ant-calendar-picker-input {
    padding-left: 30px;
}

#g-recaptcha {
    transform: scale(0.7);
}

.sf-g-captcah>div {
    display: flex;
    justify-content: center;
    height: 66px;
    align-items: flex-end;
}

.col.org-logo-col {
    flex-basis: 100px;
    flex-grow: 1;
    max-width: 100px;
}

.view-logo {
    background: #7b1fa2;
    border-radius: 50px;
    height: 55px;
    width: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.view-logo img {
    /* height: 28px;
  width: auto; */
    height: 100%;
    width: 100%;
}

.view-text-value {
    line-height: normal;
}

.view-text-value label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
}

.view-text-value span {
    font-size: 15px;
    display: block;
    font-weight: 300;
    color: rgba(45, 59, 72, 0.8);
    word-break: break-all;
}

.view-text-value.lbl-b label {
    font-weight: 600;
    font-size: 14px;
    color: #222;
}


/* add borders */

.lbr-1 {
    border-left: 1px solid #eaebec;
}

.vtrow-bx {
    margin-bottom: 15px;
}


/* Subscription Level */

.view-text-value .sf-form {
    margin: 0;
}

.view-text-value .price-pm {
    display: inline-block;
    width: 100%;
    padding-top: 5px;
    color: #57626d;
    font-weight: 500;
}

.sf-badge {
    display: flex;
    justify-content: space-between;
}

.sf-badge .badge {
    color: #fff;
    background: #417505;
    font-weight: 400;
    font-size: 13px;
    border-radius: 50px;
    padding: 5px 10px 6px;
    display: inline-block;
    vertical-align: middle;
}

.sub-status-dtl {
    position: relative;
}

span.sub-pill {
    position: absolute;
    right: 0;
    height: 24px;
    border-radius: 100px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    width: 61px;
    line-height: 24px;
}

span.sub-pill.active {
    background: #417505;
}

span.sub-pill.inactive {
    background: rgba(45, 59, 72, 0.3);
}

.sub-amt-period {
    margin-top: 5px;
    display: flex;
}

.sub-amt-period span {
    font-size: 15px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: 600;
    padding-right: 2px;
}

.sub-amt-period span:first-child::after {
    content: "/";
    font-size: 15px;
    font-weight: 700;
    padding-left: 2px;
}


/* edit logo image */

.add-new-file {
    display: flex;
    align-items: center;
    justify-content: left;
    border: 1px solid rgba(45, 59, 72, 0.08);
    padding: 10px;
    border-radius: 4px;
}

.add-new-file .view-logo {
    flex: 0 0 55px;
}

.edit-img-title {
    padding-left: 20px;
}

span.edit-image-logo {
    color: #3cb4a4;
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.edit-img-title>span {
    display: flex;
    flex-direction: column;
}

.ant-upload-list-text {
    order: 1;
}

.add-new-file .edit-img-title .ant-upload-list-item-name {
    max-width: 150px;
    min-width: 100%;
    width: auto;
}

.add-new-file .edit-img-title .ant-upload-list-item {
    margin-top: 2px;
}


/* user management */

.bulk-import-files {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
}

.bulk-import-files .wnt-img {
    font-size: 64px;
    color: #e6e8e9;
    padding: 10px;
}

.blki-msg {
    padding-left: 10px;
}

.blki-msg h5 {
    font-weight: 500;
    font-size: 14px;
    color: #a1a7ad;
    margin-bottom: 2px;
}

.blki-msg span {
    color: #a1a7ad;
    font-weight: 500;
}

.blki-msg a {
    font-weight: 500;
}

.bulk-import-details {
    padding: 25px 10px 10px;
}

.bulk-import-details strong {
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

ul.imp-sum-us {
    list-style: none;
    margin: 0;
    padding: 15px 0;
    line-height: normal;
}

ul.imp-sum-us li {
    padding: 0;
    margin: 0;
    font-size: 13px;
    line-height: normal;
}

ul.imp-sum-us li span {
    width: 100px;
    display: inline-block;
    padding-right: 5px;
    line-height: normal;
}

ul.imp-status-us {
    margin: 0;
    padding: 0;
    line-height: normal;
    list-style: none;
}

ul.imp-status-us li {
    padding: 0 15px 0 0;
    margin: 0;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
}

ul.imp-status-us li b {
    display: inline-block;
    font-weight: 400;
    width: 100%;
}


/* upload file  */

.upload-sfv-file .ant-upload.ant-upload-drag {
    border: 1px solid rgba(45, 59, 72, 0.08);
    background: #fff;
    border-radius: 2px;
    padding: 15px;
}


/* .upload-sfv-file .ant-upload.ant-upload-drag{
  border: none;
  background: transparent;
  padding: 0;
} */

.upload-sfv-file .ant-upload-text {
    border: 1px solid #03a791;
    display: inline-block;
    border-radius: 6px;
    padding: 4px 15px 5px;
    font-size: 12px !important;
    line-height: normal;
    color: #03a791 !important;
}

.upload-sfv-file .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 12px;
}

.upload-sfv-file .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 35px;
}

.upload-sfv-file .ant-upload-drag-icon {
    margin-bottom: 0 !important;
    height: 48px;
}

.upload-sfv-file .ant-upload-drag-icon i.material-icons {
    font-size: 38px;
    color: #03a791;
}

.user-p-notxt img {
    height: 40px;
}

.user-p-notxt span {
    padding: 0 15px;
    font-size: 13px;
    line-height: normal;
}

.upload-sfv-file .ant-upload-list.ant-upload-list-text {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.upload-sfv-file.up-sign-doc .ant-upload-list.ant-upload-list-text {
    display: block;
    visibility: visible;
    opacity: 1;
}

.uplf-success,
.uplf-failed {
    font-size: 12px;
    color: #03a791;
    font-weight: 400;
}

.uplf-success {
    display: flex;
    align-items: center;
    justify-content: center;
}

.uplf-success i {
    background: #03a791;
    font-size: 7px;
    height: 10px;
    width: 10px;
    line-height: 13px;
    margin-right: 4px;
    display: inline-block;
    border-radius: 100px;
    color: #fff;
}

.uplf-failed {
    color: #d0021b;
}

.uplf-failed i {
    display: inline-block;
    padding-right: 4px;
}

.uplod-txt {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    width: 100%;
}

.upload-sfv-file .ant-progress-status-success .ant-progress-bg {
    background-color: #03a791;
    height: 6px !important;
}

.file-up-msg {
    width: 60%;
    margin: 0 auto;
}

.upload-sfv-file .ant-progress-status-success .ant-progress-text {
    display: none;
}

.upload-sfv-file .ant-progress-outer {
    padding: 0;
    margin: 0;
}

.big-error {
    background: #fdf3f4;
    padding: 12px 15px;
    line-height: normal;
    color: #d0021b;
    font-weight: 400;
    font-size: 13px;
    margin-top: 15px;
    word-break: break-word;
}


/* checkbox groups ---- user management */

.sf-checkbox-b {
    margin: 0;
    padding: 0;
}

.sf-checkbox-b h5 {
    font-weight: 400;
}

.more-role-prmison h4 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
}

.sf-chkbx-group {
    display: flex;
    flex-wrap: wrap;
}

.split-job-plp .sf-chkbx-group label.ant-checkbox-wrapper {
    height: 15px;
    margin-bottom: 0;
}

.sf-chkbx-group label.ant-checkbox-wrapper {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    margin-bottom: 4px;
    line-height: 1;
    align-items: flex-start;
}

.sf-row-bx.more-role-prmison .sf-chkbx-group .cu-checkbox {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2px;
}

.sf-chkbx-group .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #03a791;
    width: 4px;
    height: 8px;
    top: 48%;
    left: 25%;
    transition: none;
}

.sf-chkbx-group .ant-checkbox-wrapper+span,
.sf-chkbx-group .ant-checkbox+span {
    padding-left: 8px;
    padding-right: 4px;
}

.sf-chkbx-group .ant-checkbox-checked .ant-checkbox-inner {
    background: #fff;
    border-color: #979797;
}

.sf-chkbx-group .ant-checkbox-inner {
    height: 14px;
    width: 14px;
}

.error,
.error b {
    color: #d0021b;
}

.view-text-value span.error-input,
.error-input,
span.est-items span.error-input {
    font-size: 11px;
    color: #d0021b;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    padding-top: 5px;
}

span.est-items span.error-input {
    position: static;
    background: transparent;
    padding: inherit;
    border-radius: 0;
    line-height: normal;
    height: inherit;
}

.sf-row-bx {
    margin-bottom: 15px;
    border-bottom: 1px solid #fdf3f4;
    padding-bottom: 15px;
}

.sf-row-bx:last-child {
    padding-bottom: 0;
    border: 0;
    margin-bottom: 0;
}

.sf-row-bx .sf-chkbx-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sf-row-bx .sf-chkbx-group .cu-checkbox {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 2px;
}


/* has scroll on button click */

.sf-scroll-bar {
    overflow-x: hidden;
    overflow-y: scroll;
}

.sf-scroll-bar::-webkit-scrollbar {
    width: 2px;
    border-radius: 100px;
}

.sf-scroll-bar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sf-scroll-bar::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.1);
}

.sf-scroll-bar:hover::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.2);
}


/* all scroll heights */

.h-400 {
    max-height: 500px;
    min-height: auto;
}


/* left menu scroll bar */

.sf-service-list.has-scroll .ant-list-items {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 628px;
}

.sf-service-list.has-scroll .ant-list-items::-webkit-scrollbar {
    width: 2px;
    border-radius: 100px;
}

.sf-service-list.has-scroll .ant-list-items:hover::-webkit-scrollbar {
    width: 5px;
}

.sf-service-list.has-scroll .ant-list-items::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sf-service-list.has-scroll .ant-list-items::-webkit-scrollbar-thumb {
    background: #3cb4a4;
}


/* switch style */

.sf-form .ant-switch {
    max-width: 44px;
}


/* readonly textbox */

.hide-txtbox,
.hide-txtbox:focus,
.text-box-disable input[type="text"] {
    border: none !important;
    box-shadow: none !important;
    background: #f1f1f1 !important;
    cursor: not-allowed;
}


/* not me button */

.outr-notme {
    position: relative;
}

.notmetxt {
    position: absolute;
    right: 10px;
    top: 18px;
    font-weight: 500;
    font-size: 12px;
    z-index: 9;
    background: #FC544E;
    line-height: normal;
    padding: 2px 8px 4px;
    margin: 0;
    border-radius: 5px;
    height: auto;
    color: #fff;
}

.rember-me {
    margin-top: 10px;
}


/* Roles permission buttons */

.sf-roles-group .ant-radio-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.sf-roles-group .ant-radio-button-wrapper,
.sf-roles-group .ant-radio-button-wrapper:first-child {
    border: 0;
    border-radius: 0;
    width: 50%;
    padding: 0 6px 12px;
    height: auto;
}

.view-perm-items {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
    padding-bottom: 5px;
}

.view-perm-items .sf-chkbx-group {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.view-perm-items .sf-chkbx-group span {
    padding-right: 20px;
    margin: 4px 0;
}

span.rbp-btn {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    height: 50px;
    position: relative;
}

.sf-roles-group .ant-radio-button-wrapper-checked span.rbp-btn:after {
    content: "\F00C";
    background: #03a791;
    width: 12px;
    color: #fff;
    font-size: 7px;
    height: 12px;
    border-radius: 50px;
    display: inline-block;
    position: absolute;
    right: -4px;
    text-align: center;
    top: -4px;
    font-family: FontAwesome;
}

.ant-radio-button-wrapper:not(:first-child)::before,
.ant-radio-button-wrapper-checked::before {
    contain: none;
    background: transparent !important;
    opacity: 0 !important;
}

.ant-radio-button-wrapper-checked {
    box-shadow: none !important;
}

.sf-roles-group .ant-radio-button-wrapper-checked span.rbp-btn {
    border: 1px solid #03a791;
}

.sf-roles-group .ant-radio-button-wrapper-checked:focus-within {
    outline: 0;
}


/* sidebar slide menu */

.sideBarOverlay::after {
    content: "";
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.42);
    z-index: 99;
}


/* dashboard */

.dashb-heading {
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 23px;
}

.sf-dashb h4 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
}

.sf-dashb p {
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    line-height: normal;
}

.sf-card.sf-dash-items {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
    text-align: center;
    padding: 18px 20px;
    margin: 0 0 20px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: 0.5s all;
}

.sf-card.sf-dash-items:hover {
    border: 1px solid #03a791;
}

.sf-dash-items i {
    color: #03a791;
    font-size: 30px;
}

.sf-dash-items h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0;
}

.sf-dash-items p,
.sf-modal-hd p {
    font-size: 14px;
    font-weight: 300;
    padding: 0;
}

.sf-card-head.sf-dashb {
    position: relative;
    padding: 0 20px;
    margin-bottom: 20px;
}

.sf-dashb button.closed-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.sf-card.sf-dash-items.active {
    /* opacity: 0.6; */
    border: 0;
    cursor: default;
}

.sf-modal-hd {
    text-align: center;
}

.sf-modal-hd h3 {
    margin-bottom: 2px;
}

.sf-intro-modal .ant-modal-footer {
    display: none;
}

.sf-intro-modal .ant-modal-close {
    background: transparent;
    color: #222 !important;
    padding: 0;
    line-height: normal;
    height: auto;
}

.sf-intro-modal .ant-modal-close .ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.dash-dtrmind .dash-dtrmn-card {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dtrmin-hd {
    padding: 100px 0;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    color: rgba(169, 165, 170, 0.45);
}


/* profile */

.profile-tabs .ant-tabs-nav-container {
    height: auto !important;
}

.profile-tabs .ant-tabs-bar {
    border: 0;
    margin-bottom: 30px;
    border-radius: 16px !important;
}

.profile-tabs .ant-tabs-tab .tab-item {
    display: grid;
    text-align: center;
}

.profile-tabs .ant-tabs-tab .tab-item i {
    font-size: 30px;
}

.ant-tabs.ant-tabs-card.profile-tabs .ant-tabs-card-bar .ant-tabs-tab {
    background: #f0efee;
    border-radius: 16px !important;
    height: 85px;
    padding-top: 10px;
    color: #362148;
    width: 20%;
    font-size: 14px;
    white-space: break-spaces;
    overflow-wrap: break-word;
    line-height: 1.4;
    margin: 0 6px;
}

.ant-tabs.ant-tabs-card.profile-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
    background: #362148;
    color: #e8ff84;
    border-radius: 16px !important;
    height: 85px;
    padding-top: 10px;
    font-size: 14px;
    width: 20%;
    margin: 0 6px;
    white-space: break-spaces;
    overflow-wrap: break-word;
    line-height: 1.4;
}

.ant-tabs.ant-tabs-card.profile-tabs .ant-tabs-card-bar .ant-tabs-tab:first-child {
    border-radius: 4px 0 0 0;
}

.tab-wraper {
    padding: 0 25px 25px;
}

.psnl-pro-table.sf-table tr th {
    font-weight: 500;
    color: #222;
    font-size: 14px;
}

.psnl-pro-table.sf-table td .sf-form label {
    display: none;
}

.psnl-pro-table.sf-table .sf-form {
    margin: 0;
}


/* payment details */

.hascarddtl {
    position: relative;
}

.ewcard-icons {
    display: inline-block;
    position: absolute;
    z-index: 99;
    right: 5px;
    top: 36px;
}

.ewcard-icons span img {
    width: 30px;
    height: auto;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.5;
}

.Visa .visa-card img,
.mastercard .master-card img,
.amex .amex-card img,
.dinner .dinner-card img {
    -webkit-filter: grayscale(0%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
}

.ewcard-icons span {
    background: #fbfbfb;
    padding: 0;
    margin-left: 3px;
    line-height: 31px;
    height: 25px;
    width: 40px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}

.or-w-br {
    width: 100%;
    text-align: center;
    position: relative;
    margin: 15px 0;
    text-transform: uppercase;
}

.or-w-br:after {
    position: absolute;
    left: 0;
    top: 10px;
    content: "";
    width: 100%;
    height: 1px;
    background: #d8d8d8;
}

.or-w-br span {
    position: relative;
    z-index: 2;
    background: #fff;
    padding: 3px 10px;
}

.or-swtpay-bnt button.bnt {
    width: 100%;
}


/* profile pages */

.user-profile-card {
    background: #4a4a4a;
    border-radius: 4px 4px 0 0;
    text-align: center;
    padding: 10px;
}

.profile-pic {
    height: 80px;
    margin: 0 auto;
    width: 80px;
    font-size: 30px;
    color: #fff;
    line-height: 75px;
    text-align: center;
    border-radius: 100%;
    border: 4px solid #fff;
    position: relative;
}

.profile-pic img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    float: left;
    object-fit: cover;
}

.profile-loader {
    background: transparent;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100%;
}

.profile-loader.otherloader {
    background: rgba(0, 0, 0, 0.1);
    display: inline-block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 0;
    z-index: 9999;
}

.user-title {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin: 10px 0 0;
}

.user-title span {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
}

.upload-pic {
    position: absolute;
    right: -4px;
    bottom: 30px;
    width: 25px;
    height: 25px;
}

.upload-pic button.ant-btn {
    background: #fff;
    border: 0;
    width: 25px;
    height: 25px;
    font-size: 12px;
    text-align: center;
    color: #03a791;
    padding: 0;
    border-radius: 50px;
}

.upload-pic button.ant-btn i.fa {
    color: #03a791;
}

.usr-pro-footer {
    background: rgba(169, 165, 170, 0.45);
}

.usr-pro-body {
    padding: 20px;
}

.usr-pro-body h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.usr-pro-body p {
    font-weight: 300;
    margin: 0;
}

.usr-pro-footer {
    background: rgba(169, 165, 170, 0.45);
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}

.pro-status {
    width: 100%;
    border-right: 1px solid #d8d8d8;
    padding: 10px;
    text-align: left;
}

.pro-status:last-child {
    border-right: 0;
}

.pro-status strong {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
}

.pro-status span {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
}

.ovhdn {
    overflow: hidden;
}

.dob-dropd {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
}

.dob-dropd .sf-select {
    flex: auto;
    padding: 0 5px;
}

.dob-dropd .sf-select label {
    display: none;
}

.dob-dropd .sf-select:first-child {
    padding-left: 0;
}

.dob-dropd .sf-select:last-child {
    padding-right: 0;
}

.sf-form .dob-dropd .ant-select {
    min-width: 100%;
}

.drop-mw .ant-select-dropdown,
.ant-select-dropdown {
    min-width: 80px;
    max-width: 100%;
}

.drop-mw .ant-select-dropdown-menu-item {
    text-overflow: unset;
}

.select-wibg .ant-select-arrow,
.service-table .view-text-value span.ant-select-arrow {
    background: #03a791;
    padding: 5px;
    line-height: normal;
    color: #fff;
    top: 0;
    height: 100%;
    right: 0;
    width: 30px;
    margin: 0;
    border-radius: 0 4px 4px 0;
}

.select-wibg .ant-select-arrow .ant-select-arrow-icon {
    display: block;
    height: 13px;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: normal;
    transform: translate(-50%, -50%);
}

.sf-table,
.sf-table tr th,
.sf-table tr td {
    border: 0;
    padding: 2px 4px;
}

.sf-table {
    margin: 0;
    width: 100%;
}

button.exp-bnt {
    border-radius: 4px;
    color: #fff;
    height: 34px;
    width: 34px;
    text-align: center;
    line-height: 34px;
    padding: 0;
    border: 0;
    transition: 0.5s all;
}

button.exp-bnt.delete {
    background: #d0021b;
}

button.exp-bnt.add {
    background: #03a791;
}

button.exp-bnt.delete:hover,
button.exp-bnt.add:hover {
    background: #008572;
}

.w-30 {
    width: 30%;
}

.w-5px {
    width: 50px;
}

.strong {
    font-weight: 600;
}

.exp-details {
    margin: 5px 0 0;
    padding: 0;
}

.exp-details li {
    list-style: none;
    padding: 0 0 5px;
    margin: 0 0 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.exp-details li:last-child {
    border-bottom: 0;
    padding: 0;
    margin: 0;
}

.exp-details li span {
    font-size: 14px;
    font-weight: 300;
}


/* sf view details grid  */

.data-v-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.data-v-col {
    border-right: 1px solid rgba(45, 59, 72, 0.08);
    margin-bottom: 15px;
}

.data-v-col:last-child {
    border: 0;
}

.data-v-col .view-text-value {
    line-height: normal;
    padding: 0;
}

.data-v-row .data-v-col .view-text-value {
    padding: 0 15px;
}

.sf-table.sf-t-br-strip.rostr-table th,
.sf-table.sf-t-br-strip.rostr-table tr td:first-child {
    font-size: 15px;
    font-weight: 500;
    color: #222;
}

.sf-table.sf-t-br-strip th,
.sf-table.sf-t-br-strip td {
    padding: 10px;
}

.sf-table.sf-t-br-strip tr td {
    border-bottom: 1px solid #d8d8d8;
}

.sf-table.sf-t-br-strip tr:nth-child(even) td {
    background: #f1f1f1;
}

.rostr-table .w-60 {
    width: 60% !important;
}


/* licence */

.no-licence {
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    padding: 50px 0;
}

.no-licence h2 {
    font-size: 16px;
    color: rgba(45, 59, 72, 0.45);
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: 15px 0;
}

.no-licence h2 strong {
    font-weight: 600;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}

.no-licence h2 span {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.licence-list {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 15px;
    border: 1px solid #efefef;
    border-radius: 4px;
    margin-bottom: 15px;
    background-color: #f7f7f6;
}

.nowrap.licence-list {
    flex-wrap: nowrap;
}

.li-details {
    padding-left: 20px;
    flex: 0 0 60%;
    width: 60%;
}

.li-details h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.li-lists {
    list-style: none;
    margin: 5px 0 8px;
    padding: 0;
}

.li-lists li {
    padding: 1px 0;
    font-size: 15px;
    font-weight: 300;
}

.action-bnt {
    text-align: right;
}

.action-bnt button {
    border: 1px solid #03a791;
    background: transparent;
    width: 25px;
    padding: 0;
    height: 25px;
    line-height: 20px;
    border-radius: 50px;
    margin-left: 5px;
    text-align: center;
}

.action-bnt button i.fa {
    color: #03a791;
    font-size: 13px;
}

.licence-list .action-bnt {
    display: flex;
    justify-content: flex-end;
}

.licence-list .action-bnt a,
.licence-list .action-bnt button {
    display: inline-block;
    border: 1px solid #362148;
    background: transparent;
    width: 26px;
    height: 26px;
    padding: 0;
    line-height: 24px;
    border-radius: 50px;
    margin-left: 5px;
    text-align: center;
}

.licence-list .action-bnt button i,
.licence-list .action-bnt a i {
    color: #362148;
    font-size: 14px;
}

.licence-list .action-bnt a i {
    font-size: 16px;
}

.licence-list .action-bnt a {
    line-height: 30px;
}

.action-bnt button:hover,
.licence-list .action-bnt a:hover {
    background: #362148;
}

.action-bnt button:hover i.fa,
.licence-list .action-bnt a:hover i {
    color: #fff;
}

.upload-d-file span {
    display: inline-block;
    width: 100%;
    color: #03a791;
    font-size: 10px;
    font-weight: 400;
}

.upload-d-file label {
    margin-bottom: 5px;
}

.sf-chkbx-group.nrml-txt label.ant-checkbox-wrapper {
    font-size: 14px;
    color: #222;
}

.medic-content h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
}

.medic-content p {
    margin: 10px 0;
}

i.sf-note {
    font-weight: 300;
    font-size: 14px;
}

.sf-sign-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-upload.sf-signature {
    flex: auto;
}

button.clear-txt {
    background: transparent;
    padding: 0;
    color: #03a791 !important;
    font-weight: normal;
    text-decoration: underline;
    margin: 0 15px;
    width: 50px;
    height: auto;
}

.ts-report-items {
    border-bottom: 1px solid rgba(45, 59, 72, 0.05);
}

.crt-licence-lists {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
}

.crt-licence-lists li a {
    color: #03a791 !important;
    text-decoration: none !important;
    font-size: 13px !important;
}

.crt-licence-lists li {
    padding: 2px 0;
}

.crt-licence-lists button.normal-bnt i {
    padding-right: 10px;
}

.crt-licence-lists button.normal-bnt {
    white-space: pre-line;
    word-break: break-all;
    text-align: left;
}

.sf-signature.logo-upload .ant-upload.ant-upload-drag {
    border-style: dashed;
}

.sf-signature.logo-upload .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 14px;
    color: #222;
    font-weight: 300;
}

.sf-table.rostr-table td,
.sf-table.rostr-table tr {
    padding: 10px 15px;
}

.rostr-table .sf-form label {
    display: none;
}

.rostr-table .sf-form input[type="text"] {
    height: 30px;
}

.leave-lists {
    list-style: none;
    padding: 0;
    margin: 0;
}

.leave-items {
    padding: 0;
    margin: 0 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.leave-items:last-child {
    margin: 0;
}

.leave-items strong {
    font-size: 13px;
    flex: 100% 1;
    font-weight: 500;
    color: #222222;
}

.leave-items strong span {
    display: inline-block;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    color: #777;
    padding-top: 3px;
}

.leave-items strong.l-apprvd {
    text-align: right;
}

.leave-items strong.l-apprvd span {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #417505;
    font-size: 10px;
    font-weight: 500;
}

.pro-leave button.bnt.bnt-active {
    font-size: 12px;
    line-height: 31px;
}

.leave-items i {
    color: #979797;
}

.visiable-5 {
    opacity: 0.5;
}

.time-o-dtl {
    display: flex;
    margin-left: -10px;
}

.time-o-dtl .cust-dpicker,
.time-o-dtl .sf-select {
    padding-left: 10px;
    width: auto;
}

.time-o-dtl .ant-time-picker {
    padding-left: 10px;
    width: auto;
}


/* invite user table */

.invite-user table tr th {
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
}

.invite-user table thead {
    border: none;
}

.invite-user table .ant-table-thead>tr>th,
.invite-user table .ant-table-tbody>tr>td {
    padding: 10px;
}

.ant-table-pagination.ant-pagination {
    margin: 20px 0 0;
}

.bnt-simple {
    background: transparent;
    height: auto;
    line-height: normal;
    padding: 0;
    margin: 0;
    color: #03a791 !important;
    font-weight: 400;
    font-size: 12px;
}

.bnt-simple:hover {
    background: transparent;
    text-decoration: underline;
}

.bnt-simple-v2 {
    background: transparent;
    height: auto;
    line-height: normal;
    padding: 0;
    margin: 0;
    color: #362148 !important;
    font-weight: 600;
    font-size: 12px;
}

.bnt-simple-v2:hover {
    background: transparent;
    text-decoration: underline;
}

.bnt-disable {
    background: rgba(45, 59, 72, 0.1);
    color: rgba(45, 59, 72, 0.3);
    border: 0;
}

.sf-row-bx.role-per-lists .sf-chkbx-group {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.sf-row-bx .sf-chkbx-group .permission-items .cu-checkbox {
    flex: auto;
    max-width: 100%;
}

.sf-row-bx .sf-chkbx-group .permission-items {
    flex: auto;
    min-width: 30%;
    max-width: 33.33%;
}

.sf-row-bx.edit-pr-items .sf-chkbx-group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.sf-row-bx.edit-pr-items .sf-chkbx-group .edit-pr-list {
    flex: 0 0 50%;
    width: 50%;
}


/* Service Agent */

.sr-agent-tbl tr td {
    padding: 8px 10px;
}

.sr-agent-tbl tr:nth-child(odd) td {
    background: #f1f1f1;
}

.add-n-industry .sf-form {
    border-top: 1px solid #f1f1f1;
    padding: 10px 15px 0;
}

.auto-search-txt.ttab-input {
    padding: 0;
    width: 50%;
}

.all-selt-tags {
    padding: 0 15px 7px;
}

.all-selt-tags .ant-tag {
    background: #eeeff0;
    border: 0;
    padding: 3px 25px 3px 20px;
    min-width: 100px;
    border-radius: 50px;
    position: relative;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #222;
    margin-bottom: 8px;
}

.all-selt-tags .ant-tag .anticon {
    background: #222;
    color: #fff;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1;
}

.all-selt-tags .ant-tag:hover {
    color: #fff;
    background: #03a791;
}

.all-selt-tags .ant-tag:hover .anticon {
    color: #03a791;
    background: #fff;
}

.add-n-industry.sub-ind-cd .sf-form {
    padding: 0 0 10px;
    border: none;
}

.add-n-industry.sub-ind-cd .sf-form span.ant-input-affix-wrapper {
    width: 100%;
}

.sf-form .auto-search-txt .ant-select-selection {
    border: 0 !important;
}

.con-org-list span {
    display: inline-block;
    width: 100%;
    padding: 0 0 5px;
    font-size: 13px;
    font-weight: 400;
}

.con-ord-mh {
    min-height: 250px;
}

strong.price-pm {
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
    color: #777;
}

.due-pay {
    font-size: 30px;
    font-weight: 300;
    margin: 0;
    color: #a7a7a7;
    padding: 0;
    /* border-bottom: 1px solid #efefef; */
}

.due-pay span {
    font-weight: 600;
    color: #03a791;
}

.saved-pay-card {
    background: #fff;
    border-radius: 4px;
    margin: 25px 1px;
    box-shadow: 0 1px 4px rgba(45, 59, 72, 0.1);
}

.sp-card-dtl {
    border-bottom: 1px solid #efefef;
    padding: 15px;
}

.sp-card-dtl label {
    font-size: 13px;
    margin: 0;
    font-weight: 500;
    padding: 0;
    color: #777;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.sp-card-dtl span {
    font-size: 18px;
    color: #222;
    font-weight: 500;
    letter-spacing: 1px;
}

.spdts .sp-card-dtl {
    border-bottom: 0;
    border-left: 1px solid #efefef;
}

.spdts .col-md-6:first-child .sp-card-dtl {
    border-left: 0;
}

.pay-date-slt .select-wibg label {
    display: none;
}

.add-nm-card .pay-date-slt .select-wibg {
    width: 100%;
}

.add-nm-card {
    padding: 20px;
}

.bnt.bnt-normal.wx1 {
    width: 130px !important;
    padding: 0 4px;
}

.ant-btn>i,
.ant-btn>span {
    color: #03a791;
}

.ant-btn.ant-btn-primary>span {
    color: #fff;
}


/* edit and update service industries */

.sf-card-body.update-industry {
    padding: 0 0 15px;
}

.sfs-cat-sub.sub-cat-update {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.sub-cat-update::-webkit-scrollbar {
    height: 3px;
    border-radius: 100px;
}

.sub-cat-update::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sub-cat-update::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.1);
}

.sub-cat-update:hover::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.2);
}


/* view service agent */

.sa-cat-heading {
    position: relative;
    padding: 15px 0 15px 45px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}

.view-text-value.sa-cat-heading label {
    margin: 0;
}

.sa-sub-cat {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
}

.sa-sub-cat:last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.sa-sub-cat .view-text-value label {
    font-size: 14px;
}

.sa-sub-cat .view-text-value {
    flex: 0 0 70%;
}

.cat-view-value.view-text-value {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid rgba(45, 59, 72, 0.1);
    flex: 0 0 30%;
}

span.sub-cat-value {
    display: flex;
    flex-wrap: wrap;
}

span.sub-cat-value span i {
    padding-right: 5px;
}

span.sub-cat-value span:last-child {
    padding-right: 0;
}

.sa-cat-pleft {
    padding-left: 45px;
}


/* induction */

.courses-dtls {
    margin-bottom: 30px;
}

.sf-course-wrap {
    margin-top: 30px;
}

.course-items {
    background: #f0f2f5;
    padding: 20px 20px 35px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: normal;
}

.course-items i {
    font-size: 34px;
    color: #03a791;
}

.course-value-dtl {
    padding-left: 20px;
    position: relative;
}

.course-value-dtl:after {
    content: "";
    width: 40px;
    height: 1px;
    background: #d8d8d8;
    position: absolute;
    bottom: -15px;
    left: 20px;
}

.course-value-dtl h2 {
    margin: 0;
    padding: 0;
    line-height: normal;
    font-size: 32px;
    font-weight: bold;
    color: #03a791;
}

.course-value-dtl span {
    color: #2d3b48;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
}

.courses-lists .ant-tabs {
    overflow: unset;
}

.fillters-courses .ant-tabs-bar {
    border: none;
    margin: 0;
    background: transparent;
}

.fillters-courses.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: auto;
    background: #fff;
    border: 0;
    padding: 0 5px;
    position: relative;
    margin-right: 20px;
}

.fillters-courses.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .tab-item {
    color: rgba(45, 59, 72, 0.45);
    font-size: 12px;
    font-weight: 400;
}

.fillters-courses .ant-tabs-tab-active:after {
    content: "";
    background: #03a791;
    height: 2px;
    width: 100%;
    bottom: 0px;
    left: 0;
    z-index: 99;
    position: absolute;
}

.sfc-progbr .ant-progress-show-info .ant-progress-outer {
    margin: 0;
    padding: 0;
}

.sfc-progbr .ant-progress-text {
    width: auto;
    margin: 0;
    color: rgba(45, 59, 72, 0.45);
    font-size: 12px;
    line-height: normal;
}

.prnt-txt {
    position: absolute;
    bottom: -1px;
    left: 28px;
    font-size: 12px;
    color: rgba(45, 59, 72, 0.45);
}

.sfc-total-time {
    font-size: 12px;
    color: rgba(45, 59, 72, 0.45);
    display: inline-block;
    width: 100%;
    text-align: right;
    margin-top: 10px;
}

.sfc-progbr {
    position: relative;
}

.sfc-progbr .ant-progress {
    line-height: normal;
}

.sf-courses {
    border: 1px solid rgba(45, 59, 72, 0.08);
    border-radius: 4px;
    background: #fff;
    margin-bottom: 20px;
}

.cors-img {
    overflow: hidden;
    min-height: 130px;
    text-align: center;
}

.cors-img img {
    width: 100%;
}

.cors-contnt {
    padding: 10px;
}

.cors-contnt h4 {
    font-weight: 400;
    font-size: 13px;
    margin: 0;
}


/* pagination */

ul.ant-pagination.sf-pagination {
    margin: 30px 0 10px;
    display: flex;
    justify-content: flex-end;
}

ul.ant-pagination>li {
    padding: 0;
}

ul.ant-pagination .ant-pagination-item-active {
    background: #03a791;
    color: #fff;
}

.progress-dtl {
    width: calc(100% - 420px);
    margin: 10px 0px;
}

.progress-dtl .steps-progress {
    width: 100%;
}

.pro-hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pro-hd h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #222;
    padding-right: 10px;
}

.dash-steps {
    text-align: left;
    line-height: normal;
    color: #03a791;
    display: flex;
    align-items: center;
}

.dash-steps b {
    font-size: 24px;
    font-weight: 700;
    display: inline-block;
    color: #03a791;
    width: auto;
    padding-right: 10px;
    padding-bottom: 0;
}

.dash-steps span {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
}

.steps-progress {
    position: relative;
    margin: 0;
}

.steps-progress .ant-progress-show-info .ant-progress-outer {
    margin: 0;
    padding: 0;
}

.steps-progress .ant-progress-text {
    position: absolute;
    top: 1px;
    right: 10px;
    width: auto;
    font-size: 11px;
    margin: 0;
    color: #fff;
    font-weight: 500;
    display: none;
}


/* .steps-progress .ant-progress-text {
  position: absolute;
  top: 5px;
  right: 1px;
  width: auto;
  font-size: 11px;
  margin: 0;
  height: 14px;
  background: #03a791;
  border-radius: 100%;
  color: #fff;
  font-weight: 500;
  padding: 1px 4px;
} */

.steps-progress .ant-progress-text i {
    font-size: 14px;
    position: absolute;
    left: -6px;
    top: 0px;
}

ul.steps-lists {
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
}

.steps-lists li {
    margin: 0;
    padding: 4px 0;
    color: #222222;
    font-size: 14px;
}

.steps-lists li i {
    color: #03a791;
    padding-right: 10px;
}

.steps-lists li span {
    text-decoration: underline;
}

.steps-lists li.active {
    opacity: 0.6;
}

.steps-lists li.active span {
    text-decoration: none;
}

.sagt-tabg.auto-search-txt .anticon {
    color: rgba(45, 59, 72, 0.4);
}


/* Scope Doc */

.sf-form.lsico .ant-calendar-picker-icon {
    right: 12px;
    left: auto;
    color: #03a791;
}

.sf-form.lsico .ant-calendar-picker-input {
    padding-left: 10px;
    padding-right: 25px;
}

.client-name {
    position: relative;
    width: 100%;
}

.service-table .view-text-value span.srch-icons {
    color: rgba(169, 165, 170, 0.45);
}

.srch-icons {
    position: absolute;
    right: 36px;
    top: 11px;
    font-size: 12px;
    color: rgba(169, 165, 170, 0.45);
}

.client-name i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.add-n-site {
    padding: 15px;
    border: 1px solid rgba(45, 59, 72, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
}

.estm-lists {
    padding: 0 15px;
}

.view-a-n-task .estm-lists {
    padding: 0;
}

.add-task-bnt {
    margin-left: 15px;
}

.view-a-n-task .add-task-bnt {
    margin-left: 0;
}

.sc-add-task {
    padding: 15px 25px 15px 15px;
    background: rgba(45, 59, 72, 0.04);
    border-radius: 4px;
    position: relative;
}

.estimate-lists {
    display: flex;
    margin-left: -5px;
}

.est-items {
    padding-left: 5px;
    position: relative;
}

.est-items span {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 0;
    font-size: 10px;
    background: #ddd;
    font-weight: bold;
    height: 100%;
    padding: 4px 5px;
    line-height: 27px;
    border-radius: 0 4px 4px 0;
}

.scope-upload .ant-upload.ant-upload-drag {
    height: 65px;
    overflow: hidden;
}

.scope-upload .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
}

.scope-upload .ant-upload.ant-upload-drag .ant-upload-drag-container {
    /* display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 10px; */
}

.logo-upload.scope-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    /* text-align: left; */
    margin: 0;
    /* height: 18px; */
}

.logo-upload.scope-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon i {
    font-size: 21px;
    color: #03a791;
}

.logo-upload.scope-upload .ant-upload.ant-upload-drag p.ant-upload-text {
    /* text-align: left;
  padding-right: 0; */
    font-weight: 500;
    opacity: 0.8;
}

.show-friquen .normal-bnt {
    margin: 0 0 10px;
}

.add-line-bnt {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.add-line-bnt i {
    font-size: 18px;
    padding-right: 3px;
}

.delete-task-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}

.delete-task-btn:hover {
    color: #008572;
}

.ant-collapse-item.sc-doc-panel {
    border: none;
}

.ant-collapse-item.ant-collapse-item-active.sc-doc-panel {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
}

.sf-roles-group.add-sub-mod {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
}

.add-sub-mod .cu-checkbox {
    flex: 0 0 50%;
    padding: 0 10px 10px 0;
    max-width: 50%;
    margin: 0;
}

.add-sub-mod .ant-checkbox-wrapper+span,
.add-sub-mod .ant-checkbox+span {
    padding: 0;
    font-size: 13px;
    white-space: pre-wrap;
    word-break: break-word;
}

.add-sub-mod .ant-checkbox-wrapper {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    height: 50px;
    position: relative;
}

.add-sub-mod .ant-checkbox-wrapper-checked {
    border: 1px solid #03a791;
}

.add-sub-mod .ant-checkbox-wrapper .ant-checkbox {
    position: absolute;
    right: -6px;
    top: -6px;
    display: none;
    opacity: 0;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
}

.add-sub-mod .ant-checkbox-wrapper-checked .ant-checkbox {
    display: block;
    opacity: 1;
}

.add-sub-mod .ant-checkbox-wrapper-checked span {
    color: #03a791;
}

.add-sub-mod .ant-checkbox-wrapper .ant-checkbox-inner {
    border-radius: 50px;
}

.pbx-5 {
    padding-bottom: 5px;
}


/* sub panel  */

.swms-hd {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.swms-hd i {
    font-size: 13px;
    color: #03a791;
    padding-right: 5px;
}

.rfcl-img {
    width: 28px;
    height: 28px;
    background: rgba(3, 167, 145, 0.21);
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 34px;
    margin-right: 9px;
}

.rfcl-img img {
    width: 15px;
    height: auto;
}

.ant-collapse-item.swms-panel {
    border: none;
}

.swms-collapse.sf-collps-rt.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-size: 13px;
    background: rgba(229, 225, 231, 0.4);
    padding: 5px 10px;
    color: #4a4a4a;
    font-weight: bold;
    border: 1px solid rgba(45, 59, 72, 0.08);
    border-radius: 2px !important;
    margin: 10px 0 0;
}

.sf-collps-rt .swms-panel .ant-collapse-content>.ant-collapse-content-box {
    padding: 15px 0 5px;
}

.swms-body {
    padding: 0 10px;
}

.swms-check-list .cu-checkbox {
    padding-bottom: 8px;
}

.swms-check-list .cu-checkbox:last-child {
    padding: 0;
}

.swms-check-list .ant-checkbox-wrapper {
    display: flex;
}

.swms-check-list .ant-checkbox-wrapper span {
    padding: 0;
    vertical-align: top;
    font-size: 13px;
    color: #222;
    font-weight: 500;
    flex: 100%;
    line-height: normal;
}

.swms-check-list span.ant-checkbox {
    padding-right: 8px;
    position: relative;
    top: 0;
    flex: auto;
}

.ant-checkbox-checked::after {
    content: none;
}

.swms-check-list .ant-checkbox-wrapper span.exp-dt {
    display: block;
    color: #ddd;
    font-size: 12px;
    font-weight: normal;
}

.swms-check-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin: 6px 0 0;
}

.chemocal-dtl {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.chemocal-dtl a {
    font-size: 11px;
    text-decoration: underline;
}

.chemocal-dtl a:hover {
    color: #222;
}

.sf-card.add-swms-pnl {
    border: 1px solid rgba(45, 59, 72, 0.06);
    margin: 20px 0;
    box-shadow: none;
}

.scope-upload.chml-up-doc .ant-upload.ant-upload-drag .ant-upload-drag-container {
    flex-wrap: wrap;
    flex-direction: column;
}

.logo-upload.scope-upload.chml-up-doc .ant-upload.ant-upload-drag p.ant-upload-text {
    text-align: center;
}

.scope-upload.chml-up-doc .ant-upload.ant-upload-drag {
    height: auto;
}

.upload-f {
    line-height: normal;
}

.upload-f span {
    font-size: 11px;
    display: inline-block;
    width: 100%;
    font-weight: 500;
    color: #03a791;
    padding-top: 4px;
}

.sf-card.scope-v-value {
    background: #fbf8fc;
    box-shadow: 0 2px 4px 2px rgba(45, 59, 72, 0.1);
}

.doc-vlue {
    font-size: 14px;
    color: #9b9b9b;
    font-weight: 400;
}

.doc-vlue span {
    color: #03a791;
    font-size: 14px;
    display: inline-block;
    padding-left: 5px;
    font-weight: 400;
}

.doc-vlue i {
    color: #ddd;
}

.doc-v-usr {
    font-size: 13px;
    font-weight: 500;
}

.doc-v-usr span {
    color: #9b9b9b;
    padding-right: 5px;
    display: inline-block;
}

.site-ser-view {
    background: rgba(45, 59, 72, 0.04);
    margin-bottom: 2px;
}

.site-ser-view.active {
    background: rgba(3, 167, 145, 0.06);
}

.site-ser-view:hover {
    background: rgba(3, 167, 145, 0.06);
}

.site-s-head {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding: 15px 15px 0 40px;
    margin-bottom: 15px;
    position: relative;
}

.site-s-body {
    padding-left: 40px;
}

.site-s-body .d-flex:first-child {
    border-top: 0;
    padding-top: 0;
}

.site-s-body .d-flex {
    border-top: 1px solid rgba(45, 59, 72, 0.1);
    padding-top: 15px;
}

.site-s-footer {
    padding: 15px 0;
}

.dragde-bnt {
    display: flex;
    flex-direction: column;
    line-height: normal;
    position: absolute;
    left: 15px;
    top: 16px;
    cursor: move;
    z-index: 99;
}

.dragde-bnt i {
    height: 3.5px;
    color: rgba(45, 59, 72, 0.24);
}

.doc-action-bnt {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    z-index: 99;
}

.doc-action-bnt button {
    margin-left: 10px;
}

.doc-action-bnt button:hover {
    color: #008572;
}

.site-s-body label {
    font-size: 14px;
    font-weight: normal;
}

.site-s-body .view-text-value span {
    font-size: 13px;
}

.doc-sr-img {
    width: 90px;
    height: 50px;
    overflow: hidden;
    margin: 0 10px;
    border-radius: 4px;
    position: relative;
}

.doc-sr-img img {
    max-height: 100%;
    max-width: 100%;
}

.site-s-footer .data-v-col {
    padding: 0 10px 0 0;
}

.view-internal-note {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding-bottom: 10px;
}

.view-internal-note span {
    font-size: 13px;
    font-weight: normal;
    color: rgba(45, 59, 72, 0.7);
    line-height: 1.4;
}

.view-internal-note span.note-dtls {
    font-size: 12px;
    padding-left: 10px;
    flex: 0 0 180px;
    text-align: right;
    min-width: 180px;
}

.doc-update-task .sf-form textarea {
    height: 65px;
}

.int-notes .sf-form textarea {
    height: 60px;
}

.navbar-bnt {
    color: rgba(45, 59, 72, 0.3);
}

.navbar-bnt i {
    font-size: 20px;
}

.navbar-bnt:hover {
    color: #03a791;
}


/* left nav style kalpesh */

.ant-list-item-meta-avatar label {
    background: #7b1fa2;
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    border-radius: 50px;
    padding: 5px;
    line-height: 32px;
    text-align: center;
}

.itm-amount-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itm-amount-date span {
    font-size: 11px;
}

table.sub-cat-table {
    width: 100%;
}


/* edit update SAI */

.edit-updet-sai .add-sfs-agent {
    margin: 0;
}

.edit-updet-sai .sf-card-inn-bg {
    padding: 10px;
    background: transparent;
    border: 1px solid rgba(45, 59, 72, 0.08);
}

.edit-updet-sai .sfs-cat-sub.sub-cat-update {
    overflow: unset;
}

.edit-sai-table .edit-sai-tbl-items {
    border-bottom: 1px solid #eaebec;
    padding-bottom: 10px;
}

.edit-sai-table .edit-sai-tbl-items:nth-child(even) {
    background: rgba(45, 59, 72, 0.03);
}

.edit-sai-table .edit-sai-tbl-items:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.add-sfs-agent {
    margin: 15px 0 0;
}

.mor-sub-cat {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 7px 0;
}

.mor-sub-cat label {
    display: none;
}

.mor-sub-cat .sf-form.form-group {
    margin: 0;
    padding: 0 8px 0 0;
    flex: 1;
}

.mor-sub-cat button.exp-bnt.add {
    flex: 0 0 34px;
}

.wc-6 {
    width: 50%;
}

table.sub-cat-table tr td:first-child .mor-sub-cat {
    padding-left: 0;
}

table.sub-cat-table tr th:first-child,
table.sub-cat-table tr td:first-child {
    padding-right: 5px;
}

table.sub-cat-table tr th:last-child,
table.sub-cat-table tr td:last-child {
    padding-left: 5px;
}

.sub-cat-table tr th {
    padding: 10px 0 0;
}

.sf-card-inn-bg .form-group.sf-form {
    margin: 0;
}

.auto-comp .ant-select-auto-complete.ant-select .ant-select-selection--single {
    border: 0 !important;
    height: 35px !important;
}

.auto-comp .ant-select-auto-complete.ant-select .ant-input {
    height: 35px !important;
    padding-right: 10px;
}

.sc-doc-bnt .btn-hs-icon {
    padding-left: 10px;
}

.quote {
    margin-top: 10px;
}

.quote.doc-vlue span {
    font-size: 18px;
}

.quote-vlue label {
    margin-bottom: 5px;
}

.quote-vlue .form-group {
    margin: 0;
}

.quote-vlue .view-text-value {
    border-left: 1px solid rgba(45, 59, 72, 0.08);
    padding-left: 10px;
}

.quote-total {
    padding: 60px 0 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.quote-table {
    padding: 0 20px;
    background: rgba(45, 59, 72, 0.04);
}

.quote-table th {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
}

.quote-table th,
.quote-table td {
    padding: 20px 15px;
    text-align: right;
    text-transform: uppercase;
}

.quote-table td {
    font-weight: 600;
}

.send-email.user-p-notxt span {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.2;
}

.email-checkbx .cu-checkbox {
    display: flex;
    justify-content: flex-end;
}

.email-checkbx,
.sf-form.email-checkbx label.ant-checkbox-wrapper {
    margin: 0;
}

.email-form {
    margin-top: 20px;
}


/* rich text box */

.sf-rich-txtbox .rdw-editor-toolbar {
    border: 1px solid rgba(45, 59, 72, 0.08);
    border-radius: 4px 4px 0 0;
    background: rgba(45, 59, 72, 0.04);
    margin-bottom: 0;
    padding: 6px 5px;
}

.sf-rich-txtbox .rdw-editor-main {
    border: 1px solid rgba(45, 59, 72, 0.08);
    border-top: 0;
    border-radius: 0 0 4px 4px;
}

.sf-rich-txtbox .public-DraftStyleDefault-block {
    padding: 0;
}

.sf-rich-txtbox .DraftEditor-editorContainer {
    padding: 0 20px;
}

.sf-rich-txtbox .rdw-option-wrapper {
    background: transparent;
    border: 0;
}

.sf-rich-txtbox .rdw-history-wrapper,
.sf-rich-txtbox .rdw-dropdown-wrapper,
.sf-rich-txtbox .rdw-text-align-wrapper,
.sf-rich-txtbox .rdw-list-wrapper,
.sf-rich-txtbox .rdw-inline-wrapper .rdw-option-wrapper {
    border: rgba(45, 59, 72, 0.04);
    background: rgba(45, 59, 72, 0.04);
    margin: 0 3px 0 0;
    border-radius: 4px;
}

.sf-rich-txtbox .rdw-inline-wrapper .rdw-option-wrapper {
    height: 100%;
}

.rdw-block-wrapper,
.rdw-inline-wrapper {
    margin: 0;
}


/* client feedback  */

.client-fdbk {
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    color: #fff;
    background: #f5a623;
    border-radius: 50px;
    width: 78px;
    height: 78px;
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-fdbk.approved {
    background: #417505;
}

.jbd-status .client-fdbk.not-started {
    background: #d0021b;
}

.jbd-status .client-fdbk.started {
    background: #417505;
}

.jbd-status .client-fdbk.paused {
    background: #f5a623;
}

.jbd-status .client-fdbk.complete {
    background: #4a4a4a;
}

.sf-exp-dates {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: -10px;
}

.sf-exp-dates .sf-form {
    flex: 1;
    padding-right: 10px;
}

.sr-agent-modal button.ant-modal-close {
    display: none;
}

.sr-agent-modal .ant-modal-body {
    padding: 0;
}

.task-wrapper {
    position: relative;
}

.pro-abbr {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #7b1fa2;
    color: #fff;
    border-radius: 50px;
    font-size: 11px;
    margin-right: 5px;
    text-align: center;
}

.pro-org-img {
    width: 20px;
    display: inline-block;
    margin-right: 5px;
}


/* alert message box  */

.select-org-modal .ant-modal-body {
    padding: 25px;
}

.slt-org-mdl .ant-select.ant-select-enabled {
    width: 100%;
    margin-top: 8px;
}


/* scope doc */

.area-lists .area-items:first-child {
    margin-top: 0;
}

.area-items {
    margin-top: 5px;
}

.area-items>div {
    flex: 1;
}

.no-label label,
.est-items label,
.int-notes label {
    display: none;
}

.br-xy {
    border: 1px solid rgba(45, 59, 72, 0.08);
}

.sf-card-body.int-notes {
    padding-top: 30px;
}

.sr-client-area button.exp-bnt {
    flex: 0 0 34px;
    margin-left: 5px;
}

.show-frquency-box {
    background: transparent;
}

.show-frquency-box>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.show-frquency-box .ant-collapse-content-box .form-group.sf-form {
    margin: 0;
}

.show-frquency-box>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: 0;
    font-size: 10px;
}

.show-frquency-box>.ant-collapse-item>.ant-collapse-header {
    padding: 0 0 0 15px;
    font-size: 11px;
    font-weight: 500;
    color: #03a791;
    max-width: 110px;
}

.show-frquency-box>.ant-collapse-item {
    border: 0;
}

.show-frquency-box>.ant-collapse-item>.ant-collapse-content {
    margin-top: 15px;
    overflow: unset;
}

.quote.view-jd-history .show-frquency-box>.ant-collapse-item>.ant-collapse-header {
    max-width: 100%;
}

.sf-chkbx-group.sf-updt-roles {
    padding: 2px 0;
}

.sf-chkbx-group.sf-updt-roles .ant-checkbox-wrapper {
    flex: 0 0 50%;
    margin: 0 0 10px;
}


/* Job calender */

.calender-heading {
    margin: 20px 0 10px;
}

.status-bnt {
    display: flex;
    margin-bottom: 20px;
}

.status-bnt button {
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    background: #4a4a4a;
    border: 1.5px solid #4a4a4a;
    border-radius: 4px;
    height: 30px;
    line-height: 27px;
    margin-right: 10px;
}

.status-bnt .completed,
.status-bnt .shift-completed {
    background: #4a4a4a;
    border: 1.5px solid #4a4a4a;
}

.status-bnt .completed-active,
.status-bnt .shift-completed-active {
    background: rgba(74, 74, 74, 0.5843137254901961);
    border: 1.5px solid #4a4a4a;
}

.status-bnt .not-approved {
    background: #d0021b;
    border: 1.5px solid #d0021b;
}

.status-bnt .not-approved-active {
    background: rgba(208, 2, 27, 0.5843137254901961);
    border: 1.5px solid #d0021b;
}

.status-bnt .approved {
    background: #4a90e2;
    border: 1.5px solid #4a90e2;
}

.status-bnt .approved-active {
    background: rgba(74, 144, 226, 0.5843137254901961);
    border: 1.5px solid #4a90e2;
}

.status-bnt .started,
.status-bnt .shift-started {
    background: #7ed321;
    border: 1.5px solid #7ed321;
}

.status-bnt .started-active,
.status-bnt .shift-started-active {
    /* background: #7ed32195; */
    background: rgba(126, 211, 33, 0.5843137254901961);
    border: 1.5px solid #7ed321;
}

.status-bnt .paused {
    background: #f5a623;
    border: 1.5px solid #f5a623;
}

.status-bnt .paused-active {
    /* background: #f5a62395; */
    background: rgba(245, 166, 35, 0.5843137254901961);
    border: 1.5px solid #f5a623;
}

.status-bnt .client-approved {
    background: #417505;
    border: 1.5px solid #417505;
}

.status-bnt .client-approved-active {
    /* background: #41750595; */
    background: rgba(65, 117, 5, 0.5843137254901961);
    border: 1.5px solid #417505;
}

.status-bnt .admin-approved {
    background: #18b87a;
    border: 1.5px solid #18b87a;
}

.status-bnt .admin-approved-active {
    /* background: #18b87a95; */
    background: rgba(24, 184, 122, 0.5843137254901961);
    border: 1.5px solid #18b87a;
}

.status-bnt .signed-off {
    background: #7b1fa2;
    border: 1.5px solid #7b1fa2;
}

.status-bnt .signed-off-active {
    /* background: #41750595; */
    background: rgba(116, 38, 150, 0.584);
    border: 1.5px solid #7b1fa2;
}

.status-bnt .invoiced {
    background: #ffff00;
    border: 1.5px solid #ffff00;
    color: black;
}

.status-bnt .invoiced-active {
    background: #dbdb37;
    border: 1.5px solid #ffff00;
    color: black;
}

.status-bnt .reset {
    background: #03a791;
    border: 0;
}

.status-selects {
    display: flex;
}

.status-selects .sf-select label {
    display: none;
}

.status-selects .sf-select.select-wibg .ant-select-arrow {
    background: transparent;
    color: #9b9b9b;
}

.status-selects .sf-form .ant-select-selection {
    height: 30px;
}

.status-selects .sf-form {
    margin: 0 0 0 10px;
    max-width: 120px;
}

.status-selects .sf-form:first-child {
    margin-left: 0;
}

.status-selects .sf-form.org-slt-tags {
    max-width: 120px;
}

.search-filter input[type="text"] {
    height: 30px;
}

.search-filter label {
    display: none;
}

.sf-form.accountMngr{
    max-width: 100%;
    width: 160px;
}

.sf-form.servAgent {
    max-width: 100%;
    width: 140px;
}

.sf-form.org-slt-tags {
    min-width: 160px;
    max-width: 100%;
}

.sf-form.accountMngr .ant-select-selection-selected-value,
.zone-grp-txt .ant-select-selection-selected-value {
    color: rgba(45, 59, 72, 0.45);
}


/* Organisations selected tags */

.org-all-tags {
    display: flex;
    padding-left: 10px;
    flex-wrap: wrap;
}

.org-select-tags {
    display: flex;
    align-items: flex-start;
    flex: 100%;
    width: 100%;
}

.org-select-tags strong {
    font-size: 14px;
    font-weight: 600;
    color: #4a4a4a;
    line-height: 20px;
    text-align: left;
}

.org-all-tags .ant-tag {
    background: rgba(45, 59, 72, 0.08);
    border: 0;
    border-radius: 100px;
    padding: 3px 3px 3px 14px;
    font-weight: 400;
    font-size: 13px;
    color: #4a4a4a;
    position: relative;
    justify-content: space-between;
    display: flex;
    margin-bottom: 6px;
    transition: 0.5s all;
}

.org-all-tags .ant-tag:hover {
    background: rgba(45, 59, 72, 0.22);
}

.org-all-tags .ant-tag .anticon-close {
    background: #4a4a4a;
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    font-size: 12px;
}

.org-slt-tags .ant-select-selection--multiple .ant-select-selection__choice {
    background: transparent;
    border: 0;
    padding: 0 2px;
    height: 30px;
    line-height: 30px;
    margin-top: 0;
}

.org-slt-tags .ant-select-selection__rendered ul {
    display: inline-flex;
}

.org-slt-tags .ant-select-selection--multiple .ant-select-selection__rendered {
    overflow: hidden;
}

.org-slt-tags .ant-select-selection--multiple .ant-select-selection__choice__remove {
    display: none;
}

.org-slt-tags .ant-select-selection__choice__content:after {
    content: ",";
}

.org-slt-tags ul li:nth-last-child(2) .ant-select-selection__choice__content:after {
    content: "";
}


/* add jobs and assign */

.job-date-list.sf-scroll-bar {
    padding: 0 15px 10px;
    min-height: auto;
    max-height: 600px;
}

.job-date-list.sf-scroll-bar::-webkit-scrollbar {
    width: 4px;
    border-radius: 25px;
}

.job-date-list.sf-scroll-bar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.job-date-list.sf-scroll-bar::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.1);
}

.job-date-list.sf-scroll-bar:hover::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.2);
}

.sf-card-head.jc-head {
    padding: 25px 15px 10px;
}

.job-date-item {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.job-date-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.job-c-details .ant-checkbox-wrapper+span
/*.ant-checkbox + span*/

    {
    font-size: 14px;
    color: #4a4a4a;
    padding-right: 0;
}

.job-c-details {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.job-c-details .job-c-details {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.job-c-my {
    padding-bottom: 15px;
    font-weight: 600;
}

.jc-value-dtls {
    display: flex;
    flex-direction: column;
    width: calc(100% - 85px);
}

.jc-value-dtls span {
    font-size: 13px;
}

span.jc-no {
    color: #03a791;
    padding: 0 0 5px;
}

.jc-chk-value {
    position: relative;
    padding: 0 0 0 24px;
}

.add-sa {
    position: absolute;
    right: 0;
    top: 7px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 99;
}

.add-sa label.sa-us-nm,
.add-sa>label {
    font-size: 12px;
    color: #444c63;
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-sa-bnt span {
    display: block;
    font-size: 12px;
    font-weight: 500;
}

.add-sa-bnt i {
    font-size: 16px;
}

.jc-outsource {
    font-size: 12px;
    color: #008572;
    padding-top: 2px;
    text-decoration: underline;
}

button.jc-outsource:disabled {
    color: #bee5e0;
}

.jc-outsource:hover {
    color: #008572;
    text-decoration: underline;
}

.jc-chk-value button.add-line-bnt i {
    transform: rotate(90deg);
    font-size: 18px;
}

.jc-chk-value button.add-line-bnt {
    display: flex;
    align-items: center;
}

.jc-chk-value button.add-line-bnt span {
    padding-left: 5px;
}


/* custom collapse */

.ant-collapse.sf-collapse {
    background: rgba(45, 59, 72, 0.04);
    margin-top: 30px;
}

.sf-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 12px;
}

.sf-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 16px;
}

.sf-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    border: 0;
    padding: 0 10px 12px;
}

.sf-collapse.ant-collapse .ant-collapse-item.ant-collapse-item-active,
.sf-collapse.ant-collapse.ant-collapse-borderless>.ant-collapse-item {
    border: 0;
}

.sf-collapse .ant-collapse-header {
    font-weight: 600;
}

.sf-collapse .sf-form {
    margin: 0;
}

.jc-dtime {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.jc-calndr {
    flex: 0 0 1;
}

.jc-calndr.sf-form .ant-calendar-picker-input {
    padding-left: 10px;
    padding-right: 25px;
}

.jc-time {
    flex: 0 0 100px;
    padding-left: 10px;
}

.jc-time .ant-time-picker {
    width: 100%;
}

.jc-time .ant-time-picker-icon .ant-time-picker-clock-icon {
    color: #03a791;
}

.add-sa .add-sa-bnt,
.add-sa .add-sa-bnt:focus,
.add-sa .add-sa-bnt:focus-within {
    border: 0;
    box-shadow: none;
}

.add-sa .add-sa-bnt::after {
    display: none;
}

.add-sa .bnt-simple.add-sa-bnt:hover {
    text-decoration: underline;
}

.add-sa .bnt-simple.add-sa-bnt:hover i,
.add-sa .bnt-simple.add-sa-bnt:hover span {
    color: #008572;
}

.add-sa-search.sf-form,
.add-staff-search.sf-form {
    margin: 5px 0;
}

.jc-bnt button.bnt {
    margin-top: 10px;
}

.jc-bnt button.bnt:first-child {
    margin: 0;
}


/* popover style  */

.ant-popover.ant-popover-placement-bottomRight {
    min-width: 250px;
}

.split-j-item {
    padding-left: 10px;
    width: 100%;
    position: relative;
}

button.split-dlt-bnt {
    position: absolute;
    left: -35px;
    top: -6px;
}

.split-job-plp {
    margin-top: 15px;
}

.split-job-plp .sf-chkbx-group {
    padding-top: 8px;
    flex: 0 0 14px;
}

.split-j-item .sf-form {
    margin-bottom: 10px;
}

.split-j-item .sf-form:last-child {
    margin-bottom: 0;
}

.split-j-item.sf-form .ant-calendar-picker-input,
.split-j-item.sf-form input[type="text"] {
    height: 30px;
}

.split-j-item .ccalnd-icon {
    top: 5px;
}

.color-txt.sf-form input[type="text"] {
    color: #03a791;
}

.service-table {
    margin: 0 0 15px;
}

.service-table .table {
    padding: 0;
    margin: 0;
    background: rgba(45, 59, 72, 0.04);
}

.service-table .table td {
    padding: 15px;
    border-top: 1px solid #fff;
}

.service-table .table tr:first-child td {
    border-top: 0;
}

.service-table .view-text-value label,
.service-table .view-text-value span {
    color: #4a4a4a;
}

.service-table .table tr td:first-child .view-text-value {
    border: 0;
    padding-left: 0;
}

.service-table .table tr td .view-text-value {
    border-left: 1px solid rgba(45, 59, 72, 0.1);
    padding-left: 15px;
}

.service-table .table .view-text-value button.normal-bnt {
    text-decoration: underline;
    border: 0;
    color: #03a791;
}

.service-table .table .view-text-value button.normal-bnt span {
    color: #03a791;
}

.service-table .table .view-text-value button.normal-bnt:hover {
    color: #008572;
}

.sm-chkbx .ant-checkbox+span {
    font-size: 13px;
    color: #222;
    font-weight: 400;
}

.sli-table-items .table td,
.sli-table-items .table th {
    padding: 10px;
}

.sli-table-items table tr th {
    border-top: 0;
    background: #4a4a4a;
    color: #fff;
    border-left: 1px solid rgba(169, 165, 170, 0.45);
}

.sli-table-items table tr th:first-child {
    vertical-align: middle;
    text-align: center;
    border-left: 0;
}

.staff-li-table .sli-table-items table tr th:first-child {
    text-align: left;
}

.sli-table-items .table tr td {
    border-bottom: 1px solid #dfdfdf;
    border-top: 0;
}

.sli-table-items .table tr:nth-child(odd) td {
    background: #fafafa;
}

.sli-th-chk label.ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sli-th-chk .ant-checkbox+span {
    color: #fff;
    text-align: center;
    line-height: normal;
    font-size: 13px;
    padding-top: 5px;
    padding-left: 0;
}

.sli-name-chk .ant-checkbox-wrapper {
    display: flex;
}

.sli-name-chk .ant-checkbox-wrapper span.ant-checkbox {
    flex: auto;
    padding-right: 8px;
    position: relative;
    top: 2px;
}

.sli-name-chk .ant-checkbox-wrapper span {
    flex: 100% 1;
    padding-left: 0;
    vertical-align: top;
    line-height: 1.4;
    font-size: 13px;
}

.sli-viewd-chk {
    text-align: center;
}

.sli-action i {
    font-size: 16px;
    color: #03a791;
}

.sli-action button:hover i {
    color: #008572;
}

.sli-action button {
    background: transparent;
    padding: 4px;
    margin: 0;
    line-height: normal;
    height: auto;
}

.sli-action {
    display: flex;
    justify-content: center;
}

.sli-download i {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.ant-collapse.sl-inductions {
    margin-bottom: 10px;
}

.ant-collapse.sl-inductions:last-child {
    margin-bottom: 0;
}

.sl-inductions .ant-collapse-item {
    border-bottom: 0;
}

.sl-inductions .ant-collapse-item:last-child {
    border-bottom: 1px solid #d9d9d9;
}

.sli-table-items .ant-collapse-content {
    padding-bottom: 10px;
}

.add-staff-search.sf-form {
    min-width: 400px;
}

.add-jd-staff-bnt {
    border: 0;
}

.add-jd-staff-bnt::after {
    content: none;
}

.sf-roles-group.ics-chkbox.add-sub-mod {
    margin: 10px 0;
    display: block;
}

.add-sub-mod.ics-chkbox .ant-checkbox-wrapper {
    height: auto;
    padding: 20px;
    margin-bottom: 10px;
}

.ics-chkbox .cu-checkbox {
    max-width: 100%;
    padding: 0;
}

.inc-details h4 {
    font-size: 16px;
    color: #222;
    padding: 0;
    line-height: normal;
}

.add-sub-mod .ant-checkbox-wrapper-checked .inc-details h4 {
    color: #03a791;
}

.inc-info span {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    padding-top: 7px;
    line-height: normal;
}

.add-sub-mod .ant-checkbox-wrapper-checked .inc-info span {
    color: #222;
}

.inc-actions .sli-action button,
.inc-actions .sli-action a.sli-download {
    border: 1px solid #03a791;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    margin: 0 5px;
}

.inc-actions .sli-action a.sli-download {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inc-info {
    margin-top: 10px;
}


/* custom add staff search box */

#addStaffDrop div:last-child,
#addStaffLince>div:last-child {
    position: static !important;
}

#addStaffDrop div.select-wibg {
    position: relative !important;
}

#addStaffDrop.sf-form .ant-select-selection {
    padding-right: 40px;
}

#addStaffDrop .select-wibg .ant-select-arrow {
    line-height: 24px;
}

.add-staff-search .ant-select-selection--multiple .ant-select-selection__choice {
    border-radius: 100px;
    background: #2d3b4814;
    font-size: 13px;
    color: #4a4a44;
    font-weight: normal;
    padding-right: 26px;
}

.add-staff-search .ant-select-selection--multiple .ant-select-selection__choice__remove {
    background: #4a4a4a;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    top: 1px;
    line-height: 20px;
}

.add-staff-search .ant-select-selection--multiple .ant-select-selection__choice__remove i {
    font-size: 10.5px;
    color: #fff;
}

.add-staff-search .ant-select-dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.searchStffPopu .all-btn .btn-hs-icon {
    width: 240px;
}

.searchStffPopu .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 12px;
    padding-left: 32px;
}

.searchStffPopu .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    left: 12px;
    right: auto;
}

.equipmnt-table tr td:last-child {
    width: 50px;
}

.equipmnt-table tr td:nth-child(2) {
    width: 200px;
}

.equipmnt-table tr td,
.equipmnt-table tr th {
    padding: 0 0 10px;
}

.equipmnt-table tr:last-child td {
    padding: 0;
}

.equipmnt-table tr td:nth-child(2),
.equipmnt-table tr th:nth-child(2) {
    padding: 0 10px 0 20px;
}

.equipmnt-table tr th,
.equipmnt-table tr td {
    border: 0;
}

.equipmnt-table.equip-cast-tble tr th,
.equipmnt-table.equip-cast-tble tr td {
    padding: 10px;
}

.equipmnt-table.equip-cast-tble tr td {
    font-weight: 300;
    border-top: 1px solid rgba(45, 59, 72, 0.04);
}

.eqip-total-vlue {
    justify-content: space-between;
    display: flex;
    font-size: 14px;
    background: rgba(45, 59, 72, 0.04);
    padding: 10px;
}


/* job doc preview modal style */

.job-doc-preview,
.toolbox-talk-form {
    top: 0;
    padding: 0;
}

.job-doc-preview .ant-modal-content,
.toolbox-talk-form .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
}

.job-doc-preview .ant-modal-header,
.job-doc-preview .ant-modal-close {
    display: none;
}

.job-doc-preview .ant-modal-body,
.toolbox-talk-form .ant-modal-body {
    padding: 0;
}

.sf-jobdoc-preview .sf-card-wrap,
.toolbox-talk-form .sf-card-wrap {
    box-shadow: none;
    padding: 50px 40px 5px;
}

.sf-jobdoc-preview.job-doc-prev {
    padding: 0;
}

.jdp-head {
    background: #f0f2f5;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.jdp-body {
    padding: 40px 20px 0;
}

.jdp-head img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 2px solid rgba(45, 59, 72, 0.04);
}

.jdp-head h2.page-mn-hd {
    font-size: 24px;
    font-weight: 500;
}

.jdp-c-exp-date {
    text-align: right;
}

.jdp-c-exp-date h3 {
    font-size: 17px;
    padding-bottom: 4px;
}

.jdp-c-exp-date h5 {
    font-size: 13px;
}

.jdp-c-exp-date h3,
.jdp-c-exp-date h5 {
    color: #4a4a4a;
    font-weight: 400;
    text-align: right;
}

.jdp-table .table {
    margin: 0;
}

.jdp-table .table tr th {
    border: 0;
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
    color: rgba(45, 59, 72, 1);
    font-size: 14px;
    font-weight: 400;
}

.jbdc-staff-list .jdp-table .table tr th {
    background: rgba(45, 59, 72, 0.05);
}

.jdp-table .table tr td {
    border: 0;
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
    color: rgba(45, 59, 72, 0.8);
    font-size: 15px;
    font-weight: 300;
}

.jdp-table .table tr:hover td {
    background: rgba(45, 59, 72, 0.04);
}

.jdp-note-title {
    font-size: 16px;
    color: #222;
    font-weight: 400;
    margin-bottom: 10px;
}

.jdp-notes p {
    margin: 2px 0;
    font-size: 15px;
    color: rgb(34, 34, 34);
    font-weight: 300;
}

.jdp-footer {
    padding: 0 50px;
}

.sf-jobdoc-preview {
    padding-bottom: 50px;
}

.jdp-table .sli-action {
    justify-content: flex-start;
}

.jdp-table .sli-action button {
    border: 1px solid #03a791;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    margin: 0;
    line-height: 13px;
}

.jdp-table button.sli-download {
    margin-right: 10px;
}

.jdp-table .sli-action i {
    font-size: 12px;
}

.jdp-big-cntr-head {
    margin: 50px 0;
    text-align: center;
}

.jdp-big-cntr-head h1 {
    font-size: 27px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: 600;
    margin-bottom: 10px;
}

.jdp-big-cntr-head h2.page-mn-hd {
    font-size: 21px;
    font-weight: 600;
    color: rgba(45, 59, 72, 0.8);
    text-align: center;
    line-height: normal;
}

.jdp-c-exp-date.co-details-prv {
    text-align: left;
}

.co-details-prv p {
    font-size: 15px;
    font-weight: 500;
    color: rgba(45, 59, 72, 0.8);
    padding-top: 2px;
}

.co-details-prv p:first-child {
    padding-top: 0;
}

.jbdc-staff-list>h2 {
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(45, 59, 72, 0.05);
    padding-bottom: 10px;
}

.jbdc-staff-list {
    margin-bottom: 25px;
}

.jbdc-staff-list:last-child {
    margin-bottom: 0;
}


/* job doc email  */

.bcc-userid {
    position: relative;
}

.cc-bcc-email {
    display: flex;
    position: absolute;
    right: 0;
}

.cc-bcc-email button {
    margin-left: 15px;
    color: rgba(45, 59, 72, 0.45);
    font-size: 14px;
    font-weight: 600;
}

.cc-bcc-email button:hover {
    color: #03a791;
}


/* service farm custom div table */

.sf-c-table {
    display: table;
    width: 100%;
    margin-bottom: 1rem;
}

.sf-c-table .tr {
    display: table-row;
}

.sf-c-table .td,
.sf-c-table .th {
    display: table-cell;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding: 10px;
    vertical-align: top;
    word-break: break-all;
}

.sf-c-table .td {
    font-size: 14px !important;
    font-weight: 400;
    transition: 0.5s all;
}

.sf-c-table .tr:hover .td {
    background: rgba(45, 59, 72, 0.04);
}

.sf-c-table .th {
    font-size: 15px;
    font-weight: 400;
}

.sf-c-table .sf-form label {
    display: none;
}

.view-logo b {
    color: #fff;
}


/* update payment info */

.sf-exp-dates {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: -10px;
}

.sf-exp-dates .sf-form {
    flex: 1;
    padding-right: 0;
}

.sf-exp-dates .card-exp-drop .sf-form {
    flex: none;
    padding: 0;
}

.sf-exp-dates .card-exp-drop,
.sf-exp-dates .ccv-cvc {
    flex: 0 0 50%;
    padding-right: 10px;
    width: 50%;
    position: relative;
}

.sf-form .exp-slt-box .ant-select {
    min-width: 100%;
}

.exp-slt-box {
    display: flex;
}

.exp-slt-box .sf-select.select-wibg {
    flex: 0 0 50%;
    width: 50%;
}

.sf-form .exp-slt-box .ant-select-selection {
    border-radius: 4px 0 0 4px;
}

.sf-form .exp-slt-box .sf-select.select-wibg:last-child .ant-select-selection {
    border-radius: 0 4px 4px 0;
    border-left: 0 !important;
}

.ccv-cvc .sf-form input[type="text"] {
    line-height: 34px;
}

.sf-form .exp-slt-box .ant-select-selection__rendered {
    line-height: 34px;
}

.sf-form .exp-slt-box .ant-select-selection-selected-value {
    padding-right: 0;
}

.cvv-tooltip {
    position: absolute;
    top: 4px;
    right: 10px;
}

.cvv-tooltip .normal-bnt {
    color: rgba(45, 59, 72, 0.45);
}

.cvv-tooltip .normal-bnt i {
    font-size: 14px;
}

#cvvTooltip .ant-tooltip-inner {
    background: #fff;
}

#cvvTooltip .ant-tooltip-inner img {
    width: 100px;
}

#cvvTooltip .ant-tooltip-placement-left .ant-tooltip-arrow {
    border-top-color: #fff;
    border-right-color: #fff;
}

.card-exp-drop .select-wibg .ant-select-arrow,
.card-exp-drop .sf-select.select-wibg label {
    display: none;
}


/* end */

.rap-cell-table tr td:last-child {
    word-break: break-all;
}

/* .hlight-n-user td {
    background: rgba(3, 167, 145, 0.1);
} */


/* full screen center card */

.card-expands {
    display: flex;
    justify-content: flex-end;
}

.exapnd-bnt {
    margin-bottom: 8px;
    font-size: 15px;
    color: rgba(45, 59, 72, 0.3);
}

.exapnd-bnt:hover {
    color: #008572;
}

.main-container.po-relative {
    position: relative;
}

.sf-card-wrap.fullscreen {
    position: absolute;
    left: 18px;
    top: 20px;
    z-index: 99;
    margin: 0 15px;
    width: calc(100% - 48px);
    min-height: 100%;
}

.po-relative .col-lg-1,
.po-relative .col-lg-2,
.po-relative .col-lg-3,
.po-relative .col-lg-4,
.po-relative .col-lg-5,
.po-relative .col-lg-6,
.po-relative .col-lg-7,
.po-relative .col-lg-8,
.po-relative .col-lg-9,
.po-relative .col-lg-10,
.po-relative .col-lg-11,
.po-relative .col-lg-12,
.po-relative .col-md-1,
.po-relative .col-md-2,
.po-relative .col-md-3,
.po-relative .col-md-4,
.po-relative .col-md-5,
.po-relative .col-md-6,
.po-relative .col-md-7,
.po-relative .col-md-8,
.po-relative .col-md-9,
.po-relative .col-md-10,
.po-relative .col-md-11,
.po-relative .col-md-12,
.po-relative .col-sm-1,
.po-relative .col-sm-2,
.po-relative .col-sm-3,
.po-relative .col-sm-4,
.po-relative .col-sm-5,
.po-relative .col-sm-6,
.po-relative .col-sm-7,
.po-relative .col-sm-8,
.po-relative .col-sm-9,
.po-relative .col-sm-10,
.po-relative .col-sm-11,
.po-relative .col-sm-12,
.po-relative .col-xs-1,
.po-relative .col-xs-2,
.po-relative .col-xs-3,
.po-relative .col-xs-4,
.po-relative .col-xs-5,
.po-relative .col-xs-6,
.po-relative .col-xs-7,
.po-relative .col-xs-8,
.po-relative .col-xs-9,
.po-relative .col-xs-10,
.po-relative .col-xs-11,
.po-relative .col-xs-12 {
    position: static;
}

.zoom-save-bnt {
    margin-top: 15px;
    display: none;
}

.zoom-save-bnt.show-bnt {
    display: block;
}


/* service farm custom div table */

.sf-c-table {
    display: table;
    width: 100%;
    margin-bottom: 1rem;
}

.sf-c-table .tr {
    display: table-row;
}

.sf-c-table .td,
.sf-c-table .th {
    display: table-cell;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding: 10px;
    vertical-align: top;
}

.sf-c-table .td {
    font-size: 13px;
    font-weight: 400;
    transition: 0.5s all;
}

.sf-c-table .tr:hover .td {
    background: rgba(45, 59, 72, 0.04);
}

.sf-c-table .th {
    font-size: 13px;
    font-weight: 400;
}

.sf-c-table .sf-form label {
    display: none;
}

.s-n-bnt.btn-hs-icon {
    position: relative;
    top: 1px;
}

.a-sr-agent .s-n-bnt {
    display: none;
    opacity: 0;
}

.v-sr-agent .s-n-bnt.btn-hs-icon {
    position: static;
}

.pr-c-person tr td:last-child {
    vertical-align: middle;
}

.upload-sfv-file.add-equipment-img {
    display: flex;
    justify-content: center;
}

.upload-sfv-file.add-equipment-img>span {
    width: 300px;
}

.upload-sfv-file.add-equipment-img.tester-license>span {
    width: 100%;
}

.tester-license .ant-upload.ant-upload-drag {
    height: 150px;
}

.eqip-view-pic .col-md-4 {
    margin-bottom: 15px;
}

.equip-pic {
    height: 150px;
    text-align: center;
    padding: 0;
    border: 1px solid rgba(45, 59, 72, 0.04);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    overflow: hidden;
    background: rgba(45, 59, 72, 0.06);
}

.equip-pic img {
    width: auto;
    height: 150px;
    float: left;
    border-radius: 4px;
}

.equip-pic-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.equip-pic-icons i {
    font-size: 4.5rem;
    color: rgba(45, 59, 72, 0.4);
}

.tt-pre-history .show-frquency-box>.ant-collapse-item>.ant-collapse-header {
    font-size: 14px;
    font-weight: normal;
    max-width: 100%;
}

.tt-pre-history .show-frquency-box>.ant-collapse-item>.ant-collapse-content {
    margin-top: 0;
}

.test-tag-lists {
    list-style-type: disc;
    padding-left: 17px;
}

.test-tag-lists li span {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.3;
}

.test-tag-lists li {
    padding-bottom: 20px;
}

.tt-pre-history {
    margin-bottom: 20px;
}

.t-tag-history .bnt.bnt-active {
    margin: 0 auto;
    width: 100%;
}


/* react scrollArea */

.scrollarea.sf-scroll {
    min-height: auto;
    max-height: 500px;
    margin-bottom: 15px;
}

.scrollarea .scrollbar-container.vertical {
    width: 6px;
    border-radius: 100px;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
    width: 4px;
    border-radius: 100px;
}

.scrollarea.sf-scroll.h750 {
    max-height: 650px;
}


/* menu scroll */

.scrollarea.sf-scroll.menu-scroll {
    max-height: inherit;
}

.scrollarea.menu-scroll .scrollbar-container.vertical {
    width: 4px;
    border-radius: 100px;
    background: #222;
}

.scrollarea.menu-scroll .scrollbar-container.vertical .scrollbar {
    width: 2px;
    border-radius: 100px;
    background: #fff;
}

.scrollarea.sf-scroll.menu-scroll.h800 {
    max-height: 800px;
}


/* menu scroll end */

.scrollarea.sf-scroll-width {
    width: 100%;
}

.scrollarea.sf-scroll-width .scrollarea-content {
    min-width: 600px;
    margin-bottom: 20px;
}

.scrollarea .scrollbar-container.horizontal {
    height: 6px;
    border-radius: 100px;
}

.scrollarea .scrollbar-container.horizontal .scrollbar {
    height: 4px;
    border-radius: 100px;
}

.scrollarea.sf-scroll-width.mw6 .scrollarea-content {
    min-width: 600px;
}


/* all search box scrollbar */

.sf-search-body .scrollarea.sf-scroll {
    max-height: none;
    margin-bottom: 0;
    min-height: 620px;
}

.sf-search-body .search-lists-bar .scrollarea.sf-scroll {
    max-height: 610px;
}


/* Job Doc */

.sf-job-doc-bg {
    padding: 15px 15px 0;
    background: rgba(45, 59, 72, 0.04);
    border-radius: 4px;
    position: relative;
    margin-bottom: 2px;
}

.sAgent-note {
    border-top: 1px solid rgba(45, 59, 72, 0.1);
    padding: 10px 10px 10px 0;
}

.sAgent-note .data-v-col {
    border-right: 0;
    margin-bottom: 0;
}

.job-note-pic {
    width: 65px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
}

.job-note-pic img {
    max-width: 100%;
}

.swms-table tr th,
.swms-table tr td {
    padding: 10px;
    border: 0;
}

table.swms-table tr td {
    background: rgba(45, 59, 72, 0.025);
    color: rgba(45, 59, 72, 0.9);
    font-weight: 400;
    font-size: 14px;
    vertical-align: top;
}

table.swms-table tr td span {
    position: relative;
    padding-left: 10px;
}

table.swms-table tr td .ant-checkbox-wrapper {
    display: flex;
}

table.swms-table tr td span.ant-checkbox {
    padding-right: 8px;
}

table.swms-table tr td .ant-checkbox+span {
    padding: 0;
    line-height: 1.1;
}

table.swms-table tr td span::before {
    list-style: disc;
    content: ".";
    font-size: 23px;
    font-weight: 800;
    position: absolute;
    top: 3px;
    left: 0;
    line-height: 0;
}

.swms-table tr th {
    font-weight: 500;
    color: rgba(45, 59, 72, 0.9);
    font-size: 13px;
    width: calc(100% / 5);
}

.sm-chkbx span.ant-checkbox {
    padding-left: 0;
}

.sm-chkbx {
    margin-bottom: 6px;
}

.swms-co-items .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    border: 0;
}

.swms-co-items.ant-collapse>.ant-collapse-item,
.swms-co-items .ant-collapse-content,
.swms-content-list.ant-collapse,
.swms-content-list.ant-collapse>.ant-collapse-item {
    border: 0;
    background: transparent;
}

.swms-content-list .ant-collapse-content-box {
    padding-top: 0;
}

.swms-content-list.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: 0;
}

.swms-content-list.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-left: 22px;
    font-weight: 600;
    font-size: 13px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
}

.swms-content-list.ant-collapse>.ant-collapse-item .swms-content-list.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-size: 14px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: 400;
}

.all-budget {
    display: flex;
    flex-wrap: wrap;
}

.budget-list {
    padding: 10px;
    background: rgba(45, 59, 72, 0.04);
    border: 1px solid rgba(45, 59, 72, 0.08);
    width: auto;
    border-radius: 4px;
    color: #4a4a4a;
    font-weight: 300;
    font-size: 24px;
}

.budget-list b {
    font-weight: 600;
    padding-right: 3px;
}

#calenderPopover .ant-popover-inner-content {
    padding: 0;
    max-width: 450px;
    min-width: 300px;
}

#sfPopOver {
    position: relative;
}

#sfPopOver .ant-popover-inner {
    padding: 18px 15px;
}


/* po number with save button */

.po-num-txt .sf-form label {
    width: 100%;
    font-weight: 400;
    margin-bottom: 5px;
    display: inline-block;
}

.po-num-txt .normal-bnt i {
    font-size: 24px;
}

.bnt.bnt-active.btn-xs {
    font-size: 13px;
    height: auto;
    padding: 5px 10px;
    min-width: 60px;
}

.data-v-col.po-num-txt {
    flex: 1;
    margin: 0;
}

.po-num-txt input[type="text"] {
    margin: 0;
}

.po-num-txt.data-v-col .view-text-value {
    margin: 0;
}

.w-save-bnt button.normal-bnt {
    margin-left: 5px;
}

.calendar-popover .sf-pg-heading {
    font-weight: 600;
}

.popup-info-txt {
    margin-bottom: 15px;
}

.calendar-popover button.bnt {
    height: 35px;
    font-size: 15px;
}

.calendar-popover .sf-card-head {
    padding: 5px 15px;
}


/* job calendar view  */

.job-calendar-view {
    height: 90vh;
}

.job-calendar-view-expand {
    height: 150vh;
}

.job-calendar-view .rbc-month-view {
    height: auto;
}

.job-calendar-view .rbc-toolbar {
    margin: 0;
    padding: 20px 10px;
    background: rgba(3, 167, 145, 0.1);
    position: relative;
}

.job-calendar-view .rbc-toolbar button {
    background: transparent;
    color: #4a4a4a;
    font-weight: 400;
    font-size: 14px;
    border: 0;
    border-radius: 50px !important;
    padding: 5px 18px;
    text-align: center;
    line-height: normal;
    margin-right: 10px;
    height: 30px;
}

.job-calendar-view .rbc-toolbar button:active,
.job-calendar-view .rbc-toolbar button.rbc-active {
    background: #03a791;
    border-color: transparent;
    box-shadow: none;
    color: #fff;
}

.job-calendar-view .rbc-toolbar button.today-bnt-c:active,
.job-calendar-view .rbc-toolbar button.today-bnt-c:focus,
.job-calendar-view .rbc-toolbar button.today-bnt-c:focus-within {
    background: rgba(3, 167, 145, 1);
    color: #fff;
}

.job-calendar-view .rbc-header {
    border-bottom: 1px solid #dadfea;
    padding: 15px 10px;
    font-size: 12px;
    color: #4a4a4a;
    text-transform: uppercase;
}

.job-calendar-view .rbc-header+.rbc-header {
    border-left: 1px solid #dadfea;
}

/* .job-calendar-view .rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid #dadfea;
}

.job-calendar-view .rbc-today {
    background: rgba(3, 167, 145, 0.1);
}

.job-calendar-view .rbc-label.rbc-time-header-gutter {
    display: none;
}

.job-calendar-view .rbc-time-content {
    display: none;
}

.job-calendar-view .rbc-time-header {
    height: 100%;
}

.job-calendar-view .rbc-date-cell {
    color: #4a4a4a;
    font-size: 14px;
    padding: 2px 5px 0 0;
}

.job-calendar-view .rbc-event {
    background: #4a90e2;
    border-radius: 0;
    font-size: 13px;
    font-weight: 300;
    padding: 1px 4px;
    letter-spacing: 0.4px;
}

.job-calendar-view .rbc-off-range-bg {
    background: transparent;
}

.job-calendar-view .rbc-date-cell.rbc-off-range {
    color: rgba(169, 165, 170, 0.65);
} */

.job-calendar-view .rbc-header.rbc-today {
    background: transparent;
    color: #03a791;
}

.ant-select-dropdown-menu-item {
    text-align: left;
}

.edit-jdls button.normal-bnt {
    margin-left: 10px;
}


/* Job status */

.job-status-time {
    background: rgba(45, 59, 72, 0.04);
    padding: 30px 15px 20px 15px;
    border-radius: 7px;
    text-align: center;
    width: 142px;
    margin-right: 50px;
    box-shadow: 0 2px 4px 2px rgba(45, 59, 72, 0.1);
}

.job-status-time .job-action-area button.bnt {
    margin: 0 auto;
    height: 32px;
    font-size: 14px;
    margin-top: 15px;
    line-height: 30px;
    background: #03a791;
    color: #fff;
    padding: 0;
    width: 100%;
    min-width: 100%;
}

.job-status-time h4 {
    font-weight: normal;
    text-align: center;
    color: #222;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.job-status-time label {
    font-size: 14px;
    color: rgba(45, 59, 72, 0.45);
    font-weight: normal;
}

.job-status-time .job-stated {
    color: #03a791;
}

.job-status-time i {
    font-size: 32px;
    color: #03a791;
}

.swms-sr-dtl.has-checkbx span::before {
    display: none;
}

.swms-sr-dtl.swms-doc-lists td span {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    font-weight: 400;
    padding-right: 15px;
    font-size: 13px;
}

.swms-sr-dtl.swms-doc-lists td span:first-child {
    margin-top: 0;
}

.add-moreswms label {
    border-top: 1px dotted rgba(45, 59, 72, 0.2);
    padding: 10px 0;
    display: inline-block;
    width: 100%;
}

.add-moreswms button.normal-bnt {
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
}

.job-status-time .pause-job {
    margin-top: 5px;
    text-decoration: underline;
}


/* job details staff */

.jd-staff table td button.normal-bnt i {
    font-size: 16px;
}

.jd-staff table .edit-jdls button.normal-bnt i {
    font-size: 16px;
}

.jd-staff.sli-table-items table tr th {
    text-align: left;
}

.staff-actn button {
    margin-left: 20px;
}

.staff-actn button i {
    padding-right: 5px;
}

.jd-staff table tr td.allsf-tt-dtls {
    padding: 10px 0;
}

.allsf-tt-dtls>.d-flex {
    padding: 0 10px;
}

.time-datails h3 {
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.time-datails h3.active {
    color: #03a791;
}

.view-satff-time {
    margin-left: 10px;
}

.view-satff-time span {
    padding: 0 10px;
    margin: 0 10px;
    border-left: 2px solid rgba(45, 59, 72, 0.1);
    color: #3f3f3f;
    font-size: 13px;
}

.view-satff-time span strong {
    font-weight: 500;
    padding-right: 5px;
}

.sli-table-items.jd-staff .table tr.no-bg td {
    background: transparent;
}

.jd-staff tr td:nth-child(5) {
    text-align: center;
}

.jd-staff tr td:nth-child(6) {
    text-align: center;
}

.jd-staff tr td:nth-child(7) {
    text-align: center;
}

.jd-staff tr td:nth-child(8) {
    text-align: center;
}

.jd-staff tr td:nth-child(9) {
    text-align: center;
}

.jd-staff tr td:nth-child(10) {
    text-align: center;
}

.jd-staff tr td:nth-child(5) button.normal-bnt,
.jd-staff tr td:nth-child(6) button.normal-bnt,
.jd-staff tr td:nth-child(10) button.normal-bnt {
    display: inline-block;
}

.jd-staff tr td {
    font-size: 13px;
    color: #3f3f3f;
}

.tools-lists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 40px;
}

.tools-lists-row {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 40px;
}

.tools-items {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 15px;
    white-space: normal;
    width: 120px;
}

button.tools-items:disabled,
button[disabled].tools-items {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    cursor: not-allowed;
}

button.tools-items i,
a.tools-items i {
    border-radius: 50px;
    border: 1px solid #ddd;
    width: 45px;
    height: 45px;
    font-size: 20px;
    line-height: 45px;
}

button.tools-items span,
a.tools-items span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 5px;
    color: #4a4a4a;
}

.tools-items.brb-1 {
    border-bottom: 1px dashed rgba(169, 165, 170, 0.45);
    padding-bottom: 30px;
    margin-bottom: 20px;
}

.tools-items.color-1 i {
    color: #417505;
    border-color: #417505;
}

.tools-items.color-2 i {
    color: #7ed321;
    border-color: #7ed321;
}

.tools-items.color-3 i {
    color: #d391e1;
    border-color: #d391e1;
}

.tools-items.color-4 i {
    color: #f57423;
    border-color: #f57423;
}

.tools-items.color-5 i {
    color: #d6041e;
    border-color: #d6041e;
}

.tools-items.color-6 i {
    color: #1f5fa2;
    border-color: #1f5fa2;
}

.tools-items.color-7 i {
    color: #f5a623;
    border-color: #f5a623;
}

.tools-items.color-8 i {
    color: #ad78db;
    border-color: #ad78db;
}

.tools-items.color-9 i {
    color: #979797;
    border-color: #979797;
}

.tools-items.color-10 i {
    color: #a7a603;
    border-color: #a7a603;
}

.tools-items.color-11 i {
    color: #89b4e2;
    border-color: #89b4e2;
}

.act-bnt .normal-bnt {
    color: rgba(45, 59, 72, 0.45);
}

.edit-bnt .normal-bnt {
    font-size: 13px;
    text-decoration: underline;
    font-weight: normal;
}

.note-text,
.note-text span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
}

.jd-staff .table tr.open-ttbale td.allsf-tt-dtls {
    border-bottom: 0;
}

.add-staff-ttable {
    border: 1px solid #d3e6e3;
    background: rgba(3, 167, 145, 0.06);
    padding: 20px 10px;
    position: relative;
}

button.extend-closed {
    position: absolute;
    right: 0;
    top: 0;
    background: #03a791;
    color: #fff;
    border-radius: 2px 0 2px 2px;
    width: 20px;
    height: 20px;
}

button.extend-closed:hover {
    background: #008572;
}

.ttbale-row .sf-form label {
    display: inline-block;
    width: 100%;
    font-size: 13px;
}

.ttbale-row .sf-form .ant-time-picker {
    width: 100%;
}

.ttbale-row .sf-form {
    padding-right: 10px;
    width: 120px;
}

.ttbale-row .sf-form.form-group {
    width: auto;
}

.allocate-staff-collaps .ant-collapse>.ant-collapse-item {
    border: 0;
}

.allocate-staff-collaps .ant-collapse {
    border: 1px solid rgba(3, 167, 145, 0.26);
}

.allocate-staff-collaps .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding: 6px 16px !important;
    background: #e5f3f1;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #03a791;
    border-bottom: 0 !important;
}

.allocate-staff-collaps .ant-collapse-content {
    border-top: 1px solid rgba(3, 167, 145, 0.26);
    overflow: unset;
    padding: 0;
}

.allocate-staff-collaps .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    color: #03a791;
}

.allocate-staff-collaps .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.alsf-head {
    padding: 15px;
    border-bottom: 1px solid rgba(3, 167, 145, 0.26);
}

.alsf-head .sf-form {
    margin: 0;
}

.alsf-body,
.alsf-foot {
    padding: 15px;
}

.alsf-body button.bnt {
    margin: 0 auto;
    min-width: 50%;
}

.alsf-checkbx {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: -10px;
}

.alsf-checkbx .cu-checkbox {
    padding-left: 10px;
    padding-bottom: 10px;
    width: 15%;
}

.alsf-status {
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid rgba(45, 59, 72, 0.08);
    padding-top: 15px;
}

.alsf-status span {
    color: #222222;
    font-size: 13px;
    font-weight: 500;
}

.alsf-status span strong {
    font-weight: 500;
    padding-right: 2px;
    text-decoration-line: line-through;
}

.alsf-status span.provarity-txt strong {
    color: #d0021b;
}

.alsf-status span.done-txt strong {
    color: #417505;
    text-decoration-line: none;
}

.sign-swms-opt {
    padding: 9px 10px;
    background: #e5f3f1;
    border-radius: 4px;
}

.sign-swms-opt span {
    font-weight: 500;
    font-size: 13px;
    color: #3f3f3f;
    padding-right: 15px;
}

.sign-swms-opt span i {
    font-size: 13px;
    color: #03a791;
    padding-right: 5px;
}


/* job reposrts list */

.jd-report-list li {
    color: #4a4a4a;
    font-size: 14px;
    margin-top: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 20px;
}

.jd-report-list li::before {
    position: absolute;
    left: 5px;
    top: 6px;
    content: "\f111";
    font-size: 5.5px;
    font-family: fontawesome;
}

.jd-report-list li:first-child {
    margin-top: 0;
}

.jd-report-list li button {
    font-size: 12px;
}

.jd-report-list li button span {
    text-decoration: underline;
    padding-left: 3px;
}

.jd-report-list li button i {
    font-size: 14px;
}

.jbd-status {
    position: relative;
}

.jbd-status .client-fdbk {
    width: 60px;
    height: 60px;
    font-size: 12px;
    position: absolute;
    right: 0;
    padding: 2px;
    bottom: -60px;
    background: #bd10e0;
    margin-bottom: 0;
}

.jdw-notes.doc-vlue {
    margin-top: 35px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.jdw-notes span.jc-assign {
    color: #444c63;
    padding: 5px 0;
}

.jdw-notes span.jc-assign span {
    color: #4a4a4a;
}

.jdw-notes .jc-value-dtls {
    width: calc(100% - 60px);
}

.jc-note {
    color: #4a4a4a;
    font-weight: 300;
}

.jdw-notes.doc-vlue>span {
    padding: 0;
    width: calc(100% - 65px);
    word-break: break-all;
}

button.add-nr-bnt i {
    color: #03a791;
}

button.add-nr-bnt {
    font-size: 13px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

button.add-nr-bnt span {
    padding-left: 2px;
    text-decoration: underline;
}

.job-time-chk {
    display: flex;
}

.job-time-chk .add-sub-mod .cu-checkbox {
    padding: 0;
    flex: 1;
    max-width: 100%;
}

.job-time-chk .add-sub-mod .ant-checkbox-wrapper {
    padding: 5px;
    height: 34px;
    width: 34px;
    margin: 0 0 0 10px;
}

.job-time-chk .add-sub-mod:first-child .ant-checkbox-wrapper {
    margin-left: 0;
}

.add-day-night-shift .ant-collapse-content>.ant-collapse-content-box {
    padding: 15px 0;
}

.add-day-night-shift .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-left: 0;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
}

.add-day-night-shift .ant-collapse-borderless>.ant-collapse-item {
    border: 0;
}

.group-radio-bnt label.ant-radio-wrapper:last-child {
    margin-right: 0;
}

.sf-form.form-group.group-radio-bnt>label {
    display: inline-block;
    width: 100%;
}

.ttbale-row.ysf-time .sf-form:last-child {
    padding-right: 0;
}

.ysf-time {
    margin-bottom: 10px;
}

.add-day-night-shift .ant-collapse-borderless {
    padding: 0 10px 10px;
}

.add-day-night-shift .allocate-staff-collaps .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.alsf-rt-staff .alsf-checkbx .cu-checkbox {
    width: 50%;
    flex: 0 0 50%;
}

.alsf-rt-staff .alsf-checkbx .cu-checkbox .ant-checkbox-wrapper {
    display: flex;
    line-height: normal;
}

.alsf-rt-staff .alsf-checkbx .cu-checkbox .ant-checkbox-inner {
    top: 3px;
}

.alsf-rt-staff .ant-checkbox-wrapper {
    font-size: 12px;
}

.alsf-rt-staff .alsf-status span:nth-child(2) {
    padding: 0 10px;
}


/* job report */

.location-afbf-pic .ant-upload.ant-upload-select-picture-card {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    text-align: left;
}

.location-afbf-pic .ant-upload.ant-upload-select-picture-card>.ant-upload {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0;
    text-align: left;
}

.loadf-txt {
    display: flex;
    align-items: center;
    color: #03a791;
    line-height: normal;
}

.loadf-txt i {
    font-size: 15px;
}

.loadf-txt .ant-upload-text {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #03a791;
}

.job-cleaning-report .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
    border-radius: 0;
    border: 0;
    width: 90px;
    height: 90px;
}

.job-cleaning-report .ant-upload-list-picture-card {
    display: flex;
}

.job-cleaning-report .table td,
.job-cleaning-report .table th {
    border-top: 0;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    padding: 0.5rem 0.75rem;
}

.job-cleaning-report .table tr:nth-child(even) td {
    background: rgba(45, 59, 72, 0.04);
}

.job-cleaning-report .table tr td:last-child {
    width: 70px;
}

.drag-row .td:nth-child(1) {
    position: relative;
    width: 35px;
}

.drag-row .dragde-bnt {
    position: relative;
    left: 0;
    top: 4px;
    z-index: 99;
}

.job-cleaning-report .sf-form input[type="text"],
.job-cleaning-report .sf-form .ant-select-selection {
    height: 28px;
}

.job-cleaning-report .ant-select-selection__rendered {
    line-height: 26px;
}

td .act-bnt {
    position: relative;
    top: 3px;
}

.view-img-slider .ant-collapse .ant-collapse-header {
    font-size: 18px;
    font-weight: 400;
    padding: 15px;
}

.view-img-slider .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content-active {
    border-top: 1px solid rgba(45, 59, 72, 0.06);
}

.view-img-slider .ant-collapse-borderless>.ant-collapse-item {
    border: 0;
}

.view-img-slider .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    padding: 4px;
    background: #03a791;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
}


/* job report preview table  */

.location-pic-dtl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 20px;
    transition: 0.5s all;
}

.location-pic-dtl:hover {
    background: rgba(45, 59, 72, 0.04);
    transition: 0.5s all;
}

.job-abaf-table .ant-table-tbody>tr>td {
    padding: 0;
    border: 0;
    vertical-align: middle;
}

.loc-pic {
    height: 130px;
    width: 200px;
    overflow: hidden;
    border-radius: 4px 0 0 4px;
    position: relative;
}

.loc-pic img {
    /* height: auto;
  width: auto;
  min-width: 130px;
  min-height: 200px; */
    max-width: 100%;
    max-height: 100%;
    max-height: -webkit-fill-available;
}

.ab-images .loc-pic img {
    height: auto;
    width: auto;
    /* min-width: 140px;
  min-height: 100px; */
}

.job-pre-tag {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding: 6px 12px;
    letter-spacing: 0.5px;
}

.ab-images {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}

.ab-images .loc-pic:last-child {
    border-radius: 0 4px 4px 0;
    margin-left: 1px;
}

.ab-images .loc-pic {
    height: 100px;
    width: 140px;
}

.loc-name {
    flex: 1;
    padding: 10px 10px 10px 30px;
}

.loc-name h3 {
    font-size: 18px;
    font-weight: 600;
    color: #444c63;
}

.job-abaf-table .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.job-abaf-table .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.job-abaf-table .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.job-abaf-table .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: transparent;
}

.ant-carousel .slick-slide {
    text-align: center;
    height: 350px;
    line-height: 160px;
    background: #fff;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #03a791;
}

.view-img-slider .ant-carousel .slick-dots li button {
    background: #03a791;
}


/* date 17 Sept 2019 */

.upload-sfv-file.add-equipment-img.upeqip-pic.sm-drbx {
    display: inline-block;
    width: 100%;
}

.upload-sfv-file.add-equipment-img.sm-drbx>span {
    width: 100%;
    display: inline-block;
}

.add-equipment-img.sm-drbx .ant-upload.ant-upload-drag {
    width: 300px;
    height: 152px;
    margin: 0 auto 20px;
}

.sm-drbx .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
}

.upload-sfv-file.sm-drbx .ant-upload-drag-icon i.material-icons {
    font-size: 20px;
}

.upload-sfv-file.add-equipment-img.upeqip-pic.sm-drbx .ant-upload-drag-icon i.material-icons {
    font-size: 38px;
}

.upload-sfv-file.sm-drbx .ant-upload.ant-upload-drag p.ant-upload-hint {
    /* display: none; */
}

.upload-sfv-file.sm-drbx .ant-upload-text {
    /* padding: 5px; */
}

.sm-drbx .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
}

.sm-drbx .ant-upload-list-picture-card .ant-upload-list-item {
    float: none;
}

.photo-doc-up-table .table tr td:first-child,
.photo-doc-up-table .table tr th:first-child {
    text-align: center;
    width: 80px;
}

.photo-doc-up-table .table tr td:first-child i {
    font-size: 20px;
}

.view-img-slider .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}


/* job preview slider */

.view-img-slider .carousel-item {
    padding: 40px 90px 0;
}

.ab-image-slide {
    display: flex;
    position: relative;
}

.sld-pic {
    flex: 0 0 50%;
    width: 50%;
    overflow: hidden;
    position: relative;
    border: 0.095rem solid #5c5c5c;
    height: 450px;
    justify-content: center;
    display: flex;
}

.sld-pic img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

.before-image.sld-pic {
    border-left: 0;
}

.abpic-txt {
    position: absolute;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 14px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    bottom: 0;
    left: 0;
}

.before-image.sld-pic .abpic-txt {
    left: auto;
    right: 0;
}

.abpic-txt.loc-txt {
    left: 50%;
    background: rgba(0, 0, 0, 0.8);
    transform: translateX(-50%);
}

.slider-navigation {
    display: flex;
    justify-content: flex-end;
    padding: 10px 90px 30px 0;
}

.slider-navigation .carousel-control-next,
.slider-navigation .carousel-control-prev {
    position: static;
    width: 30px;
    height: 30px;
    border: 1px solid #03a791;
    border-radius: 100px;
    margin-left: 15px;
}

.slider-navigation a span {
    font-size: 20px;
    color: #03a791;
}

.location-afbf-pic .ant-upload-list-item-actions a:first-child {
    display: none;
}


/* Sign SWMS */

.sf-big-hd {
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    margin-bottom: 20px;
}

.co-logo {
    text-align: center;
}

.co-logo img {
    width: 100px;
}

.normal-txt span {
    font-size: 15px;
    font-weight: 300;
    color: #4a4a4a;
}

.normal-txt.brt {
    padding: 10px 0;
    border-top: 1px solid rgba(229, 225, 231, 0.4);
}

.sign-swms-items .ant-collapse-header {
    background: #fff;
    font-size: 15px;
    font-weight: 500;
}

.sign-swms-col .sign-swms-items {
    border-bottom: 1px solid rgba(229, 225, 231, 0.4);
}

.sign-swms-wrap .ant-collapse {
    border: 1px solid rgba(229, 225, 231, 0.4);
}

.sign-swms-items .ant-collapse-content {
    border-top: 1px solid rgba(229, 225, 231, 0.4);
}

.view-swms-site {
    border: 1px solid rgba(229, 225, 231, 0.4);
    border-radius: 4px;
}

.view-swms-site .vsite-title {
    padding: 10px;
    border-bottom: 1px solid rgba(229, 225, 231, 0.4);
    background: #fafafa;
}

.view-site-conte {
    padding: 10px;
}

.view-swms-panel {
    border-bottom: 1px solid rgba(229, 225, 231, 0.4);
}

.view-swms-panel:last-child {
    border-bottom: 0;
}

.site-name-list {
    margin-bottom: 20px;
}

.site-name-list:last-child {
    margin-bottom: 0;
}

.task-name-list {
    margin-bottom: 5px;
}

.task-name-list:last-child {
    margin-bottom: 0;
}

.site-title {
    padding-bottom: 10px;
    color: #4a4a4a;
    font-weight: 400;
}

.task-name-list {
    background: rgba(45, 59, 72, 0.03);
    padding: 20px;
}

.task-title {
    font-size: 16px;
    font-weight: 500;
    color: #4a4a4a;
    border-bottom: 1px solid rgba(229, 225, 231, 0.4);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.signature-box {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin: 30px 0 0;
}

.signature-box .sig-title {
    font-size: 14px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: normal;
    flex: 0 0 150px;
}

.upload-ur-sign {
    /* flex-grow: 1; */
    padding: 0 40px;
}

.upload-ur-sign.no-pl {
    padding-left: 0;
}

.upload-ur-sign .sign-box {
    border: 1px dashed rgba(169, 165, 170, 0.45);
    display: inline-block;
    width: 500px;
    border-radius: 4px;
    height: 104px;
    padding: 1px;
}

.sign-box img {
    height: 100%;
}

.sign-box svg {
    width: 100%;
}

.sign-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign-action .normal-bnt {
    margin-top: 5px;
    text-decoration: underline;
}

.sign-box canvas {
    width: 100%;
    height: 80px;
    visibility: hidden;
}

.swms-doc-title {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    margin-bottom: 10px;
}

.swms-doc-title span {
    font-size: 13px;
    font-weight: normal;
    display: inline-block;
    width: 100%;
    padding-top: 3px;
}

.swms-act-list {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba(229, 225, 231, 0.4);
    padding-top: 5px;
}

.swms-act-list span {
    padding: 10px;
    padding-right: 0;
    padding-left: 30px;
    position: relative;
    color: #4a4a4a;
    flex: 25%;
    font-size: 14px;
}

.swms-act-list span::before {
    content: "radio_button_checked";
    font-size: 12px;
    position: absolute;
    color: #03a791;
    left: 10px;
    top: 13px;
    font-family: "Material Icons";
}


/* Incident Steps New ----
--- just remove this class name if needs '.ant-steps-item-container'
*/

.incident-steps {
    padding: 10px;
}

.incident-steps .ant-steps-vertical .ant-steps-item .ant-steps-item-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.incident-steps .ant-steps-item-title {
    padding: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
}

.incident-steps .ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    position: static;
    order: 3;
    height: 40px;
    padding: 0;
    margin: 8px 0 15px;
}

.incident-steps .ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
    height: 100%;
    margin: 0;
    background: rgba(45, 59, 72, 0.29);
}

.incident-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: transparent;
    border: 1px solid rgba(45, 59, 72, 0.45);
}

.incident-steps .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin: 0 0 15px;
    padding: 0;
    line-height: normal;
    width: 14px;
    height: 14px;
    border-radius: 100px;
}

.incident-steps .ant-steps-dot .ant-steps-item-content {
    min-height: auto;
}

.incident-steps .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after {
    position: static;
}

.incident-steps .ant-steps-item-process>.ant-steps-item-content>.ant-steps-item-title {
    color: #03a791;
}

.incident-steps .ant-steps-item-wait>.ant-steps-item-content>.ant-steps-item-title {
    color: #4a4a4a;
}

.incident-steps .ant-steps-dot .ant-steps-item-content {
    width: auto;
}


/* --------- end steps ----- */

.w-100 .ant-time-picker {
    width: 100%;
}


/* Thank you Modal */

.thank-you-modal .ant-modal-close {
    display: none;
}

.thnu-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 22px 0;
}

.thnu-wrap span.glyph-item {
    font-size: 57px;
    color: #03a791;
    padding: 0;
    line-height: normal;
}

.thnu-wrap h1 {
    font-size: 24px;
    font-weight: 600;
    color: #222;
    padding: 20px 0;
}

.thnu-wrap p {
    font-weight: 400;
    font-size: 14px;
    color: #222;
}

.thnu-wrap h3 {
    font-size: 18px;
    font-weight: 400;
    color: #4a4a4a;
    padding: 30px 0;
}

.thnu-wrap button.bnt.bnt-active {
    padding: 0 50px;
    margin: 20px 0;
}

.thnu-wrap a {
    font-weight: 500;
    font-size: 14px;
    color: #03a791;
    margin-top: 10px;
}

.scope-of-work .site-s-body {
    background: rgba(45, 59, 72, 0.04);
    padding: 15px 20px;
    margin-bottom: 2px;
}

.scope-of-work .site-s-body:last-child {
    margin-bottom: 0;
}

.sco-note {
    border-top: 1px solid rgba(45, 59, 72, 0.1);
    padding-top: 5px;
}

.upload-sfv-file.dotted-br .ant-upload.ant-upload-drag {
    border-style: dashed;
}

.vrbl-acc-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    border-left: 1px solid rgba(45, 59, 72, 0.1);
    padding-left: 20px;
    min-height: 100%;
}


/* Report */

.reports-bnt.normal-bnt {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 30px;
    border-radius: 4px;
    white-space: normal;
    display: flex;
    flex-direction: column;
    min-height: 150px;
    align-items: center;
    color: #222;
    justify-content: center;
}

.reports-bnt.normal-bnt:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    transition: 0.5s all;
}

.reports-bnt.normal-bnt:hover .report-icon {
    background: #03a791;
}

.report-icon {
    width: 70px;
    height: 70px;
    line-height: 90px;
    background: #d1d1d1;
    border-radius: 50px;
    transition: 0.5s all;
}

.report-icon i {
    color: #fff;
    font-size: 32px;
}

.reports-bnt h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
}

.reports-bnt span {
    font-size: 14px;
    font-weight: 300;
}

.job-cleaning-report .sf-form.lsico .ant-calendar-picker-input {
    height: 28px;
}

.job-cleaning-report .ccalnd-icon {
    top: 5px;
}


/* incident report page */

.incident-lists {
    padding-left: 20px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.incident-lists li {
    padding: 0 20px 15px 0;
    margin: 0 20px 0 0;
    list-style: disc;
    font-size: 15px;
    font-weight: 300;
    color: #4a4a4a;
}

.incident-lists li:last-child {
    margin: 0;
    padding: 0;
}

.imgs-items {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.imgs-items:last-child {
    margin-bottom: 0;
}

.imgs-items i.glyph-item {
    font-size: 20px;
    padding-right: 10px;
    color: 000;
}

.img-title {
    font-size: 14px;
    color: #03a791;
}

img.sign-img {
    height: 40px;
    width: auto;
    display: block;
    margin-top: 5px;
}

.ts-report-list li a {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    margin-bottom: 4px;
}

.ts-report-list li a i {
    font-size: 16px;
    padding-right: 5px;
}

.ts-report-list li a:hover {
    color: #008572;
    text-decoration: underline;
}

.swhd-task-nm {
    font-size: 18px;
    font-weight: 600;
    color: #222;
}


/* Job Manager */

.job-status-lists {
    background: #f0f2f5;
    margin: 20px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.job-status-lists.job-request-box {
    background: #fff;
}

.jobmr-status {
    height: 80px;
    width: 80px;
    background: rgba(74, 144, 226, 0.6);
    border-radius: 50px;
    padding: 4px;
    box-shadow: 0 0 0 4px rgba(74, 144, 226, 0.3);
}

.sts-circle {
    background: #4a90e2;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: normal;
}

.sts-circle span {
    font-size: 14px;
    font-weight: 600;
}

.jb-request-status {
    background: rgba(208, 2, 27, 0.6);
    box-shadow: 0 0 0 4px rgba(208, 2, 27, 0.3);
}

.jb-request-status .sts-circle {
    background: rgba(208, 2, 27, 1);
}

.jb-started-status {
    background: rgba(126, 211, 33, 0.6);
    box-shadow: 0 0 0 4px rgba(126, 211, 33, 0.3);
}

.jb-started-status .sts-circle {
    background: rgba(126, 211, 33, 1);
}

.col.job-mr-w {
    flex-grow: 0;
    max-width: 110px;
}

.col.job-dtl-w {
    flex-grow: 3;
    border-right: 1px solid rgba(45, 59, 72, 0.1);
    display: flex;
    align-items: center;
}

.col.job-act-w {
    flex-grow: 1;
    position: relative;
}

.job-mr-head .col {
    padding: 15px;
}

.job-mr-details .job-name-id {
    font-size: 18px;
    font-weight: 500;
}

.job-mr-time {
    margin-top: 10px;
}

.job-mr-time span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(45, 59, 72, 0.8);
    display: inline-block;
}

.job-mr-time span:last-child {
    margin-left: 60px;
}

.job-mr-time span i {
    display: inline-block;
    margin-right: 6px;
}

.job-action-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

button.open-job-bnt {
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px;
    border-radius: 0 4px 0 0;
}

button.open-job-bnt i {
    font-size: 22px;
    color: #222;
}

button.open-job-bnt:hover {
    background: rgba(45, 59, 72, 0.1);
}

button.open-job-bnt:hover i {
    color: #03a791;
}

.job-action-area span {
    font-weight: 300;
    color: rgba(45, 59, 72, 0.8);
}

.job-action-area strong {
    font-weight: 500;
    font-size: 16px;
    color: rgba(45, 59, 72, 0.8);
}

.job-action-area button.bnt {
    height: 32px;
    background: transparent;
    margin-top: 8px;
    min-width: 120px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    width: 140px;
}

.job-action-area button.bnt:hover {
    background: #008572;
    border-color: #008572;
    color: #fff;
}

.job-action-area button.bnt-active {
    background: #03a791;
}

.job-action-area button.bnt i {
    display: inline-block;
    margin-right: 8px;
}

.job-mr-body {
    width: calc(100% - 150px);
    margin-left: 120px;
    margin-right: 30px;
    border-top: 1px solid rgba(45, 59, 72, 0.1);
    padding: 15px 0 30px;
}

.licence-slider .licence-list {
    flex-direction: column;
    margin: 0;
    text-align: center;
    align-items: center;
    padding: 20px;
}

.licence-slider .li-details {
    padding-left: 0;
}

.licence-slider .licence-list img {
    max-width: 100%;
}

.has-licence .licence-list .licence-img {
    width: 40%;
    flex: 0 0 40%;
    height: 100%;
    overflow: hidden;
}

.has-licence .licence-list .licence-img img {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
}

.licence-slider .li-details h3 {
    font-size: 14px;
}

.licence-slider .li-lists li {
    font-weight: 300;
    line-height: normal;
    padding: 0;
}

.slider-nav .carousel-control-prev,
.slider-nav .carousel-control-next {
    background: #362148;
    border: 1px solid rgba(45, 59, 72, 0.1);
    opacity: 1;
    width: 28px;
    height: 28px;
    border-radius: 100px;
    top: 50% !important;
    text-align: center;
    transform: translateY(-50%);
}

.slider-nav .carousel-control-prev {
    left: -4px;
    right: 171px !important;
}

.slider-nav .carousel-control-next {
    right: -4px;
}

.slider-nav i {
    font-size: 16px;
    color: #90bf00;
}

#licencesSlider {
    padding: 10px;
}

#licencesSlider .carousel-item {
    margin: 0 40px;
}

#licencesSlider.taskfimgModal .carousel-item {
    margin: 0;
    height: 300px;
}

.ts-select-date {
    background: rgba(45, 59, 72, 0.1);
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    align-items: center;
}

button.ts-nav-arrow {
    background: #fff;
    padding: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    line-height: 26px;
    text-align: center;
    transition: 0.5s all;
}

button.ts-nav-arrow:hover {
    background: #03a791;
}

button.ts-nav-arrow:hover i {
    color: #fff;
}

button.ts-nav-arrow i {
    color: rgba(45, 59, 72, 0.6);
    font-size: 18px;
}

.ts-select-date label {
    color: rgba(45, 59, 72, 0.8);
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
}

.update-upload-img.upload-sfv-file .ant-upload.ant-upload-drag {
    height: 150px;
}

.remove-error span.error-input {
    display: none;
}


/* calendar header */

.rbc-toolbar>span:nth-child(1) {
    order: 3;
}

.rbc-toolbar>span:nth-child(2) {
    order: 2;
}

.rbc-toolbar>span:nth-child(3) {
    order: 1;
}

.job-calendar-view .rbc-toolbar {
    justify-content: space-between;
}

button.today-bnt-c {
    position: absolute;
    left: 255px;
    margin: 0;
}

.job-calendar-view .rbc-toolbar-label {
    text-align: right;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 10px;
    color: #444c63;
}

.job-calendar-view .rbc-toolbar-label:after {
    font-family: fontawesome;
    content: "\f073";
    font-size: 20px;
    padding-left: 10px;
}

.job-calendar-view .rbc-toolbar .nb-date-nav button {
    text-indent: 40px;
    background: #fff;
    width: 30px;
    overflow: hidden;
    height: 30px;
    padding: 0;
    border-radius: 50px;
    display: inline-block;
    border: 1px solid #dadfea;
    position: relative;
    margin: 0;
}

.nb-date-nav {
    display: flex;
    align-items: center;
    text-align: center;
}

.nb-date-nav button:nth-child(1):before,
.nb-date-nav button:last-child:before {
    font-size: 20px;
    text-indent: 0;
    font-family: fontawesome;
    color: #8c97b2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.nb-date-nav button:nth-child(1):before {
    content: "\f104";
}

.nb-date-nav button:last-child:before {
    content: "\f105";
}

.job-calendar-view .calendar-popover {
    display: inline-block;
    width: 100%;
}

.sf-st-item .iconbx.key-rtic {
    line-height: 35px;
}

.key-rtic img {
    width: 16px;
}

.cor-action {
    position: relative;
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.cor-action:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cor-action .info-btn {
    position: absolute;
    right: -1px;
    top: -5px;
}

.staff-timesheet-table .scrollarea.sf-scroll-width .scrollarea-content {
    min-width: 800px;
}

.staff-timesheet-table .org-user-table .tr .td:last-child {
    min-width: 40px;
}

.staff-timesheet-table .org-user-table .tr.update-tsheet .td:last-child {
    min-width: 65px;
}

.rost-slider .ant-slider {
    margin: 0;
}

.rost-slider .ant-slider-rail {
    background: #c0c0c0;
}

a.normal-link:not([href]):not([tabindex]) {
    color: #03a791;
}

a.normal-link:not([href]):not([tabindex]):hover {
    color: #008572;
}

.jdw-wrap .btn-hs-icon .bnt {
    min-width: 100%;
}

.jdw-wrap .btn-hs-icon {
    flex: 1;
}

.taskfimgModal img {
    max-width: 100%;
    max-height: 300px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0;
}

table.swms-doc-table {
    width: 100%;
}

.swms-doc-table tr th {
    background: #4a4a4a;
    color: #fff;
    border-bottom: 1px solid rgba(169, 165, 170, 0.25);
    border-left: 1px solid rgba(169, 165, 170, 0.25);
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
}

.swms-doc-table tr th:first-child {
    border-left: 0;
}

.swms-doc-table tr th span {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    font-weight: 400;
    color: #c8c8c8;
}

.swms-doc-table tr td {
    border: 0;
    border-bottom: 1px solid rgba(169, 165, 170, 0.25);
    border-left: 1px solid rgba(169, 165, 170, 0.25);
}

.swms-doc-table tr td:first-child {
    border-left: 0;
}

table.swms-act-list {
    border: 0;
}

.update-upload-img .ant-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    float: none;
}

.update-upload-img .ant-upload-list-picture-card .ant-upload-list-item {
    width: 60px;
    height: 60px;
    padding: 5px;
}

.update-upload-img .ant-upload-list-picture-card .ant-upload-list-item:last-child {
    margin-right: 0;
}

.update-upload-img .ant-upload-list-item-info>span {
    height: 100%;
}

.ins-pill {
    background: rgba(74, 144, 226, 0.14);
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 12px;
    min-width: 60px;
    color: rgba(45, 59, 72, 0.8);
    text-align: center;
}

.ins-pill.partial-pill {
    background: rgba(248, 231, 28, 0.29);
}

.ins-pill.complete-pill {
    background: rgba(3, 167, 145, 0.14);
}

.sf-form.select-wibg.cor-act-srch .ant-select {
    height: 28px;
}

.sf-form.select-wibg.cor-act-srch .ant-select-selection {
    border: 0 !important;
}

.cor-act-srch.sf-form .ant-select-combobox .ant-select-search__field__wrap {
    height: 28px;
    display: flex;
}

.cor-act-srch .srch-icons {
    top: 8px;
}

.ttbale-row input.ant-time-picker-input {
    border: 1px solid rgba(45, 59, 72, 0.08);
    background: transparent;
    padding-right: 25px;
}

.sf-form.sf-note {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.add-jdd-time .add-sub-mod .ant-checkbox-wrapper {
    background: #fff;
}

.au-sign {
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
}

.au-sign:last-child {
    border-bottom: 0;
}

.no-varify-form {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.no-varify-form button.bnt.bnt-active {
    height: 30px;
    font-weight: 500;
    width: 60px;
    flex: 0 0 60px;
    font-size: 13px;
}

.no-varify-form fieldset.sf-form {
    width: calc(100% - 60px);
    padding-right: 10px;
}

.no-varify-form fieldset.sf-form input[type="text"] {
    height: 30px;
}

.re-send-bnt {
    flex: 0 0 100%;
    font-size: 12px;
    padding-top: 5px;
}

.certifi-img .view-text-value label {
    display: inline-block;
    width: 100%;
}

.data-v-row.certifi-img .data-v-col {
    width: 100%;
    border-top: 1px solid #eaebec;
    padding: 10px 0;
    margin-top: 10px;
}

img.cerfic-pic {
    max-width: 80%;
    height: 200px;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #f1f1f1;
}


/* view invoice page */

.invoice-content {
    padding: 0 30px;
}

.invoice-content .co-details-prv p {
    font-size: 13px;
    color: #4a4a4a;
}

.invoice-content .co-details-prv p strong {
    font-weight: 600;
}

.jdp-head.inv-head {
    padding: 10px 30px;
}

.inv-head .co-details-prv p,
.inv-head h3 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
    line-height: normal;
    color: #4a4a4a;
}

.inv-head h3 {
    text-align: right;
}

.inv-date-dtl span {
    display: inline-block;
    width: 85px;
    text-align: right;
    padding-right: 10px;
    margin-bottom: 4px;
    font-size: 13px;
}

.inv-date-dtl strong {
    font-weight: 600;
    font-size: 13px;
}

.inv-to-dtls {
    display: flex;
}

.inv-to-dtls strong {
    font-weight: 600;
    flex: 0 0 30px;
}

.inv-table-details table {
    width: 100%;
}

.inv-table-details table tr th,
.inv-table-details table tr td {
    border: 1px solid rgba(45, 59, 72, 0.3);
    padding: 5px 10px;
    font-size: 13px;
    vertical-align: top;
    color: #4a4a4a;
}

.inv-table-details table tr th {
    text-align: center;
    font-weight: 600;
    background: #f1f1f1;
    text-transform: uppercase;
}

.inv-table-details table td strong {
    font-weight: 600;
    display: inline-block;
    width: 100%;
    margin-bottom: 2px;
}

.inv-table-details.inv-price-dtl tr th:first-child {
    width: 60px;
}

.inv-table-details.inv-price-dtl tr th:nth-child(3) {
    width: 90px;
    text-align: center;
}

.inv-price-dtl tr.no-border td:last-child {
    border: 1px solid rgba(45, 59, 72, 0.3);
}

.inv-price-dtl tr.no-border td {
    border: 0;
}

.inv-task-img img {
    width: 70px;
    margin: 0 auto;
    height: auto;
    border-radius: 4px;
}

.inv-table-details table td.inv-wo-prerd span,
.inv-table-details table td.inv-wo-prerd strong {
    display: inline-block;
    width: auto;
}

.inv-table-details table td.inv-wo-prerd strong {
    padding-left: 10px;
}


/* swms view  */

.swms-view-items>h2 {
    font-weight: 600;
    font-size: 13px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
    padding: 5px 0 10px;
}

.swms-task-view-items>h3 {
    border-bottom: 1px solid rgba(45, 59, 72, 0.04);
    padding: 5px 0 10px;
    font-size: 14px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: 400;
}

.smws-view-task-table table {
    margin-top: 10px;
    width: 100%;
}

.swms-task-view-items,
.smws-view-task-table {
    padding: 0 10px;
}

.smws-view-task-table table.swms-table tr td span {
    display: inline-block;
    width: 100%;
    padding-right: 14px;
    font-size: 13px;
    font-weight: 400;
}

.swms-view-items,
.swms-task-view-items {
    margin-bottom: 20px;
}

.up-jd-scop th .ant-checkbox-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    line-height: normal;
    font-size: 13px;
    font-weight: 600;
}

.up-jd-scop th {
    color: #fff;
    line-height: normal;
    font-size: 13px;
    font-weight: 600;
}

.up-jd-scop th .ant-checkbox-wrapper .ant-checkbox {
    padding-bottom: 5px;
}

.stff-action-bnt {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    align-items: center;
    line-height: normal;
}

.stff-action-bnt {
    line-height: normal;
}

.stff-action-bnt .normal-bnt {
    margin-left: 6px;
}

.stff-action-bnt i {
    font-size: 16px;
}


/* Mobile no with country code */

.co-code-mobile-no {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.sf-form.co-code-txt .ant-select {
    /* min-width:auto; */
    min-width: 100%;
}

.sf-form.co-code-txt .ant-select-selection {
    border-radius: 4px 0 0 4px;
    border: 0 !important;
    background: #03a791;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
}

fieldset.sf-form.co-code-txt {
    position: relative;
    min-width: 40px;
    max-width: 100px;
}

.add-user-table .sf-form.co-code-txt .ant-select-selection {
    height: 30px;
}

.sf-form.co-code-txt .select-wibg .ant-select-arrow {
    background: transparent;
    color: #fff;
    border-radius: 0;
    width: 10px;
    position: static;
    padding: 0;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
}

fieldset.sf-form.mobile-ntxt {
    width: calc(100% - 100px);
    flex: auto;
    min-width: 100px;
    max-width: 100%;
}

.sf-form.co-code-txt .ant-select-selection-selected-value {
    color: #fff;
    padding: 0;
    overflow: unset;
    text-overflow: unset;
    min-width: 16px;
    max-width: 100px;
    float: none;
    /* font-family: "Lato", sans-serif; */
    font-weight: 400;
    font-size: 13px;
}

.sf-form.mobile-ntxt input[type="text"],
.add-user-table tr td .sf-form.mobile-ntxt input[type="text"] {
    border-radius: 0 4px 4px 0;
    border-left: 0;
}

.co-code-txt .ant-select-selection__rendered,
.add-user-table tr td .co-code-txt .ant-select-selection__rendered {
    line-height: normal;
    margin: 0;
    height: 34px;
    display: flex;
    align-items: center;
}

.co-code-txt .ant-select-search--inline,
.add-user-table tr td .co-code-txt .ant-select-search--inline {
    top: 0;
}

.sf-form.co-code-txt .select-wibg .ant-select-arrow .ant-select-arrow-icon {
    position: static;
    height: auto;
    transform: none;
}

.co-code-txt .ant-select-dropdown-menu,
.ant-select-dropdown-menu {
    overflow-x: hidden;
    overflow-y: scroll;
}

.co-code-txt .ant-select-dropdown-menu::-webkit-scrollbar,
.ant-select-dropdown-menu::-webkit-scrollbar {
    width: 4px;
    border-radius: 100px;
}

.co-code-txt .ant-select-dropdown-menu::-webkit-scrollbar-track,
.ant-select-dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.co-code-txt .ant-select-dropdown-menu::-webkit-scrollbar-thumb,
.ant-select-dropdown-menu::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.1);
}

.co-code-txt .ant-select-dropdown-menu:hover::-webkit-scrollbar-thumb,
.ant-select-dropdown-menu:hover::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.2);
}

.ant-select-dropdown-menu-item {
    text-overflow: unset;
}


/* end */


/* change password */

.sf-change-pass label {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.sf-change-pass .sf-form input[type="password"] {
    height: 45px;
}

.sf-change-pass {
    padding: 20px;
}

.sf-change-pass .password-strength-meter {
    top: 42px;
}

.sf-change-pass .password-strength-meter .password-strength-meter-label {
    margin: 2px 0 0 0;
    font-size: 12px;
    font-weight: 300;
}

.sf-change-pass span.error-input {
    text-align: left;
    font-size: 13px;
}

.sf-change-pass .sf-form>div>div {
    position: relative;
}

.sf-change-pass .sf-form>div>div:after {
    font-size: 19px;
    position: absolute;
    color: #ddd;
    left: 10px;
    top: 14px;
    font-family: "Material Icons";
    content: "vpn_key";
}

.notify-mngr-search.sf-form .ant-select-selection.ant-select-selection--multiple {
    min-height: 34px;
    max-height: 100%;
    height: auto;
}


/* add staff popover */

.sub-popover-slt {
    padding: 10px;
}

.staff-dtl-list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(169, 165, 170, 0.2);
    padding: 0 0 10px;
    margin-bottom: 10px;
}

.staff-dtl-list label.ant-checkbox-wrapper {
    flex: 0 0 100%;
    width: 100%;
}

.staff-dtl-list span.staff-licence-list {
    padding-left: 18px;
    margin-left: 10px;
    padding-top: 5px;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: rgba(45, 59, 72, 0.8) !important;
    position: relative;
}

.staff-dtl-list span.staff-licence-list::before {
    content: "check";
    font-family: "Material Icons";
    color: #03a791;
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 1px;
}

.jd-add-staff-popover #addStaffDrop.sf-form .ant-select-selection {
    height: auto;
}

.staff-dtl-list .ant-checkbox-wrapper span.ant-checkbox,
.staff-dtl-list .ant-checkbox+span {
    color: rgba(45, 59, 72, 1) !important;
    font-size: 13px !important;
    display: inline-block !important;
    font-weight: 400 !important;
}

#addStaffLince .ant-popover.ant-popover-placement-bottomLeft {
    position: static;
}

#addStaffLince {
    display: flex;
    flex-direction: column;
}

#addStaffLince .all-btn.multibnt {
    order: 3;
}

#addStaffLince .multibnt .btn-hs-icon .bnt {
    font-size: 12px;
}

#addStaffLince>div:last-child .ant-popover-inner-content {
    width: 100%;
}

.sf-login .pre-login-left-arrow {
    margin-top: 30px;
    color: rgba(45, 59, 72, 0.4);
}

.sf-login .pre-login-left-arrow:hover {
    color: #03a791;
}

.bnt.sf-big-bnt {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    font-weight: 700;
    padding: 0 18px;
}

.bnt.sf-big-bnt i {
    font-size: 20px;
}

.time-slider fieldset.form-group.rost-slider {
    flex: 1 1 90%;
    width: 90%;
}

.time-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.time-slider:last-child {
    margin-bottom: 0;
}

.time-slider button.exp-bnt {
    border-radius: 100px;
    height: 20px;
    width: 20px;
    font-size: 12px;
    line-height: 20px;
    margin-left: 10px;
}

.time-slider button.exp-bnt:disabled {
    background: rgba(45, 59, 72, 0.3);
    cursor: not-allowed;
}


/* search box */

.show-search-box {
    display: flex;
    background: #fff;
    border: 1px solid rgba(45, 59, 72, 0.2);
    border-radius: 50px;
    height: 35px;
    padding: 0 10px;
}

.page-search-box input.toggle-textbox,
.page-search-box input.toggle-textbox:active,
.page-search-box input.toggle-textbox:focus {
    border: 0;
    outline: none;
    background: transparent;
    min-height: 100%;
    padding: 5px;
    line-height: normal;
    margin-right: 10px;
    color: rgba(45, 59, 72, 0.5);
    font-size: 14px;
}

.page-search-box {
    display: flex;
}

.page-search-box button.normal-bnt {
    margin: 0 10px;
}

.sett-badge.search-badge {
    cursor: default;
}

.sf-chkbx-group.chk-bullet {
    padding: 2px 10px;
}

.chk-bullet>span {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    font-weight: 500;
    padding: 0 10px 12px;
    flex: 0 0 50%;
    position: relative;
}

.chk-bullet>span:before {
    content: "fiber_manual_record";
    font-family: "Material Icons";
    color: rgba(0, 0, 0, 0.5);
    font-size: 6px;
    position: absolute;
    left: 0;
    top: 5px;
}

.yard-site-rd-bnt .ant-radio-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.vjh-items {
    margin: 5px 0 5px 20px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.03);
    width: 100%;
}

.vjh-items button.normal-bnt {
    font-size: 13px;
    color: #8a8a8a;
    font-weight: 600;
    padding: 5px 0;
}

.vjh-items button.normal-bnt:hover {
    color: #03a791;
    text-decoration: underline;
}

.vjh-items-box .show-frquency-box>.ant-collapse-item>.ant-collapse-content {
    margin-top: 0;
}

#confirmPopPo,
#user-edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#user-edit-org {
    display: flex;
    justify-content: center;
    align-items: center;
}

.site-s-footer.sco-note-vlue {
    border-bottom: 0;
    padding: 10px 0 15px;
}

.sco-note-vlue .data-v-col {
    border-left: 1px solid rgba(45, 59, 72, 0.08);
    min-width: 100px;
    padding-left: 10px;
    margin-bottom: 0;
}

.sco-note-vlue .no-note-brder .data-v-col {
    border-right: 0;
    padding-left: 0;
}

.sco-note-vlue .no-note-brder .data-v-col:first-child {
    border-left: 0;
}

.view-text-value.scodc-site-title {
    max-width: 70%;
}

.view-a-n-task .sf-card-body .sf-card-body {
    padding: 0 0 15px;
}

.view-a-n-task .sf-card-body .sf-card-body .add-n-site .estm-lists {
    padding: 0 15px;
}

.view-a-n-task .sf-card-body .sf-card-body .add-n-site .add-task-bnt {
    margin-left: 15px;
}

.view-a-n-task .sf-card-body .sf-card-body .add-n-site label,
.view-a-n-task .sf-card-body .add-n-site .estm-lists label {
    font-weight: normal;
}

.view-a-n-task .sf-card-body .add-n-site .estm-lists .quote-vlu-txt label {
    font-weight: 600;
}

.quote-vlue.qto-position-st {
    flex: 0 0 130px;
    padding: 0 15px;
    width: 130px;
}

.inv-table-details.inv-price-dtl .quote-send-table tr th:first-child {
    width: 60%;
}

.inv-table-details.inv-price-dtl .quote-send-table tr th:last-child {
    width: 20%;
}

.inv-table-details table.quote-send-table td strong {
    color: #222;
    font-size: 15px;
}

.inv-table-details table.quote-send-table td.quo-dtls-txt {
    padding-left: 20px;
}

.thank-re-f-quote {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 60px 0 10px;
}

.thank-re-f-quote span {
    background: #c8f7f0;
    border: 1px solid #03a791;
    font-weight: 700;
    padding: 5px 60px;
    font-size: 16px;
    color: #222;
}

.quo-dtls-txt span b {
    font-weight: 700;
    font-size: 13px;
    text-transform: capitalize;
    color: #222;
}

.quote-send-table .inv-task-img,
.inv-table-details.inv-price-dtl .quote-send-table tr td:last-child {
    vertical-align: middle;
}

.inv-table-details.inv-price-dtl .quote-send-table .inv-wo-prerd strong {
    font-size: 18px;
}

i.check-list-item {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    color: #03a791;
}

i.check-list-item.org-colr {
    color: #f5a623;
}

.sf-service-list .ant-list-item-meta-content {
    position: relative;
    width: calc(100% - 56px);
    overflow: hidden;
}

.calendar-bnt-ico {
    display: none !important;
}

.calendar-popover .sf-card-body {
    padding: 15px;
}

.closed.sf-searchbar .ant-empty-image img {
    width: 44px;
}

.closed.sf-searchbar .ant-list-empty-text {
    padding: 16px 10px;
}

.sub-th-vlue th {
    text-align: center;
}

.swms-doc-table tr td.std-sty {
    border-left: 0;
}

.swms-doc-new-tbl .swms-doc-table tr td {
    padding: 10px;
    font-size: 13px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: 300;
}

.swms-doc-new-tbl .swms-act-list {
    border: 0;
    padding: 0;
}

img.sign-image {
    height: 104px;
    width: auto;
}

.no-licence button.bnt.bnt-w-br {
    margin: 0 auto;
}

.job-completed {
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    color: #03a791;
}

.job-completed span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.job-completed span i {
    width: 30px;
    font-size: 21px;
}

.jb-note-items .data-v-row.sAgent-note {
    margin: 0;
    padding-right: 0;
}

.jb-note-items .data-v-row .data-v-col .view-text-value {
    padding-left: 0;
}


/* estimate  */

.estimate-rd-bnt .sf-roles-group .ant-radio-button-wrapper {
    background: transparent;
    width: 33.33%;
    flex: 0 0 33.33%;
    padding: 0 0 0 5px;
    margin: 0;
}

.estimate-rd-bnt span.rbp-btn {
    background: #fff;
    height: 30px;
}

.estimate-rd-bnt .ant-radio-group {
    flex-wrap: nowrap;
}

.estimate-rd-bnt .sf-roles-group .ant-radio-button-wrapper-checked span.rbp-btn {
    color: #03a791;
}

.estimate-rd-bnt .sf-roles-group .ant-radio-button-wrapper-checked span.rbp-btn:after {
    width: 14px;
    font-size: 8px;
    height: 14px;
    line-height: 14px;
    right: -4px;
    top: -5px;
}

.add-estimate-scdo .sf-form input[type="text"],
.add-estimate-scdo .sf-form input[type="number"] {
    min-width: auto;
}

.esti-hrs-dtl.row {
    margin-left: 0;
    margin-right: -5px;
}

.esti-hrs-dtl .col-md-3 {
    padding-left: 0;
    padding-right: 5px;
}

.qunty-rate {
    padding-left: 8px;
    color: rgba(45, 59, 72, 0.6);
    font-weight: 400;
}

.split-j-item .add-estimate-scdo {
    padding-bottom: 0 !important;
}

.split-j-item .add-estimate-scdo .row span.error-input {
    bottom: -14px;
}

.sf-form.esti-tabs {
    margin-bottom: 10px;
}

.esti-tabs-items {
    padding-left: 5px;
}

.add-estimate-scdo .add-line-bnt {
    font-size: 11px;
}

.add-estimate-scdo .add-line-bnt i {
    font-size: 16px;
}

.esti-data-view>label {
    margin-bottom: 10px;
}

.esti-hrs-hd .qunty-rate {
    padding-left: 4px;
}

.esti-hrs-hd b {
    font-size: 14px;
    color: #03a791;
    font-weight: 600;
    padding-left: 1px;
}

.esti-hrs-hd b:before {
    content: "|";
    font-size: 16px;
    color: rgba(45, 59, 72, 0.4);
    padding-right: 5px;
}

.esti-data-view {
    flex: 0 0 100%;
    width: 100%;
}

.view-esti-sec .esti-table table.table,
.view-sub-task .esti-table table.table {
    margin-bottom: 0;
}

.view-sub-task,
.view-esti-sec {
    padding-bottom: 10px;
}

.site-s-body.view-sub-task {
    border-top: 1px solid #fff;
    padding-top: 15px;
}

.esti-table .table td,
.esti-table .table th {
    padding: 8px;
    border-top: 1px solid #e5e8ea;
    font-size: 13px;
    font-weight: 500;
}

.esti-table .table td {
    font-weight: 300;
}

.esti-table .table .esti-thead th {
    font-weight: 600;
    font-size: 14px;
    border-right: 1px solid #e5e8ea;
}

.esti-table .table .esti-thead th:last-child,
.esti-table .table .est-sc-thd th:last-child,
.esti-table .table td:last-child {
    border-right: 0;
}

.esti-table .table .est-sc-thd th,
.esti-table .table td {
    border-right: 1px solid #e5e8ea;
}

.client-add-site-dtl .sf-card-head {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid #eaebec;
    margin-bottom: 10px;
    border-top: 2px solid #eaebec;
}

.img-abbri-str {
    background: #03a791;
    width: 50px;
    height: 50px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    border-radius: 50px;
    padding: 5px;
    letter-spacing: 1px;
    line-height: 40px;
    text-align: center;
}

.status-selects .sf-form:last-child .ant-select-selection__placeholder {
    color: #4a4a4a;
}


/* delete edit service industries */

.edit-si-box {
    position: relative;
}

.edit-si-box .doc-action-bnt {
    top: 0;
    right: 0;
}

.add-estimate-scdo.active-label label {
    display: block;
}


/* remove arrow in number textbox */

.add-estimate-scdo input::-webkit-outer-spin-button,
.add-estimate-scdo input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-estimate-scdo input[type="number"] {
    -moz-appearance: textfield;
}

.add-estimate-scdo {
    position: relative;
}

.add-estimate-scdo .col-md-3,
.add-estimate-scdo .col-md-6 {
    position: static;
}

.add-estimate-scdo .row span.error-input {
    position: absolute;
    left: 6px;
    width: 100%;
    margin: 0;
    bottom: 3px;
    background: #f7f7f8;
    padding: 0;
}

.esti-w-bg.add-estimate-scdo .row span.error-input {
    background: #fff;
}

.course-list-bnt i {
    font-size: 22px;
    padding-bottom: 5px;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.site-ser-table ul,
.site-ser-table ul li,
li {
    list-style: none;
}

.add-day-night-shift .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 25px;
}

button.delete-day-shift {
    position: absolute;
    right: 0;
    top: 12px;
    margin: 0;
    padding: 0;
    z-index: 99;
    width: 22px;
    height: 22px;
}

button.bnt-disabled-st {
    cursor: not-allowed;
    color: rgba(45, 59, 72, 0.4) !important;
}

.link-disabled a[disabled] {
    cursor: not-allowed;
    background: rgba(45, 59, 72, 0.1) !important;
    color: rgba(45, 59, 72, 0.29) !important;
    border: 0 !important;
}

.edit-eqip-img i {
    font-size: 20px;
    color: #03a791;
}

.edit-eqip-img .edit-img-title {
    padding-left: 10px;
}


/* small design of file upload */

.sm-upload-box {
    display: inline-block;
    width: 100%;
}

.sm-upload-box div.ant-upload {
    background: #fff;
    border: 1px solid rgba(45, 59, 72, 0.08);
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.5s all;
    margin-bottom: 10px;
}

.sm-upload-box div.ant-upload:hover {
    border-color: #03a791;
}

.sm-upload-box .ant-upload span.ant-upload {
    padding: 8px 0;
    display: inline-block;
    height: 100%;
    width: 100%;
}

.sm-upload-box .ant-upload .ant-upload-drag-container {
    display: inline-block;
}

.sm-upload-box .ant-upload p.ant-upload-drag-icon {
    margin-bottom: 5px;
    text-align: center;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sm-upload-box .ant-upload p.ant-upload-drag-icon i,
.sm-upload-box .ant-upload p.ant-upload-drag-icon .anticon {
    font-size: 21px;
    color: #20b39a;
}

.sm-upload-box .ant-upload p.ant-upload-text {
    margin: 0;
    color: #03a791;
    font-size: 10px;
    text-align: center;
    padding: 0 10px;
    line-height: normal;
}


/* page scrollbar design */

.sf-ps-scroll-content {
    min-width: 600px;
    padding-bottom: 20px;
}

.sf-ps-scroll-content.non-scroll {
    min-width: 100px;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
    height: 9px;
    border-radius: 0;
}

.sf-ps-scroll .ps__rail-x .ps__thumb-x,
.sf-ps-scroll .ps__rail-y .ps__thumb-y {
    border-radius: 20px;
    height: 6px !important;
    bottom: 1px;
}


/* data time & duration */

.date-time-durtion .ant-collapse-borderless .dt-dur-style {
    border: 0;
    background: rgba(45, 59, 72, 0.04);
    border-radius: 2px;
}

.date-time-durtion .dt-dur-style .ant-collapse-header {
    font-weight: 600;
    color: #222222;
    padding: 10px;
    padding-right: 40px;
    font-size: 13px;
    line-height: normal;
}

.date-time-durtion .sf-form label {
    font-size: 13px;
}

.date-time-durtion .dt-dur-style .ant-collapse-content-box {
    padding: 10px;
}

.date-time-durtion {
    margin-top: 10px;
}

.dt-dur-style .ant-time-picker {
    width: 100%;
}

.dt-dur-style .ant-time-picker input[type="text"] {
    min-width: 100%;
    padding-right: 28px;
}

.dt-dur-style .col-md-6 {
    padding-left: 0;
}

.dt-dur-style .ant-time-picker-icon .ant-time-picker-clock-icon {
    color: #03a791;
    font-size: 13px;
}

.dt-dur-style .ant-time-picker-icon {
    right: 10px;
}


/* calendar style 12 Dec 2019 */

span.jc-job-no {
    font-size: 13px;
    color: #03a791;
    margin-bottom: 2px;
}

.jc-assign {
    color: #444c63;
    font-weight: 400;
}

.sf-jc-check-box.sf-chkbx-group .ant-checkbox-wrapper+span,
.sf-jc-check-box.sf-chkbx-group .ant-checkbox+span {
    color: #444444;
    font-weight: 500;
    font-size: 14px;
}

.sf-jc-check-box.sf-chkbx-group .ant-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.25);
}

.sf-jc-main .split-jc-chkbx .jc-value-dtls {
    margin-top: 2px;
    padding-left: 24px;
    width: 100%;
    padding-right: 10px;
}

.sf-jc-main .jc-value-dtls {
    width: 100%;
    margin-top: 2px;
    padding-right: 10px;
}

.jc-esti-details {
    background: rgba(45, 59, 72, 0.04);
    margin-top: 8px;
    padding: 8px;
    border-radius: 4px;
}

.jc-est-items label,
.jc-est-items span,
.jc-est-items span b {
    font-size: 13px;
    font-weight: 500;
    color: rgba(45, 59, 72, 0.9);
}

.jc-est-items span.jc-qty-vlu,
.jc-est-items span b {
    font-weight: 600;
    color: #03a791;
}

.jc-est-items span.jc-qty-vlu {
    padding-left: 5px;
}

.jc-est-items span {
    padding-left: 10px;
}

.jc-est-items span b {
    display: inline-block;
    width: 100%;
}

.jc-est-items,
.jc-hrs-list {
    display: flex;
    flex-wrap: wrap;
}

.jc-hrs-list {
    width: 100%;
    flex: 0 0 100%;
    margin-top: 2px;
    justify-content: space-between;
}

.jc-hrs-list span:first-child {
    padding-left: 0;
}

.jc-area-view span {
    display: flex;
    padding-left: 5px;
}

.jc-area-view span b {
    padding: 0 3px 0 0;
}

label.est-hrs-hd {
    font-weight: 600;
}


/* split job */

.sf-jc-main .split-jc-chkbx .spil-jc-popover {
    text-align: right;
    min-width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sf-jc-main .split-jc-chkbx .sf-chkbx-group label.ant-checkbox-wrapper {
    margin-bottom: 0;
}

.sf-jc-main .split-jc-chkbx .spil-jc-popover .add-sa-bnt {
    border: 0;
}

.spil-jc-popover button.jc-outsource {
    white-space: pre-wrap;
    text-align: right;
    font-size: 11px;
}

.sf-jc-main .split-job-plp .split-jc-chkbx .sf-chkbx-group {
    display: flex;
    flex-wrap: nowrap;
    flex: auto;
    padding: 0;
    align-items: center;
}

.sf-jc-main .split-jc-chkbx {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-start;
    position: relative;
}

.sf-jc-main .split-job-plp {
    margin-top: 15px;
    border-bottom: 1px solid rgba(45, 59, 72, 0.06);
    padding-bottom: 15px;
}

.sf-jc-main .job-c-details #sfPopOver .ant-popover-inner {
    padding: 0 0;
}

.sf-jc-main .job-c-details .split-jc-chkbx .ant-checkbox-wrapper+span {
    font-weight: 500;
}

.sf-jc-main .job-c-details .split-jc-chkbx .ant-checkbox-wrapper-disabled+span {
    color: rgba(0, 0, 0, 0.25);
}

.add-jc-esti-dtl .split-j-item {
    padding-left: 22px;
    width: 100%;
    position: static;
    margin-top: 10px;
}

.add-jc-esti-dtl {
    position: relative;
}

.add-jc-esti-dtl button.split-dlt-bnt {
    position: absolute;
    left: -38px;
    top: -6px;
}

.add-sa-popover .add-sa-bnt {
    border: 0;
}

#sfPopOver.add-sa-popover {
    position: static;
}

.add-sa-popover {
    min-width: 80px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.add-sa-popover button.split-dlt-bnt {
    top: -6px;
}

button.split-dlt-bnt i {
    font-size: 14px;
}

.add-sa-popover .jc-outsource {
    text-align: right;
    white-space: pre-line;
    font-size: 11px;
}

.add-sa-popover>label {
    font-size: 13px;
    color: rgba(45, 59, 72, 0.8);
    font-weight: 500;
}

.split-sub-dtl button.ant-btn {
    border: 0;
    padding: 0;
}

.split-sub-dtl button.jc-outsource {
    padding-top: 4px;
}

.label-block label {
    display: block !important;
}

label.bold-label {
    font-weight: 600;
}

/* .rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    max-width: 188px;
} */

.view-dt-durtion .dure-lable>label {
    font-weight: 600;
    color: #222222;
    padding: 10px 0 0;
    font-size: 13px;
    line-height: normal;
}

.view-dt-durtion .dure-lable>span {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    font-size: 14px;
}

.po-doc-w-save-bnt {
    display: flex;
}

.save-po-no-file {
    padding-top: 36px;
}

.save-po-no-file button.normal-bnt i {
    font-size: 22px;
}

.po-doc-w-save-bnt .ant-upload-list-item-card-actions {
    top: 0;
}

/* .rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    max-width: 200px;
} */


/* update task images */

.files-lits-sc {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
}

.files-lits-sc .sc-upld-files {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 10px;
    margin-bottom: 10px;
}

.view-text-value .files-lits-sc .sc-upld-file:hover {
    background: transparent;
}

.files-lits-sc .sc-upld-files img {
    border-radius: 4px;
    height: 55px;
    width: 100%;
}

.files-lits-sc .sc-upld-files .normal-bnt {
    top: 5px;
    right: 8px;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #03a791;
}

.files-lits-sc .sc-upld-files .normal-bnt:hover {
    background: #03a791;
}


/* job doc popup */

.job-img-gallery .ant-modal-body {
    padding: 0;
}

.job-img-gallery .ant-modal-close {
    top: -12px;
    right: -6px;
}

.job-img-gallery .ant-modal-close-x {
    background: #fff;
    border: 1px solid rgba(45, 59, 72, 0.1);
    width: 28px;
    height: 28px;
    border-radius: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}


/* user permission collapse list */

.ant-collapse.user-permis-list,
.ant-collapse.user-permis-list div {
    border: 0;
    background: transparent;
}

.ant-collapse.user-permis-list .ant-collapse-item {
    margin: 0 0 10px;
}

.ant-collapse.user-permis-list .ant-collapse-header {
    padding: 0 40px 10px 0;
    border-bottom: 1px solid rgba(45, 59, 72, 0.08);
    color: #222;
    font-weight: 500;
}

.ant-collapse.user-permis-list .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
    color: #222;
}

.user-permis-list.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    color: rgba(45, 59, 72, 0.45);
}

.user-permis-list.ant-collapse .ant-collapse-content-box {
    padding: 15px 0 0 0;
    border-bottom: 1px solid rgba(45, 59, 72, 0.08);
}

.user-permis-list .ant-collapse-content-box .scrollarea.sf-scroll {
    margin-bottom: 0;
}


/* forgot user name */

.forgot-co-code {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.forgot-co-code .fldset-box {
    margin: 0;
    flex: auto;
}

.forgot-co-code fieldset.sf-form.co-code-txt {
    min-width: 40px;
    max-width: 100px;
}

.forgot-co-code .userTxtbox input {
    padding: 0 10px;
    border-left: 0;
    border-radius: 0 4px 4px 0;
}

.forgot-co-code .sf-form.co-code-txt .ant-select-selection {
    height: 45px;
    justify-content: space-between;
}

.forgot-co-code .co-code-txt .ant-select-selection__rendered {
    height: 45px;
}

.forgot-co-code .sf-form.co-code-txt .select-wibg .ant-select-arrow .ant-select-arrow-icon {
    font-size: 16px;
    height: 15px;
    width: 15px;
}

.forgot-co-code .sf-form.co-code-txt .select-wibg .ant-select-arrow {
    padding: 0 10px;
}

.forgot-co-code .sf-form.co-code-txt .ant-select-selection-selected-value {
    font-size: 18px;
    font-weight: 400;
    min-width: 100%;
    max-width: 100%;
}

.co-code-txt .ant-select-search--inline .ant-select-search__field {
    color: #fff;
}

.calender-heading .ant-select-selection--single .ant-select-selection__rendered,
.calender-heading li.ant-select-dropdown-menu-item {
    text-transform: capitalize;
}

.ant-list.sf-service-list .ant-avatar>img,
.usr-profile .ant-avatar-image img,
.jdp-head img {
    object-fit: cover;
}

.card-exp-drop .ant-select-selection__placeholder,
.card-exp-drop .ant-select-search__field__placeholder {
    right: 0px;
}


/* list search spiner */

.list-search-spin .ant-input-suffix {
    height: 38px;
    width: 38px;
}

.list-search-spin .ant-input-suffix .ant-spin-dot {
    top: -3px;
    left: -3px;
}


/* search list spin */

.sf-search-body {
    position: relative;
}

.search-list-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.68);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sf-search-body.add-height .search-lists-bar .scrollarea.sf-scroll {
    max-height: 666px;
}


/* staff dropdown scratch */

span.red-scratch {
    text-decoration-line: line-through;
    color: #d0021b;
}

span.default-scratch {
    text-decoration-line: line-through;
}

.alsf-scratch-label {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: -15px;
}

.alsf-spn-list {
    padding: 0 0 5px 15px;
}


/* view History Scope doc */

.his-li-table table {
    margin-bottom: 0;
}

.ad-srch-btn {
    color: #8e8e8e;
    border: 0;
    margin-right: 5px;
    box-shadow: none;
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 0px 20px 0 10px;
    height: 32px;
    line-height: normal;
    border-radius: 4px;
    min-width: 70px;
}

.his-li-table table tr th,
.his-li-table table tr td {
    padding: 0.3rem;
    font-size: 12.2px;
}

.his-li-table table tr th {
    font-weight: 600;
}

.his-li-table table tr td .normal-bnt {
    font-size: 12.2px;
}

.his-li-table.show-frquency-box>.ant-collapse-item>.ant-collapse-content {
    margin-top: 5px;
}


/* scope doc new style */

.equip-miscell-dtl {
    padding-top: 10px;
}

.miscell-equip-dtl {
    padding-bottom: 15px;
}

.eq-mill-edit .equipmnt-table,
.eqip-input-pnl .equipmnt-table {
    margin-bottom: 0;
}

.calculate-dtl .sf-form label {
    font-weight: normal;
    margin-bottom: 5px;
}

.eqip-sd-edit label {
    margin-bottom: 10px;
    /* calendar view notes style */
}

.v-calndr-note .view-text-value label {
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

.eqip-input-pnl .equipmnt-table.equip-cast-tble tr td {
    padding-left: 0;
}

.eqip-input-pnl .equipmnt-table.equip-cast-tble tr td:last-child {
    padding-right: 0;
}

.add-task-eqmicell .esti-table .table td {
    border-right: 0;
}

.add-task-eqmicell .equip-cast-tble {
    margin-bottom: 0;
}

.add-task-eqmicell {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    margin-bottom: 10px;
}

.adal-task-tags.sf-form .ant-select-selection {
    min-height: 34px;
    height: auto;
    max-height: 100px;
    overflow-x: hidden;
    padding-bottom: 0;
    margin-bottom: 0;
}

.adal-task-tags .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-bottom: 0;
}


/* temp profile pic */

.upload-pic.temp-upload-pic {
    height: 100%;
    width: 100%;
    bottom: auto;
    top: 0;
    left: 0;
    border-radius: 100%;
}

.temp-pic-view {
    width: 72px;
    height: 72px;
    overflow: hidden;
    display: inline-block;
}

.upload-pic.temp-upload-pic button.ant-btn {
    position: absolute;
    bottom: -9px;
    right: 2px;
    border: 1px solid #ddd;
}


/* add currency symbol */

.w-currency-symbl>div>div {
    position: relative;
}

.w-currency-symbl>div>div:after {
    content: "$";
    position: absolute;
    left: 10px;
    top: 7px;
    color: #444444;
    text-align: center;
    font-weight: 400;
}

.w-currency-symbl.sf-form input {
    padding-left: 22px;
}

.calendar-drop .cust-dpicker .ant-calendar-picker-clear,
.calendar-drop .cust-dpicker .ccalnd-icon {
    display: none;
}

.btn-hs-icon.resend-bnt {
    padding: 4px 4px 0;
}

.licence-img.show-pdf-file-view i {
    font-size: 60px;
    color: #7d8f8c;
}


/* scope doc search list style */

.ant-list-item-meta-avatar {
    margin-right: 16px;
}


/* new style for role management */

.role-sub-access {
    margin-left: 10px;
    border-top: 1px solid #efefef;
}

fieldset.note-drop .sf-select .ant-select .ant-select-selection {
    max-width: 200px;
    height: 30px;
    min-width: 120px;
    border: 1px solid #ddd;
    margin-bottom: 0;
    border-radius: 4px;
    margin-left: 0;
}

fieldset.note-drop .sf-select .ant-select .ant-select-selection--multiple .ant-select-selection__rendered {
    height: 30px;
    margin-bottom: 0;
}

fieldset.note-drop .ant-select ul {
    display: flex;
}

fieldset.note-drop .src-cls-bnt.material-icons {
    right: 2px;
}

.ant-select-dropdown--empty {
    display: none;
}

fieldset.ad-srch-content.note-drop {
    padding: 0 15px 0 10px;
}

.calendar-drop {
    max-width: 185px;
    padding-right: 10px;
    margin-right: 10px;
}

.calendar-drop .cust-dpicker>label {
    display: none;
}

.role-sub-access .sf-chkbx-group {
    margin-left: 10px;
}

.role-sub-access h5 {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.sf-form.role-jb-ccld-tbl .ant-select-selection {
    min-height: 35px;
    height: auto;
}

.scop-inn-bg {
    border: 1px solid rgba(45, 59, 72, 0.04);
}

.scop-inn-bg .data-v-col {
    margin-bottom: 0;
}


/* --- */

.cust-expand-txt-area textarea {
    resize: auto;
}


/* scope doc collapse  */

.sc-doc-collapse-pnl .sc-doc-co-head .ant-collapse-header {
    padding: 0;
    border: 0;
}

.sc-doc-collapse-pnl .ant-collapse-item.sc-doc-co-head {
    border: 0;
}

.sc-doc-collapse-pnl .sc-doc-co-head .ant-collapse-content-box {
    padding: 0;
}

.sc-doc-collapse-pnl .sc-doc-co-head>.ant-collapse-header>i.ant-collapse-arrow {
    left: 13px;
    color: rgba(45, 59, 72, 0.45);
}

.sc-doc-co-head .sf-pg-heading.hd-w-clps {
    padding-left: 15px;
}

.ant-collapse.sc-doc-collapse-pnl {
    position: relative;
    background: transparent;
}

.ant-collapse.sc-doc-collapse-pnl>.info-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 99;
}

.sc-site-name-pnl .sc-doc-co-head>.ant-collapse-header>i.ant-collapse-arrow {
    right: 0;
    left: auto;
    top: 12px;
    color: rgba(45, 59, 72, 0.45);
}

.sc-task-name-pnl .sc-doc-co-head>.ant-collapse-header>i.ant-collapse-arrow {
    right: 15px;
    top: 26px;
    left: auto;
    color: rgba(45, 59, 72, 0.45);
}

.sc-site-name-pnl .sc-doc-co-head .ant-collapse-header .data-v-row .data-v-col {
    margin-bottom: 0;
}

.sc-site-name-pnl .sc-doc-co-head.ant-collapse-item-active .ant-collapse-header .data-v-row .data-v-col {
    margin-bottom: 15px;
}

.sc-task-wrap .doc-action-bnt {
    right: 40px;
    top: 20px;
}

.sc-doc-co-head .ant-collapse-content-box .estm-lists .show-frquency-box .ant-collapse-header {
    padding-left: 15px;
}

.view-text-value.d-bn-txt-bx {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #ddd;
}

.sc-task-name-pnl .sc-doc-co-head .sm-tsk-view {
    display: flex;
}

.sc-task-name-pnl .ant-collapse-item-active.sc-doc-co-head .sm-tsk-view {
    display: none;
}

.sc-task-name-pnl .ant-collapse-item.sc-doc-co-head .ant-collapse-header .site-s-head {
    margin-bottom: 0;
}

.sc-task-name-pnl .ant-collapse-content.ant-collapse-content {
    padding-top: 15px;
}

.esti-data-view .equipmnt-table.equip-cast-tble tr td {
    padding: 10px 2px;
}

.cat-table-view fieldset.form-group.sf-form {
    flex: 0 0 100px;
}

.sm-tsk-view {
    display: flex;
    border-top: 1px solid rgba(45, 59, 72, 0.1);
    padding-top: 10px;
    margin-top: 10px;
    justify-content: space-around;
    /* margin-right: 5rem; */
}

.sm-tsk-view .view-text-value.d-bn-txt-bx:first-child {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
}

.sm-tsk-view .view-text-value label {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.65);
}

.sm-tsk-view .view-text-value span {
    font-size: 13px;
    font-weight: 300;
}

.update-bnt-w-popup .btn-hs-icon .update-spn button {
    background: transparent;
    font-size: 15px;
    font-weight: bold;
    height: 30px;
    padding: 0;
    width: 100%;
    line-height: 30px;
}

.update-bnt-w-popup .btn-hs-icon .bnt,
.update-bnt-w-popup .btn-hs-icon .update-spn {
    width: 50%;
    min-width: auto;
}

.doc-sr-img .sl-no-img {
    position: absolute;
    right: 2px;
    bottom: 2px;
    background: rgba(33, 37, 41, 0.30196078431372547);
    color: rgba(255, 255, 255, 0.8);
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 2px;
}


/* position fixed of the right panel with scroller */

.scdo-sidebar.fixed,
.scroll-f-scopdoc .scdo-sidebar {
    /* position: fixed;
  top: 10px; */
}

.scdo-sidebar .scrollarea.sf-scroll {
    max-height: none;
    margin-bottom: 0;
}

.scdo-sidebar.fixed .scrollarea.sf-scroll {
    margin-bottom: 15px;
}


/* Additional Task Tags */

span.att-tags {
    display: flex;
    flex-wrap: wrap;
}

span.att-tags span {
    border: 1px solid #e8e8e8;
    background: #fafafa;
    padding: 2px 8px;
    margin-right: 4px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    line-height: normal;
    font-weight: 400;
    margin-top: 4px;
}

span.att-tags span:last-child {
    margin-right: 0;
}

.single-icon i {
    color: #03a791;
    font-size: 15px;
}

.auto-height-txt.sf-form .ant-select-selection {
    min-height: 34px;
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}


/* Advance Search box */

#stickAddMore {
    position: relative;
}

.sf-card-wrap.advance-search-wrap {
    box-shadow: 0 1px 4px 0px rgba(45, 59, 72, 0.04);
}

.ant-popover.ad-srh-popup {
    min-width: 250px;
    max-width: 300px;
}

.lhs-ad-src {
    flex-wrap: wrap;
    align-items: center;
}

.rhs-ad-src {
    align-items: center;
    padding-left: 10px;
}

.rhs-ad-src button.bnt.bnt-active {
    height: 32px;
    line-height: 30px;
    padding: 0 12px;
}

.ad-srch-btn>i {
    padding-left: 2px;
    font-size: 18px;
    color: #8e8e8e;
}

.ad-sr-more-bnt.ad-srch-btn>i {
    padding: 0 2px 0 0;
}

.ad-srch-btn.ant-popover-open,
.ad-srch-btn.ant-popover-open>span {
    background: #03a791;
    color: #fff;
}

.ad-srch-btn.ant-popover-open>i {
    color: #fff;
}

.ad-srh-popup .ant-popover-arrow {
    display: none;
}

.ad-srh-popup .ant-popover-inner-content {
    padding: 0;
}

.ad-srch-box input.form-container {
    border: 0;
    border-radius: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgba(45, 59, 72, 0.08);
    height: 40px;
    font-size: 14px;
}

.ad-srch-box input.form-container:focus {
    outline: none;
    box-shadow: none;
}

.ad-srch-box.sf-form {
    position: relative;
}

.ad-srch-box.sf-form:after {
    content: "search";
    font-family: "Material Icons";
    color: #ddd;
    position: absolute;
    right: 15px;
    top: 7px;
    font-size: 18px;
}

.advance-search-popup .ant-checkbox-group {
    padding: 10px;
    max-height: 300px;
    overflow-x: hidden;
}

.advance-search-popup .ant-checkbox-group label {
    width: 100%;
    padding: 5px 0;
}

.advance-search-popup .ant-checkbox-group label:hover {
    background: rgba(45, 59, 72, 0.06);
}

fieldset.note-drop .sf-select {
    position: relative;
    padding-right: 0;
    display: flex;
    align-items: center;
}

fieldset.note-drop .sf-select:after {
    content: "arrow_drop_down";
    font-family: "Material Icons";
    font-size: 18px;
    color: #8e8e8e;
    position: absolute;
    right: 7px;
    top: 7px;
}

fieldset.note-drop .sf-select>label {
    color: #8e8e8e;
    font-size: 14px;
}

fieldset.note-drop .sf-select .ant-select {
    margin: 0 5px;
}

fieldset.note-drop .sf-select .ant-select .ant-select-selection--multiple .ant-select-selection__rendered {
    max-width: 200px;
    overflow: hidden;
    height: 30px;
    margin-bottom: 0;
}

fieldset.note-drop .ant-select ul {
    display: flex;
}

.ant-select-dropdown--empty {
    display: none;
}

.calendar-drop {
    max-width: 185px;
    padding-right: 10px;
    margin-right: 10px;
}

.calendar-drop .cust-dpicker>label {
    display: none;
}

.calendar-drop .ant-calendar-picker-input.ant-input {
    border: 0;
    padding: 4px 0;
}

.calendar-drop .ccalnd-icon {
    right: -8px;
}

.calendar-drop .ant-calendar-range-picker-input::placeholder {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}

.search-type-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
}

.search-type-btn .normal-bnt {
    font-size: 13px;
    font-weight: 400;
}

.closed .search-type-btn {
    display: none;
}

.search-key-list.sf-service-list.ant-list-split .ant-list-item a {
    min-height: 62px;
    height: auto;
}

.search-keys {
    width: 100%;
    padding: 4px 0 0;
    display: inline-block;
}

.search-keys strong {
    font-weight: 600;
    padding-left: 5px;
}

.advance-search-popup .ant-checkbox-wrapper-disabled {
    display: none;
}

.allsf-tt-dtls .d-flex.ttbale-row {
    margin-bottom: 10px;
}

.doc-vlue .view-text-value span {
    font-size: 13px;
    display: block;
    font-weight: 500;
    color: rgba(45, 59, 72, 0.8);
    word-break: normal;
    padding-left: 0;
    line-height: 1.4em;
}

.v-note-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    margin: 0;
}

.v-n-username {
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    color: rgba(45, 59, 72, 0.9);
}

span.v-n-dates {
    font-size: 12px;
    color: rgba(45, 59, 72, 0.5);
    font-weight: 500;
    padding: 5px 0 0 0;
    font-style: italic;
}

.add-notes-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.add-notes-row .form-group.sf-form {
    margin: 0;
    width: calc(100% - 30px);
}

.add-notes-row button.normal-bnt {
    width: 20px;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}


/* staff dropdown scratch */

span.red-scratch {
    text-decoration-line: line-through;
    color: #d0021b;
}

span.green-scratch {
    text-decoration-line: none;
    color: #417505;
}

span.default-scratch {
    text-decoration-line: line-through;
}

.alsf-scratch-label {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: -15px;
}

.alsf-spn-list {
    padding: 0 0 5px 15px;
}


/* toolbox talk modal */

.toolbox-container {
    padding: 20px 40px 40px;
}

.tbtf-logo {
    width: 250px;
}

.tbtf-logo img {
    max-width: 100%;
}

.tbt-heading {
    font-size: 2.3rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

table.tbtf-table-sm {
    background: #f1f1f1;
    margin: 0;
}

table.tbtf-table-sm tr td {
    padding: 0 10px;
    vertical-align: middle;
}

table.tbtf-table-sm tr:first-child td:first-child {
    border-right: 1px solid #ddd;
}

span.tbt-date-dtl {
    font-weight: 600;
    font-size: 15px;
}

table.tbtf-table-sm tr:first-child td:last-child {
    text-align: right;
}

table.tbtf-table-sm tr:last-child td {
    border-top: 1px solid #ddd;
    text-align: right;
}

table.tbt-dtl-table {
    width: 100%;
    margin: 1rem 0;
}

.tbt-dtl-table tr th,
.tbt-dtl-table tr td {
    padding: 8px 10px;
    border: 1px solid rgba(45, 59, 72, 0.29);
    vertical-align: top;
}

.tbt-dtl-table tr th {
    background: #e6e6e6;
    font-weight: 600;
    font-size: 16px;
    color: #222;
}

.tbt-input-text {
    display: inline-block;
    padding-bottom: 5px;
}

.tbt-input-text {
    width: 100%;
}

.tbt-input-text input {
    border: 0;
    border-bottom: 1px solid #939393;
    width: 100%;
    outline-style: none;
}

.tbt-nor-list {
    display: flex;
    flex-wrap: wrap;
    padding: 2px 0;
}

.tbt-nor-list span {
    padding-right: 5px;
}

ul.tbt-lists {
    list-style: disc;
    padding-left: 15px;
}

ul.tbt-lists li {
    list-style: disc;
}

.tbt-nor-list strong {
    font-weight: 600;
}

.tbtf-body {
    margin: 0 0 2rem;
}

.t-comt-foter td:nth-child(1),
.t-comt-foter td:nth-child(3) {
    width: 110px;
    border-right: 0;
    font-weight: 600;
    color: #222222;
}

.t-comt-foter td:nth-child(2),
.t-comt-foter td:nth-child(4) {
    border-left: 0;
}

pre.info {
    text-align: center;
    color: red;
    font-weight: 400;
    padding: 10px 0;
}

.info-l-tbl {
    background: #f1f1f1;
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
}

.handout-box {
    border: 1px solid #ddd;
    margin-top: 1rem;
    padding: 10px;
}

.handout-box strong {
    color: #222;
    font-weight: bold;
    padding-right: 10px;
}

.ttg-notes-list ul {
    list-style: disc;
    padding-left: 15px;
}

.ttg-notes-list ul li {
    list-style: disc;
    padding-top: 5px;
}

.tbt-foter-table table {
    width: 100%;
    background: #f1f1f1;
}

.tbt-foter-table table tr td {
    border: 1px solid #ddd;
    padding: 4px;
    font-weight: 500;
}

.tbt-foter-table table tr:first-child td:nth-child(3) {
    vertical-align: middle;
    font-size: 16px;
}

.ttlk-checklists button.normal-bnt {
    margin-bottom: 10px;
}

.swms-mgnt-nav {
    display: inline-block;
    width: 100%;
}

.sm-nav-items {
    border-bottom: 1px solid rgba(45, 59, 72, 0.06);
    border-top: 1px solid transparent;
    padding: 16px 15px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: 0.5s all;
    color: #8e8e8e;
    font-size: 15px;
    font-weight: 500;
}

.sm-nav-items i {
    padding: 0 10px 0 0;
    font-size: 16px;
}


/* add swms  */

.sf-c-table.swms-view-table .td,
.sf-c-table.swms-view-table .th {
    word-break: unset;
}

.scrollarea.swms-table-scroll {
    padding-bottom: 5px;
    margin-bottom: 10px;
}


/* Drawer */

.antt-form .btn-hs-icon {
    padding-left: 10px;
}

.antt-form .btn-hs-icon .bnt {
    width: 100%;
    height: 32px;
    font-size: 15px;
    font-weight: bold;
    min-width: 100px;
}

.toolbox-talk-drawer .ant-drawer-header-no-title {
    display: none;
}

.toolbox-talk-drawer .ant-drawer-body {
    padding: 15px;
}

.tbox-table .tr .td:last-child {
    width: 20px;
}

.tbox-table .tr .td .delete-bnt {
    margin: 0;
}

.tbox-table .sf-form textarea {
    height: 40px;
}

.tbox-table.sf-c-table .td,
.tbox-table.sf-c-table .th {
    border: 0;
}

.tbox-table.sf-c-table .td {
    padding-top: 2px;
    padding-bottom: 2px;
    background: transparent !important;
}

.tbox-table .tr .th:nth-child(3) {
    width: 40%;
}

.update-signle-tool-type .ant-select-selection {
    max-height: 100%;
    height: 100%;
    max-width: 400px;
}

.ackn-tbl-heading {
    font-size: 18px;
    font-weight: 500;
}

img.attach-jb-img {
    width: 100px;
    border-radius: 4px;
}


/* upload chemicals */

.chemicals-afbf-pic .loadf-txt {
    border: 1px solid rgba(45, 59, 72, 0.06);
    border-radius: 4px;
    padding: 5px;
    display: flex;
    align-items: center;
    color: #03a791;
    line-height: normal;
}

.chemicals-afbf-pic .loadf-txt .material-icons {
    font-size: 14px;
}

.chemicals-afbf-pic .loadf-txt:hover {
    border: 1px solid #03a791;
}


/* auto complete search */

.client-name {
    position: relative;
    width: 100%;
}

.tbox-table .ant-select-auto-complete.ant-select .ant-input {
    padding-right: 35px;
}

.tbox-table .select-wibg .ant-select-arrow {
    line-height: 14px;
}

.srch-icons {
    position: absolute;
    right: 36px;
    top: 9px;
    font-size: 12px;
    color: rgba(169, 165, 170, 0.45);
}

.tool-talk-attach .logo-upload .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 11px;
    font-weight: 400;
}

.add-line-bnt {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    display: flex;
    align-items: center;
}

.add-line-bnt i {
    font-size: 16px;
    padding-right: 2px;
}

.swms-table-scroll {
    max-width: 1000px;
    padding-bottom: 20px;
}

fieldset.ad-srch-content {
    position: relative;
}

.src-cls-bnt.material-icons {
    position: absolute;
    right: 2px;
    right: 8px;
    top: 9px;
    border-radius: 100px;
    background: #e7eaed;
    color: rgba(45, 59, 72, 0.6);
    width: 15px;
    height: 15px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    font-size: 11px;
    z-index: 99;
}

.src-cls-bnt.material-icons:hover {
    background: #cbd2d9;
}

.calendar-drop .src-cls-bnt.material-icons {
    right: -4px;
}

.ad-srch-btn>span {
    color: #8e8e8e;
    max-width: 350px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.sub-swms-act {
    padding: 4px 0 2px 15px;
}

strong.swms-info-st {
    margin-left: 24px;
}

strong.category-head {
    font-weight: 600;
    font-size: 14px;
}

.chemicals-afbf-pic .ant-upload-list-item-name {
    max-width: 150px;
}

.sc-doc-bnt .btn-hs-icon {
    padding-left: 10px;
    display: flex;
}

.swms-act-popup {
    width: 80%;
}

.swms-act-popup .org-user-table .tr .td:last-child {
    text-align: left;
}

.ant-popover-title {
    font-size: 19px;
    font-weight: 600;
    padding: 10px 16px 10px;
}

.ttg-sign-img {
    height: 40px;
    margin-top: 10px;
}

.tbtf-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.tbtf-list .normal-bnt {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgba(45, 59, 72, 0.12);
    color: #03a791;
    margin-bottom: 5px;
}

.tbtf-list .normal-bnt:hover {
    color: #008572;
}

.tbtf-list .normal-bnt:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
}

.tbtf-list .normal-bnt.active {
    color: #03a791;
    text-decoration: underline;
}

.tbtf-mn-heading {
    padding-bottom: 0;
    margin: 0;
    font-size: 24px;
}


/* equipment */

.download-view {
    background: #fff;
    color: #ddd;
    border: 1px solid rgba(45, 59, 72, 0.24);
    border-radius: 20px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    transition: 0.5s all ease-in-out;
    margin-top: 0;
}

.download-view i {
    font-size: 14px;
    color: rgba(45, 59, 72, 0.6);
}

.download-view:hover i {
    color: #03a791;
}

.download-view:hover {
    border-color: #03a791;
}


/* profile view  */

.profile-view-wrap {
    top: 0;
    padding: 0;
}

.profile-view-wrap .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
}

.profile-view-wrap .sf-card-wrap {
    box-shadow: none;
    padding: 0;
}

.p-exp-table tr th {
    font-weight: 500;
    color: #222;
    padding: 6px;
    vertical-align: top;
}

.p-exp-table tr td {
    border-top: 1px solid rgba(45, 59, 72, 0.15);
    padding: 6px;
    vertical-align: top;
}

.p-v-pic-dtl .profile-pic {
    margin-right: 40px;
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
    border-color: rgba(45, 59, 72, 0.15);
}

.lic-card-pic {
    width: 100px;
}

.p-v-pic-dtl .data-v-row {
    width: 100%;
}

.ama-panel h3.job-c-my {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
    margin-bottom: 15px;
    padding-bottom: 8px;
}

.ama-panel {
    margin-top: 10px;
}

.ama-panel .data-v-row {
    margin-left: -10px;
    margin-right: -10px;
}

.ama-panel .data-v-row .data-v-col .view-text-value {
    padding: 0 10px;
}

.ama-panel .view-text-value label {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #222;
}

.ant-collapse-borderless>.ant-collapse-item.ama-colps-head {
    border: 0;
}

.ama-collapse .ama-colps-head .ant-collapse-header {
    padding-bottom: 0;
    position: relative;
}

.ama-collapse .ama-colps-head .ant-collapse-header .ant-collapse-arrow {
    top: 16px;
}

.ama-panel-form {
    margin-top: 15px;
    display: inline-block;
    width: 100%;
}

.ama-collapse.ant-collapse-borderless {
    background: rgba(45, 59, 72, 0.04);
}

.ama-panel-form .sf-form textarea {
    height: 55px;
}

.doc-vlue.doc-vlue-sm {
    margin-top: 10px;
}

.doc-vlue.doc-vlue-sm span {
    font-size: 13px;
}

.ama-panel .ama-panel-form .view-text-value>label {
    margin-bottom: 10px;
    font-weight: 600;
}

.avl-label-rost label {
    display: inline-block;
    width: 100%;
}

.p-v-signature img {
    width: 200px;
}


/* swms editor box */

.dynamic-html-cnt strong {
    font-weight: 600;
    display: inline-block;
}

.dynamic-html-cnt ul {
    list-style: disc;
    padding-left: 15px;
}

.dynamic-html-cnt ul li {
    list-style: disc;
}

.dynamic-html-cnt em {
    font-style: italic;
}

.dynamic-html-cnt ol {
    padding-left: 15px;
    list-style: decimal;
}

.dynamic-html-add .rdw-editor-main {
    border: 1px solid #f1f1f1;
    border-top: 0;
    padding: 0 10px;
    border-radius: 0 0 2px 2px;
    background: #fff;
}

.dynamic-html-add .rdw-editor-toolbar {
    background: #fcfcfc;
    margin-bottom: 0;
    border-radius: 2px 2px 0 0;
}

.dynamic-html-add .rdw-editor-main pre {
    margin-bottom: 10px;
}

.dynamic-html-cnt blockquote {
    quotes: "'""'";
}

.dynamic-html-cnt p {
    padding-bottom: 5px;
}

.dynamic-html-cnt h1 {
    font-size: 2rem;
    font-weight: 600;
}

.dynamic-html-cnt h2 {
    font-size: 1.75rem;
    font-weight: 600;
}

.dynamic-html-cnt h3 {
    font-size: 1.5rem;
    font-weight: 600;
}

.dynamic-html-cnt h4 {
    font-size: 1.25rem;
    font-weight: 600;
}

.dynamic-html-cnt h5 {
    font-size: 1rem;
    font-weight: 600;
}

.dynamic-html-cnt h6 {
    font-size: 0.75rem;
    font-weight: 600;
}


/* ------- end ------------- */

.clnd-notes-dtl .row .col-md-6:nth-child(1) {
    padding-right: 5px;
}

.clnd-notes-dtl .row .col-md-6:nth-child(2) {
    padding-left: 5px;
}

.clnd-notes-dtl .row .col-md-6:nth-child(3) {
    padding-right: 5px;
}

.clnd-notes-dtl .row .col-md-6:nth-child(4) {
    padding-left: 5px;
}

.clnd-notes-dtl .btn-hs-icon {
    flex: 1 0 auto;
    padding: 0;
}

.clnd-notes-dtl .sc-doc-bnt .btn-hs-icon {
    width: 50%;
}

.clnd-notes-dtl .sc-doc-bnt .btn-hs-icon:nth-child(1) {
    padding-right: 5px;
}

.clnd-notes-dtl .sc-doc-bnt .btn-hs-icon:nth-child(2) {
    padding-left: 5px;
}

.clnd-notes-dtl .sc-doc-bnt .btn-hs-icon .bnt {
    min-width: auto;
    width: 100%;
}


/* time sheet new design */

.dynamic-html-cnt h1 {
    font-size: 1.75rem;
    font-weight: 600;
}

.dynamic-html-cnt h2 {
    font-size: 1.5rem;
    font-weight: 600;
}

.dynamic-html-cnt h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

.dynamic-html-cnt h4 {
    font-size: 1rem;
    font-weight: 600;
}

.dynamic-html-cnt h5 {
    font-size: 0.85rem;
    font-weight: 600;
}

.dynamic-html-cnt h6 {
    font-size: 0.65rem;
    font-weight: 600;
}

.sf-timesheet-table .sf-c-table .td,
.sf-timesheet-table .sf-c-table .th {
    word-break: normal;
}

.sf-timesheet-table .sf-c-table .tr .th {
    background: rgba(45, 59, 72, 0.04);
}

.sf-timesheet-table .ant-time-picker {
    width: auto;
    min-width: 80px;
}

.timesheet-filter {
    display: flex;
    margin-bottom: 20px;
}


/* time sheet new design */

.sf-timesheet-table .sf-c-table .td,
.sf-timesheet-table .sf-c-table .th {
    word-break: normal;
}

.sf-timesheet-table .sf-c-table .tr .th {
    background: rgba(45, 59, 72, 0.04);
}

.sf-timesheet-table .ant-time-picker {
    width: auto;
    min-width: 80px;
}

.sf-timesheet-table .bnt.bnt-active {
    height: 30px;
    line-height: 28px;
    font-weight: 400;
    font-size: 13px;
    padding: 0px 15px;
}

.timesheet-filter {
    display: flex;
    margin-bottom: 20px;
}

.ext-div {
    display: flex;
    align-items: flex-end;
    flex: 1;
}

.ext-div .bnt.bnt-active {
    font-size: 14px;
    height: 34px;
    line-height: 33px;
}

.re-pop-box {
    display: flex;
}

.re-pop-box fieldset.sf-form div>label {
    display: none;
}

.staff-timesheet-table.sf-timesheet-table .scrollarea.sf-scroll-width .scrollarea-content {}

.re-pop-box fieldset.sf-form div>label {
    display: none;
}

.staff-timesheet-table.sf-timesheet-table .scrollarea.sf-scroll-width .scrollarea-content {
    min-width: 1200px;
}

.timesheet-filter.ts-footer-filter {
    margin: 10px 0 0;
    justify-content: space-between;
    align-items: center;
}

label.ts-status-dtl {
    font-weight: 500;
    margin-bottom: 10px;
    padding-right: 10px;
}

label.ts-status-dtl span {
    color: tomato;
}

.timesheet-add-nr-scroll {
    overflow-x: scroll;
    width: 100%;
}

ul.ant-pagination.time-sh-pagniton {
    text-align: right;
    margin: 15px 0;
}

label.ts-status-dtl span {
    color: tomato;
}

.timesheet-add-nr-scroll {
    overflow-x: scroll;
    width: 100%;
}


/* induction Management Module */

.up-course-files .ant-upload.ant-upload-drag {
    width: 50%;
}

.logo-upload.up-course-files label {
    margin-bottom: 10px;
}

.sf-roles-group.so-check-role {
    display: flex;
    flex-wrap: wrap;
}

.trtc-minut input {
    width: 50% !important;
}

.trtc-minut {
    position: relative;
}

.trtc-mt {
    position: absolute;
    right: 0;
    top: 28px;
    display: flex;
    color: #a7a7a7;
    align-items: center;
}

.trtc-mt button.bnt {
    width: 30px;
    padding: 0;
    flex: 0 0 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border-color: rgba(45, 59, 72, 0.06);
}

.trtc-mt button.bnt i {
    margin: 0;
    padding: 0;
    color: rgba(45, 59, 72, 0.5);
    font-size: 15px;
}


/* quiz  */

.quiz-tabs-wrap .ant-tabs {
    overflow: unset;
}

.quiz-tabs-wrap .ant-tabs-bar {
    border: 0;
    margin: 0 0 15px 0;
}

.quiz-tabs-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border: 1px solid #03a791;
    border-radius: 4px;
    color: #03a791;
    padding: 0 10px;
    background: #fff;
    height: 35px;
    line-height: 35px;
    width: 50%;
    font-size: 13px;
    margin-right: 5px;
    text-align: center;
    font-weight: 500;
}

.quiz-tabs-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
    background: #03a791;
    color: #fff;
}

.quiz-tabs-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: #03a791;
    color: #fff;
    border-color: #03a791;
}

.quiz-tabs-wrap .ant-tabs-nav.ant-tabs-nav-animated {
    width: calc(100% - 10px);
}

button.add-m-choice.bnt {
    padding: 0;
    width: auto;
    height: auto;
    min-width: auto;
    border: 0;
    position: absolute;
    right: 0;
    top: 30px;
}

button.add-m-choice i.material-icons {
    font-size: 30px;
    padding: 0;
}

button.add-m-choice.non-active i.material-icons {
    color: #d0021b;
}

.tbs-quiz-choice {
    position: relative;
}

.tbs-quiz-choice .cu-checkbox {
    margin-top: 5px;
    margin-bottom: 10px;
}

.tbs-quiz-choice input {
    width: calc(100% - 35px) !important;
}

.max-cho-t-show fieldset.sf-form>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.max-cho-t-show .sf-form label {
    margin: 0;
}

.max-cho-t-show .sf-form input[type="text"] {
    min-width: 80px;
    margin-left: 10px;
    width: 80px;
}

.quiz-settings .cu-checkbox {
    margin: 10px 0;
}

.qz-st-pass h3 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

.qz-st-input label {
    display: none;
}

.qz-st-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.qz-st-input .qz-or {
    padding: 0 10px;
    color: #bebebe;
}

.quiz-settings {
    margin-top: 10px;
}

.logo-upload.up-course-files {
    display: inline-block;
    width: 100%;
}

.logo-upload.up-course-files .ant-upload-list-picture-card-container {
    margin: 8px 8px 0 0;
}

.autoheight-box .ant-select-selection {
    height: auto;
    min-height: 35px;
}

.so-check-role fieldset.bcc-userid {
    width: 100%;
}

img.cover-img-vid {
    width: 100px;
    display: block;
}

.full-width-pic.up-course-files .ant-upload.ant-upload-drag {
    width: 100%;
}

.qiz-dlt-bnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.qiz-dlt-bnt fieldset.form-group.sf-form.select-wibg {
    flex: 1;
    padding-right: 10px;
}

.qiz-dlt-bnt button.normal-bnt {
    font-size: 16px;
}

.vido-covr a {
    display: block;
}

.vido-covr a i {
    font-size: 4em;
}

.risk-asses-img {
    max-width: 100%;
}

.swms-info-size {
    font-size: 20px !important;
}

.detect {
    border: 2px dashed blue;
}

.status-bnt .booked,
.status-bnt .job-offered {
    background: #548235;
    border: 1.5px solid #548235;
}

.status-bnt .booked-active,
.status-bnt .job-offered-active {
    background: #5fa085;
    border: 1.5px solid #548235;
}

.status-bnt .allocated,
.status-bnt .job-accepted {
    background: #4a90e2;
    border: 1.5px solid #4a90e2;
}

.status-bnt .allocated-active,
.status-bnt .job-accepted-active {
    background: #5cbbff;
    border: 1.5px solid #4a90e2;
}

.status-bnt .outsourced {
    background: #00b0f0;
    border: 1.5px solid #00b0f0;
}

.status-bnt .outsourced-active {
    background: #87defd;
    border: 1.5px solid #00b0f0;
}

.quote-date {
    position: absolute;
    margin-left: 7.5rem;
}

.normal-bnt:disabled {
    /* background: rgba(45, 59, 72, 0.1) !important; */
    color: rgba(45, 59, 72, 0.29) !important;
    border: 0 !important;
    cursor: not-allowed;
}

.tiny-icon {
    float: right;
    position: relative;
    top: 8px;
    right: 5px;
}

.tsk-book-body {
    padding-left: 10px !important;
}

.button-spc button,
.view-text-value {
    margin: auto 8px;
}

.ribion-offset {
    margin-right: 100px;
}

.card-tsk-book::before {
    position: absolute;
    top: 26px;
    right: -0.5rem;
    content: "";
    height: 28px;
    width: 28px;
    transform: rotate(45deg);
    background: #283593;
}

.card-tsk-book.booked::before {
    background: #2d5005;
}

.card-tsk-book.booked::after {
    background: #548235;
}

.card-tsk-book.outsrc::after {
    background: #00b0f0;
}

.card-tsk-book.outsrc::before {
    background: #0192c7;
}

.card-tsk-book.started::after {
    background: #7ed321;
}

.card-tsk-book.started::before {
    background: #60a318;
}

.card-tsk-book.paused::after {
    background: #f5a623;
}

.card-tsk-book.paused::before {
    background: #cc8a1f;
}

.card-tsk-book.completed::after {
    background: #4a4a4a;
}

.card-tsk-book.completed::before {
    background: #363535;
}

.card-tsk-book::after {
    position: absolute;
    content: attr(data-label);
    top: 8px;
    right: -14px;
    padding: 0.5rem;
    width: 120px;
    background: #4a90e2;
    color: white;
    text-align: center;
    font-family: "Roboto", sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}

.task-panel {
    border: 1px solid red;
    width: inherit;
}

div.float-right>span.tick-ico {
    color: #008572;
    font-size: 22px;
}

.job-details-header {
    display: flex;
    align-items: center;
    flex-flow: column;
    font-weight: 600;
}

.job-details-header>label {
    margin: 1rem 0;
}

.button-cust>button~button~button {
    margin-left: auto !important;
}

.job-allocation-panel {
    padding-top: 0px;
}

.job-task-bnt {
    position: absolute;
    right: 20px;
    vertical-align: center;
    top: -2.5rem;
    font-style: italic;
    font-size: 13px;
    font-weight: 600;
    z-index: 3000;
    color: #fff;
    background: #03a791;
    border: 1.5px solid #03a791;
    border-radius: 4px;
    height: 30px;
    line-height: 27px;
}

.panel-cancel {
    color: grey;
    font-size: 1rem !important;
    position: absolute;
    top: 10px;
    right: 20px;
}

.panel-cancel:hover {
    cursor: pointer;
}

.ama-panel .ama-collapse .site-header-text label {
    font-size: 15px;
    font-weight: 600;
}

.task-header-main {
    height: 8vh;
}

.ant-collapse-item.ant-collapse-item-active.ama-colps-head .ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-right.ama-collapse.task-collapse div.ant-collapse-content-box {
    padding-top: 0px;
}

.task-collapse {
    border: 1px solid rgb(204, 204, 204);
}

.task-collapse .task-header-main div>label {
    font-weight: 600;
}

.task-collapse .task-header-main div>span {
    font-size: 14px;
}

.task-header-panel {
    width: 90%;
}

.task-data-column div {
    margin-bottom: 10px;
}

.task-collapse div>.column.task-data-column label {
    margin-bottom: 0;
    font-weight: bold;
}

.new-ama-form .ama-panel .task-collapse div.ant-collapse-item.ant-collapse-item-active.ama-colps-head>.ant-collapse-header {
    height: 2rem;
}

.new-ama-form .ama-panel .task-collapse div.ant-collapse-item.ant-collapse-item-active.ama-colps-head>.ant-collapse-header div.task-header-panel>div>label~span {
    display: block;
    text-overflow: ellipsis;
}

.new-ama-form .ama-panel .task-collapse div.ant-collapse-item.ant-collapse-item-active.ama-colps-head>.ant-collapse-header div.task-header-panel>div~div {
    display: none;
}

.new-ama-form .ama-panel .task-collapse div.ant-collapse-item.ant-collapse-item-active.ama-colps-head>.ant-collapse-header div.task-header-panel>div>.hide {
    display: none;
}

.task-header-panel .material-icons.icon-task-panel {
    font-size: 1rem;
    font-weight: bold;
}

div.task-panel-bottom {
    margin-bottom: 10px;
}

div.task-panel-bottom>div.view-text-value~div.view-text-value {
    margin-left: 20px;
    margin-right: 20px;
}

div.task-panel-bottom>div.view-text-value>label,
div.task-panel-bottom>div.view-text-value>span {
    font-weight: 600;
}

.sf-card .clnd-notes-dtl button.bnt.bnt-normal.task-save:hover {
    background: #03a791;
    color: #fff;
    border-color: #03a791;
}

.swms-big-tb-scroll {
    width: 800px;
    overflow-x: scroll;
    overflow-y: auto;
    padding-right: 20px;
}

.swms-big-tb-scroll::-webkit-scrollbar {
    width: 2px;
    border-radius: 100px;
}

.swms-big-tb-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.swms-big-tb-scroll::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.1);
}

.swms-big-tb-scroll:hover::-webkit-scrollbar-thumb {
    background: rgba(45, 59, 72, 0.2);
}

.swms-big-tb-scroll .sf-c-table {
    display: table;
    min-width: 2500px;
    margin-bottom: 1rem;
}

.swms-big-tb-scroll .sm-frm-tbl {
    min-width: 2500px;
}

.swms-big-tb-scroll .rdw-editor-toolbar {
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
}

.swms-big-tb-scroll .public-DraftStyleDefault-block {
    margin: 0;
    padding: 10px;
}

.swms-big-tb-scroll .rdw-editor-main {
    border: 1px solid #f1f1f1;
    border-top: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
}

.swms-big-tb-scroll .tr .th:nth-child(1),
.swms-big-tb-scroll .tr .td:nth-child(1) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(2),
.swms-big-tb-scroll .tr .th:nth-child(2) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(3),
.swms-big-tb-scroll .tr .th:nth-child(3) {
    width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(4),
.swms-big-tb-scroll .tr .th:nth-child(4) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(5),
.swms-big-tb-scroll .tr .th:nth-child(5) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(6),
.swms-big-tb-scroll .tr .th:nth-child(6) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(7),
.swms-big-tb-scroll .tr .th:nth-child(7) {
    width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(8),
.swms-big-tb-scroll .tr .th:nth-child(8) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(9),
.swms-big-tb-scroll .tr .th:nth-child(9) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(10),
.swms-big-tb-scroll .tr .th:nth-child(10) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(11),
.swms-big-tb-scroll .tr .th:nth-child(11) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(12),
.swms-big-tb-scroll .tr .th:nth-child(12) {
    width: 10%;
}

.swms-big-tb-scroll .tr .td:nth-child(13),
.swms-big-tb-scroll .tr .th:nth-child(13) {
    width: 3%;
}

.swms-big-tb-scroll .tr .td:nth-child(14),
.swms-big-tb-scroll .tr .th:nth-child(14) {
    width: 2%;
    vertical-align: top;
}

.autohtxtbox .ant-select-selection {
    max-height: 200px;
    overflow: hidden;
}

.swms-big-tb-scroll .tr .td:nth-child(5),
.swms-big-tb-scroll .tr .th:nth-child(5) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(6),
.swms-big-tb-scroll .tr .th:nth-child(6) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(7),
.swms-big-tb-scroll .tr .th:nth-child(7) {
    width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(8),
.swms-big-tb-scroll .tr .th:nth-child(8) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(9),
.swms-big-tb-scroll .tr .th:nth-child(9) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(10),
.swms-big-tb-scroll .tr .th:nth-child(10) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(11),
.swms-big-tb-scroll .tr .th:nth-child(11) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(12),
.swms-big-tb-scroll .tr .th:nth-child(12) {
    width: 10%;
}

.swms-big-tb-scroll .tr .td:nth-child(13),
.swms-big-tb-scroll .tr .th:nth-child(13) {
    width: 3%;
}

.swms-big-tb-scroll .tr .td:nth-child(14),
.swms-big-tb-scroll .tr .th:nth-child(14) {
    width: 2%;
    vertical-align: top;
}

.autohtxtbox .ant-select-selection {
    max-height: 200px;
    overflow: hidden;
}

.service-agent-title {
    color: #222222;
    font-weight: 600;
}

.split-delete-bnt {
    float: right;
}


/* .job-allocation-panel .task-data-column .esti-hrs-hd label {
  color: #222222;
} */

.rdw-image-modal-size,
.rdw-image-modal-btn-section {
    display: none;
}

.ico-red {
    color: #ff0000;
}

.material-icons.ico-red {
    color: #ff0000;
}

.swms-big-tb-scroll .tr .td:nth-child(2),
.swms-big-tb-scroll .tr .th:nth-child(2) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(3),
.swms-big-tb-scroll .tr .th:nth-child(3) {
    width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(5),
.swms-big-tb-scroll .tr .th:nth-child(5) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(6),
.swms-big-tb-scroll .tr .th:nth-child(6) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(7),
.swms-big-tb-scroll .tr .th:nth-child(7) {
    width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(8),
.swms-big-tb-scroll .tr .th:nth-child(8) {
    width: 5%;
}

.ama-panel .ama-panel div.task-panel-bottom.totals-panel div.view-text-value>label {
    margin: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row wrap;
}

.scope-doc-task-header-panel {
    width: 92%;
}

.complete-icon {
    color: #03a791;
}

.incomplete-icon {
    color: #d0021b;
}

.btn-book-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-calendar-container {
    width: 100%;
}

.white-back {
    background-color: #fff !important;
    padding: 12px 20px !important;
    border-radius: 10px;
    /* border: 1px solid #03a791; */
}

.job-drcn-main {
    /* background: #E7E6E6; */
    background: #fff;
    width: 100%;
}

.calendar-back-nav {
    display: flex;
    flex-flow: row;
    margin-bottom: 10px;
}

.addstaff-top-head .ant-collapse-content .ant-collapse-content-box {
    padding: 16px 5px;
}

.job-drcn-main .ant-collapse-content .ant-collapse-content-box {
    padding: 16px 5px;
}

.job-drcn-main .color-add {
    color: #009999;
    font-weight: bold;
}

.status-bnt .create-job,
.status-bnt .update-job {
    max-width: 100px !important;
    text-align: center;
    /* margin-left: 10px !important; */
    position: absolute;
}

.status-bnt .create-job {
    right: 120px;
}

.status-bnt .update-job {
    right: 0;
}

.status-bnt .update-job {
    margin-right: auto;
}

.update-job:disabled,
.create-job:disabled {
    background: rgba(45, 59, 72, 0.1) !important;
    color: rgba(45, 59, 72, 0.29) !important;
    border: 0 !important;
    cursor: not-allowed;
    width: 92%;
}

.sc-task-wrap .ant-checkbox-wrapper {
    position: absolute;
    top: 15px;
    left: 34px;
}

.thumb-ico {
    vertical-align: bottom;
    margin: 0 0 0 5px;
    padding-bottom: 3px;
    height: 25px;
}


/* 
.ant-checkbox .ant-checkbox-inner {
  background: #ff0000;
  border-color: #ff0000;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #03A791;
  border-color: #03A791;
} */

.customcheck {
    position: relative;
}

.customcheck input {
    display: none;
}

.customcheck input~.checkmark {
    background: #ee0b0b;
    width: 17px;
    display: inline-block;
    position: relative;
    height: 17px;
    border-radius: 2px;
    vertical-align: middle;
    margin-right: 10px;
}

.customcheck input~.checkmark:after,
.customcheck input~.checkmark:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 16px;
    background: #fff;
    left: 8px;
    top: 1px;
}

.customcheck input~.checkmark:after {
    transform: rotate(-45deg);
    z-index: 1;
}

.customcheck input~.checkmark:before {
    transform: rotate(45deg);
    z-index: 1;
}

.customcheck input:checked~.checkmark {
    background: #03a791;
    width: 17px;
    display: inline-block;
    position: relative;
    height: 17px;
    border-radius: 2px;
}

.customcheck input:checked~.checkmark:after {
    display: none;
}

.customcheck input:checked~.checkmark:before {
    background: none;
    border: 2px solid #fff;
    width: 6px;
    top: 2px;
    left: 6px;
    border-top: 0;
    border-left: 0;
    height: 10px;
    top: 2px;
}

.swms-check-list {
    display: inline-grid;
}

.quote-job-item-selected .normal-bnt {
    text-decoration: underline;
    font-weight: 600;
}

.job-allocation-header {
    padding: 10px !important;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.job-allocation-header .job-allocation-logo label {
    background: #7b1fa2;
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    border-radius: 50px;
    padding: 5px;
    line-height: 32px;
    height: 75px;
    width: 75px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-allocation-quote-button {
    height: 1.1rem;
    background: none;
    font-size: 1rem;
    color: #03a791;
    text-decoration: underline;
    margin-left: 0px;
    padding-left: 0px;
    font-weight: 600;
}

.job-allocation-header-meta {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.job-allocation-header-meta .meta-line-item {
    font-weight: 600;
    margin-top: 5px;
}

.job-details-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-collapse-item.ant-collapse-item-active.ama-colps-head>div[aria-expanded="true"] label.collapse-task-name {
    position: absolute;
    top: 11px;
    left: 24px;
}

.view-outsource-task-percent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.view-outsource-task-percent label {
    font-weight: 800;
}

.view-outsource-task-percent span {
    font-weight: 600;
    margin-top: 1.25rem;
}

.job-outsource-icon-pass {
    color: green;
}

.job-outsource-icon-fail {
    color: red;
}

.ant-collapse-item.ant-collapse-item-active.ama-colps-head>div[aria-expanded="true"]~div .estimate-data-view label {
    margin-left: 6px;
}

.job-accept-decline-table {
    /* margin-top: 5px; */
}

.job-accept-decline-table-row td {
    padding: 5px;
    font-weight: 600;
    color: #333;
}

.job-accept-decline-table-row td.row-item-accept-label {
    color: mediumseagreen;
}

.job-accept-decline-table-row td.row-item-reject-label {
    color: crimson
}

.view-quote-time-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0 -2rem 0;
}

.view-quote-time-header div div label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.view-quote-time-header div div span {
    font-size: 16px;
}

.green-uline {
    text-decoration: underline;
    text-decoration-color: #417505;
    color: #417505;
}

.blue-uline {
    text-decoration: underline;
    text-decoration-color: #0b8ed0;
    color: #0b8ed0;
}

.red-uline {
    text-decoration: underline;
    text-decoration-color: rgba(208, 2, 27, 1);
    color: rgba(208, 2, 27, 1);
}

.purple-uline {
    text-decoration: underline;
    text-decoration-color: #3949ab;
    color: #3949ab;
}

.staff-list>.cu-checkbox {
    min-width: 120px;
}

.swms-big-tb-scroll .tr .td:nth-child(9),
.swms-big-tb-scroll .tr .th:nth-child(9) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(10),
.swms-big-tb-scroll .tr .th:nth-child(10) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(11),
.swms-big-tb-scroll .tr .th:nth-child(11) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(12),
.swms-big-tb-scroll .tr .th:nth-child(12) {
    width: 10%;
}

.swms-big-tb-scroll .tr .td:nth-child(13),
.swms-big-tb-scroll .tr .th:nth-child(13) {
    width: 3%;
}

.swms-big-tb-scroll .tr .td:nth-child(14),
.swms-big-tb-scroll .tr .th:nth-child(14) {
    width: 2%;
    vertical-align: top;
}

.autohtxtbox .ant-select-selection {
    max-height: 200px;
    overflow: hidden;
}

.service-agent-title {
    color: #222222;
    font-weight: 600;
}

.split-delete-bnt {
    float: right;
}


/* .job-allocation-panel .task-data-column .esti-hrs-hd label {
  color: #222222;
} */

.rdw-image-modal-size,
.rdw-image-modal-btn-section {
    display: none;
}

.ico-red {
    color: #ff0000;
}

.material-icons.ico-red {
    color: #ff0000;
}

.ama-panel .ama-panel div.task-panel-bottom.totals-panel div.view-text-value {
    /* width: 100%; */
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.ama-panel .ama-panel div.task-panel-bottom.totals-panel div.view-text-value>label {
    margin: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row wrap;
}

.scope-doc-task-header-panel {
    width: 92%
}


/* ----------------------------------------- */

.confirm-btn:hover {
    background: #20b39a !important;
}

.white-back {
    background-color: #fff !important;
    padding: 12px 20px !important;
    border-radius: 10px;
    /* border: 1px solid #03a791; */
}

.job-drcn-main {
    /* background: #E7E6E6; */
    background: #fff;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    clear: both;
}

.addstaff-top-head .ant-collapse-content .ant-collapse-content-box {
    padding: 16px 5px;
}

.job-drcn-main .ant-collapse-content .ant-collapse-content-box {
    padding: 16px 5px;
}

.job-drcn-main .color-add {
    color: #009999;
    font-weight: bold;
}

.devider {
    margin: 10px;
    float: left;
    clear: both;
}

.confirm-btn {
    color: #fff;
    background: #03a791;
    border: none;
    font-size: 16px;
    padding: 4px 15px;
}

.ant-checkbox-group {
    display: grid;
}

.ant-collapse-header {
    color: #009999 !important;
    font-weight: bold;
}


/* .job-drcn-main .ant-collapse-content {
  background-color: transparent !important;
} */

.confirm-btn span {
    color: #fff;
}

.staff-multi .ant-checkbox-group-item {
    width: 46%;
}

.staff-multi .ant-checkbox-group {
    display: block;
}

.border0 {
    border: none !important;
}

.del-btn {
    background: #fff;
}

.del-btn span {
    color: #03A791 !important;
}

.del-btn:hover span {
    color: #fff !important;
}

.add-more {
    color: #20b39a;
    background: transparent;
    border: none;
    box-shadow: none;
}


/* .add-more:hover, .ant-btn:focus {
  background: transparent;
} */

.docu-boxes span {
    font-size: 12px;
    display: block;
}

.colorblue-back {
    background: #66FFCC;
    padding: 7px 20px;
    border-radius: 10px;
    border: 1px solid #03a791 !important;
}

.colore-back {
    background-color: #fff !important;
    padding: 12px 20px !important;
    border-radius: 10px;
    border: 2px solid #66FFCC !important;
}

.select-document {
    background: #66FFCC;
    padding: 12px 20px;
    border-radius: 10px;
    border: 1px solid #66FFCC !important;
}

.staf-list-table-head .ant-table table {
    border-collapse: collapse;
}

.doc-lnes-hed {
    line-height: 25px;
}

.ant-collapse-content-box .add-tsk-main:nth-child(2) {
    /* border-top: 1px solid #ddd; */
    padding-top: 15px;
    margin-top: 15px;
}

.docu-boxes {
    padding-bottom: 25px;
    line-height: 18px;
}

.docu-boxes-main i {
    color: #00CC99;
    position: absolute;
    bottom: 5px;
    cursor: pointer;
}

.document-main .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.staff-list-main .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.staff-licence-main .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.dcmt-img img {
    max-width: 100%;
}

.docu-boxes-main {
    margin-bottom: 10px;
    border: 1px solid #767171;
    text-align: center;
}

.document-main {
    clear: both;
    margin-bottom: 10px;
}

.boxes-documents .col-lg-2 {
    max-width: 13%;
}


/* .header-main {
  background: #767171;
  padding: 10px;
} */

.hed-text {
    width: 100%;
    align-self: center;
    position: absolute;
}

.hed-img img {
    border-radius: 50%;
    border: 1px solid #767171;
    width: 50px;
    height: 50px;
}

.hed-text h1 {
    font-size: 20px;
    font-weight: bold;
    color: #767171;
}

.check-icon li i {
    padding-left: 20px;
    color: #03a791;
    font-size: 18px;
}

.check-icon .list-tasks i {
    padding-left: 10px;
    color: #03a791;
    font-size: 18px;
    float: right;
    width: 10%;
    /* margin-top: -8px; */
}

.lst-tskks {
    display: flex;
}

.dash-tsklist .job-doc-label {
    width: 100%;
    display: flex;
}

.check-icon li {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.tabel-main-stafflist .ant-table-thead tr th {
    color: #fff !important;
    background: #4a4a4a !important;
    padding-bottom: 5px !important;
}

.hdg-mn .ant-collapse-header {
    font-size: 16px !important;
}

.tabel-main-stafflist .ant-table-tbody tr td {
    background: #fff;
    white-space: nowrap;
    padding: 7px;
}


/* .tabel-main-stafflist .table-bordered {
  border: 1px solid #000000;
} */

.tabel-main-stafflist .table-bordered td {
    border: 1px solid #757171;
}

.tabel-main-stafflist .table-bordered th {
    border: 1px solid #ffffff;
    border-top: 0;
    border-bottom: 0;
}

.tabel-main-stafflist .ant-table-row:hover td {
    background-color: #ecebeb !important;
}

.staff-licnce-main .ant-table-thead tr th {
    background: transparent;
    border: none;
}

.staff-licnce-main .ant-table-thead tr {
    border: none;
}

.staff-licnce-main .ant-table-tbody tr td {
    border-bottom: none;
}

.add-sdul {
    background: transparent;
    color: #009999;
    border: 2px solid #009999;
    font-weight: bold;
    padding: 8px 20px;
}

.staff-text-tsk textarea {
    margin-bottom: 20px;
}

.staff-licnce-main .ant-table-pagination {
    display: none;
}

.add-note-main .ant-table-pagination {
    display: none;
}

.add-note-main .ant-table-tbody>tr>td {
    border-bottom: none;
}

.hed-img {
    width: 100px;
    display: flex;
}


/* .addstaff-top-head .ant-collapse-content-box {
  padding: 5px;
} */

.allocate-btn:focus {
    background: #20b39a;
}

.addstaff-top-head .ant-collapse-header {
    /* background: #e7e6e6; */
    border: none;
}

.del-btn:focus {
    background: #fff;
}

.addstaff-top-head .ant-collapse .ant-collapse-item {
    /* border-bottom: 1px solid #d9d9d9 !important; */
    margin-bottom: 10px;
}

.icon-tbl-main i {
    font-size: 18px;
}

.staf-list-table-head .ant-table-column-title .ant-checkbox .ant-checkbox-inner {
    display: block;
    /* width: 100%; */
    text-align: center;
    margin: 0 auto;
}

.staf-list-table-head .ant-checkbox-wrapper span {
    color: #fff;
    display: inherit;
    padding-top: 10px;
}

.dash-tsklist .tsjd-lst:hover {
    /* background: #f4f4f4; */
    /* border: 1px solid #03a791; */
}

.dash-tsklist .tsjd-lst {
    padding: 15px 5px 15px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    max-width: 32%;
    /* border: 1px solid #03a791; */
    /* align-self: center; */
    /* cursor: pointer; */
    margin-right: 10px;
}

.dash-stafflist li:hover {
    background: #e8e8e8;
    /* border-radius: 5px; */
}

.whiteback-staff label:hover {
    background: #e8e8e8;
    border-radius: 5px;
}

.whiteback-staff {
    background-color: #fff !important;
    padding: 15px;
    border-radius: 10px;
    /* border: 1px solid #03a791; */
}

.dash-stafflist li i {
    position: absolute;
    right: 10px;
}

.dash-stafflist li {
    padding-left: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 4px 5px;
    position: relative;
}


/* .boxes-documents .white-back:hover {
  background: #66FFCC !important;
} */

.staff-licnce-main .ant-table-tbody tr th {
    padding: 10px;
}

.staff-licnce-main .ant-table-tbody tr td {
    padding: 10px;
}


/* .dash-tsklist {
  justify-content: space-around;
} */

.tsjd-lst .job-doc-label .lbl-docs {
    padding-right: 10px;
    width: 100%;
    display: flex;
}

.pdfimg-tab img {
    max-width: 100%;
}


/* .lbl-docs .tsk-hdg {
  padding-right: 20px;
} */

.lbl-docs .tsk-lines {
    width: 80%;
    cursor: pointer;
}


/* .dash-tsklist .job-doc-label span {
  padding-left: 20px;
} */

.job-doc-label b {
    padding-right: 20px;
}

.staf-list-table-head .ant-checkbox-wrapper {
    display: block;
}

.whiteback-staff li {
    padding-left: 10px;
}

.ant-collapse-item {
    transition-duration: 2s;
}

.staff-lists-datas .whiteback-staff {
    height: 300px;
    overflow-y: auto;
}

.staff-lists-datas .whiteback-staff.allocate-staff-list {
    height: 150px;
}

.addstaff-top-head .add-tsk-main {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.docu-boxes-main .docmnt-clicks .fa-check {
    position: relative;
    float: right;
    font-size: 15px;
    color: #66FFCC;
    margin-right: -15px;
}

@media (min-width: 320px) and (max-width: 575.98px) {
    .boxes-documents .col-sm-12 {
        max-width: 100% !important;
    }

    .hed-img {
        text-align: left !important;
    }

    .dash-tsklist .tsjd-lst {
        max-width: 100% !important;
    }

    .ant-table-layout-fixed table {
        width: 300% !important;
    }

    #pdfobject {
        min-height: 80vh !important;
    }

    .pdf-main-mdl .pdfobject {
        min-height: 80vh !important;
    }

    .pdf-main-mdl {
        min-height: 80vh !important;
    }

    .ant-modal {
        max-width: 90% !important;
    }

    .ant-modal {
        top: 10px !important;
    }

    .job-doc-label b {
        font-size: 10px !important;
    }

    .job-doc-label span {
        font-size: 10px !important;
    }

    .tsk-lines .lst-tskks {
        font-size: 10px;
    }

    .main-filelds-frms .sf-form {
        max-width: 100% !important;
        width: 100% !important;
    }

    .headcol {
        position: relative !important;
        margin-left: 0px !important;
        width: auto !important;
    }

    .payroll-headcol {
        position: relative !important;
        margin-left: 0px !important;
        width: auto !important;
    }

    .swms-big-tb-scroll .payrate-leftfixed {
        margin-left: 0px !important;
    }

    .swms-big-tb-scroll .payroll-leftfixed {
        margin-left: 0px !important;
    }

    .btn-headcol {
        position: relative !important;
        margin-left: 0px !important;
        width: auto !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .boxes-documents .col-sm-12 {
        max-width: 100% !important;
    }

    .dash-tsklist .tsjd-lst {
        max-width: 100% !important;
    }

    .ant-table-layout-fixed table {
        width: 300% !important;
    }

    #pdfobject {
        min-height: 65vh !important;
    }

    .pdf-main-mdl .pdfobject {
        min-height: 65vh !important;
    }

    .pdf-main-mdl {
        min-height: 65vh !important;
    }

    .ant-modal {
        max-width: 90% !important;
    }

    .ant-modal {
        top: 10px !important;
    }

    .main-filelds-frms .sf-form {
        max-width: 100% !important;
        width: 100% !important;
    }

    .headcol {
        position: relative !important;
        margin-left: 0px !important;
        width: auto !important;
    }

    .payroll-headcol {
        position: relative !important;
        margin-left: 0px !important;
        width: auto !important;
    }

    .swms-big-tb-scroll .payrate-leftfixed {
        margin-left: 0px !important;
    }

    .swms-big-tb-scroll .payroll-leftfixed {
        margin-left: 0px !important;
    }

    .btn-headcol {
        position: relative !important;
        margin-left: 0px !important;
        width: auto !important;
    }

    .swms-big-tb-scroll .job-leftfixed {
        margin-left: 0px !important;
    }

    .headcolnew1 {
        position: relative !important;
        margin-left: 0 !important;
    }

    .headcolnew2 {
        position: relative !important;
        margin-left: 0 !important;
    }

    .headcolnew3 {
        position: relative !important;
        margin-left: 0 !important;
    }

    .swms-big-tb-scroll .job-leftfixed {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .boxes-documents .col-md-3 {
        max-width: 25% !important;
    }

    .complete-icon {
        color: #03A791;
    }

    .incomplete-icon {
        color: #D0021B;
    }

    .btn-book-calendar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .booking-calendar-container {
        width: 100%;
    }

    .calendar-back-nav {
        display: flex;
        flex-flow: row;
        margin-bottom: 10px;
    }

    .status-bnt .create-job,
    .status-bnt .update-job {
        max-width: 100px !important;
        text-align: center;
        /* margin-left: 10px !important; */
        position: absolute;
    }

    .status-bnt .create-job {
        right: 120px
    }

    .status-bnt .update-job {
        right: 0;
    }

    .status-bnt .update-job {
        margin-right: auto;
    }

    .update-job:disabled,
    .create-job:disabled {
        background: rgba(45, 59, 72, 0.1) !important;
        color: rgba(45, 59, 72, 0.29) !important;
        border: 0 !important;
        cursor: not-allowed;
        width: 92%;
    }

    .view-text-value label.tsk_select_check {
        vertical-align: bottom;
        margin-right: 5px;
    }

    .thumb-ico {
        vertical-align: bottom;
        margin: 0 0 0 5px;
        padding-bottom: 3px;
        height: 25px;
    }

    /* 
.ant-checkbox .ant-checkbox-inner {
  background: #ff0000;
  border-color: #ff0000;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #03A791;
  border-color: #03A791;
} */
    .customcheck {
        position: relative;
    }

    .customcheck input {
        display: none;
    }

    .customcheck input~.checkmark {
        background: #ee0b0b;
        width: 17px;
        display: inline-block;
        position: relative;
        height: 17px;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: 10px;
    }

    .customcheck input~.checkmark:after,
    .customcheck input~.checkmark:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 16px;
        background: #fff;
        left: 8px;
        top: 1px;
    }

    .customcheck input~.checkmark:after {
        transform: rotate(-45deg);
        z-index: 1;
    }

    .customcheck input~.checkmark:before {
        transform: rotate(45deg);
        z-index: 1;
    }

    .customcheck input:checked~.checkmark {
        background: #03A791;
        width: 17px;
        display: inline-block;
        position: relative;
        height: 17px;
        border-radius: 2px;
    }

    .customcheck input:checked~.checkmark:after {
        display: none;
    }

    .customcheck input:checked~.checkmark:before {
        background: none;
        border: 2px solid #fff;
        width: 6px;
        top: 2px;
        left: 6px;
        border-top: 0;
        border-left: 0;
        height: 10px;
        top: 2px;
    }

    .swms-check-list {
        display: inline-grid;
    }

    /* .quote-job-item {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
} */
    .quote-job-item .normal-bnt {
        /* border-radius: 5px;
  border: 1px solid white;
  padding: 2px; */
    }

    .quote-job-item-selected .normal-bnt {
        /* border-radius: 5px;
  border: 1px solid #03A791;
  padding: 2px; */
        text-decoration: underline;
        font-weight: 600;
    }

    .dash-tsklist .tsjd-lst {
        max-width: 80% !important;
    }

    .ant-table-layout-fixed table {
        width: 300% !important;
    }

    .main-filelds-frms .sf-form {
        max-width: 50% !important;
        width: 50% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .ant-table-layout-fixed table {
        width: 200% !important;
    }

    .col-lg-2.staff-lists-datas {
        max-width: 25%;
        flex: 25%;
    }

    .col-lg-4.tsjd-lst {
        max-width: 47% !important;
        flex: 47% !important;
    }

    .main-filelds-frms .sf-form {
        max-width: 33.3% !important;
        width: 33.3% !important;
    }
}

@media (max-width: 991.98px) {
    .job-updatetable th {
        position: relative !important;
        top: 0;
        background: transparent !important;
    }

    .job-updatetable th.rightfiix-tbl1 {
        right: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.rightfiix-tbl2 {
        right: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.headcolnew1 {
        left: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.headcolnew2 {
        left: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.headcolnew3 {
        left: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.headcol1 {
        left: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.headcol2 {
        left: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }

    .job-updatetable th.headcol3 {
        left: auto !important;
        border: 1px solid rgba(45, 59, 72, 0.1) !important;
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
    .col-lg-4.tsjd-lst {
        max-width: 45% !important;
        flex: 45% !important;
    }
}


/* -------------- */

.header-bs {
    background: #D0CECE;
    padding: 5px;
}

.defalut-mai {
    background: transparent !important;
    border: 1px solid #d0cece;
    border-radius: 10px;
    padding: 15px !important;
}

.note-mn tr:hover td {
    background-color: transparent !important;
}

.staff-multi .ant-checkbox-wrapper {
    margin-left: 0 !important;
    width: 45%;
    margin-right: 5px;
    padding: 2px 5px;
}

.dflt-lcnc .icon-tbl-main i {
    color: #009999;
}

.link-head-main {
    right: 20px;
    position: absolute;
    height: 85%;
    display: flex;
    align-items: center;
}

.link-head {
    color: #767171;
    font-size: 12px;
}

.dflt-lcnc .ant-table-row-level-0:hover td {
    background: transparent !important;
}

.dflt-lcnc .icon-tbl-main a {
    cursor: not-allowed;
}

.defalut-mai .boxes-documents .white-back:hover {
    background: transparent !important;
}

.defalut-mai .boxes-documents .white-back i {
    cursor: not-allowed;
}

.staff-lists-datas .whiteback-staff::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.staff-lists-datas .whiteback-staff::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.staff-lists-datas .whiteback-staff::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.box {
    display: block;
    background-color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.box.green {
    background-color: #f4f4f4;
}

#pdfobject {
    min-height: 65vh;
}

.pdf-main-mdl .pdfobject {
    min-height: 65vh;
}

.pdf-main-mdl {
    min-height: 65vh;
}

.ant-modal {
    max-width: 90%;
}

.allocate-btn:focus span {
    color: #fff !important;
}

.disable {
    color: darkGray;
    pointer-events: none;
}

.dot {
    height: 16px;
    width: 16px;
    border: 1px solid #DBDBDB;
    border-radius: 50%;
    display: inline-block;
    background: #fff;
}

.bg-green {
    background: #f4f4f4;
}

.bg-red {
    background: #ff0000;
}

.bg-yellow {
    background: #ffa500;
}

#table {
    border-collapse: collapse;
    width: 100%;
}

#table td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#table tr:nth-child(even) {
    background-color: #f2f2f2;
}

#table tr:hover {
    background-color: #ddd;
}

#table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
}

.payrate-tbl table {
    table-layout: fixed;
    white-space: nowrap;
    display: block;
    overflow-x: auto;
}

.user-add-payrate-table tr th {
    font-size: 15px;
    font-weight: 400;
}

.user-add-payrate tr td {
    font-size: 13px;
    font-weight: 400;
}

.user-add-payrate tr td:last-child {
    vertical-align: middle;
    /* line-height: normal; */
}

.user-add-payrate tr th,
.user-add-payrate tr td {
    border-top: none;
    padding: 10px;
    vertical-align: top;
    transition: 0.5s all;
}

.user-add-payrate tr td input[type="text"],
.user-add-payrate tr td select,
.user-add-payrate tr td .ant-select-selection {
    height: 30px;
    padding: 5px;
    font-size: 13px;
    display: inline-block;
}

.user-add-payrate tr td label {
    display: none;
}

.user-add-payrate tr td select {
    min-width: 100px;
}

.user-add-payrate tr td .ant-select-selection .ant-select-selection__rendered {
    margin: 0 5px 0 0;
    line-height: normal;
}

.user-add-payrate tr td .ant-select-selection .ant-select-arrow {
    right: 0;
}

.user-add-payrate tr:hover td {
    background: #f1f1f1;
}

.user-add-payrate td {
    border: 1px solid rgba(45, 59, 72, 0.1);
}

.user-add-payrate th {
    border-bottom: 1px solid rgba(45, 59, 72, 0.1);
}

.pay-rate-table>tr:hover td {
    background: #f1f1f1;
}

.pay-rate-table th,
.pay-rate-table td {
    border: 1px solid rgba(45, 59, 72, 0.1);
    font-size: 13px;
    font-weight: 400;
}

.pay-rate-table>tbody>tr td:first-child {
    white-space: pre;
}

.pay-rate-table>thead>tr:nth-of-type(2) {
    color: #212529;
}

.pay-rate-table .table thead th {
    border-bottom: 1px solid #dee2e6;
}

.pay-rate-table tbody td:hover {
    background: rgba(45, 59, 72, 0.04);
}

.pay-rate-table thead th:first-child {
    white-space: nowrap;
}

.border-b-black {
    border-bottom: 1px solid black;
}

.dot.bg-green {
    background: #03A791;
}

.dot.bg-red {
    background: red;
}

.dot.bg-org {
    background: orange;
}

.job-inline-checkbox .custom-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
}

.flot-R .cu-checkbox {
    float: right;
}

.mt-center .cu-checkbox {
    margin-top: 20px;
    text-align: center;
}

.jobinput76 input {
    border: 1px solid rgba(45, 59, 72, 0.08);
    padding: 7px;
    width: 76px !important;
    border-radius: 4px;
}

.jobinput50 input {
    border: 1px solid rgba(45, 59, 72, 0.08);
    padding: 7px;
    width: 50px !important;
    border-radius: 4px;
}

.disable-input-job input {
    border: 0px;
    background-color: #fff;
    padding: 7px 0px 0px 0px;
    width: 102px !important;
}

.job-updatetable>tr:hover td {
    background: #f1f1f1;
}

.job-updatetable th,
.job-updatetable td {
    padding: 5px !important;
    margin: 0px;
    border: 1px solid rgba(45, 59, 72, 0.1);
    font-size: 13px;
    font-weight: 400;
}

.job-updatetable>thead>tr:nth-of-type(2) {
    color: #212529;
}

.job-updatetable .table thead th {
    border-bottom: 1px solid #dee2e6;
}

.job-updatetable tbody td:hover {
    background: rgba(45, 59, 72, 0.04);
}

.job-updatetable thead th:first-child {
    white-space: nowrap;
}

.jobupdate-title {
    font-weight: 500;
    padding-bottom: 15px;
}

.pointer {
    cursor: pointer;
}

.main-filelds-frms .sf-form {
    width: 25%;
    padding: 10px;
    text-align: left;
    padding-bottom: 20px;
}

.main-filelds-frms .sf-form label {
    font-weight: normal;
}

.btns-main-flds {
    float: right;
    clear: both;
    width: 100%;
    justify-content: flex-end;
}

.main-filelds-frms {
    padding: 0px 15px;
}

.main-filelds-frms h3 {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    font-size: 24px;
    letter-spacing: 1px;
}

.nodata-img {
    text-align: center;
}

.job-notes-slider .carousel-item.active img {
    width: 100%;
}

.job-notes-slider .carousel-item.active {
    width: 100%;
    min-height: 150px;
}

.job_notes {
    padding: 10px;
}

.custom-checkbox-inner {
    background-color: #03A791;
    border-color: #03A791;
}

.custom-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
}

.job-shift-slider-icon {
    text-align: center;
    margin-top: 10px;
}

.headcol {
    position: absolute;
    margin-left: -280px;
    top: auto;
    width: 280px;
    min-height: 41px;
}

.payroll-headcol {
    position: absolute;
    margin-left: -280px;
    top: auto;
    width: 280px;
    min-height: 71px;
}


/* table {
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid grey;
} */

.btn-headcol {
    position: absolute;
    margin-left: -280px;
    top: auto;
    width: 280px;
    /* min-height: 41px !important; */
}


/* td, th {
  margin: 0;
  border: 1px solid grey;
  white-space: nowrap;
  border-top-width: 0px;
} */

.swms-big-tb-scroll .payrate-leftfixed {
    /* width: 500px; */
    overflow-x: scroll;
    margin-left: 280px;
    overflow-y: visible;
    padding: 0;
}

.swms-big-tb-scroll .payroll-leftfixed {
    /* width: 500px; */
    overflow-x: scroll;
    margin-left: 280px;
    overflow-y: visible;
    padding: 0;
}

.swms-big-tb-scroll .job-leftfixed {
    /* width: 500px; */
    overflow-x: scroll;
    margin-left: 390px;
    overflow-y: visible;
    padding: 0;
    margin-right: 185px;
}

.pay-rate-table td {
    min-height: 41px !important;
    height: 41px;
}

.pay-rate-table {
    margin-bottom: 3rem !important;
}


/* checkbox */

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
    background: #ccc;
}

.control input:checked~.control__indicator {
    background: #2aa1c0;
}

.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
    background: #0e647d;
}

.control input:disabled~.control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}

.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}

.control input:checked~.control__indicator:after {
    display: block;
}

.control--checkbox .control__indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.control--checkbox input:disabled~.control__indicator:after {
    border-color: #7b7b7b;
}

.job-updatetable th.headcolnew1 {
    left: 0;
    z-index: 1;
    background: #f1f1f1;
}

.job-updatetable th.headcolnew2 {
    left: 110px;
    z-index: 1;
    background: #f1f1f1;
}

.job-updatetable th.headcolnew3 {
    left: 210px;
    z-index: 1;
    background: #f1f1f1;
}

.job-updatetable th.headcol1 {
    left: 0;
    z-index: 1;
}

.job-updatetable th.headcol2 {
    left: 110px;
    z-index: 1;
}

.job-updatetable th.headcol3 {
    left: 210px;
    z-index: 1;
}

.job-updatetable th.rightfiix-tbl1 {
    right: 110px;
    z-index: 1;
}

.job-updatetable th.rightfiix-tbl2 {
    right: 0;
    z-index: 1;
}

.job-updatetable th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
}

.bckflrgt {
    background: #f1f1f1 !important;
}

.swms-big-tb-scroll .job-leftfixed1 {
    overflow-x: scroll;
}

.hed-newddsds {
    height: 137px;
    background: #f1f1f1;
}

.payrollhed-newddsds {
    height: 85px;
    background: #f1f1f1;
}

.pay-rate-table tr:first-child {
    background: #f1f1f1;
}

.job-updatetable tr:first-child {
    background: #f1f1f1;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}


/* --------------PAY ROLL TABLE ------------- */

.pay-roll-table>tr:hover td {
    background: #f1f1f1;
}

.pay-roll-table th,
.pay-roll-table td {
    border: 1px solid rgba(45, 59, 72, 0.1);
    font-size: 13px;
    font-weight: 400;
}

.pay-roll-table>tbody>tr td:first-child {
    white-space: pre;
}

.pay-roll-table>thead>tr:nth-of-type(2) {
    color: #212529;
}

.pay-roll-table .table thead th {
    border-bottom: 1px solid #dee2e6;
}

.pay-roll-table tbody td:hover {
    background: rgba(45, 59, 72, 0.04);
}

.pay-roll-table thead th:first-child {
    white-space: nowrap;
}

.pay-roll-table thead th {
    white-space: nowrap;
}

.pay-roll-table td {
    /* min-height: 71px !important; */
    /* height: 41px; */
}

.pay-roll-table {
    margin-bottom: 3rem !important;
}

.pay-roll-table tr:first-child {
    background: #f1f1f1;
}

.pay-roll-table th,
.pay-roll-table td {
    border-bottom: 0;
    border-left: 0;
}


/* Customize the label (the container) */

.pay-roll-table {
    display: block;
    position: relative; 
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.pay-roll-table .custom-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.custom-checkbox-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}


/* On mouse-over, add a grey background color */

.pay-roll-table:hover input~.custom-checkbox-inner {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.pay-roll-table input:checked~.custom-checkbox-inner {
    background-color: #2196F3;
}


/* Create the checkmark/indicator (hidden when not checked) */

.custom-checkbox-inner:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.pay-roll-table input:checked~.custom-checkbox-inner:after {
    display: block;
}


/* Style the checkmark/indicator */

.pay-roll-table .custom-checkbox-inner:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/* ======================================================== */

.advance-srch-u-m input{
    border: 1px solid rgba(45, 59, 72, 0.08);
    color: #222;
    border-radius: 2px;
    height: 38px;
    padding: 5px 10px;
    margin: 0;
    font-weight: 400;
    width: 87%;
}
.advance-srch-u-m label {
    display: none;
}


.inline-sign>.signature-box>.upload-ur-sign>.sign-box{
    width: 100%;
}

.inline-sign>.signature-box{
    width: 100%;
    margin: unset;
}

.inline-sign>.signature-box>.upload-ur-sign{
    padding: unset;
    width: 100%;
}
.cursor-not-ant-checkbox .ant-checkbox-input{
    cursor: not-allowed;
}

.first-checkbox .cu-checkbox {
    float: left;
}

.swms-big-tb-scroll .public-DraftStyleDefault-block{
    margin: 0;
padding: 2px;
}

 .ant-select-dropdown.expand-dropdwn{
    min-width: 330px;
    max-width: 100%;

}

 .ant-select-dropdown.extra-expand-dropdwn{
    min-width: 450px;
    max-width: 100%;
 }

.first-checkbox .ant-checkbox-input{
    cursor: not-allowed;
}

.second-checkbox-not-allowed .ant-checkbox-input{
    cursor: not-allowed;
}
.second-checkbox-allowed .delete-bnt{
    cursor: pointer;
}

.lastChild .tr .td:last-child {
    width: auto !important;
    vertical-align: middle;
    text-align: left !important;
}

.scope-doc-book-calendar {
    margin-top:20px
}

.profile-icon-key > i:hover {
    cursor: pointer;
}

.report_to {
    width: 75px
}

.start_date .ant-calendar-picker-input.ant-input.ant-input-sm,
.finish_date .ant-calendar-picker-input.ant-input.ant-input-sm {
    width: 110px
}

.td.start_time #stickDatepicker .react-datepicker-wrapper .react-datepicker__input-container input,
.td.finish_time #stickDatepicker .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 65px;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px;
    font-size: 12.5px;
}

td div.jobinput76.date {
    width: 115px;
}

td div.jobinput76.date #stickDatepicker .ant-calendar-picker-input.ant-input {
    width: 110px !important;
}

.td.start_time, .td.start_date, .td.finish_date, .td.finish_time {
    padding: 0;
    text-align: left;
}

.staff-check-line-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.staff-check-list {
    max-height: 300px;
    overflow-y: scroll;
}

.staff-check-line-item .cu-checkbox label.ant-checkbox-wrapper {
    display: flex;
}

.required_location {
    text-align: center;
}

.timesheet-main-container .ant-table-wrapper table thead, .timesheet-main-container .ant-table-wrapper table td {
    white-space: nowrap !important;
}

.timesheet-filters-container,
.swms-activity-filters-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: baseline;
    margin: 1rem;
}

.timesheet-filters-container > fieldset,
.swms-activity-filters-container > fieldset {
    margin-left: 10px;
}

.timesheet-filter-button {
    margin-left: 10px;
    margin-top: 10px;
}

.client-filter-timesheets {
    max-width: 150px;
}

.popover-checkbox-filter button {
    border: 1px #eff0f1 solid;
    border-radius: 4px;
}

.timesheet-main-container .ant-table-wrapper table { 
    font-size: 13px;
}

.timesheet-main-container .ant-table-wrapper table td { 
    padding: 11px;
}

.timesheet-action-buttons {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.timesheet-cell-editable {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timesheet-cell-editable #stickDatepicker label{
    display: none
}

.timesheet-cell-editable #stickDatepicker .ccalnd-icon,
.timesheet-cell-editable #stickDatepicker .anticon.anticon-close-circle.ant-calendar-picker-clear {
    display: none;
}

.timesheet-cell-editable.jobinput76 input {
    padding: 1px;
}

.edit-line-item-timesheet i:hover {
    cursor: pointer;
}

button.ant-btn.timesheet-filter-button.ant-btn-primary[disabled] * {
    color: #aaa;
}

button.ant-btn.timesheet-filter-button.ant-btn-primary[disabled] {
    border-color: #f5f5f5;
}

.ant-tabs-tab-active.ant-tabs-tab {
    background-color: #eee;
}

.add-row-swms-bnt {
    z-index: 3000;
    /* float: right; */
    width: 100px;
    height: 30px;
    font-size: 14px;
    position: relative;
    margin-left: auto
}

.add-row-swms-bnt:hover {
    cursor: pointer;
}

.new-swms-table .ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word>div>div>label {
    display: none
}

.new-swms-table td.ant-table-row-cell-break-word>div>i {
    margin: 0 10px;
}

.new-swms-table td.ant-table-row-cell-break-word>div>i:hover {
    cursor: pointer;
}

.new-swms-table tr.ant-table-row>td.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word>div>label {
    display: none
}

.new-swms-table tr.ant-table-row>td.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word>div>div>div>input {
    height: 30px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid rgba(45, 59, 72, 0.08);
    color: rgba(45, 59, 72, 0.9);
    font-weight: 400;
    width: 100%;
    padding: 8px;
    font-size: 13px;
    min-width: 90px;
}

.new-swms-table tr.ant-table-row>td.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word>div>div>div>input:focus {
    box-shadow: 0 0 0 1px rgb(3 167 145 / 30%);
    outline: none;
}

.new-swms-table .ant-table-wrapper table { 
    font-size: 13px;
}

.new-swms-table .ant-table-wrapper table td { 
    padding: 14px;
}

.new-swms-table div.cell-validate-highlight>div>div>input {
    /* border: 1px solid #FFAE9B !important; */
    box-shadow: 0 0 0 1px #FFAE9B !important;
}

.new-swms-table td button.ant-btn.normal-bnt.ad-srch-btn.ad-srch-content {
    max-width: 150px;
}

.new-swms-table .ant-table-wrapper table tbody {
    white-space: nowrap !important;
}

.new-swms-table .ant-table-wrapper table thead {
    white-space: nowrap !important;
}

.main-container.swms-masters-container {
    padding: 20px 15px
}

.new-swms-table td.ant-table-row-cell-break-word>div>div>label {
    display: none
}

.rdw-editor-main {
    border: 1px solid #eff0f1;
    border-radius: 5px;
}

.controls-container .adal-task-tags.sf-form .ant-select-selection {
    height: 190px;
    max-height: 190px;
}

.main-form-fields>div>fieldset {
    flex-basis: 15%;
    flex-grow: 0;
}

.controls-container .rdw-editor-wrapper .rdw-editor-main {
    height: 122px;
}

.droppable-list-area>div>div {
    max-width: 80%
}

.add-activity-btn {
    width: 150px;
}

.controls-container .sf-card .sf-card-body div.info-btn.disable-dot-menu {
    margin-top: -7px;
}

.swms-activity-filters-container {
    /* max-width: 70vw;
    overflow-x: scroll; */
}

.swms-activity-filters-container > fieldset:not(:last-child) > button/* ,
.swms-activity-filters-container > fieldset:not(:nth-last-child(2)) > button */ {
    width: 9rem;
}

.swms-activity-filters-container > fieldset input {
    width: 6rem;
}

.ant-table-thead>tr>th {
    background: #03A791;
    color: white;
}

.ant-btn.normal-bnt.ad-srch-btn.ant-popover-open.ad-srch-content>div {
    color: white !important;
}

.big-circle {
    height: 50px;
    width: 50px;
    background: white;
    border-radius: 50%;
}

.rbc-event-content {
    font-size: 0.9rem;
    padding: 0 5px;
    letter-spacing: 0.25px;
    width: 100% !important;
    max-width: 100% !important;
}

.ant-tabs-nav-scroll {
    text-align: left;
}
/* .swms-table {
    border: 1px solid #dee2e6;
} */

.staff-check-list .staff-check-line-item span.ant-checkbox {
    padding-top: 4px !important;
}

.allocation-actions-form .td.start_time label,
.allocation-actions-form .td.start_date label,
.allocation-actions-form .td.finish_date label,
.allocation-actions-form .td.finish_time label {
    display: none;
}

.allocation-actions-form .td.start_time,
.allocation-actions-form .td.start_date,
.allocation-actions-form .td.finish_date,
.allocation-actions-form .td.finish_time {
    vertical-align: middle;
}

.allocation-actions-form .td.start_date,
.allocation-actions-form .td.finish_date {
    width: 150px;
}

.allocation-actions-form .td.start_time,
.allocation-actions-form .td.finish_time {
    width: 90px;
}

.allocation-actions-form .start_date .ant-calendar-picker-input.ant-input.ant-input-sm,
.allocation-actions-form .finish_date .ant-calendar-picker-input.ant-input.ant-input-sm {
    width: 120px
}

.popover-width-limit button {
    width: 120px;
}

.allocation-timesheet-table-button {
    background: none;
    border: 1px solid #03a791;
    border-radius: 2rem;
    width: 5rem;
    padding: 2px;
    color: #03a791;
    height: 1.5rem;
}

.allocation-timesheet-table-button.allowance-green:hover {
    background: #03a791;
    border: 1px solid #03a791;
    color: white;
    cursor: pointer;
}

.allocation-timesheet-table-button.allowance-red {
    border: 1px solid #d0021b;
    color: #d0021b;
}

.allocation-timesheet-table-button.allowance-red:hover {
    background: #d0021b;
    border: 1px solid #d0021b;
    color: white;
    cursor: pointer;
}

.timesheet-allowance-update-modal {
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
}

.timesheet-allowance-update-modal fieldset {
    margin-right: 4rem;
    width: 10rem;
}

.red-status-dot {
    background: #D0021B;
}

.shift-status {
    background: none;
    border: 1px solid #03A791;
    border-radius: 2rem;
    min-width: 10rem;
    padding: 5px 20px;
    color: #03a791;
    height: 1.5rem;
}

.shift-status.notified {
    background: #ffa500;
    border: 1px solid #ffa500;
    color: white;
}

.shift-status.accepted {
    background: #5CBBFF;
    border: 1px solid #5CBBFF;
    color: white;
}

.shift-status.declined {
    background: #d0021b;
    border: 1px solid #d0021b;
    color: white;
}

.shift-status.started {
    background: #7ED321;
    border: 1px solid #7ED321;
    color: white;
}

.shift-status.finished {
    background: #4A4A4A;
    border: 1px solid #4A4A4A;
    color: white;
}

.timesheet-row-approved {
    background: none;
    border: 1px solid #03A791;
    border-radius: 2rem;
    color: #03a791;
    display: block;
    width: inherit;
    text-align: center;
    padding: 3px;
}

.timesheet-row-approved.approved-red {
    border: 1px solid #D0021B;
    color: #D0021B;
}

.allocation-timesheet-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EEEEEE;
    border-radius: 10px;
    padding: 10px;
}

.allocation-timesheet-header > div {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.allocation-timesheet-header .view-text-value>label {
    display: flex;
    font-size: 14px;
}

.allocation-timesheet-header .view-text-value>label>span {
    margin-right: 2px;
    font-weight: bold;
}

.allowances-modal-header {
    display: flex;
    flex-flow: row wrap;
    background: #E4E4E4;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.allowances-modal-header>fieldset{
    margin-right: 2rem;
    width: 10rem;
}

.react-datepicker-wrapper>div.react-datepicker__input-container>input[type="text"]:disabled {
    cursor: not-allowed;
    background: #eeeeee;
}

span.ant-calendar-picker>div>input.ant-input-disabled {
    background: #eeeeee;
}

.timesheet-main-container .timesheet-filters-container {
    margin-top: 30px;
    margin-bottom: -10px;
    min-width: 60vh;
}

.job-details-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
    margin: 5px;
}

.job-details-tabs-content .ant-tabs-bar.ant-tabs-top-bar{
    margin-bottom: -4px;
}

.fixed-width-8 {
    min-width: 12rem;
    max-width: 12rem;
}

button:focus, button.bnt:focus  {
    outline: none !important;
}

.job-calendar-v2-container .rbc-event,
.job-board-calendar-container .rbc-event,
.booking-calendar-container .rbc-event,
.job-calendar-v2-container .rbc-event.rbc-event-allday,
.job-board-calendar-container .rbc-event.rbc-event-allday,
.booking-calendar-container .rbc-event.rbc-event-allday,
.job-calendar-v2-container .rbc-event.rbc-event-allday.rbc-event-continues-after,
.job-board-calendar-container .rbc-event.rbc-event-allday.rbc-event-continues-after,
.booking-calendar-container .rbc-event.rbc-event-allday.rbc-event-continues-after {
    padding: 0 0 0 2px;
}

.job-calendar-v2-container .rbc-event-content,
.job-board-calendar-container .rbc-event-content,
.booking-calendar-container .rbc-event-content {
    padding: 1px 0 0 2px;
    font-size: 0.7825rem;
}

.job-calendar-view.job-calendar-view-expand.allocations-shifts-calendar .rbc-day-slot .rbc-event {
    border: none;
}

.extended-width-multiselect {
    min-width: 150px;
}

.extended-width-multiselect>div>label~div>div>div>div>div {
    font-size: 11px;
}

.job-doc-custom-collapse-panel .ant-collapse-content-box {
    padding: 0;
}

.job-doc-custom-collapse-panel .ant-collapse-header .ant-collapse-arrow {
    color: #000 !important;
}

/* HTML/CSS styles for Job Doc */
.table-fixed-column {
    position:sticky;
    right: -1px;
    background-color: #fff;
}

span.task-status-icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
    padding-bottom: 2px;
}

span.approved-task-color {
    color: #00b050;
    font-weight: 500;
}

span.not-approved-task-color {
    color: #f00;
    font-weight: 500;
}

.table-centered-selection .ant-table-selection-column {
    vertical-align: middle;
}

.job-board-tabs .ant-tabs-tab {
    padding: 0px !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #FAFAFA;
    border: none !important;
    border-radius: 10px !important;
    color: #362148 !important;
    font-weight: 400 !important;
    height: auto !important;
    margin: 0px 5px !important;
}

.job-board-tabs .ant-tabs-tab .disable {
    color: #ccc !important;
}

.job-board-tabs .ant-tabs-nav-container {
    height: auto !important;
}
.job-board-tabs .ant-tabs-tab.ant-tabs-tab-active {
    height: auto !important;
    background: #362148 !important;
    color: #E8FF84 !important;
    font-weight: 400 !important;
}

.job-board-tabs .ant-tabs-nav .ant-tabs-tab .anticon {
    margin: 0px !important;
}

.job-board-tabs .ant-tabs-bar{
    border-bottom: 0px;
    margin-left: 10px;
}

.slider  {
    position: absolute;
    right: 0;
    background: #eee;
    padding-top: 5px;
    border-radius: 3px;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    z-index: 14;
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
    position: absolute;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    position: fixed;
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
}

.ant-collapse-item-disabled.job-board-custom-panel>.ant-collapse-header{
    cursor: default !important;
}

.job-board-panel-part>.ant-collapse-header{
    cursor: default !important;
    border-top: 1px solid #2b689c !important;
    margin: 0px 5px;
}

.job-board-panel-part:last-child {
    margin-bottom: 0px;
}

button.job-board-text-button {
    background-color: #16abff;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 6px;
    height: auto;
    cursor: pointer;
}

button:disabled.job-board-text-button, button[disabled].job-board-text-button {
    background-color: #ccc;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 6px;
    height: auto;
    cursor: not-allowed;
}

.text-booked {
    color: #45B0E1 !important;
}

.text-allocated {
    color: #4a90e2 !important;
}

.text-outsourced {
    color: #5cbbff !important;
}

.text-started {
    color: #7ed321 !important;
}

.text-completed {
    color: #92D050 !important;
}

.text-inprogress {
    color: #FFC000 !important;
}

.text-signedoff {
    color: #7b1fa2 !important;
}

.text-invoiced {
    color: #ee82ee !important;
}

.text-cancelled {
    color: #d0021b !important;
}

button[disabled].circular-icon-button, button[disabled].circular-icon-button i,
button:disabled.circular-icon-button, button:disabled.circular-icon-button i{
    color: #ccc !important;
    border-color: #ccc !important;
    cursor: not-allowed;
}

button[disabled].circular-icon-button:hover, button:disabled.circular-icon-button:hover{
    background: none !important;
}

.job-board-menu-dropdown > li.ant-dropdown-menu-item {
    font-size: 13px;
    padding: 2px 10px;
}
.job-board-menu-dropdown > li.ant-dropdown-menu-item:hover {
    background: #2b689c;
}
.job-board-menu-dropdown > li.ant-dropdown-menu-item-divider {
    display: none;
}

.custom-acr-searchbar input {
    padding: 6px;
    font-size: 14px;
    border: 1px solid #135F9B;
    float: left;
    width: calc(100% - 45px);
    background: #fff;
    border-radius:4px 0 0 4px;
    margin-bottom:20px;
    outline: none;
}

.custom-acr-searchbar button {
    float: left;
    width: 40px;
    height: 40px;
    padding: 10px;
    background: #135F9B;
    color: white;
    font-size: 21px;
    border: 1px solid #135F9B;
    border-left: none;
    cursor: pointer;
    margin-left: -26px;
    border-radius: 50%;
    line-height: 0px;
    position: relative;
    top: -2px;
}

.custom-acr-searchbar button:hover {
    background: #0b7dda;
    border: 1px solid #135F9B;
}


.custom-acr-searchbar::after {
    content: "";
    clear: both;
    display: table;
}

ul.custom-acr-pagination li{
    color: #135f9b;
    background: #fff;
}

ul.custom-acr-pagination li:hover, ul.custom-acr-pagination .ant-pagination-prev:hover .ant-pagination-item-link, ul.custom-acr-pagination .ant-pagination-next:hover .ant-pagination-item-link{
    border-color: #135f9b;
}

ul.custom-acr-pagination li.ant-pagination-item-active {
    background: #135f9b;
    color: #fff;
    border-color: #135f9b;
}

.custom-htmlBox ul > li, .custom-htmlBox ol > li {
    list-style-type: initial;
}

.custom-signoff-table{
    margin-bottom: 0px !important;
}
.custom-signoff-table > tbody > tr > td {
    font-size: 13px !important;
    padding: 5px;
}
.custom-signoff-table > thead > tr > th {
    font-size: 14px !important;
    color: #337ab7 !important;
    font-weight: 400 !important;
    padding: 10px 5px !important;
    vertical-align: middle;
}
.custom-signoff-table > thead > tr {
    background-color: #f8f8f8;
}

.custom-single-datepicker{
    width: -webkit-fill-available;
    width: -moz-available;
}

.custom-single-datepicker:focus {
    box-shadow: none;
}

.custom-single-datepicker input{
    padding: 1px 2px;
    border: 0px;
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
    height: 22px;
    color: #000;
    outline: none !important;
    width: -webkit-fill-available;
    width: -moz-available;
    border-radius: 0px;
    font-size: 13px !important;
}
      
.custom-single-datepicker:hover input, .custom-single-datepicker:focus input, .custom-single-datepicker input:focus{
    border-bottom-width: 1px !important;
    border-bottom-color: #16abff !important;
    border-color: #16abff !important;
    box-shadow: none !important;
    outline: none !important;
}

.custom-single-datepicker .ccalnd-icon {
    top: 0px !important;
    color: #337ab7 !important;
}

.custom-single-datepicker .ant-calendar-picker-clear {
    right: 28px !important;
    top: 10px !important;
    width: fit-content !important;
    background: transparent !important;
}

.vh-100 {
    height: 100vh !important;
}

.text-break {
    word-break: break-word!important;
    overflow-wrap: break-word!important;
}

ol.signOff-conditions-list > li {
  list-style: auto !important;
}

.user-mgmt-search input.ant-input:hover,
.user-mgmt-search input.ant-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #03A791;
}

.user-mgmt-search input.ant-input {
  border-color: #03A791;
}
