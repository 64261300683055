.profile-container *,
.profile-modal-container * {
  font-family: 'Poppins', sans-serif;
  text-align: initial;
}

.profile-container .fa,
.profile-modal-container .fa {
  font-family: FontAwesome !important;
}

.profile-container {
  background-color: #F0EFEE;
  padding: 16px;
  height: calc(100vh - 70px);
  border-radius: 50px;
}

.profile-v2-date-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.profile-v2-date-picker .ant-input {
  background-color: #eaeaea;
  height: 34px;
  border: none;
  border-radius: 8px !important;
  font-size: 12px;
}

.profile-v2-date-picker .ant-calendar-picker-clear {
  background-color: #eaeaea;
}

.profile-picture button.ant-btn {
  background: #90bf00 !important;
  border: 0;
  width: 25px;
  height: 25px;
  font-size: 12px;
  text-align: center;
  color: #000 !important;
  padding: 0;
  position: relative;
  top: 32%;
  left: 30%;
  border-radius: 50px;
}

.profile-picture button.ant-btn i.fa {
  color: #fff !important;
}

.profile-container * {
  font-family: 'Poppins', sans-serif;
}

.profile-v2-tabs .ant-tabs-tab {
  padding: 0px !important;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #FAFAFA;
  border: none !important;
  border-radius: 10px !important;
  color: #362148 !important;
  font-weight: 400 !important;
  height: auto !important;
  margin: 0px 5px !important;
}

.profile-v2-tabs .ant-tabs-nav-container {
  height: auto !important;
}

.profile-v2-tabs .ant-tabs-tab.ant-tabs-tab-active {
  height: auto !important;
  background: #362148 !important;
  color: #E8FF84 !important;
  font-weight: 400 !important;
}

.profile-v2-tabs .ant-tabs-nav .ant-tabs-tab .anticon {
  margin: 0px !important;
}

.profile-v2-tabs .ant-tabs-bar {
  border-bottom: 0px;
  margin-left: 10px;
}