.Container {
  display: flex;
  flex-direction: column;
  max-height: 44rem;
  overflow: auto;
  overflow-y: auto;
}

.SWMSList {
  padding: 10px;
  margin: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.ListItem {
  margin-bottom: 5px;
  background: #efefef;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 2.5px;
  line-height: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.DropArea {
  min-height: 25px;
  max-height: 15vh;
  overflow: auto;
  overflow-y: auto;
}

.AddButton {
  height: 13px;
  background: none;
  color: #333;
  padding: 0;
  margin-top: -25px;
  margin-right: -25px;
  float: right;
}

.AreaHeader {
  text-align: "center";
  margin-bottom: 10px;
  font-weight: 600;
  color: "#777";
}

.AddButton:hover {
  color: #03A791;
}