.cs-single-datepicker,
.cs-range-picker {
  width: -webkit-fill-available;
  width: -moz-available;
}

.cs-single-datepicker:focus,
.cs-range-picker:focus {
  box-shadow: none;
}

.cs-single-datepicker input,
.cs-range-picker .ant-calendar-picker-input .ant-input {
  padding: 1px 2px !important;
  border: 0px;
  box-shadow: none !important;
  border-bottom: 1px solid #ccc;
  height: 22px;
  color: #000;
  outline: none !important;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 0px;
  font-size: 13px !important;
}

.cs-single-datepicker:hover input,
.cs-single-datepicker:focus input,
.cs-single-datepicker input:focus,
.cs-range-picker:hover .ant-input,
.cs-range-picker:focus .ant-input,
.cs-range-picker .ant-input:hover,
.cs-range-picker .ant-input:focus {
  border-bottom-width: 1px !important;
  border-bottom-color: #c0b4ff !important;
  border-color: #c0b4ff !important;
  box-shadow: none !important;
  outline: none !important;
}

.cs-single-datepicker .ccalnd-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #135f9b !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.cs-single-datepicker .ant-calendar-picker-clear {
  right: 28px !important;
  top: 50% !important;
  margin: 0px !important;
  width: fit-content !important;
  background: transparent !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.cs-single-timepicker-wrapper input {
  padding: 1px 2px !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  box-shadow: none !important;
}

.cs-single-timepicker-wrapper .react-datepicker-wrapper {
  display: block !important;
}

.cs-single-timepicker-wrapper input:focus {
  border-bottom-width: 1px !important;
  border-bottom-color: #135f9b !important;
  border-color: #135f9b !important;
  box-shadow: none !important;
  outline: none !important;
}

.cs-single-datepicker .ant-calendar-picker-icon {
  z-index: 0;
}

.cs-datepicker-dropdown .ant-calendar-today .ant-calendar-date {
  color: #c0b4ff;
  border-color: #c0b4ff;
}

.cs-datepicker-dropdown .ant-calendar-today.ant-calendar-disabled-cell .ant-calendar-date {
  color: #00000040;
  border-color: #f5f5f5;
}

.cs-datepicker-dropdown .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.cs-datepicker-dropdown .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.cs-datepicker-dropdown .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.cs-datepicker-dropdown .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.cs-datepicker-dropdown .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  color: #fff !important;
  background: #c0b4ff;
}

.cs-datepicker-dropdown .ant-calendar-selected-day .ant-calendar-date,
.cs-datepicker-dropdown .ant-calendar-date:hover,
.cs-datepicker-dropdown .ant-calendar-month-panel-month:hover,
.cs-datepicker-dropdown .ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-decade:hover,
.cs-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #c0b4ff;
}

.cs-datepicker-dropdown .ant-calendar-selected-day .ant-calendar-date {
  background: #c0b4ff;
  color: #fff;
}

.cs-datepicker-dropdown .ant-calendar-in-range-cell::before {
  background: #c0b4ff85;
}

.cs-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background-color: #c0b4ff;
}

/* .cs-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date, */
.cs-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date,
/* .cs-datepicker-dropdown .ant-calendar-active-week .ant-calendar-cell .ant-calendar-date, */
.cs-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week .ant-calendar-cell .ant-calendar-date {
  color: #fff;
}

.cs-datepicker-dropdown input {
  text-align: center;
}

.cs-datepicker-dropdown .ant-calendar-range-middle {
  padding: 0px;
}

.cs-full-input input {
  border: 1px solid #ccc !important;
  height: 32px !important;
  padding: 4px 11px !important;
  border-radius: 4px !important;
  outline: none !important;
}

.cs-full-input:hover input,
.cs-full-input:focus input,
.cs-full-input input:focus {
  border-color: #135f9b !important;
  outline: none !important;
}

.cs-full-input:focus input,
.cs-full-input input:focus {
  box-shadow: 0 0 0 2px #135F9B33 !important;
}

input.ant-input.cs-input:hover {
  border-color: #135f9b;
}

input.ant-input.cs-input:focus {
  box-shadow: 0 0 0 2px #135f9b33;
  border-color: #135f9b;
}

input.ant-input.ant-input-disabled.cs-input {
  background-color: #e7e7e7;
}

.stc-table .ant-table-placeholder {
  z-index: 0;
}

.cs-table {
  border-collapse: separate;
  border-spacing: 0px 3px;
}

.cs-table tbody td,
.cs-table thead th {
  font-size: 14px !important;
  border-color: #ccc !important;
}

.cs-table th {
  border: none !important;
}

.cs-table tbody td {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.cs-table td:first-child {
  border-left: 1px solid;
}

.cs-table td:last-child {
  border-right: 1px solid;
}

/* cs-pagination */
.cs-pagination .ant-pagination-item-active {
  background: #c0b4ff !important;
}

.cs-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
.cs-pagination .ant-pagination-next:focus .ant-pagination-item-link,
.cs-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.cs-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.cs-pagination .ant-pagination-item:focus,
.cs-pagination .ant-pagination-item:hover,
.cs-pagination .ant-pagination-item-active {
  border-color: #c0b4ff;
}

.cs-radio .ant-radio-checked .ant-radio-inner {
  border-color: #135f9b !important;
}

.cs-radio .ant-radio-checked::after {
  border: 1px solid #135f9b !important
}

.cs-radio .ant-radio-inner::after {
  background-color: #135f9b !important;
}

.cs-radio .ant-radio-wrapper:hover .ant-radio,
.cs-radio .ant-radio:hover .ant-radio-inner,
.cs-radio .ant-radio-input:focus+.cs-radio .ant-radio-inner {
  border-color: #135f9b !important;
}

.cs-radio .ant-radio-input:focus+.cs-radio .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px #135F9B33 !important;
  box-shadow: 0 0 0 3px #135F9B33 !important;
}

.cs-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #135f9b;
  border-color: #135f9b;
}

.cs-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.cs-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.cs-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #135f9b;
}

.cs-checkbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff !important;
}

.cs-popconfirm-okay-button.ant-btn-primary:hover,
.cs-popconfirm-okay-button.ant-btn-primary:focus {
  background-color: #135f9b;
  border-color: #135f9b;
}

.cs-popconfirm-cancel-button>span {
  color: red !important;
}

.cs-popconfirm-cancel-button:focus,
.cs-popconfirm-cancel-button:active {
  border: none !important;
  box-shadow: none !important;
}

.cs-error-boundary-text {
  text-align: initial;
  color: red;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.cs-error-boundary-button {
  background-color: initial;
  border: 1px solid #ccc;
  color: #362148 !important;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  margin: 20px 0px;
}