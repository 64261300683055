.ContainerPage {
  background: #EAEAEA;
  border-radius: 35px;
  padding: 12px;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
}

.PageBackground {
  background-color: #fff;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
  border-radius: 25px;
  padding: 8px 0px;
}

.PageHeading {
  font-size: 20px !important;
  font-weight: 600;
}

.BackButton {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 0px 5px;
}

.AddButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
}

.AddButton:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.StatusChip {
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #c0b4ff;
  color: #000;
  font-weight: 400;
}

.FilterButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
}

.FilterButton:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.ResetButton {
  background-color: initial;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}

.PublishButton {
  background-color: #b9d658;
  color: #362148 !important;
  border-radius: 50px 0 0 50px;
  padding: 15px;
  font-size: 12px !important;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 17px !important;
}

.PublishButton:disabled,
.PublishButtonIcon:disabled {
  background-color: #ccc;
  color: #000;
  cursor: not-allowed;
}

.PublishButtonIcon {
  background-color: #b9d658;
  color: #362148 !important;
  border-radius: 0 50px 50px 0;
  padding: 15px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 17px !important;
  border-left: 1px solid #36214859;
}

.GreenCell {
  background-color: #b9d658;
}

.PurpleCell {
  background-color: #c0b4ff;
  text-align: center !important;
}

.ModalCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RosterFormLabel {
  font-size: 12px;
  color: #362148;
}

.RosterFormField {
  background: #EAEAEA !important;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
  font-family: 'Poppins', sans-serif;
}

.RosterFormField:disabled {
  color: #999999 !important;
}

.ModalHeading {
  font-size: 14px !important;
  font-weight: bold;
  color: #362148;
}

.BtnNextTab {
  background-color: #362148;
  color: #90bf00 !important;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 14px;
  border: 0;
  border: 1px solid #362148;
  font-weight: 500;
  line-height: 1;
}

.BtnNextTab:hover {
  background-color: transparent;
  color: #362148 !important;
  border: 1px solid #362148;
}

.BtnNextTab:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
  border: none !important;
}

.ShiftBox {
  background-color: #fc544e;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
}

.ShiftBox02 {
  background-color: #e8ff84;
  padding: 5px 20px;
  text-align: center;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
}

.ShiftBox03 {
  background-color: #b9d658;
  padding: 5px 20px;
  text-align: center;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
}

.ShiftHead {
  background-color: #c0b4ff;
  text-align: center;
  font-weight: 700;
  padding: 8px 0;
}

.ShiftStaff {
  background-color: #dbd2ff;
  text-align: center;
  font-weight: 700;
  padding: 8px 0;
}

.ShiftBoxes {
  background-color: #a9c845;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 25%;
  width: 40%;
  padding: 49px;
}

.ShiftBox05 {
  background-color: #c0b4ff;
  padding: 5px 20px;
  text-align: center !important;
  border-radius: 5px;
  font-weight: 400;
  margin: 2px;
  width: 100%;
  flex: 0 0 16%;
}

.ShiftsTable {
  overflow: hidden auto;
  height: calc(100vh - 310px);
}

.noHover:hover {
  background-color: #fff !important;
}

.StaffTiming {
  flex: 0 0 12%;
  padding: 2px;
}

.StaffOptions {
  padding: 5px 10px;
  border-radius: 5px;
  column-gap: 10px;
  display: flex;
  align-items: center;
}

.StaffOptionIcon {
  width: 18px;
  display: block;
}

.ShiftHoursCell {
  height: 30px;
  padding: 0px !important;
  box-sizing: border-box;
  vertical-align: middle !important;
}

.TopAlignCell {
  vertical-align: top !important;
}

.HourlyShiftBlock {
  position: absolute;
  width: 150px;
  border-radius: 15px;
  box-shadow: 0px 1px 5px 1px #999;
}

.HourCellShiftName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
}

.DisabledButton {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}

.erroredText {
  color: #ff000096;
}

.NotificationSentIcon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: orange;
}

.NotificationPendingIcon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: #fff;
}

.LegendBtn {
  color: #000;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 7px;
  padding: 4px 20px;
  height: auto;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.LegendWrapper {
  display: flex;
  align-items: center;
  column-gap: 7px;
}

.LegendIndicator {
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.isExpanded {
  background-color: #f5f5f5 !important;
}

.IconBtn {
  height: auto;
  padding: 0px !important;
}

.SuccessIcon {
  color: #90bf00 !important;
}

.TableText {
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
}