.centeredCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacedCell {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.spacedBetweenCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.validation {
  position: absolute;
  color: red;
  font-size: 11px;
  letter-spacing: 0.5px;
  margin-left: 5px;
  margin-top: -1.5px;
}

.spacedBetweenCell>i {
  transition: 0.3s
}

.spacedBetweenCell>i:hover {
  transform: scale(1.2);
  transition: 0.3s
}
