.FetchFieldsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.ShiftCreation {
  display: flex;
  flex-flow: row wrap;
}

.ShiftCreation fieldset{
  /* width: 10rem; */
  margin-right: 1rem;
}

/* .ShiftSettings{
  margin-left: -5rem;
} */

.DichotomyFields {
  width: 100%
}

.DichotomyFields fieldset {
  width: 7.5rem;
  margin-right: 7.5px;
}

.GetStaffButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.ActionButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.FormContainer {
  padding: 5px
}

.CopyValidate {
  margin: 1rem 0;
  padding: 1rem 2rem;
}

.CopyLabel {
  margin-left: -1rem;
}

.CopyFields {
  display: flex;
  flex-direction: row;
}

.CopyFields fieldset {
  margin-right: 1rem;
}

.ActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: -12px;
}

.tableHeader {
  margin-left: 5px;
}

.FormTitle {
  margin: 0px;
  font-size: 1.2rem;
}

.GetStaffButtonLeft {
  margin-right: auto;
}

.FormBackButton {
  padding: 0px 5px 0px 0px;
}