.cs-table {
  border-collapse: separate;
  border-spacing: 0px 3px;
  margin-bottom: 0 !important;
}

.cs-table tbody td,
.cs-table thead th {
  font-size: 15px !important;
  border-color: #ccc !important;
}

.cs-table th {
  border: none !important;
  font-weight: 700;
}

/* .cs-table tbody td {
  border-bottom: 1px solid;
  padding: 6px;
  vertical-align: middle;
} */

.cs-table td:first-child {
  border-left: 1px solid;
}

.cs-table td:last-child {
  border-right: 1px solid;
}

.cs-table.cs-table-v2 tbody td {
  border-top: 0px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
  padding: 6px;
  vertical-align: middle;
}

.txt-head {
  font-size: 26px !important;
}

.additional_field_form label {
  margin-bottom: 0 !important;
}

.cs-timesheets-container * {
  font-family: 'Poppins', sans-serif;
  text-align: initial;
}

.cs-timesheets-container .fa {
  font-family: 'FontAwesome' !important;
}

.cs-timesheets-container .material-icons {
  font-family: 'Material Icons' !important;
}

.cs-timesheets-container {
  background-color: #ffffff;
  padding: 16px;
  height: calc(100vh - 50px);
}

.swms-table-v2 thead>tr>th {
  background-color: #fff !important;
  color: #362148;
}

.cs-table-v2 .ant-table-thead > tr > th, .cs-table-v2 .ant-table-tbody > tr > td {
  padding: 6px !important;
}


.cs-table-v2 .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: rgba(0,0,0,.075) !important;
}

.cs-switch .ant-switch-checked {
  background-color: #c0b4ff !important;
}