.JobBoardHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.LeftEntities {
  display: flex;
  flex-flow: column;
}

.Tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #d0f1ed;
  height: 2rem;
  font-size: 16px;
  font-weight: normal;
  color: white;
  margin-bottom: 0.5rem;
}

.gridContainer {
  width: 70vw;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.gridItem {
  font-size: 14px;
  border: 1px solid #03A791;
  border-radius: 5px;
  padding: 7.55px;
  text-align: left;
  margin-bottom: 30px;
  margin-right: 20px;
}

.JobBoardHeaderContainer .LeftEntities div label, .SingleSiteItem label  {
  font-weight: bold;
}

.JobBoardHeaderContainer .LeftEntities div span {
  margin-left: 1rem;
}

.gridContainer .gridItem label {
  font-weight: bold;
}

.gridContainer .gridItem span {
  margin-left: 5px;
}

.MiddlePanelWrapper {
  display: flex;
  justify-content: space-between;
}

.SingleSiteItem {
  margin-bottom: 10px;
}

.SingleSiteItem > div {
  margin-bottom: -10px;
}