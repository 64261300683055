.MainFormFieldsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
}

.MainFormFieldsContainer fieldset/* ,
.ControlsContainer fieldset */ {
  margin: 10px 30px 10px 0;
}

.FormFieldsRow {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
}

.FormFieldsRow fieldset,
.ControlsContainer > fieldset {
  /* width: 200px; */
}

.ControlsContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;

}

.Dropdowns {
  /* width: 100px; */
}

.TextEditor {
  /* margin: 10px 30px; */
  width: 300px;
  height: 100%;

}

.TextEditor > label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.Dropdowns {
  flex-basis: 15%;
  flex-grow: 0;
}
.rdw-editor-main{
  background-color: #fff;
}
