@charset "UTF-8";
*, ::after, ::before {
    box-sizing: border-box;
}
body {
    margin: 0;
    /* font-family: 'Lato',sans-serif; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.sf-card {
    box-shadow: 0 2px 4px 2px rgb(45 59 72 / 10%);
    padding:15px ;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 5px;
    display: inline-block;
}
.sf-card-wrap{
    box-shadow: none;
    padding: 50px 40px 5px;
}
.left-box{
     float: left;
}
.right-box{
    float: right;
    padding: 11px 20px;
}
ul.task-list{
    font-size: 15px;
    padding: 0px;
    margin: 0px;
}
ul.task-list span{
    font-weight: bold;
} 
ul.task-list li{
    list-style: none;
}
ul.job-summary-list {
    display: inline-block;
    width: 100%;
    padding: 0px;
}
ul.job-summary-list li {
    float: left;
    text-align: left;
    background-color: #f3f3f3;
    padding: 10px;
    font-size: 13px;
    color: #000;
    width: 13%;
    list-style: none;
    line-height: 21px;
}
ul.job-summary-list li span {
    display: block;
}
ul.job-summary-list li + li {
    margin-left: 1%;
}
.col-6{
    width: 50%;
    float: left;
}
.tasks-completed-box {
    text-align: left;
    background-color: #f3f3f3;
    padding: 10px;
    font-size: 13px;
    color: #000;
    width: 100%;
    display: inline-block;
    line-height: 21px;
}
.bg-white {
    background-color: #fff!important;
}
.pt-0{ 
    padding-top: 0px !important;
}
.sf-card h4.card-heading{
    margin: 0px;
    padding: 15px;
    font-size: 19px;
    font-weight: 400;
    color: #009999;
    border-bottom: 1px solid rgba(45, 59, 72, 0.06);
}
ul.documents-list {
    display: inline-block;
    width: 100%;
    padding: 0px;
}
ul.documents-list li {
    width: 32.33%;
    margin: 0.5%;
    float: left;
    list-style: none;
    text-align: left;
}
ul.documents-list li a {
    display: inline-block;
    padding: 13px 20px;
    background-color: #f2f2f2;
    width: 100%;
    color: #03A791;
     text-decoration: none;
    border-radius: 5px;
}
ul.documents-list li i {
    float: right;
    color: #000;
    font-size: 20px;
}
ul.documents-list li a:hover {
    color: #fff;
    background-color: #03a791;
}
ul.documents-list li a:hover i {
    color: #fff;
}
.ab-image-slide {
    display:inline-block;
    position: relative;
    width: 100%;
    padding:50px 100px 0px;
    margin-bottom: 40px;
}
.sld-pic {
    float: left;
    width: 50%;
    overflow: hidden;
    position: relative;
    border: 0.095rem solid #5c5c5c;
    height: 450px;
    display: flex;
}
.abpic-txt {
    position: absolute;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 14px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    bottom: 0;
    left: 0;
}
.abpic-txt.loc-txt {
    left: 50%;
    background: rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.before-image.sld-pic .abpic-txt {
    left: auto;
    right: 0;
}
.text-after-before{
    font-size: 16px;
    font-weight: 400;
    margin: 14px 0px 0px 20px;
    color: #4a4a4a;
}
.job-pre-tag {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding: 6px 12px;
    letter-spacing: 0.5px;
}
ul.table li.fisrt-box{
    float: left;
    width: 20%;
    position: relative;
    list-style: none;
}
ul.table li.second-box{
    float: left;
    width: 50%;
    position: relative;
    list-style: none;
    padding-top: 34px;
}
ul.table li.third-box{
    float: left;
    width: 30%;
    position: relative;
    list-style: none;
}
.ab-images .loc-pic {
    height: 100px;
    width: 140px;
}
.loc-pic {
    height: 130px;
    width: 200px;
    overflow: hidden;
    border-radius: 4px 0 0 4px;
    position: relative;
    float: left;
}
.ab-images .loc-pic {
    height: 130px;
    width: 185px;
}
.ab-images .loc-pic:last-child {
    border-radius: 0 4px 4px 0;
    margin-left: 1px;
}
ul.table li h4{
    margin: 0px 0px 5px;
    font-size: 26px;
    line-height: 1;
    font-weight: 400;
}
.table-box ul.table {
    align-items: center;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    border-radius: 4px;
    margin-bottom: 20px;
    transition: 0.5s all;
    display: inline-block;
    width: 100%;
    padding:0px;
    margin: 0px 0px 15px;
}
.table-box ul.table:hover {
    background: rgba(45, 59, 72, 0.04);
    transition: 0.5s all;
}
ul.form-signOff li{
    list-style: none;
}
ul.form-signOff{
    margin-top: 40px;
}
ul.form-signOff li span:first-child{
    width: 200px;
    font-size: 15px;
    width: 17%;
    color: #7f7f7f;
     display:inline-block;
} 
ul.form-signOff li{
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
}
ul.form-signOff li span{
    float: left;
    width: 80%;
}
input{
    padding: 8px;
    font-size: 16px;
    width: 100%;
    display: inline-block;
}
.form-control.input1{
    width: 50%;
     float: left;
}
.form-control.input1{
    width: 50%;
     float: left;
} 
.form-control.input2{
    width: 48%;
     float: left;
     margin-left: 2%;
}  
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} 
.border-box{
    height: 100px;
    width: 480px;
    display: inline-block;
    margin-top: 10px;
    border: 1px dashed #ced4da;
}
ul.form-signOff li:last-child{
    margin-top: 30px;
}
ul.form-signOff li a{
    color:#008572;
    position: relative;
    left: 50px;
    bottom: 40px;
}