.sf-form{
    text-align: left;
}
.toolbox-talk .oh-cont{
    float: right;
}
.toolbox-talk .add-row-swms-bnt {
    width: 200px;
    height: auto;
    float: left;
}
.toolbox-talk .add-row-swms-bnt.btn-item{
    float: right;
}
.toolbox-talk .btn-reset{
    width: 90px;
    margin-left: 10px;
}
.TabsContainer_tabHeader__3fQuw {
    font-size: 1.2rem;
    float: left;
    margin-bottom: 1rem;
    margin-left: .1rem;
}

.toolbox-talk .sf-form select {
    padding: 2px 15px;
    color: #8b7575;
}
ul.search-list{
    float: left;
    width: 100%;
    margin:15px 0px 0px;
}
ul.search-list li{
    width: 11.5%;
    float: left;
    margin:0% 0.5%;
}
ul.search-list li.date-range{
    width: 23%;
}
ul.search-list li.date-range .date-input{
    width: 48%;
    min-width:auto;
    float: left;
}
ul.search-list li.date-range .date-input label{
    display: none;
} 
ul.search-list li.date-range .date-input + .date-input{
 margin-left:4%;
}
ul.search-list li.date-range label{
    width: 100%;
   }
ul.search-list li label{
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    display: inline-block;
}
.add-user-table tr th, .add-user-table tr td {
    border-top: none;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    vertical-align: top;
    transition: 0.5s all;
}
.table-border td{
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 10px;
}
.table-border th{
    border: 1px solid #dee2e6;
    vertical-align: bottom;
    border-collapse: collapse;
    font-weight: bold;
    border-bottom: 2px solid #dee2e6;
}
.table-border th, .table-border td {
        padding: .75rem;
        vertical-align: top;
        font-size: 14px;
        border-top: 1px solid #dee2e6;
        vertical-align: middle !important;
        text-align: left;
}
.w-100{
    width: 100%;
}
.btnw-50{
    width: 100px !important;
}
.bg-color{
    background-color: #03a791;
    color: #fff;
}
.table-border tr:nth-of-type(even) {
    background-color: rgba(0,0,0,.05);
}
.table-border .icons i{
    font-size: 16px;
    margin:0px 3px;
}
.section-heading{
    font-size: 15px;
    font-weight: bold;
    text-align: left;
}
/* .mt-2{
    margin-top: 10px !important;
}
.mb-2{
    margin-top: 10px !important;
} */
.multi-fields i{
    font-size: 20px;
}
.multi-field{
    width: 90%;
    float: left;
    margin-top: 5px;
}
.multi-field .remove-field{
    width: 12%;
    float: left;
    padding-left: 10px;
    padding-top: 6px;
}
.multi-field #input-add{
    width: 88%;
    float: left;
}
.multi-fields .add-field{
    float: left;
    margin-top: 10px;
    padding-left: 8px;
    width: 10%;
}
.multi-fields  .multi-field + .multi-field{
    margin-top: 05px;
}
.row-delete-btn a{
     font-size: 30px;
     color: #000;
}
.table-border .bg-white{
    width: auto !important;
    border: none !important;
     background-color: #fff;
}
.item-table td{
    width: 33%;
}
.comments-box{
    margin-top: 20px;
    margin-bottom: 20px;
}
.comments-box h4{
    margin-bottom: 15px;
}
.bottom-save-btn{
    text-align: right;   
}
.bottom-save-btn .bnt{
    display: inline-block;
}
.bottom-save-btn .bnt + .btn{
    margin-left: 20px;
}
ul.search-list li.float-right{
    float: right;
}
ul.search-list.hazard-report-list li{
    width: 24%;
}
.risk-rating{
    margin-top:20px;
    display: inline-block;
    width: 100%;
}
.risk-rating .bnt{
    border-radius: 50px;
    width: 100%;
}
.bnt:hover{
    background: #008572;
    color: #fff;
    border-color: #008572;
}
select:focus{
       box-shadow: 0 0 0 1px rgb(3 167 145 / 30%);
    outline: none;
}
a i{
    color: green;
}
.uplode-img span{
    width: 10%;
    font-size: 40px;
    margin-right: 2%;
    position:  relative;
    top: 3px;
    float: left;
}
.uplode-img input{
    border: 1px solid #ddd;
    padding: 10px;
    width: 88%;
    float: left;
    border-radius: 5px;
    background-color: #ddd;
}
.sf-card-wrap{
     display: inline-block;
     width: 100%;
}
.col-hazard-review .table{
    width: 570px;
}
/********************************************************/
@media(max-width:1100px){
    ul.search-list li{ width: 24%;}
    .table-responsive .table{ width: 820px !important;}
    .table-responsive .item-table{ width: 100% !important;}
    ul.search-list.applicability-list li{ width: 32%;}
    ul.search-list.hazard-report-list li{ width: 32%;}
    .risk-rating .bnt{ font-size: 13px;}
    .uplode-img input{ width: 86%;}
    .col-hazard-review .table.item-table{ width: 570px !important;}
}
@media(max-width:1100px){
    .risk-rating [class*="col-"]{ padding: 0px 6px;}
    .risk-rating .bnt{ font-size: 12px;}
    ul.hazard-report-list li label{ font-size: 12px;}
}
@media(max-width:767px){
    .table-responsive .table{ width: 900px !important;}
    .ant-tabs .ant-tabs-left-bar{  width: 100%; margin-bottom: 30px;}
    html .ant-layout.ant-layout-has-sider>.ant-layout{ overflow: hidden;}
    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab{ display: inline-block;}
    .ant-tabs .ant-tabs-left-bar, .ant-tabs .ant-tabs-left-content{ border: none;}
    .ant-tabs .ant-tabs-left-content{ padding-left: 10px;}
    .ant-tabs-ink-bar{ display: none !important;}
    ul.search-list li.date-range input + input{ margin-left: 2%;}
}
@media(max-width:700px){
    ul.search-list.hazard-list li{ width: 32%;}
    .toolbox-talk .add-row-swms-bnt.btn-create-toolbox{ float: left;}
    .toolbox-talk{  display: inline-block; width: 100%;}
    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab{ padding: 8px 15px;}
}
@media(max-width:575px){
    .risk-rating [class*="col-"]{ margin-bottom: 15px;}
}
@media(max-width:550px){
    ul.search-list li{ width: 32%;}
    ul.search-list li.date-range{ width: 65% !important;}
    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {padding: 5px 8px;font-size: 12px;}
}
@media(max-width:470px){
    ul.search-list.hazard-list li label{ font-size: 12px;}
    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {padding: 5px 6px;font-size: 11px;}
}
@media(max-width:400px){
    html .main-container{ padding: 20px 15px 20px 15px;}
    ul.search-list li.date-range{ width: 100% !important;}
    ul.search-list.applicability-list li{width: 49%;}
    ul.search-list.hazard-list li{ width: 49%;}
    .ant-tabs .ant-tabs-left-bar{ margin-bottom: 0px;}
    ul.search-list li{ width: 49%;}
    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab{ float: left; width: 50%; text-align: center;}
    ul.search-list.hazard-report-list li {width: 49%;}
    .bottom-save-btn .bnt{ font-size: 12px; height: 30px; line-height: 28px;}
}


/********************************************************************************/
.styled-checkbox {
    position: absolute;
    opacity: 0;
  }
  .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  .styled-checkbox + label:before {
    content: "";
    margin-right:2px;
    margin-bottom:2px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #008572;
  }
  .styled-checkbox:hover + label:before {
    background: #008572;
  }
  /* .styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  } */
  .styled-checkbox:checked + label:before {
    background: #008572;
  }
  .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  .styled-checkbox:checked + label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 7px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
    
  }
  .ant-select-selection--multiple .ant-select-selection__choice{ width: 58px; padding: 0px 10px 0px 0px;}
  .ant-select-selection--multiple .ant-select-selection__choice__content{ font-size: 12px;}
  .ant-select-selection--multiple .ant-select-selection__rendered{ margin-right: 5px;}
  .ant-select-selection--multiple .ant-select-search--inline{ display: none;}
  .ant-select-selection--multiple .ant-select-selection__choice__remove{ right: 0px;}
  html .sf-form .ant-select-selection, html .sf-form .ant-calendar-picker-input{ height: auto; min-height: 30px;}
  html .sf-form .ant-calendar-picker-input{ padding-left: 15px;}
  .multi-field #input-add label{ display: none;}
  html .ant-calendar-picker-clear{ right: 0px; width:30px; opacity: 1;}
  .applicability-list .ad-srch-btn, .toolbox-talk .ad-srch-btn{ padding: 0px 5px 0 10px; background-color: #fff; width: 100%;border: 1px solid #eff0f1;}
 html .sf-form input[type="text"], html .sf-form input[type="number"]{border: 1px solid #eff0f1;}
 html .rdw-editor-main{ background-color: #fff;}
 html .OHSContainer_TextEditor__3g6Jj{ width: 100%;}
 .required_field .ad-srch-btn { background-color: #fff; width: 100%; height: auto; max-width: 100% !important; text-align: left; border: 1px solid #eff0f1; line-height: 22px;}
 html .sf-form.lsico .ant-calendar-picker-input{ padding: 4px 25px 3px 10px;}
 .required_field .ad-srch-btn span{ white-space: break-spaces;}
 html .new-swms-table .ant-table-wrapper table tbody{  white-space:inherit !important;}
 html .ant-table-thead > tr > th, .ant-table-tbody > tr > td{overflow-wrap: break-word;}
 html .timesheet-filter-button i{
    color: #fff;
 }
 html .timesheet-filter-button{ margin-top: 0px;}
 /*******************************************************************************************/
 ul.title-list li{
    text-align: left;
    float: left;
    width: 100%;
 }
 .job-document-page .bg-color{
    padding: 10px 12px;
    width: 100%;
    border-radius: 15px;
    font-size: 14px;
    background-color: #f9f9f9;
    color: #3a3a3a;
 }
 ul.title-list li span{
    /* float: left; */
    font-weight: bold;      
}
 ul.title-list li .form-group{
     margin-bottom: 0px;
 }
 ul.title-list li .form-group label{
    display: none;
 }
 ul.title-list li .sf-form input{
    height: 18px;
    padding: 4px;
    border: none;
    color: #fff;
    background-color: transparent;
 }
 .job-document-page .bg-white{
     background-color: #fff;
     color: #000;
     padding: 10px;
 }
 .job-document-page .bg-white span{
     font-weight: bold;
 }
 ul.task-list{
     margin-bottom: 0px;
     text-align: left;
 }
 .sec-add-staff{}
 .sec-add-staff input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .sec-add-staff .checkbox-right .checkmark {
    left: auto; 
    right: 0px;
    top: 3px;
    background-color: transparent;     
  }
  .sec-add-staff .checkbox-right .container input:checked ~ .checkmark, .sec-add-staff .checkbox-right .container:hover input ~ .checkmark{
    background-color: transparent; 
  }
  .sec-add-staff .checkbox-right  .container .checkmark:after{
       border-color: #03a791;
       width: 8px;
       left: 1px;
       top: 3px;
  }
  .sec-add-staff .checkbox-right .container{
    padding-left: 0px;
    border: 2px solid #e9e9e9;
    border-radius: 10px;
    padding: 3px 12px;
  }
  /* Create a custom checkbox */
  .sec-add-staff .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .sec-add-staff .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .sec-add-staff .container input:checked ~ .checkmark {
    background-color: #03a791;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .sec-add-staff .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .sec-add-staff .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .sec-add-staff .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .sec-add-staff .container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 7px;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ul.checkmark-list li{
       float: left;
       width: 50%;    
  }
  ul.checkmark-list.w-100 li{
    width: 100%;
  }
.sec-add-staff .bg-box{
    display: inline-block;
    width: 100%;
    padding: 15px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
    border-radius: 10px;
    max-height: 250px;
    overflow-y: auto;
  }
  .box-add-staff .card-header{
      padding: 0px;
  }
  .box-add-staff .btn-link{
    font-size: 17px;
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    width: 100%;
    padding: 10px 20px;
}
.box-add-staff .btn-link.collapsed i{
    font-size: 23px;
    top: 2px;
    position: relative;
    margin-right: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.box-add-staff .btn-link i{
    font-size: 23px;
    top: 4px;
    position: relative;
    margin-right: 5px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
