.FiltersLegendWrapper {
  min-width: 40%;
  padding: 5px;
  font-size: 20px;
  /* position: sticky; */
  top: 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px 2px rgb(45 59 72 / 10%);
  /* margin: 0 10px 5px; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}