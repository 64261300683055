.auth-v2-checkbox {
  width: 1em;
  height: 1em;
  margin-top: 3px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 7px;
  /* margin-left: -1.5em; */
  border-radius: 0.25em;
  cursor: pointer;
}

.auth-v2-checkbox:checked {
  background-color: #362148;
  border-color: #362148;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}