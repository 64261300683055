.staff-table .ant-table-small {
  border: none !important;
}

.staff-table .ant-table tbody td,
.staff-table .ant-table thead th {
  font-size: 13px !important;
  border-color: #000 !important;
}

.staff-table .ant-table tbody tr,
.staff-table .ant-table thead tr {
  border: none !important;
}

.staff-table .ant-table th {
  border-bottom: 1px solid !important;
  font-weight: 700;
}

.staff-table .ant-table-selection-column {
  border: none !important;
}

.staff-table .ant-table tbody td {
  border-top: 0px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
  border-right: 1px solid #eaeaea !important;
  padding: 6px;
  vertical-align: middle;
}

.staff-table .ant-table td:first-child {
  border-left: 0px solid;
}

.staff-table .ant-table td:last-child {
  border-right: 0px solid;
}

.staff-table .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: none !important;
}

.staff-table .cu-checkbox {
  height: 20px;
}

.staff-table .ant-table-row-hover td {
  background: transparent !important;
}

.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #C0B4FF !important;
  border-color: #C0B4FF !important;
}

.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #C0B4FF !important;
}

.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #C0B4FF !important;
}

.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox:hover .ant-checkbox-inner,
.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #C0B4FF;
}

.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox-inner {
  border: 1px solid #000;
}

.staff-table .ant-table-column-title .ant-table-selection .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.cs-pagination-v2.mini .ant-pagination-prev,
.cs-pagination-v2.mini .ant-pagination-next {
  margin: 0px 5px !important;
}

.swms-table-v2 thead>tr>th {
  background-color: #fff !important;
  color: #362148;
}