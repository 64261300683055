.ProfileBack {
  background: #F0EFEE;
  text-align: initial;
  padding: 16px;
  border-radius: 45px;
  height: 100%;
}

.ProfileContainer {
  background-color: #fff !important;
  border-radius: 35px;
  font-family: 'Poppins', sans-serif !important;
  height: 100%;
  overflow: hidden auto;
}

.PageHead {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.ProfileLabel {
  font-size: 13px !important;
  color: #362148 !important;
  font-family: 'Poppins', sans-serif !important;
}

.ProfileLabelV2 {
  font-size: 12px !important;
  color: #362148 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  padding-top: 5px;
}


.ProfileFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.BtnSaveTab:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.BtnSaveTab {
  background-color: #C0B4FF;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
}

.BtnSaveTab:disabled {
  background: #ccc !important;
  color: #000 !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

.DetailsDivider {
  background: #F0EFEE;
  height: 1px;
  opacity: 1;
}


.MedicalLabel {
  font-size: 12px !important;
  color: #362148 !important;
  font-family: 'Poppins', sans-serif !important;
  margin: 0 !important;
}

.ModalCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.InputFieldV2 {
  border-radius: 10px !important;
  font-family: 'Poppins', sans-serif !important;
  min-height: 35px !important;
  font-size: 12px !important;
  color: #362148 !important;
  background: #eaeaea !important;
  border: 0 !important;
}

.TabHeading {
  font-size: 12px;
}

.LicenseImageWrapper {
  width: 200px !important;
  height: 150px !important;
  background-color: #eaeaea;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LicenseImage {
  max-width: 200px;
  max-height: 150px;
  object-fit: contain;
}

.BtnResetTab {
  background-color: transparent;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}

.InputFieldV2 {
  border-radius: 10px !important;
  font-family: 'Poppins', sans-serif !important;
  min-height: 35px !important;
  font-size: 13px !important;
  color: #362148 !important;
  background: #eaeaea !important;
  width: 100%;
  border: 0 !important;
  border-radius: 10px !important;
}

.CustomPhone {
  border-radius: 0px 10px 10px 0px !important;
}

.InputFieldV2:focus,
.InputFieldV2:hover {
  border-color: #eaeaea !important;
  color: #212529 !important;
  background-color: #eaeaea !important;
  outline: 0;
  box-shadow: none !important;
}


.signatureImage {
  height: 200px;
  width: 500px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  margin: 5px 0px 10px;
  object-fit: scale-down;
}

.ProfilePic {
  height: 80px;
  width: 80px;
  font-size: 30px;
  color: #fff;
  line-height: 75px;
  text-align: center;
  border-radius: 100%;
  position: relative;
  /* top: 12%;
  right: 2%; */
  border: 1px solid #eaeaea;
}

.ProfilePic img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  float: left;
  object-fit: cover;
}

.BtnFilterTab {
  background-color: #C0B4FF;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
}

.BtnFilterTab:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}


.BtnNextTab {
  background-color: #362148;
  color: #90bf00 !important;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 14px;
  border: 0;
  border: 1px solid #362148;
  font-weight: 500;
  line-height: 1;
}

.BtnNextTab:hover {
  background-color: transparent;
  color: #362148 !important;
  border: 1px solid #362148;
}

.FilterResetButton {
  background: none;
  border: none;
  color: red;
  text-decoration: underline;
  font-size: 12px;
  font-weight: normal;
}


.JobTab {
  display: flex;
  flex-direction: column;
  /* padding: 5px 10px; */
  line-height: 1.5 !important;
  align-items: center;
  font-size: 13px;
  padding: 10px 10px;
  min-width: 100px;
  justify-content: center;
  height: 100px;
  padding: 0px
}

.JobTabIcon {
  height: 30px;
  /* max-width: 28px; */
  margin-bottom: 5px;
  margin-top: 5px;
}

.TabText {
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: break-spaces;
  word-break: break-word;
  width: 80px;
}

/* Buttons */
.PrimaryButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: 'Poppins', sans-serif !important;
}

.PrimaryButton:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.PrimaryButton:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}

.OutlinedButton {
  background-color: initial;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

.OutlinedButton:disabled {
  cursor: not-allowed;
  color: #eee !important;
}
.EditIcon {
  color: #90bf00;
  width: 16px;
}
