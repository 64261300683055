.timesheet-v2-date-picker,
.timesheet-v2-time-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.timesheet-v2-date-picker .ant-input,
.timesheet-v2-time-picker .ant-time-picker-input {
  background-color: #eaeaea;
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 8px !important;
  font-size: 12px;
  padding: 8px !important;
  width: 100% !important;
}

.timesheet-v2-date-picker .ant-input:focus,
.timesheet-v2-time-picker .ant-time-picker-input:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
}

.timesheet-v2-date-picker.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #ddd !important;
}

.timesheet-v2-date-picker .ccalnd-icon,
.timesheet-v2-time-picker .ant-time-picker-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #c0b4ff !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.timesheet-v2-time-picker .ant-time-picker-clock-icon {
  color: #c0b4ff !important;
}

.timesheet-v2-date-picker .ant-calendar-picker-clear,
.timesheet-v2-time-picker .ant-time-picker-clear {
  background-color: #eaeaea;
  right: 30px;
  width: auto;
  background: transparent !important;
}

.timesheet-modal-font * {
  font-family: "Poppins", sans-serif !important;
}