.RfqFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.BtnFilterTab {
  background-color: #C0B4FF;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 12px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
}

.BtnResetTab {
  background-color: transparent;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 12px;
}

.BtnFilterTab:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.ModalCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
