.table.area-table{
    font-size: 14px;
    margin-bottom: 0px;
}
.table.area-table td{
    border-top: none;
    padding: 10px 15px;
}
.table.area-table tr + tr td{
    border-top:1px solid #dee2e6;
}
.table.area-table td + td {
    position:  relative;
}
.table.area-table td + td:after {
    width: 1px;
    height: 70%;
    content: "";
    left: 0px;
    top: 15%;
    position: absolute;
    background-color: #dee2e6;
}
.area-table p{
    margin-bottom: 0px;
}
.area-img{
    width: 125px;
    border-radius: 7px;
}
.table-management-job tr:first-child td:first-child{
    vertical-align: top !important;
    font-weight: bold;
}
.table-management-job.table-border tr:nth-of-type(even), .table-sign-history.table-border tr:nth-of-type(even){
    background-color: transparent;
}
.sec-add-staff .table-management-job  .checkmark {
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #362148;
}
.swms-preview .ant-modal-footer{
    padding-right: 0px;
    padding-left: 0px;
}
/* .sec-add-staff .table-management-job  .container:hover input ~ .checkmark{
    background-color: transparent;
} */
.sec-add-staff .table-management-job  .container input:checked ~ .checkmark{
     border: none;
}

.table-sign-history.table-border th, .table-sign-history.table-border td{
    text-align: center;
}
.table-sign-history .status {
    height: 20px;
    width: 20px;
    background-color: #ddd;
    display: inline-block;
    border-radius: 50px;
    border: 1px solid #3a3a3a;
}
.table-sign-history .status.done{
    background-color: green;
}
.table-sign-history .status.panding{
    background-color: yellow;
}
.table-sign-history td i{
    font-size: 20px;
}
/******************************/
.choose-btn {
    color: #008572;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: .125rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    font-size: 14px;
    font-weight: bold;
    position: relative;
}
.choose-btn  span {
    cursor: pointer;
}
.choose-btn  span i{
    font-size: 14px;
}
.choose-btn  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
}
.choose-img{
    width: 100px;
}
.w-auto{
    width: auto !important;
}
.job-cleaning-report .form-group{
    margin-bottom: 0px;
}
ul.job-summary-list li{
    float: left;
    text-align: left;
    background-color: #f3f3f3;
    padding: 10px;
    font-size: 13px;
    color: #000;
    width: 13%;
    line-height: 21px;
}
ul.job-summary-list{
    display: inline-block;
     width: 100%;
}
ul.job-summary-list li + li{
    margin-left:1%;  
}
ul.job-summary-list li span{
    display: block;
}
.tasks-completed-box{
    text-align: left;
    background-color: #f3f3f3;
    padding: 10px;
    font-size: 13px;
    color: #000;
    line-height: 21px;
}
.tasks-completed-box p{
    margin-bottom: 0px;
}
ul.documents-list{
    display: inline-block;
    width: 100%;
}
ul.documents-list li{
    width: 32.33%;
    margin: 0.5%;
    float: left;
    text-align: left;
}
ul.documents-list li a{
    display: inline-block;
    padding: 13px 20px;
    background-color: #f2f2f2;
    width: 100%;
    border-radius: 5px;
}
ul.documents-list li i{
    float: right;
    color: #000;
    font-size: 20px;
}
ul.documents-list li a:hover i{
    color: #fff;
}
ul.documents-list li a:hover{
    color: #fff;
    background-color: #03a791;
}
.signOff-form{
    text-align: left;
}
.job-document-page .bg-color{
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}
.job-img{
    width: 100%;
    height: 80px;
}
.job-cleaning-imgs span{
    position: absolute;
    left: 15px;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    padding: 5px 10px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
}
.job-cleaning-imgs h3{
    text-align: left;
    font-size: 16px;
     font-weight: 600;
     margin-bottom: 7px;
}
.job-cleaning-imgs p{
    text-align: left;  
    font-size: 15px;
}
.job-cleaning-imgs .row{
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.card{
    margin-bottom: 7px;
}
.box-add-staff .btn-link i{
    float: right;
     font-size: 18px !important;
}
#licencesSlider{
    margin-bottom: 10px;
    padding-bottom: 60px;
    border-bottom: 1px solid #ddd;
}
.after-before-img{
    height:125px;
    width: 100%;
}
#licencesSlider .carousel-item{
    margin: 0px;
}
.slider-nav .carousel-control-prev, .slider-nav .carousel-control-next{
    top: auto;
    bottom: 0px;
}
.slider-nav .carousel-control-prev{
    left: auto;
    right: 45px;
}
.slider-nav .carousel-control-next{
    right: 12px;
}
.bg-job{
    background-color: #03a791;
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
}
.bg-job .bg-white{
    font-size: 14px;
    padding: 5px;
}
.task-list span{
    font-weight: bold;
}
/*****************************************************************/
@media(max-width:1200px){
    
}

.texteditorPadding{
    padding-left: 0 !important;
}










/**************************************My Css***/



/*site specific  dropdwn*/
.ad-srch-btn.ant-popover-open, .ad-srch-btn.ant-popover-open>span {
    background: #03a791 !important;
    color: #fff;
}




/*history dropdwn*/
.select-history{
    float: right;
    position: relative;
    z-index: 999;
    width: 253px;   
}
.select-history button + div{
    top: 30px;
}
.select-history button{
    width: 200px;
   
    background-color:#fff;
    float: right;
    color:grey;
    border-radius: 4px;
    position: relative;
    z-index: 999;
    margin-bottom: 20px;
    margin-right: 3px;
    border: 1px solid #ddd;
    box-shadow: 1px 3px 8px #ddd;
}

.select-history .css-48ayfv{
    width: 200px;
   
    background-color: #03a791 !important;
    float: right;
    color: #fff !important;
    border-radius: 4px;
    position: relative;
    z-index: 999;
    margin-bottom: 20px;
    margin-right: 3px;
    border: 1px solid #ddd;
    box-shadow: 1px 3px 8px #ddd;
}
/*
.css-11unzgr div{
    text-align: left;
    color: grey;
}
.css-11unzgr input{
    width: 20px;
    float: left;
    top: 2px;
    position: relative;
}
*/

.select-history .css-1pcexqc-container {
    padding-top: 5px;
    margin-top: 10px;
    
    position: relative;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px grey;
}


.css-6uszmw-option {
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    background-color: #fff !important;
    -webkit-user-select: none;
user-select: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
box-sizing: border-box;
min-width: 240px;
font-weight: normal !important;
text-align: center;

}
.css-p79tkn-option:hover {
    background-color:#eeeaeac9 !important;
}

/* 
.css-11unzgr div input[type=checkbox]:before {
    font-family: "FontAwesome";
    content: "\F00C";
    font-size: 12px;
  
    font-weight: -34px;
    border-radius: 4px;
    color: transparent !important;
    background: #fff;
    display: block;
    width: 17px;
    height: 17px;
    border: 1px solid #d3d6db;
    margin-right: 7px;
   
}

.css-11unzgr div input[type=checkbox]:checked:before {
    background: #03a791;
    color: rgba(255,255,255, 0.85) !important;
    border-radius: 3px;
    text-align: center;
    width: 18px;
    height: 18px;
}
*/

  .css-p79tkn-option:hover  input[type=checkbox]:before {
    color: rgba(255,255,255, 0.85) !important;
    border: 1px solid #03a791; 
    border-radius: 3px;
    text-align: center;
    width: 18px;
    height: 18px;
}




 .css-6y1x9t-control  {
    outline: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: rgb(185, 183, 183) !important;
    border-radius: 4px;
    border-style: solid !important;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    box-shadow: none !important;
    min-width: 240px;
    margin: 8px;
}
.css-6y1x9t-control :hover{
    border-color: rgb(185, 183, 183) !important;
}
.custom-swms-category-header > .ant-collapse-header {
    padding-bottom: 8px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    font-weight: normal;
    color: #000000A6 !important;
}