.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SWMSList {
  padding: 10px;
  margin: 10px;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.ListItem {
  margin-bottom: 5px;
  background: #efefef;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 2.5px;
  line-height: 1.2rem;
  /* max-width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  letter-spacing: 0.5px;
  display: inline-block;
  width: 150px;
}

.DropArea {
  /* border: 1px solid red; */
  min-height: 100px;
}

.CloseIcon {
  color: red;

}

.CloseIcon:hover {
  cursor: pointer;
}

.Header {
  text-align: "center";
  margin-bottom: "10px";
  font-weight: 600;
  color: "#777";
}