.allocate-date-picker, .allocate-time-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
  font-family: 'Poppins', sans-serif;
}

.allocate-date-picker .ant-input, .allocate-time-picker .ant-time-picker-input {
  background-color: #eaeaea;
  height: 34px;
  border: none;
  border-radius: 8px !important;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.allocate-date-picker .ant-calendar-picker-clear, .allocate-time-picker .ant-time-picker-clear {
  background-color: #eaeaea;
}

.jobs-timepicker-wrapper input {
  padding: 1px 2px !important;
  border: none !important;
  box-shadow: none !important;
  height: 34px !important;
}

.jobs-timepicker-wrapper .react-datepicker-wrapper {
  display: block !important;
}

.jobs-timepicker-wrapper input:focus {
  border-bottom-width: 1px !important;
  border-bottom-color: #c0b4ff !important;
  border-color: #c0b4ff !important;
  box-shadow: none !important;
  outline: none !important;
}


.jobs-form .fa {
  font-family: FontAwesome !important;
  color: #c0b4ff !important;
}