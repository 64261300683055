.DocsContainer {
  font-family: "Poppins", sans-serif !important;
}

.TabsCover {
  border: 1px solid #3621484d;
  padding: 6px;
  border-radius: 8px; 
}

.Title {
  font-size: 13px;
  color: #000 !important;
  user-select: none;
  font-weight: 700;
}

.Content {
  font-size: 13px;
  color: #000 !important;
}

.ReportFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.ModalCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PrimaryButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: 'Poppins', sans-serif !important;
}

.PrimaryButton:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.PrimaryButton:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}

.BtnResetTab {
  background-color: transparent;
  border: none;
  color: #362148 !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 12px;
}