.Background {
  background-color: #E8FF84;
  font-family: 'Poppins', sans-serif !important;
}

.LoginCard {
  background: #ffffff;
  border-radius: 25px;
  font-size: 14px;
  max-width: 600px;
}

.LoginHeading {
  font-size: 28px;
  font-weight: 300;
  margin: 30px auto 20px;
}

.InputFieldV2 {
  border-radius: 10px !important;
  font-family: 'Poppins', sans-serif !important;
  min-height: 60px !important;
  font-size: 18px !important;
  color: #362148 !important;
  background: #F2F2F2 !important;
  border: 0 !important;
  border-radius: 10px !important;
}

.CustomPhone{
  border-radius: 0px 10px 10px 0px !important;
}

.InputFieldV2:focus {
  border-color: #C0B4FF !important;
  box-shadow: 0 0 0 0.18rem #c0b4ff inset !important;
  color: #212529 !important;
  background-color: #fff !important;
  outline: 0;
}

.InputFieldV2:-webkit-autofill,
.InputFieldV2:-webkit-autofill::first-line,
.InputFieldV2:-webkit-autofill:hover,
.InputFieldV2:-webkit-autofill:focus,
.InputFieldV2:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #F2F2F2 inset !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
  color: #362148 !important;
}

.SigninBtn {
  background-color: #C0B4FF;
  color: #362148 !important;
  border-radius: 50px;
  padding: 5px 25px;
  line-height: 30px;
  border: 0;
  font-weight: 500;
  min-height: 60px;
  font-size: 18px;
  width: 100%;
}

.SigninBtn:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.ForgotLink {
  margin: 10px 0;
  color: #90BF00 !important;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
}

.ForgotLink:hover{
  color: #362148 !important;
}

.RememberMe {
  font-size: 14px;
  color: #362148;
}