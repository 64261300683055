.shift_updates p {
  margin-bottom: 0px;
  color: #000;
  font-size: 13px;
}

.shift_box {
  margin: 0px 0px;
  padding: 0px 0px;
  background-color: #fff;
  color: #362148;
}

.shift_heading {
  font-size: 13px !important;
  font-weight: 700;
}

.ShiftDetailBox {
  background: #eaeaea;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow-wrap: anywhere;
}

.InputFieldV2 {
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
  color: #000;
}

.InputFieldV2::placeholder {
  color: #362148 !important;
}

.InputFieldV2:focus {
  box-shadow: 0 0 0 0.05rem #c0b4ff;
  border: 1px solid #ddd;
}

.InputFieldV2:hover {
  box-shadow: none;
  border: 1px solid #ddd;
}
