.default-v2-container {
  text-align: initial;
  font-family: 'Poppins', sans-serif;
}

.cs-table.cs-table-v2 {
  border-collapse: separate;
  border-spacing: 0px 3px;
  margin-bottom: 0 !important;
  table-layout: fixed;
  height: auto;
}

.cs-table.cs-table-v2 tbody td,
.cs-table.cs-table-v2 thead th {
  font-size: 13px !important;
  border-color: #000 !important;
}

.cs-table.cs-table-v2 tbody tr,
.cs-table.cs-table-v2 thead tr {
  height: auto !important;
}

.cs-table.cs-table-v2 th {
  border-bottom: 1px solid !important;
  font-weight: 700;
}

.cs-table.cs-table-v2 tbody td {
  border-top: 0px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
  padding: 6px;
  vertical-align: middle;
}

.cs-table.cs-table-v2 td:first-child {
  border-left: 0px solid;
}

.cs-table.cs-table-v2 td:last-child {
  border-right: 0px solid;
}

.cs-table.cs-table-v2.v2-table-no-last-border tr:last-child td {
  border-bottom: none !important;
}

.cs-pagination-v2 .ant-pagination-item,
.cs-pagination-v2 .ant-pagination-prev>a,
.cs-pagination-v2 .ant-pagination-next>a {
  background: #f9f9f9 !important;
  border-radius: 8px !important;
  color: #000;
}

.cs-pagination-v2 .ant-pagination-item a {
  text-align: center !important;
}

.cs-pagination-v2 .ant-pagination-item-active {
  background: #C0B4FF !important;
  color: #000 !important;
}

.cs-pagination-v2 .ant-pagination-prev:focus .ant-pagination-item-link,
.cs-pagination-v2 .ant-pagination-next:focus .ant-pagination-item-link,
.cs-pagination-v2 .ant-pagination-prev:hover .ant-pagination-item-link,
.cs-pagination-v2 .ant-pagination-next:hover .ant-pagination-item-link,
.cs-pagination-v2 .ant-pagination-item:focus,
.cs-pagination-v2 .ant-pagination-item:hover,
.cs-pagination-v2 .ant-pagination-item-active {
  background: #C0B4FF !important;
  border-color: #C0B4FF !important;
  color: #000;
}

.cs-pagination-v2 .ant-pagination-item-link-icon {
  color: #C0B4FF !important;
}

.cs-dropdown-v2 .ant-dropdown-menu-item {
  color: #000 !important;
}

.cs-dropdown-v2 .ant-dropdown-menu-item:hover {
  background: #e9ecef !important;
}

.cs-dropdown-v2 .ant-dropdown-menu-item:hover {
  color: #000 !important;
}

.cs-dropdown-v2-poppins * {
  font-family: 'Poppins', sans-serif !important;
}

.cs-modal-v2 .ant-modal-content {
  border: 3px solid #362148 !important;
  border-radius: 25px !important;
  padding: 0 !important;
}

.cs-modal-v2 .ant-modal-close-x {
  position: absolute;
  right: 50px;
  bottom: 33%;
  padding: 0;
  background-color: #362148;
  color: #fff;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  line-height: 1 !important;
}

.cs-modal-v2 .ant-modal-content {
  border-radius: 25px;
}

.cs-radio-v2 .ant-radio-inner,
.cs-radio-v2 .ant-radio:hover,
.cs-radio-v2 .ant-radio:focus {
  border-color: #362148 !important;
}

.cs-radio-v2 .ant-radio-checked .ant-radio-inner {
  background-color: #362148 !important;
}

.cs-radio-v2 .ant-radio-inner::after {
  background-color: #fff !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.cs-select-v2 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #C0B4FF !important;
  border-color: #C0B4FF !important;
}

.cs-select-v2.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.cs-select-v2 .ant-checkbox:hover .ant-checkbox-inner,
.cs-select-v2 .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #C0B4FF;
}

.cs-select-v2 .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff !important;
}

.cs-select-v2 .ant-checkbox-inner {
  border: 1px solid #000;
}

.cs-select-v2.ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.cs-select-v2 .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #eaeaea !important;
  border-color: #eaeaea !important;
}

.cs-tabs-v2 .ant-tabs-nav-wrap {
  margin-bottom: 2px;
}

.cs-tabs-v2 .ant-tabs-nav .ant-tabs-tab {
  margin: 0 10px 0 0;
  padding: 5px 0px;
}

.cs-tabs-v2 .ant-tabs-nav .ant-tabs-tab-active span {
  color: #90bf00 !important;
  font-weight: 500;
  background-color: #362148;
  padding: 4px 20px;
  border: 0.1rem solid #362148;
  border-radius: 7px;
  font-size: 13px !important;
}

.cs-tabs-v2 .ant-tabs-nav .ant-tabs-tab-active {
  padding: 4px 0px;
}

.cs-tabs-v2 .ant-tabs-bar {
  border: none !important;
}

.cs-tabs-v2 .ant-tabs-ink-bar {
  display: none !important;
}

.cs-tabs-v2 .ant-tabs-tab span {
  font-size: 13px !important;
  border: 0.1rem solid #362148;
  padding: 4px 20px;
  border-radius: 7px;
  color: #000;
}

.cs-tabs-v2 .ant-tabs-tab:hover {
  color: #90bf00 !important;
}

.cs-collapse-v2 .ant-collapse-header {
  color: #000 !important;
  font-weight: 400 !important;
  background: #eaeaea;
  font-weight: 12px !important;
}

.cs-v2-user-drop-list>li {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: #212529;
}

.cs-v2-user-drop-list>li:hover {
  color: #1e2125 !important;
  background-color: #e9ecef !important;
}

.cs-table-cont.cs-table-cont-v2 tbody td,
.cs-table-cont.cs-table-cont-v2 thead th {
  font-size: 12px !important;
  border-color: #000 !important;
}

.cs-table-cont.cs-table-cont-v2 th {
  border-bottom: 1px solid !important;
  font-weight: 600;
}

.cs-table-cont.cs-table-cont-v2 tbody td {
  border-top: 0px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
  padding: 6px;
  vertical-align: middle;
}

.cs-table-cont.cs-table-cont-v2 td:first-child {
  border-left: 0px solid;
}

.cs-table-cont.cs-table-cont-v2 td:last-child {
  border-right: 0px solid;
}

.cs-v2-nav-link i.anticon.anticon-dashboard,
.cs-v2-nav-link i.anticon.material-icons {
  font-size: 20px !important;
}

.cs-v3-datepicker-dropdown .ant-calendar-today .ant-calendar-date {
  color: #c0b4ff;
  border-color: #c0b4ff;
}

.cs-v3-datepicker-dropdown .ant-calendar-today.ant-calendar-disabled-cell .ant-calendar-date {
  color: #00000040;
  border-color: #f5f5f5;
}

.cs-v3-datepicker-dropdown .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.cs-v3-datepicker-dropdown .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.cs-v3-datepicker-dropdown .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.cs-v3-datepicker-dropdown .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.cs-v3-datepicker-dropdown .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  color: #fff !important;
  background: #c0b4ff;
}

.cs-v3-datepicker-dropdown .ant-calendar-selected-day .ant-calendar-date,
.cs-v3-datepicker-dropdown .ant-calendar-date:hover,
.cs-v3-datepicker-dropdown .ant-calendar-month-panel-month:hover,
.cs-v3-datepicker-dropdown .ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-decade:hover,
.cs-v3-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #c0b4ff33;
}

.cs-v3-datepicker-dropdown .ant-calendar-selected-day .ant-calendar-date {
  background: #c0b4ff;
  color: #fff;
}

.cs-v3-datepicker-dropdown .ant-calendar-in-range-cell::before {
  background: #c0b4ff33;
}

.cs-v3-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background-color: #c0b4ff;
}

/* .cs-v3-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date, */
.cs-v3-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date,
/* .cs-v3-datepicker-dropdown .ant-calendar-active-week .ant-calendar-cell .ant-calendar-date, */
.cs-v3-datepicker-dropdown .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week .ant-calendar-cell .ant-calendar-date {
  color: #fff;
}

.cs-v3-dropdown {
  font-family: 'Poppins', sans-serif;
}

.cs-v3-dropdown .ant-dropdown-menu-item:hover,
.cs-v3-dropdown .ant-dropdown-menu-submenu-title:hover,
.cs-v3-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
  background: #C0B4FF !important;
}

.cs-v3-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover,
.cs-v3-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled {
  background: #ffffff !important;
  color: #cccccc !important;
}

.cs-switch-v3 {
  margin: 0px;
}

.cs-switch-v3.ant-switch-checked {
  background-color: #C0B4FF;
}

.cs-switch-v3:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgb(192 180 255 / 20%);
  box-shadow: 0 0 0 2px rgb(192 180 255 / 20%);
}

.cs-switch-v3.ant-switch::after {
  -webkit-box-shadow: 0 2px 4px 0 rgb(192 180 255 / 20%);
  box-shadow: 0 2px 4px 0 rgb(192 180 255 / 20%);
}

.cs-datepicker-v3,
.cs-timepicker-v3 {
  width: 100% !important;
  background-color: #f9f9f9 !important;
  border-radius: 8px !important;
}

.cs-datepicker-v3 .ant-input,
.cs-timepicker-v3 .ant-time-picker-input {
  background-color: #eaeaea;
  height: 34px;
  border: 1px solid #eaeaea;
  color: #000;
  padding: 8px;
  border-radius: 8px !important;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.cs-datepicker-v3 .ant-input:focus,
.cs-timepicker-v3 .ant-time-picker-input:focus {
  -webkit-box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
}

.cs-datepicker-v3.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #ddd !important;
}

.cs-datepicker-v3 .ccalnd-icon,
.cs-timepicker-v3 .ant-time-picker-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #c0b4ff !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.cs-timepicker-v3 .ant-time-picker-clock-icon {
  color: #c0b4ff !important;
}

.cs-datepicker-v3 .ant-calendar-picker-clear,
.cs-timepicker-v3 .ant-time-picker-clear {
  background-color: #f9f9f9;
  right: 30px;
  width: auto;
  background: transparent !important;
}

.cs-upload-v3.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #c0b4ff;
}

.v3-modal-hr {
  margin: 0.5rem 0 !important;
}

.cs-switch-v2.ant-switch-checked {
  background-color: #c0b4ff !important;
}

.cs-filter-range-picker .ant-input {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
}

.cs-filter-range-picker .ant-input:hover {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
}

.cs-filter-range-picker input::placeholder {
  color: #000 !important;
}

.cs-filter-range-picker input {
  padding: 4px 0px !important;
  text-align: center;
}

.cs-filter-range-picker .ant-calendar-picker-icon {
  color: #36214880 !important;
}

.cs-filter-range-picker .ant-input:focus {
  box-shadow: none !important;
  border-color: #c0b4ff !important;
}

.timesheet-table tr>th:last-child {
  border-right: 1px solid #e8e8e8 !important;
}

.cs-default-table-row .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #C0B4FF !important;
  border-color: #C0B4FF !important;
}


.cs-default-table-row .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #C0B4FF !important;
  border-color: #C0B4FF !important;
}

.cs-default-table-row .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #C0B4FF !important;
}

.cs-default-table-row .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #C0B4FF !important;
}

.cs-default-table-row .ant-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.cs-default-table-row .ant-checkbox:hover .ant-checkbox-inner,
.cs-default-table-row .ant-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #C0B4FF;
}

.cs-default-table-row .ant-checkbox-inner {
  border: 1px solid #000;
}


.timesheet-table .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
.timesheet-table .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
  color: #C0B4FF !important;
}

.v3-form-input-field .ant-input:hover {
  border-color: none !important;
}

.v3-form-input-field .ant-input:focus {
  border-color: #C0B4FF !important;
}

.v3-form-input-field .ant-input:focus {
  box-shadow: none !important;
}

.v3-form-input-field .ant-input {
  background: #eaeaea !important;
  border-color: #eaeaea !important;
  color: #000 !important;
  font-size: 12px !important;
  border-radius: 8px !important;
  height: 35px;
}

.v3-form-input-field .ant-input-prefix {
  color: #000 !important;
  font-size: 12px;
}

.v3-form-input-field .ant-input.ant-input-disabled {
  color: #999999 !important;
  cursor: default !important;
}

.rdw-link-decorator-wrapper a {
  color: #3273f6 !important;
}

.rdw-link-decorator-wrapper a:hover {
  color: #3273f6 !important;
}

.v3-custom-subtask-day,
.rbc-today.v3-custom-subtask-day {
  background: white;
}

.rbc-off-range-bg.v3-custom-subtask-day,
.rbc-today.rbc-off-range-bg.v3-custom-subtask-day {
  background: #e6e6e6;
}

.v3-custom-subtask-non-day,
.rbc-today.v3-custom-subtask-non-day {
  background: #f8f8f8;
}

.rbc-off-range-bg.v3-custom-subtask-non-day,
.rbc-today.rbc-off-range-bg.v3-custom-subtask-non-day {
  background: #e6e6e6;
}