.v2-container *,
.v2-modal-container * {
  font-family: 'Poppins', sans-serif;
  text-align: initial;
}

.v2-container .fa,
.v2-modal-container .fa {
  font-family: FontAwesome !important;
  color: #c0b4ff;
}

.v2-container {
  background-color: #F0EFEE;
  padding: 16px;
  height: calc(100vh - 70px);
  border-radius: 50px;
}

.v2-collapse .ant-collapse-item {
  border: 1px solid #eaeaea !important;
  border-radius: 9px !important;
  padding: 0px !important;
}

.v2-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0px !important;
  border-radius: 8px !important;
}

.v2-collapse .ant-collapse-item .v2-panel-heading {
  border-radius: 8px !important;
  font-weight: normal;
  font-size: 13px;
  color: #362148;
}

.v2-collapse .ant-collapse-item-active .v2-panel-heading {
  border-radius: 8px 8px 0px 0px !important;
}

.v2-collapse .cs-select-v2 span {
  font-weight: normal;
  font-size: 13px;
  font-weight: 400;
  color: #362148;
}

.v2-container a,
.v2-modal-container a {
  color: #3273f6 !important;
}

.v2-container .cs-pagination-v2 a,
.v2-modal-container .cs-pagination-v2 a {
  color: #000 !important
}


.v2-custom-tabs .ant-tabs-tab {
  padding: 0px !important;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #FAFAFA;
  border: none !important;
  border-radius: 10px !important;
  color: #362148 !important;
  font-weight: 400 !important;
  height: auto !important;
  margin: 0px 5px !important;
}

.v2-custom-tabs .ant-tabs-nav-container {
  height: auto !important;
}

.v2-custom-tabs .ant-tabs-tab.ant-tabs-tab-active {
  height: auto !important;
  background: #362148 !important;
  color: #E8FF84 !important;
  font-weight: 400 !important;
}

.v2-custom-tabs .ant-tabs-nav .ant-tabs-tab .anticon {
  margin: 0px !important;
}

.v2-custom-tabs .ant-tabs-bar {
  border-bottom: 0px;
  margin-left: 0px;
}

.cs-range-picker .ant-input {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
  font-size: 13px !important;
  text-align: center !important;
}

.cs-range-picker .ant-input:hover {
  border-color: #ddd !important;
  color: #000 !important;
  border-radius: 10px;
}

.cs-range-picker input::placeholder {
  color: #000 !important;
  text-align: center !important;
}

.cs-range-picker input {
  padding: 4px 0px !important;
}

.cs-range-picker .ant-calendar-picker-icon {
  color: #36214880 !important;
}

.cs-range-picker .ant-input:focus {
  box-shadow: none !important;
}

.cs-range-picker .ant-calendar-range-picker-input {
  text-align: center !important;
}

.subtask-time-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
  font-family: 'Poppins', sans-serif;
}

.ant-input,
.subtask-time-picker .ant-time-picker-input {
  background-color: #eaeaea;
  height: 34px;
  border: none;
  border-radius: 8px !important;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}


.subtask-time-picker .ant-time-picker-input:disabled {
  color: #999999 !important;
}

.ant-calendar-picker-clear,
.subtask-time-picker .ant-time-picker-clear {
  background-color: #eaeaea;
}

.roster-date-picker.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #ddd !important;
}


.subtask-time-picker .ant-time-picker-icon {
  top: 50% !important;
  margin: 0px !important;
  color: #c0b4ff !important;
  transform: translateY(-50%) !important;
  z-index: 0;
}

.subtask-time-picker .ant-time-picker-clock-icon {
  color: #c0b4ff !important;
}


.subtask-time-picker .ant-time-picker-clear {
  background-color: #eaeaea;
  right: 30px;
  width: auto;
  background: transparent !important;
}

.v2-date-picker {
  width: 100% !important;
  background-color: #eaeaea !important;
  border-radius: 8px !important;
}

.v2-date-picker .ant-input {
  background-color: #eaeaea;
  height: 34px;
  border: none;
  border-radius: 8px !important;
  font-size: 12px;
}

.v2-date-picker .ant-calendar-picker-clear {
  background-color: #eaeaea;
}

.v2-date-picker .ant-calendar-selected-day .ant-calendar-date {
  background-color: #c0b4ff !important;
}

.v2-embed-view img {
  /* display: flex; */
  max-height: 70vh;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
}

.v2-collapse-panel .ant-collapse-header {
  padding: 5px 15px 5px 35px !important;
  color: #000 !important;
  font-weight: 400 !important;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
}

/* .v2-collapse-panel .ant-collapse {
  border: 0px !important;
} */

.v2-collapse-panel.v2-collapse-inner-collapse .ant-collapse-item {
  border: 1px solid #d9d9d9;
}

.v2-collapse-panel.v2-child-collapse .ant-collapse-header {
  padding: 5px 15px 5px 35px !important;
  color: #000 !important;
  font-weight: 400 !important;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
}

.v2-collapse-panel.v2-child-collapse .ant-collapse {
  border: 0px !important;
}

.v2-collapse-panel.v2-child-collapse .ant-collapse {
  border: 0px !important;
}

.v2-child-collapse {
  border: 1px solid #d0d0d0 Im !important;
}

.v3-date-picker input {
  padding: 4px 10px !important;
  font-size: 13px;
  text-align: center;
}

.v3-date-picker input::placeholder {
  color: #362148 !important;
}

.v3-date-picker input {
  padding: 4px 10px !important;
}

.v3-date-picker .ant-calendar-picker-icon {
  color: #36214880 !important;
}

.v3-date-picker .ant-input:focus {
  box-shadow: none !important;
}

.v3-date-picker .ant-calendar-range-picker-input:placeholder-shown {
  text-align: center;
}

.v3-date-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.05rem #c0b4ff !important;
  box-shadow: 0 0 0 0.05rem #c0b4ff !important;
}