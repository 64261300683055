.RowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.Delete {
  margin-top: -5px;
}

.LabelGreen {
  color: #c0b4ff !important;
}

.JobsFormLabel {
  font-size: 12px;
  color: #362148;
}

.TabsCover {
  border: 1px solid #3621484d;
  padding: 6px;
  border-radius: 8px; 
}

.JobsFormField {
  background: #EAEAEA;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 35px !important;
}

.RfqFormField:disabled {
  color: #999999 !important;
}

.TableCell {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.BlankCell {
  color: #bebebe;
}

.Table {
  border: none;
  /* border-collapse: collapse; */
}

.Table>thead>tr, .Table>tbody>tr {
  /* border: 1px solid #ddd; */
}

.Table>thead>tr>th {
  font-weight: 700;
}

.Table>thead>tr>th, .Table>tbody>tr>td {
  /* border: 1px solid #ddd; */
  padding: 5px !important;
  font-size: 14px;
  color: #000;
}

.TableHeading {
  color: #2b689c;
  font-weight: 700;
  background-color: #f1f1f1;
  border-color: #ebeff2;
  padding: 10px 15px;
  border-top: 1px solid #ebeff2;
}

.TableButton {
  background: #fff;
  border-color: #fff;
  padding: 0px;
}

.Table label {
  color: #808080;
}

.Table>tbody>tr>td input {
  /* border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  padding: 8px;
  height: 2rem !important;
  color: black; */
}

.Table>tbody>tr>td input:focus, .Table>tbody>tr>td input:focus-visible {
  /* border: none; */
  /* outline: none; */
}

.CenterCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.StaffTableWrapper ul.ant-pagination.ant-table-pagination {
  margin: 5px !important;
}

.AddNote input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  padding: 2px;
}

.AddNote label {
  font-size: 15px;
  margin-bottom: 2px;
}

.AddNote input:focus, .AddNote input:focus-visible {
  outline: none;
  border-bottom: 1px solid #03A791 !important;
}

.SchedulesWrapper {
  margin-bottom: 10px;
}

.StaffTableColumn {
  color: #000 !important;
  border: none !important;
  vertical-align: middle;
}

.SchedulesInputField:focus{
  border-bottom: 1px solid #20b39a !important;
}

.collapseBorder {
  border: 1px solid #ccc !important;
}

.BorderPadd {
  padding: 10px !important;
  padding-bottom: 0px !important;
}

.CollapseMar {
  margin-bottom: 10px !important;
}

.SingleLine {
  border-width: 0px;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  border-radius: 0px;
  padding: 1px 2px;
  height: 22px;
  color: #000;
  font-size: 14px;
  outline: none;
  width: -webkit-fill-available;
}

.SingleLine:hover {
  border-width: none !important;
  border-right-width: 0px !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #135f9b;
  box-shadow: none;
  outline: none;
}

.SingleLine::placeholder {
  color: #999;
}

.SingleLine:focus {
  box-shadow: none !important;
  border-right-width: 0px !important;
  outline: none;
  border-bottom-color: #135f9b;
}

.SingleLine[disabled] {
  color: #999999 !important;
}

.SingleLabel {
  color: #135f9b;
}