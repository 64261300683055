.myChipInput .css-2b097c-container .css-yk16xz-control {
    overflow-y: auto !important;
    height: 170px;
    min-height: 170px;
    max-height: 170px;
    width: 100%;
    display: inline-block;
}

.myChipInput .css-1pahdxg-control {
    overflow-y: auto !important;
    max-height: 170px !important;
    min-height: 170px;
    width: 100%;
    box-shadow: none;
    border-color: hsl(0, 0%, 80%);
    display: inline-block;
}

.myChipInput .css-1pahdxg-control:focus,
.myChipInput .css-1pahdxg-control:hover {
    box-shadow: none;
    outline: none;
    border-color: hsl(0, 0%, 80%);
}

.myChipInput .css-2b097c-container .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.myChipInput .css-2b097c-container .css-1wy0on6 {
    display: none !important;
}

.myChipInput input[type="text"]:focus,
.myChipInput input[type="name"]:focus,
textarea:focus {
    box-shadow: none !important;

}

.myChipInput .css-1pahdxg-control .css-2613qy-menu {

    display: none !important;
}

.myChipInput .css-1rhbuit-multiValue {
    border-radius: 12px !important;
}

.css-xb97g8:hover {
    border-radius: 0px 12px 12px 0px !important;
}

.myChipInput .css-b8ldur-Input>div {
    width: 104px;
    overflow: hidden;
}



/* for email input */
.myEmailInput .css-2b097c-container .css-yk16xz-control {
    overflow-y: auto !important;

}

.myEmailInput .css-b8ldur-Input>div {

    overflow: hidden;
}

.myEmailInput .css-1pahdxg-control {
    overflow-y: auto !important;

    box-shadow: none;
    border-color: hsl(0, 0%, 80%);
}

.myEmailInput .css-1pahdxg-control:focus,
.myEmailInput .css-1pahdxg-control:hover {
    box-shadow: none;
    outline: none;
    border-color: hsl(0, 0%, 80%);
}

.myEmailInput .css-2b097c-container .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.css-2613qy-menu {
    display: none !important;
    opacity: 0px !important;
    visibility: hidden !important;
}

.myEmailInput .css-2b097c-container .css-1wy0on6 {
    display: none !important;
}

.myEmailInput input[type="text"]:focus,
.myEmailInput input[type="name"]:focus,
textarea:focus {
    box-shadow: none !important;

}

.myEmailInput .css-1pahdxg-control .css-2613qy-menu {

    display: none !important;
}

.myEmailInput .css-1rhbuit-multiValue {
    border-radius: 12px !important;
}