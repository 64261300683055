.quote-preview-table .cs-table tbody td {
  border: 0px !important;
}

.quote-preview-table .cs-table {
  border-spacing: 0px !important;
}

.quote-task-conditions p {
  font-weight: 400;
  margin: 0px;
}

.quote-task-conditions ul,
.quote-task-conditions ol {
  margin: 0 0 0 20px;
  font-weight: 400 !important;
}

.quote-task-conditions ul > li {
  list-style: disc !important;
}

.quote-task-conditions ol > li {
  list-style: auto !important;
}

.quote-icons-alignment {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-right: 40px;
}

.quote-preview-icons {
  font-size: 15px !important;
  color: #2aa3dd !important;
}

.pdf-head {
  background: #2aa3dd;
  color: #fff;
  border-radius: 6px 0 0 6px !important;
  text-align: center !important;
  padding: 5px 0;
  font-weight: 600;
  font-size: 26px;
  margin: -39px;
}
.preview-header {
  font-weight:600;
  font-size: 13px !important;
  margin-bottom: 0px !important;
}

.quote-details-column {
  text-align: right !important;
  border-right: none !important;
}
.preview-headline {
  background-color: #595959 !important;
  border-color: #595959 !important;
}
.quote-preview-back {
  background-color: #e1e1e1 !important;
  padding: 4px 5px;
}
.quote-icons-alignment {
  column-gap: 10px;
}
.conditions-text {
  font-size: 13px !important;
}
.content-line-height * {
  line-height: 1.2 !important;
}
thead {
  display: table-header-group;
  break-inside: avoid;
}
tbody {
  break-inside: avoid-page;
}
.image-button {
  padding: 7px 15px;
  background: #ca4c40;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  border: none;
}
.quote_divider {
  height: 1px;
  background: #000;
  opacity: 1;
}
.total-preview-back {
  background-color: #d7edf6 !important;
  padding: 4px 5px;
}

.comments-section {
  background: #edeff0;
  padding: 5px;
}

.quote-preview-table-v2 .doc-quote-table-v2 td,
.doc-quote-table-v2 th {
  border: none !important;
  border-spacing: 0px !important;
  padding: 12px;
}

.doc-quote-table-v2 {
  table-layout: fixed;
  width: 100%;
}

.quote-preview-table-v2 .doc-quote-table-v2 .quote-preview-row > td {
  border: none !important;
}
.quotes-table-header-v2 {
  background-color: #d7edf6;
  border: none;
  padding: 10px 0px;
  font-weight: 600;
  border-radius: 5px;
}

.quote-preview-table .doc-quote-table td,
.doc-quote-table th {
  border-right: 1px solid #000 !important;
}
.quote-preview-table .doc-quote-table td,
.doc-quote-table th {
  border-spacing: 0px !important;
}
.quote-preview-table .doc-quote-table .quote-preview-row > td {
  border-top: 1px solid #000 !important;
  height: 40px;
}
.doc-quote-table {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}
.doc-quote-table td,
.doc-quote-table th {
  padding: 5px;
  color: rgb(0, 0, 0);
  vertical-align: top;
  font-size: 13px !important;
}
.doc-quote-table th {
  font-weight: 600;
}

.quotes-table-header {
  border-bottom: 1px solid #000;
}

.total-preview-back {
  background-color: #d7edf6 !important;
  padding: 4px 5px;
}

.bolded-text {
  font-weight: 600;
}

.special-notes-content {
  padding: 18px 18px;
  border: 1px solid #000;
  border-radius: 20px;
  margin: 10px 0;
}

.quote-info-bottom {
  border-bottom: 1px solid #000 !important;
}

.quote-info-right {
  border-right: 1px solid #000 !important;
  padding-top: 3px;
}

.eod-sec{
  background-color: #edeff0;
}