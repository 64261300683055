.PrimaryButton {
  background-color: #c0b4ff;
  color: #362148 !important;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  height: auto;
  line-height: 1 !important;
  font-family: 'Poppins', sans-serif !important;
}

.PrimaryButton:hover {
  color: #fff !important;
  background-color: #362148;
  border-color: #362148;
}

.PrimaryButton:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  color: #eee !important;
}


.UploadBox {
  display: flex;
  padding: 4px;
  border-radius: 3px;
  align-items: center;
}

.UploadImageWrapper {
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
}

.UploadImage {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.UploadNameWrapper {
  flex: 1;
  min-width: 0;
}

.UploadName {
  /* display: block;
  overflow: auto;
  direction: rtl; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AttachemntIcons {
  margin-left: auto;
  flex: 0 0 32px;
}

.UploadErrorBox {
  padding-left: 10px;
  color: red;
  border-top: 1px solid #f2f2f2;
}

.UploadPhoto {
  max-height: 40px;
  max-width: 40px;
  object-fit: scale-down;
  margin: auto;
  display: block;
}